import {
    useCallback, useContext, useEffect, useState
} from 'react'
import {
    object, string, number, array, bool, func
} from 'prop-types'
import { BrPageContext } from '@bloomreach/react-sdk'
import { useAddToCart } from '@bloomreach/connector-components-react'
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import LoginContext from '../../Account/Login/LoginContext'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import StockVerificationDialog from '../../StockVerification/StockVerificationDialog'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import { GoogleEnhancedEcommerce } from '../../ThirdPartyScripts/GoogleEnhancedEcommerce'
import Link from '../../../_Elements/Link'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'

const AddToCartButton = ({
    item, itemId, cartId, quantity, sizeList, warehouse, distributionChannel, storeKey, disabled, setSuccessMessage
}) => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const translations = useTranslation()
    const cfg = usePageCfg()
    const { setCartId } = useContext(LoginContext)
    const { pushAddToCart, prepareProductForDataLayer } = useContext(GoogleEnhancedEcommerce)
    // Read user location from cookies for warehouse assignment
    // warehouseMap.js

    const { pushCartActivity } = useContext(SFMCCollectJs)

    const [addToCart, result] = useAddToCart({
        cartId,
        storeKey,
        distributionChannel
    })

    const [openModal, setOpenModal] = useState(false)
    const [customAttrs] = useState([])
    const [itemsToVerify, setItemsToVerify] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [verificationDialog, setVerificationDialog] = useState({ open: false, items: [itemId] })

    const availableSizes = sizeList?.filter(({ quantity: q }) => q)

    useEffect(() => {
        const [sku, size] = itemId.code.split('-')

        setItemsToVerify([{ sku, size }])
    }, [itemId])

    const handleCloseVerificationDialog = () => {
        setVerificationDialog(false)
    }

    const handleCloseModal = useCallback((e, reason) => {
        if (!(reason || reason === 'backdropClick')) {
            setOpenModal(false)
        }

        setSuccessMessage(null)
    }, [setOpenModal])

    const handleOnClick = async () => {
        try {
            setIsLoading(true)
            const response = await cfg.httpClient.post(`${cfg.cmsRestserviceUrl}/stock`, {
                warehouse,
                productStockDataList: itemsToVerify
            })

            const unavailable = response?.data[0].quantity < 1 ? itemsToVerify[0] : null
            if (!unavailable) {
                const addToCartResponse = await addToCart({
                    itemId,
                    quantity,
                    customAttrs
                })

                if (addToCartResponse?.success) {
                    const { sku: id = '', size: variant = '' } = itemsToVerify[0]

                    const product = prepareProductForDataLayer(
                        item, {
                            id,
                            variant,
                            quantity: 1
                        }
                    )
                    pushAddToCart(product)
                    pushCartActivity(addToCartResponse.cart.entries)
                    setOpenModal(true)

                    setSuccessMessage(addToCartResponse?.message)
                }
            } else {
                setVerificationDialog({ open: true, items: [itemId] })
            }
            setIsLoading(false)
        } catch (e) {
            console.error('error', e)
        }
    }

    useEffect(() => {
        if (result?.cart && setCartId) {
            setCartId(result.cart.id)
        }
    }, [result, setCartId])

    return (
        <div
            className={classes.addToCartButton}
        >
            <BaseButton
                type='button'
                onClick={handleOnClick}
                fullWidth
                loading={isLoading || !availableSizes}
                disabled={disabled || !availableSizes || (sizeList.length > 0 && availableSizes.length === 0)}
                disabledText={translations('product:unavailable')}
                data-qa='button-productDetail-addToCart'
            >
                {translations('product:addToCart')}
            </BaseButton>

            <StockVerificationDialog
                open={verificationDialog.open}
                onClose={handleCloseVerificationDialog}
                origin='AddToCart'
                item={item}
                productsToDelete={verificationDialog.items}
            />
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
            >
                <DialogTitle>
                    <Typography>{translations('product:addToCart.modal.title')}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component='div'>
                        <Typography component='p' variant='h6'>
                            {translations('product:addToCart.modal.text')}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <BaseButton
                        type='button'
                        variant='contained'
                        onClick={handleCloseModal}
                    >
                        {translations('product:addToCart.modal.continueShopping')}
                    </BaseButton>
                    <BaseButton
                        variant='contained'
                        component={Link}
                        href={page.getUrl('/cart')}
                    >
                        {translations('product:addToCart.modal.proceedToCart')}
                    </BaseButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

AddToCartButton.propTypes = {
    itemId: object.isRequired,
    item: object.isRequired,
    cartId: string,
    quantity: number,
    sizeList: array,
    warehouse: string,
    storeKey: string,
    distributionChannel: string,
    disabled: bool,
    setSuccessMessage: func
}

AddToCartButton.defaultProps = {
    cartId: undefined,
    quantity: 1,
    sizeList: [],
    warehouse: undefined,
    storeKey: undefined,
    distributionChannel: undefined,
    disabled: true,
    setSuccessMessage: () => {}
}

export default AddToCartButton
