import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        overflow: 'hidden'
    },
    grid: {
        flexGrow: 1,
        justifyContent: 'center'
    },
    subHeadline: {
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '1px',
        lineHeight: '32.5px',
        paddingBottom: theme.spacing(),
        fontSize: 18,
        color: 'black',
        fontWeight: '400',
        textAlign: 'center'
    },
    headline: {
        paddingBottom: theme.spacing(6),
        fontSize: 24,
        color: 'black',
        fontWeight: '500',
        textAlign: 'center'
    },
    article: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2)
        }
    },
    imgContainer: {
        position: 'relative',
        justifyContent: 'center',
        display: 'flex'
    },
    whiteBox: {
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column'
    },
    fillerBox: {
        flexGrow: 1
    }
}))
