import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(5)
    },
    copyTerms: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    copyrightWrapper: {
        width: '100%',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2, 2, 0, 2)
    },
    termsWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2, 2, 0, 2)
    },
    socialWrapper: {
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2, 2, 0, 2),
        justifyContent: 'center'
    }
}))
