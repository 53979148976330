import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    list: {
        width: 320,
        padding: theme.spacing(2),

        '& > *': {
            marginBottom: theme.spacing(2)
        }
    }
}))
