const templatesDataLayerMap = {
    homepage: 'homepage',
    man: 'Product Category',
    woman: 'Product Category',
    productsearch: 'Product Category',
    productdetail: 'Product',
    login: 'Form',
    register: 'Form',
    'account-profile': 'Form',
    'account-change-password': 'Form',
    'account-address': 'Form',
    checkout: 'Checkout',
    cart: 'Basket',
    purchase: 'Purchase'
}

export default templatesDataLayerMap
