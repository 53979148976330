import { InputLabel, MenuItem, Select } from '@material-ui/core'
import { func, string } from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const StyledInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        color: theme.palette.primary.main,
        fontSize: 14,
        letterSpacing: '0.05em',
        fontWeight: 700,
        fontFamily: 'DM Sans',
        '&:focus': {
            backgroundColor: theme.palette.secondary.main
        }
    }
}))(InputBase)

const ProductSort = ({ sortFields, setSortFields }) => {
    const translations = useTranslation()
    const classes = useStyles()

    const sortingOptions = [
        { label: `${translations('product:sort.default')}`, value: '-variants.attributes.sort-order' },
        { label: `${translations('product:sort.price.asc')}`, value: 'listPrice' },
        { label: `${translations('product:sort.price.desc')}`, value: '-listPrice' }
    ]

    return (
        <div className={classes.selectContainer}>
            <InputLabel htmlFor='sortBy' className={classes.inputLabel}>
                {translations('product:sort.by')}
            </InputLabel>
            <Select
                label={translations('product:sort.by')}
                defaultValue='-variants.attributes.sort-order'
                value={sortFields ?? '-variants.attributes.sort-order'}
                onChange={({ target }) => setSortFields(target.value)}
                color='primary'
                id='sortBy'
                input={<StyledInput />}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.select }
                }}
            >
                {sortingOptions?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>

                ))}
            </Select>
        </div>
    )
}

ProductSort.propTypes = {
    sortFields: string.isRequired,
    setSortFields: func.isRequired
}

export default ProductSort
