import PropTypes from 'prop-types'
import { usePageCfg } from '../PageConfig/PageCfg'
import ArrowBack from '../../../lib/icons/arrow_back.svg'
import ArrowForward from '../../../lib/icons/arrow_forward.svg'
import useStyles from './style'
import useTranslation from '../ResourceBundles/useTranslation'

export const Pagination = (props) => {
    const { pagination } = usePageCfg()
    const classes = useStyles()
    const translations = useTranslation()

    const setNewPage = (newPage) => {
        const checkPagination = /page=\d+/.test(window.location.search)
        if (checkPagination) {
            window.location.href = window.location.href.replace(/page=.*?(?:#(\w+))?$/, `page=${pagination + newPage + "#idSort"}`)
        } else {
            if (window.location.search) {
                window.location.href = window.location.href + `&page=${pagination + newPage + "#idSort"}`
            } else {
                window.location.href = window.location.href + `?page=${pagination + newPage + "#idSort"}`
            }
        }
    }

    return (
        <div className={classes.paginationContainer}>
            <button
                onClick={() => setNewPage(-1)}
                className={`${classes.button} ${pagination === 1 ? classes.disabled : ''}`}
                disabled={pagination === 1}>
                    <ArrowBack />
            </button>
            <span className={classes.paginationSpans}>{translations('product:page')} {pagination} {translations('product:of')} {props.pages}</span>
            <button
                onClick={() => setNewPage(1)}
                className={`${classes.button} ${pagination >= props.pages ? classes.disabled : ''}`}
                disabled={pagination >= props.pages}>
                    <ArrowForward />
            </button>
        </div>
    )
}

Pagination.propTypes = {
    pages: PropTypes.number
}