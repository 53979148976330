import { SvgIcon } from '@material-ui/core'

// eslint-disable-next-line react/prop-types
const Favorite = (props, { stroke }) => (
    <SvgIcon
        viewBox='0 0 27 25'
        width='27'
        height='25'
        {...props}
    >
        <path
            d='M12.9488 4.68766L13.3744 5.42671L13.8114 4.69438C15.1276 2.48908 17.3139 1.5 19.3962 1.5C22.4643 1.5 25.3873 3.57174 25.5 7.52748C25.4973 9.76424 24.1616 12.1023 21.89 14.7692C20.1626 16.7972 17.9529 18.951 15.5034 21.3386C14.8511 21.9744 14.1818 22.6268 13.5 23.2978C12.8182 22.6268 12.1489 21.9744 11.4966 21.3386C9.0471 18.951 6.8374 16.7972 5.11 14.7692C2.83607 12.0996 1.5 9.75945 1.5 7.52062C1.5 5.52053 2.24157 4.0273 3.34069 3.0301C4.44757 2.02585 5.94514 1.5 7.48585 1.5C9.55816 1.5 11.746 2.59901 12.9488 4.68766Z'
            stroke={stroke}
            fill='none'
            strokeMiterlimit='10'
            strokeLinecap='round'
        />

    </SvgIcon>

)

export default Favorite
