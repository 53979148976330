import { useContext, useMemo } from 'react'
import {
    func, node, number, object
} from 'prop-types'

import { BrPageContext, BrComponentContext } from '@bloomreach/react-sdk'

import Link from '../../_Elements/Link'
import { GoogleEnhancedEcommerce } from '../ThirdPartyScripts/GoogleEnhancedEcommerce'

const ProductLink = ({
    item, children, position, onClick
}) => {
    const page = useContext(BrPageContext)
    const {
        name: pageTemplateKey = ''
    } = page.root.model

    const component = useContext(BrComponentContext)
    const {
        name: componentTemplate = ''
    } = component.model

    const { pushProductClick, prepareProductForDataLayer, getPageTemplate } = useContext(GoogleEnhancedEcommerce)

    const pageIdentifier = `${getPageTemplate(pageTemplateKey)}/${componentTemplate}`

    const url = useMemo(() => `/products/${item?.mainItem?.slug ?? item?.slug}`, [item])

    const onLinkClickHandler = () => {
        if (item) {
            const clickedProduct = prepareProductForDataLayer(
                item,
                { position: position === undefined ? '' : position + 1 }
            )

            pushProductClick(clickedProduct, pageIdentifier)

            onClick?.()
        }
    }

    if (!url) {
        return (children)
    }

    return (
        <Link href={page.getUrl(url)} onClick={onLinkClickHandler}>
            {children}
        </Link>
    )
}

ProductLink.propTypes = {
    item: object,
    children: node,
    position: number,
    onClick: func
}

ProductLink.defaultProps = {
    item: null,
    children: null,
    position: undefined,
    onClick: undefined
}

export default ProductLink
