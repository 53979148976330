import {
    useCallback,
    useState
} from 'react'
import { func } from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '../../../../lib/icons/close.svg'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import AddAddress from '../../Account/AddAddress/AddAddress'
import useStyles from './style'

const ChangeAddressButton = ({ setAddress }) => {
    const classes = useStyles()
    const translations = useTranslation()
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)

    const onSubmit = useCallback((address) => {
        setShowModal(false)
        setAddress(address)
    }, [setShowModal, setAddress])

    return (
        <>
            <BaseButton
                size='small'
                type='button'
                onClick={handleShow}
                disableElevation
            >
                {translations('checkout:change.address.button')}
            </BaseButton>
            <Dialog open={showModal} onClose={handleClose} aria-labelledby='form-dialog-title'>
                <DialogTitle disableTypography id='form-dialog-title' className={classes.dialogTitle}>
                    <Typography variant='h6'>
                        {translations('forms:change.address.title')}
                    </Typography>
                    <IconButton aria-label='close' onClick={handleClose} color='primary'>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <AddAddress onSubmit={onSubmit} disableEmail />
                </DialogContent>
            </Dialog>
        </>

    )
}

ChangeAddressButton.propTypes = {
    setAddress: func
}

ChangeAddressButton.defaultProps = {
    setAddress: undefined
}

export default ChangeAddressButton
