import axios from 'axios'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig();

class LoginUtils {

    static async checkCartWithAuth(cartId, username, password, storeKey, setCartId) {
        if (cartId && username && password) {
            axios.defaults.withCredentials = false
            axios.post(`${publicRuntimeConfig.commerceToolsAuthBaseUrl}/oauth/${publicRuntimeConfig.commerceToolsProjectKey}/customers/token?grant_type=password&username=`+username+`&password=`+password, null, { auth : {
                username: publicRuntimeConfig.commerceToolsClientId,
                password: publicRuntimeConfig.commerceToolsClientSecret
            }})
            .then((response) => {
                this.checkCart(cartId, response.data.access_token, storeKey, setCartId)
            }, (error) => {
                console.log(error);
            });
        }
    }

    static async checkCart(cartId, accessToken, storeKey, setCartId) {
        if (cartId) {
            axios.defaults.withCredentials = false
            axios.get(`${publicRuntimeConfig.commerceToolsApiBaseUrl}/me/carts/` + cartId, { headers : {
                Authorization: `Bearer ` + accessToken
            }})
            .then((response) => {
                if (response.data.store.key != storeKey) {
                    setCartId(undefined)
                }
            }, (error) => {
                console.log(error);
            });
        }
    }

}

export default LoginUtils