import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { node } from 'prop-types'

const Protected = ({ fallback, children }) => {
    const [user] = useCurrentCustomer()

    if (!user) return null
    if (user.anonymous) return fallback

    return children
}

Protected.propTypes = {
    fallback: node,
    children: node
}

Protected.defaultProps = {
    fallback: null,
    children: null
}

export default Protected
