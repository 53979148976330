import { useCallback, useContext } from 'react'
import { Box, Dialog, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import LoginContext from '../Login/LoginContext'
import useStyles from './style'
import { formatSimpleBbcode } from '../../../../lib/utils'

const WelcomePageDialog = ({
    open,
    setOpen
}) => {
    const classes = useStyles()
    const { user } = useContext(LoginContext)
    const translations = useTranslation()
    const { currentCustomer } = user ?? {}

    const { cmsBaseUrl } = usePageCfg()
    const imagePath = `${cmsBaseUrl}/binaries/content/gallery/baldininibrxm/welcome_popup.jpg/welcome_popup.jpg/hippogallery:original`

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    return (
        <Dialog open={open}
                fullWidth='true'
                maxWidth='md'>
            <Grid container>
                <Grid item xs={0} sm={6}>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, p: 1 }} style={{ height: '100%' }}>
                        <div style={{
                            height: '100%',
                            backgroundImage: `url(${imagePath})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}></div>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction='column'>
                        <Grid item>
                            <DialogTitle style={{ display: 'flex', justifyContent: 'end', padding: '.5rem'}}>
                                <IconButton onClick={handleClose}>
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <Box sx={{ p: 1, minHeight: {sm:'50vh'} }}>
                                <div style={{ padding: '10px'}}>
                                    <h3 style={{ marginTop: '0', marginBottom: '0' }}>
                                        {translations('welcome.popup.title')} {currentCustomer?.firstName  ?? ''} {currentCustomer?.lastName  ?? ''},
                                    </h3>
                                    <p style={{ marginTop: '0' }}>
                                        {formatSimpleBbcode(translations('welcome.popup.description'))}
                                    </p>
                                    {
                                        (!currentCustomer?.salutation || !currentCustomer?.dateOfBirth) &&
                                        <div style={{ marginBottom: '50', marginTop: '8' }}>
                                            <p>{formatSimpleBbcode(translations('welcome.popup.complete.profile'))}
                                                <br/>
                                                <a className={classes.atag} href='/account'>{translations('welcome.popup.profile')}</a>
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div style={{ marginTop: '50', padding: '10px' }}>
                                    <span className={classes.btnLink}
                                            onClick={handleClose}>
                                        {translations('welcome.popup.back')}
                                    </span>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Box sx={{ p: 1, height: {xs:'25vh'}, display: { xs: 'block', sm: 'none' }}}>
                                <div style={{
                                    height: '100%',
                                    backgroundImage: `url(${imagePath})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}></div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default WelcomePageDialog