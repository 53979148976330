import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React, { useCallback, useContext, useEffect } from 'react'
import { useCart, useOrder, useRemoveCartDiscount } from '@bloomreach/connector-components-react'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import CircularProgress from '@material-ui/core/CircularProgress'
import useTranslation from '../../../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import LoginContext from '../../../../Account/Login/LoginContext'
import useTrackPurchase from '../../../../../_Hooks/HeroTracker/useTrackPurchase'
import { COOKIE_LOCATION } from '../../../../../_Mappings/cookies'
import storeKeys from '../../../../../_Mappings/storeKeys'
import BaseButton from '../../../../../_Elements/Inputs/Button/BaseButton'
import useGoogleTrackPurchase from '../../../../../_Hooks/GoogleAnalytics/useGoogleTrackPurchase'
import CheckOutContext from '../../../CheckOutContext'
import useRemoveCartItems from '../../../../../_Hooks/useClearCart'
import { SFMCCollectJs } from '../../../../ThirdPartyScripts/SFMCCollectJs'

const open = true

const OrderSuccess = () => {
    const classes = useStyles()
    const translations = useTranslation()
    const removeCartItems = useRemoveCartItems()

    // Store
    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}

    // Router
    const { query, push } = useRouter()

    // Cart
    const { cartId } = useContext(LoginContext)
    const [cart] = useCart({ cartId, storeKey })
    const [removeCartDiscount] = useRemoveCartDiscount({ cartId })
    const { trackPurchase } = useContext(SFMCCollectJs)

    // Order
    const { orderId } = useContext(CheckOutContext)
    const [order, orderLoading] = useOrder({ orderId: query?.orderId ?? orderId })

    // Reset CartID
    useEffect(() => {
        if (cart && order && cart.id !== order.id) {
            (async () => {
                await removeCartItems()
                await removeCartDiscount({
                    discountCodes:
                        cart.discounts
                            ?.filter((d) => d.type === 'Promotion')
                            .map((discount) => discount.id)
                })
            })()
        }
    }, [cart, order])

    // Dialog
    const onClose = useCallback(() => {
        push('/')
    }, [])
    useTrackPurchase(cart, order)
    useGoogleTrackPurchase(cart, order)
    useEffect(() => {
        if(cart && cart.entries){
            trackPurchase(cart.entries)
        }
    }, [cart])

    if (orderLoading) return <CircularProgress />

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle disableTypography className={classes.titleWithClose}>
                <Typography variant='subtitle2'>
                    {translations('checkout:order.success.title')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component='div'>
                    <Typography className={classes.text} component='p' variant='h6'>
                        {`${translations('checkout:order.success.order.number')}: ${order?.orderNumber}`}
                    </Typography>
                    <Typography className={classes.text} component='p' variant='h6'>
                        {translations('checkout:order.success.message')}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <BaseButton
                    onClick={onClose}
                    color='primary'
                    data-qa='button-orderSuccess-backToHomepage'
                >
                    {translations('checkout:back.to.home.button')}
                </BaseButton>
            </DialogActions>
        </Dialog>
    )
}

export default OrderSuccess
