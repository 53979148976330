import { useCallback, useContext, useMemo } from 'react'
import storeKeys from '../../_Mappings/storeKeys'
import regionLocales from '../../_Mappings/regionLocales'
import { getDevice, getLoginStatus } from '../../../lib/utils'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'
import { LoyaltyPointsContext } from '../../_Modules/Account/LoyaltyPoints/LoyaltyPointsContext'

const useReviewStepPush = (user, location, language, googleAnalyticsEnvironment, cart) => {
    const {
        updateGenericDatalayer,
        getPageTemplate,
        prepareProductForDataLayer,
        pushToDataLayer
    } = useContext(GoogleEnhancedEcommerce)

    const { loyaltyPoints: { customerCode, customerStore } } = useContext(LoyaltyPointsContext)

    const userid = customerCode ? customerCode : ''
    const storeid = customerStore ? customerStore : ''

    const genericDataLayerObj = useMemo(() => ({
        environment: googleAnalyticsEnvironment,
        country: location,
        currencyCode: storeKeys[location]?.currency,
        contentGroup: 'Buying',
        language: language,
        siteName: 'Baldinini',
        userId: storeid + userid,
        pageTemplate: getPageTemplate('purchase'),
        domainName: window.location.hostname,
        device: getDevice(),
        loginStatus: getLoginStatus(user)
    }), [user, location])

    return useCallback(() => {
        const products = cart.entries.map((checkoutItem) => {
            const {
                items,
                quantity = ''
            } = checkoutItem
            const item = items ? items[0] : {}

            return prepareProductForDataLayer(item, { quantity })
        })

        const cartSubtotal = (cart.totalPurchasePrice?.moneyAmounts[0]?.amount).toFixed(2)

        pushToDataLayer({
            event: 'checkout',
            eventCategory: 'eCommerce',
            eventAction: 'Checkout',
            cartSubtotal,
            ecommerce: {
                checkout: {
                    actionField: { step: 4 },
                    products
                }
            }
        })

        updateGenericDatalayer(genericDataLayerObj)
    }, [cart, genericDataLayerObj])
}

export default useReviewStepPush
