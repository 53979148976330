import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    buttonContainerNew: {
        marginTop: theme.spacing(4)
    },
    form: {
        marginTop: theme.spacing(4)
    },
    buttonContainer: {
        marginTop: theme.spacing(2)
    },
    date: {
        width: 130,
        alignItems: 'baseline',
        marginRight: '20px'
    },
    dateFull: {
        width: '100%'
    },
    signupWrap:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto',
        flexWrap: 'wrap',
        textAlign: 'center',
        maxWidth: theme.breakpoints.values.lg,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            margin: theme.spacing(0)
        }
    },
    halfWrap: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
            width: '100%',
        }
    },
    inputGender: {
        verticalAlign: 'bottom'
        
    }
}))
