import {
    object, func, shape, string
} from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import useStyles from './style'
import useTranslation from '../../../../../_Elements/ResourceBundles/useTranslation'
import CheckOutContext from '../../../CheckOutContext'
import { usePageCfg } from '../../../../../_Elements/PageConfig/PageCfg'
import CartItem from '../../../../Cart/CartItem/CartItem'
import PriceSummary from '../../../../Cart/PriceSummary/PriceSummary'
import LoginContext from '../../../../Account/Login/LoginContext'
import Discount from '../../../../Cart/Discount/Discount'
import { COOKIE_LANGUAGE, COOKIE_LOCALE, COOKIE_LOCATION } from '../../../../../_Mappings/cookies'
import storeKeys from '../../../../../_Mappings/storeKeys'
import SubtotalCard from '../../../../Cart/PriceSummary/SubtotalCard'
import UserFeedbackDialog from '../../../../Account/UserFeedbackDialog/UserFeedbackDialog'
import useCustomCart from '../../../../../_Hooks/useCustomCart/useCustomCart'
import NavigationButtons from '../NavigationButtons/NavigationButtons'
import ChosenPayment from '../../../../Cart/PriceSummary/ChosenPayment'
import useRemoveCartItems from '../../../../../_Hooks/useClearCart'
import warehouseMap from '../../../../../_Mappings/warehouseMap'
import StockVerificationDialog from '../../../../StockVerification/StockVerificationDialog'
import regionLocales from '../../../../../_Mappings/regionLocales'
import useReviewStepPush from '../../../../../_Hooks/GoogleAnalytics/useReviewStepPush'
import Address from './AddressSelection/Address/Address'
import languages from '../../../../../_Mappings/languages'

const defaultResponse = {
    successful: undefined,
    message: undefined,
    errorCode: null
}

const Review = ({
    page, user, paymentInfos, onBack
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const { orderDraft, setOrderId } = useContext(CheckOutContext)
    const pageCfg = usePageCfg()
    const callback = page.toJSON().channel?.info?.props.checkoutCallbackUrl
    const [isLoading, setIsLoading] = useState(false)
    const router = useRouter()

    // Store
    const [cookies] = useCookies([COOKIE_LOCALE, COOKIE_LOCATION, COOKIE_LANGUAGE])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    const language4Digit = languages.find(({ value }) => value === language)?.header

    const { storeKey } = storeKeys[location] || {}

    const step = 4
    const isGuestCheckout = router?.query?.guestCheckout
    const path = `checkout?checkoutStep=${step}&orderId=%s&region=${orderDraft?.shippingAddress?.country}&guestCheckout=${isGuestCheckout}`

    // returnUrl for hosted checkout
    const returnUrl = regionLocales[language.toUpperCase()] !== 'en'
        ? `${window.location.origin}/${regionLocales[language.toUpperCase()]}/${path}`
        : `${window.location.origin}/${path}`

    // UserFeedbackModal
    const [responseForModal, setResponseForModal] = useState(defaultResponse)

    // Cart
    const { cartId, setCartId } = useContext(LoginContext)
    const { cart, refetch } = useCustomCart()

    const [itemsToVerify, setItemsToVerify] = useState([])
    const removeCartItems = useRemoveCartItems()

    const checkCart = async () => {
        await refetch()
        const productStockDataList = itemsToVerify.map((entry) => {
            const { sku, size } = entry
            return { sku, size }
        })

        await pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/stock`, {
            warehouse: warehouseMap[location],
            productStockDataList
        }).then(async (response) => {
            const unavailable = await itemsToVerify?.map((item) => item.quantity)
                .map((item, index) => (item > response?.data[index].quantity ? itemsToVerify[index] : undefined))
                .filter(Boolean)
                .map((product) => ({ skuSize: product.size ? `${product.sku}-${product.size}` : product.sku }))

            // Array der zu löschenden ProduktIds
            const productsToDelete = unavailable
                .map((product) => cart.entries.find((cartItem) => cartItem?.items[0].itemId.code === product.skuSize)?.id)
                .filter(Boolean)

            // Array der zu löschenden Produktitems
            const invalidProducts = unavailable
                .map((product) => cart.entries.find((cartItem) => cartItem?.items[0].itemId.code === product.skuSize)?.items[0]?.itemId.id)
                .map((id) => cart.entries.filter((x) => x.items[0].itemId.id.includes(id)))
                .flat()

            if (productsToDelete.length > 0) {
                setResponseForModal({
                    errorCode: 'InvalidProducts',
                    invalidProducts,
                    productsToDelete
                })
            }

            setIsLoading(false)
        }).catch((e) => {
            console.error('error', e)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (!cart) return

        const data = cart.entries.map((cartItem) => cartItem.items.map((item) => {
            const [sku, size] = item.itemId.code.split('-')

            return {
                sku,
                size,
                quantity: cartItem.quantity
            }
        })).flat()

        setItemsToVerify(data)
    }, [])

    useEffect(() => {
        checkCart()
    }, [itemsToVerify])

    useEffect(() => {
        if (!cart?.totalQuantity) {
            setResponseForModal({
                errorCode: 'CartEmpty',
                translation: 'checkout:cart.empty.text'
            })
        }
    }, [cart?.totalQuantity])

    const handleAfterClose = () => {
        switch (responseForModal.errorCode) {
            case 'InvalidProducts': {
                removeCartItems(responseForModal.productsToDelete)
                setResponseForModal(defaultResponse)
                break
            }
            case 'PriceChanged': {
                setResponseForModal(defaultResponse)
                break
            }
            case 'CartEmpty': {
                router.push({ pathname: '/' })
                break
            }
            default: {
                onBack()
            }
        }
    }

    const pushReviewStepDatalayer = useReviewStepPush(user, location, language, pageCfg.googleAnalyticsEnvironment, cart)

    const invoiceError = async (error) => {
        await refetch()

        switch (error.response?.data?.errorCode) {
            case 'PriceChanged': {
                setResponseForModal(error.response.data)
                break
            }
            default: {
                setResponseForModal(error.response.data)
            }
        }
    }

    const onClick = async () => {
        if (paymentInfos.paymentMethodCategory === 'Wire Transfer') {
            try {
                setIsLoading(true)
                const result = await pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/payment/invoice`, {
                    region: orderDraft.shippingAddress.country,
                    orderId: orderDraft.id,
                    paymentMethodCategory: paymentInfos.paymentMethodCategory,
                    locale: language4Digit,
                    storeKey,
                    callback,
                    returnUrl
                })
                setIsLoading(false)
                setOrderId(result?.data?.orderId)

                // Generic Datalayer Object
                pushReviewStepDatalayer()

                if (result.data.fallbackCartId) {
                    setCartId(result.data.fallbackCartId)
                    localStorage.setItem('fallbackCartId', result.data.fallbackCartId)
                }

                router.push({
                    pathname: '/checkout',
                    query: {
                        reload: true,
                        checkoutStep: '4'
                    }
                })
            } catch (e) {
                invoiceError(e)
                setIsLoading(false)
            }
        } else {
            try {
                setIsLoading(true)
                const result = await pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/payment/invoice`, {
                    region: orderDraft.shippingAddress.country,
                    orderId: orderDraft.id,
                    paymentMethodCategory: paymentInfos.paymentMethodCategory, // cards
                    paymentProductId: paymentInfos.paymentProductId, // 138
                    locale: language4Digit,
                    storeKey,
                    callback,
                    returnUrl
                })
                setIsLoading(false)
                setOrderId(result?.data?.orderId)

                // Generic Datalayer Object
                pushReviewStepDatalayer()

                // setCartId(result.data.fallbackCartId)
                if (result.data.fallbackCartId) {
                    localStorage.setItem('fallbackCartId', result.data.fallbackCartId)
                }

                // 3D Secure redirect
                if (result.data.ingenicoResponse?.merchantAction?.redirectData) {
                    router.push(result.data.ingenicoResponse?.merchantAction?.redirectData?.redirectURL)
                } else {
                    window.location.href = result.data.redirectUrl
                }
            } catch (e) {
                invoiceError(e)
                setIsLoading(false)
            }
        }
    }

    if (!(cart && orderDraft)) {
        return (
            <div className={classes.addressesContainer}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <div className={classes.addressesContainer}>
                <Grid
                    container
                    spacing={4}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction='column'
                        container
                    >
                        <Typography
                            variant='h6'
                            gutterBottom
                        >
                            {translations('address:shipping.address.title')}
                        </Typography>
                        <Address active address={orderDraft.shippingAddress} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction='column'
                        container
                    >
                        <Typography
                            variant='h6'
                            gutterBottom
                        >
                            { translations('address:billing.address.title') }
                        </Typography>
                        <Address active address={orderDraft.billingAddress} />
                    </Grid>
                </Grid>

                <List aria-label='cart product list' className={classes.itemList}>
                    {
                        cart?.entries?.filter(Boolean).map((entry, index) => (
                            <CartItem
                                key={entry.id}
                                entry={entry}
                                cartId={cart.id}
                                position={index}
                            />
                        ))
                    }
                </List>
                <div className={classes.summaryContainer}>
                    <ChosenPayment paymentInfos={paymentInfos} />
                    <SubtotalCard totalListPrice={cart?.totalListPrice.moneyAmounts[0]} />
                    <Discount cartId={cartId} storeKey={storeKey} readOnly />
                    <PriceSummary
                        shipAmount={orderDraft.shipAmount}
                        totalListPrice={cart?.totalListPrice.moneyAmounts[0]}
                        totalPurchasePrice={cart?.totalPurchasePrice.moneyAmounts[0]}
                        totalQuantity={cart?.totalQuantity}
                        discounts={cart.discounts}
                    />
                </div>

                <NavigationButtons
                    onClickBack={onBack}
                    dataQaBack='button-review-back'
                    onClickNext={onClick}
                    loading={isLoading}
                    dataQaNext='button-review-next'
                />
            </div>

            <StockVerificationDialog
                open={!!(responseForModal.invalidProducts?.length && responseForModal.errorCode === 'InvalidProducts')}
                onClose={handleAfterClose}
                origin='Cart'
                productsToDelete={responseForModal.invalidProducts}
            />

            <UserFeedbackDialog
                open={!!(responseForModal.translation && responseForModal.errorCode !== 'InvalidProducts')}
                setOpen={() => setResponseForModal(defaultResponse)}
                handleAfterClose={handleAfterClose}
            >
                {responseForModal.translation && (
                    <Typography variant='body1'>
                        {translations(`${responseForModal.translation}`)}
                    </Typography>
                )}
            </UserFeedbackDialog>
        </>
    )
}

Review.propTypes = {
    page: object,
    user: object,
    paymentInfos: shape({
        encryptedPayment: string,
        paymentMethodCategory: string,
        paymentLabel: string,
        paymentLogo: string
    }),
    onBack: func
}

Review.defaultProps = {
    page: undefined,
    user: null,
    paymentInfos: {
        encryptedPayment: '',
        paymentMethodCategory: '',
        paymentLabel: '',
        paymentLogo: ''
    },
    onBack: () => undefined
}

export default Review
