import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    spPayInstallment: {
        fontFamily: 'DM Sans!important',
        "& span": {
            fontFamily: 'DM Sans!important',
        }
    },

}))
