import { useForm } from 'react-hook-form'
import Router, { useRouter } from 'next/router'
import { func, bool } from 'prop-types'
import FormTextField from '../../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import useTranslation from '../../../../_Elements/ResourceBundles/useTranslation'
import { SFMCCollectJs } from '../../../ThirdPartyScripts/SFMCCollectJs'
import { useContext } from 'react'

const ProductSearchForm = ({ handleClose, autoFocus }) => {
    const { handleSubmit, reset, ...formCtx } = useForm()
    const { query: { search } } = useRouter()
    const classes = useStyles()
    const searchTerm = search ? decodeURIComponent(search) : ''
    const translations = useTranslation()
    const { pushSearch } = useContext(SFMCCollectJs)

    const handleFormSubmit = handleSubmit((values) => {
        if (values['product-search']) {
            Router.push({ pathname: '/search', query: { search: encodeURIComponent(values['product-search']) } })
            pushSearch(values['product-search'])
            handleClose()
        }
    })

    return (
        <form onSubmit={handleFormSubmit} className={classes.form}>
            <FormTextField
                className={classes.input}
                defaultValue={searchTerm}
                variant='outlined'
                name='product-search'
                formCtx={formCtx}
                autoFocus={autoFocus}
            />
            <BaseButton type='submit' className={classes.searchButton}>
                {translations('product:search.button')}
            </BaseButton>
        </form>

    )
}

ProductSearchForm.propTypes = {
    handleClose: func,
    autoFocus: bool
}

ProductSearchForm.defaultProps = {
    handleClose: () => {},
    autoFocus: false
}

export default ProductSearchForm
