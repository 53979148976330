import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    searchContainer: {
        minWidth: theme.spacing(60),
        margin: theme.spacing(0, 'auto'),
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            minWidth: theme.spacing(43)
        }
    }
}))
