import { IconButton, Popover } from '@material-ui/core'
import { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import UserIcon from '../../../_Elements/Icons/User'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import Link from '../../../_Elements/Link'


const LoginPopover = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()
    const translations = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'login-popover' : undefined

    return (
        <>
            <IconButton
                color='inherit'
                aria-label='user'
                onClick={handleClick}
            >
                <UserIcon stroke='currentColor' />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <div className={classes.loginPopContainer}>
               
                    <Typography align='center' variant='h6' component='h3'>
                        {translations('login.popover.text')}
                    </Typography>
                   
                        <BaseButton
                        variant='contained'
                        href='/signin'
                        data-qa='button-signin-register-link'
                        component={Link} 
                    >
                        {translations('login.button')}
                    </BaseButton>
                
                    <BaseButton
                        variant='outlined'
                        href='/signup'
                        data-qa='button-signin-register-link'
                        component={Link} 
                    >
                        {translations('register.button')}
                    </BaseButton>
                  
                </div>

            </Popover>
        </>
    )
}

export default LoginPopover
