import { useCookies } from 'react-cookie'
import { shape, func } from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useMediaQuery } from '@material-ui/core'
import ProductGridClientRender from './ProductGridClientRender'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import storeKeys from '../../../_Mappings/storeKeys'
import { getCountLoadMoreClickFromHistory, getFacetFilterFromUrl } from '../../../../lib/utils'
import FilterSortPanelDesktop from '../FilterSortPanel/FilterSortPanelDesktop'
import FilterSortPanelMobile from '../FilterSortPanel/FilterSortPanelMobile'
import { FilterSortPanelProvider } from '../FilterSortPanel/FilterSortPanel'
import theme from '../../../../lib/theme'
import PropTypes from 'prop-types'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import Logo from '../../../_Elements/Icons/Logo'
import UserContext from '../../../_Elements/UserContext/UserCx'
import { useContext } from 'react';


const ProductGridByCategoryOrTag = ({ page, component }) => {
    const { categoryId = '', gridLayout } = component.getParameters()
    const componentId = component.getId()
    const { products, filters } = usePageCfg()
    const [facetResult, setFacetResult] = useState({})
    const [showPanel, setShowPanel] = useState(false)
    const [total, setTotal] = useState(0)
    const [totalHome, setTotalHome] = useState(-1)
    const isMobile = useMediaQuery((thm) => thm.breakpoints.down(theme.breakpoints.values.m))
    const { scrollRef } = useContext(UserContext);
    
    useEffect(() => {
        setShowPanel(true)
         //called when the component has been mounted, sets the scroll to the currently stored scroll position
        window.scrollTo(0, scrollRef.current.scrollPos);

        const handleScrollPos = () => {
        //every time the window is scrolled, update the reference. This will not cause a re-render, meaning smooth uninterrupted scrolling.
        scrollRef.current.scrollPos = window.scrollY
        };

        window.addEventListener('scroll', handleScrollPos);

        return () => {
        //remove event listener on unmount
        window.removeEventListener('scroll', handleScrollPos);
        };
    }, [])
    
    return (
        <>
            {showPanel && (
                <FilterSortPanelProvider
                    total={total}
                    facetResult={facetResult}
                    componentId={componentId}
                    totalHome={totalHome}
                >
                    {isMobile
                        ? <FilterSortPanelMobile />
                        : <FilterSortPanelDesktop />}

                </FilterSortPanelProvider>
            )}

            <ProductGridClientRender
                categoryId={categoryId}
                page={page}
                products={products}
                filters={filters}
                gridLayout={gridLayout}
                setTotal={setTotal}
                setFacetResult={setFacetResult}
                setTotalHome={setTotalHome}
            />
        </>
    )
}

ProductGridByCategoryOrTag.propTypes = {
    page: shape({
        getUrl: func
    }),
    component: shape({
        getParameters: func
    }),
    categoryProducts: PropTypes.object
}

ProductGridByCategoryOrTag.defaultProps = {
    page: undefined,
    component: undefined,
    categoryProducts : undefined
}

export default ProductGridByCategoryOrTag
