import React, { useContext, useState } from 'react'
import { BrPageContext } from '@bloomreach/react-sdk'
import { useResetPassword } from '@bloomreach/connector-components-react'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useRouter } from 'next/router'
import Container from '@material-ui/core/Container'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'

const ResetPassword = () => {
    const translations = useTranslation()
    const [error, setError] = useState('')
    const router = useRouter()
    const page = useContext(BrPageContext)
    const classes = useStyles()
    const passwordResetToken = router.query.path[router.query.path.length - 1] ?? ''
    const [resetPassword, , loading, mutationError] = useResetPassword({ passwordResetToken })
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const { handleSubmit, ...formCtx } = useForm()

    const handleFormSubmit = handleSubmit(async ({ newPassword, newPasswordRepeat }) => {
        setError('')
        if (!passwordResetToken) {
            setError(translations('forms:password.reset.token.missing'))
            return
        }

        if (newPassword !== newPasswordRepeat) {
            setError(translations('forms:change.password.match'))
            return
        }

        if (!newPassword) {
            setError(translations('forms:change.password.empty'))
            return
        }

        const customer = await resetPassword({ newPassword })
        if (customer) {
            setIsDialogOpen(true)
        }
    })

    const errorMessage = error || mutationError?.message

    return (
        <Container maxWidth='sm'>
            <Typography variant='h4' component='h2' align='center'>
                {translations('login:reset.password.title')}
            </Typography>
            <form onSubmit={handleFormSubmit}>
                <FormTextField
                    label={translations('forms:new.password.label')}
                    name='newPassword'
                    type='password'
                    formCtx={formCtx}
                />
                <FormTextField
                    label={translations('forms:repeat.new.password.label')}
                    name='newPasswordRepeat'
                    type='password'
                    formCtx={formCtx}
                />
                <div className={classes.buttonContainer}>
                    {errorMessage && (
                        <FormHelperText error>{errorMessage}</FormHelperText>
                    )}
                    <BaseButton variant='contained' type='submit' loading={loading}>
                        {translations('forms:submit.button')}
                    </BaseButton>
                </div>
            </form>

            <UserFeedbackDialog
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                handleAfterClose={() => {
                    router.push(page.getUrl('/signin'))
                }}
            >
                <Typography variant='body1'>
                    {translations('forms:success.change.password.text')}
                </Typography>
            </UserFeedbackDialog>
        </Container>
    )
}

export default ResetPassword
