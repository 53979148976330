import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addressesContainer: {
        maxWidth: theme.breakpoints.values.md,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        margin: theme.spacing(0, 'auto'),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    addAddressContainer: {
        maxWidth: theme.breakpoints.values.sm,
        margin: theme.spacing(4, 'auto')
    },
    root: {
        width: '100%'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}))
