import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
    text: {
        display: 'flex',
        alignItems: 'center'
    },
    listItem: {
        '& .MuiListItem-root': {
            paddingTop: '14px',
            paddingBottom: '14px'
        }
    }
}))
