import { useCallback, useContext } from 'react'
import { ResourceBundlesContext } from './ResourceBundles'

const useTranslation = () => {
    const bundles = useContext(ResourceBundlesContext)

    return useCallback((t, templateVars) => {
        const strArr = t.split(':')
        // add global as default
        if (strArr.length === 1) strArr.unshift('global')

        const ctx = bundles?.[strArr[0]]
        // probably loading right now
        if (!ctx) return ''

        let translation = ctx[strArr[1]]

        if (!translation) {
            console.warn(`Missing Translation: ${strArr.join(':')}`)
            return ''
        }

        if (templateVars) {
            Object.entries(templateVars).forEach(([key, value]) => {
                const rx = new RegExp(`{{${key}}}`, 'g')
                translation = translation.replace(rx, value)
            })
        }

        return translation
    }, [bundles])
}

export default useTranslation
