import React, {
    useContext, useEffect, useRef, useState
} from 'react'
import { isMenu } from '@bloomreach/spa-sdk'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import {
    ListItem,
    Paper,
    Popper,
    Slide,
    useScrollTrigger
} from '@material-ui/core'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import List from '@material-ui/core/List'
import useStyles from './style'
import Link from '../../../_Elements/Link'
import useTransparentHeader from '../../../_Hooks/useTransparentHeader'
import DesktopMenuButtonLink from '../../../_Elements/MenuItems/Desktop/DesktopMenuButtonLink'
import SubItem from '../../../_Elements/MenuItems/Desktop/SubItem'

const DesktopMenu = () => {
    const classes = useStyles()
    const { asPath: pathname } = useRouter()
    const trigger = useScrollTrigger()

    const page = useContext(BrPageContext)
    const component = useContext(BrComponentContext)
    const menuRef = component?.getModels().menu
    const menu = menuRef && page?.getContent(menuRef)
    const menuItems = menu.getItems()
    const {
        isTransparent,
        pageIsProductDetail
    } = useTransparentHeader()

    const [activeMenu, setActiveMenu] = useState('')
    const [activeIndex, setActiveIndex] = useState(
        DesktopMenu.getIndexPathname(page, pathname, menuItems)
    )

    const anchorEl = useRef()

    useEffect(() => {
        const newIndex = DesktopMenu.getIndexPathname(page, pathname, menuItems)
        if (activeIndex !== newIndex) {
            setActiveIndex(DesktopMenu.getIndexPathname(page, pathname, menuItems))
        }
    }, [pathname, menu])

    useEffect(() => {
        if (activeMenu && trigger) setActiveMenu('')
    }, [activeMenu, trigger])

    const handleMenuOpen = (name) => (e) => {
        const { currentTarget } = e
        anchorEl.current = currentTarget
        setActiveMenu(name)
    }

    const handleMenuClose = () => {
        anchorEl.current = null
        setActiveMenu('')
    }

    const isOpen = (name) => (name === activeMenu)

    if (!isMenu(menu)) {
        return null
    }

    return (
        <div className={clsx(classes.slide, { [classes.hidden]: trigger })}>
            <Slide appear={false} direction='down' in={!trigger}>
                <nav className={clsx({ [classes.transparent]: isTransparent }, classes.toolbarBottom)}>
                    <List disablePadding className={classes.list}>
                        {menuItems
                            .map((item) => {
                                const name = item.getName()

                                return (
                                    <ListItem
                                        key={`${name}-tab`}
                                        className={classes.listItem}
                                    >
                                        <DesktopMenuButtonLink
                                            onMouseOver={handleMenuOpen(name)}
                                            isExpanded={isOpen(name)}
                                            item={item}
                                            color={isTransparent && !pageIsProductDetail ? 'secondary' : 'primary'}
                                        />
                                    </ListItem>
                                )
                            })}
                    </List>

                    {menuItems.map((item) => {
                        const children = item.getChildren()
                        const itemName = item.getName()
                        const itemUrl = item.getUrl() || ''

                        return (children.length
                            ? (
                                <Popper
                                    key={`${itemName}-menu`}
                                    open={item.getName() === activeMenu}
                                    onClose={() => { setActiveMenu('') }}
                                    anchorEl={anchorEl.current}
                                    id='menu'
                                    placement='bottom'
                                    modifiers={{
                                        flip: {
                                            enabled: false
                                        }
                                    }}
                                    popperOptions={{
                                        modifiers: {
                                            preventOverflow: {
                                                padding: 0
                                            }
                                        }
                                    }}
                                    className={classes.menuPopper}
                                >
                                    <Paper
                                        className={classes.menuPaper}
                                        onMouseLeave={handleMenuClose}
                                    >
                                        {itemUrl && (
                                            <Link
                                                href={itemUrl}
                                                variant='subtitle2'
                                                className={classes.menuTitle}
                                            >
                                                {itemName}
                                            </Link>
                                        )}
                                        <List disablePadding className={classes.menuList}>
                                            {children
                                                .map((subitem) => (
                                                    subitem.model?.parameters?.hideInRussia !== "true" &&
                                                    <SubItem
                                                        className={classes.menuItem}
                                                        subitem={subitem}
                                                        handleMenuClose={handleMenuClose}
                                                        key={`${subitem.getName()}-menu-item`}
                                                        isTitle
                                                    />
                                                ))}
                                        </List>
                                    </Paper>
                                </Popper>
                            )
                            : null
                        )
                    })}
                </nav>
            </Slide>
        </div>
    )
}

DesktopMenu.getIndexPathname = (page, pathname, siteMenuItems) => {
    const lowercaseBasePath = `/${pathname.split('/').filter(Boolean)[0]?.toLowerCase()}`
    const index = siteMenuItems.findIndex((item) => (item.getUrl()
        ?.toLowerCase() === lowercaseBasePath
    ))
    return index === -1 ? siteMenuItems.length : index
}

export default DesktopMenu
