import clsx from 'clsx'
import { BrManageContentButton } from '@bloomreach/react-sdk'
import { func, shape } from 'prop-types'

const Content = ({ component, page }) => {
    const { document: documentRef } = component.getModels()
    const document = documentRef && page.getContent(documentRef)

    if (!document) {
        return null
    }

    const {
        author, content, date, image: imageRef, title
    } = document.getData()
    const image = imageRef && page.getContent(imageRef)

    return (
        <div className={clsx({ 'has-edit-button': page.isPreview() })}>
            <BrManageContentButton context={document} />
            {image && <img src={image.getUrl()} alt={title} />}
            {title && <h1>{title}</h1>}
            {author && <p>{author}</p>}
            {date && <p>{new Date(date).toDateString()}</p>}
            {/* eslint-disable-next-line react/no-danger */}
            {content && <div dangerouslySetInnerHTML={{ __html: page.rewriteLinks(content.value) }} />}
        </div>
    )
}

Content.propTypes = {
    page: shape({
        getContent: func,
        isPreview: func,
        rewriteLinks: func
    }),
    component: shape({
        getModels: func
    })
}

Content.defaultProps = {
    page: undefined,
    component: undefined
}

export default Content
