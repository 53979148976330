import AddressesOverview from './_Modules/Account/Addresses/AddressesOverview'
import ChangePassword from './_Modules/Account/ChangePassword/ChangePassword'
import CartComponent from './_Modules/Cart/Cart/Cart'
import Content from './_Elements/Content'
import EnhancedOrderList from './_Modules/Account/OrderList/EnhancedOrderList'
import Footer from './_Modules/FooterMenu/Footer'
import HeroBanner from './_Modules/HeroBanner/HeroBanner'
import SliderBanner from './_Modules/SliderBanner/SliderBanner'
import HeroTeaser from './_Modules/HeroTeaser/HeroTeaser'
import ImageArticle from './_Modules/Articles/ImageArticle/ImageArticle'
import Login from './_Modules/Account/Login/Login'
import DesktopMenu from './_Modules/HeaderMenu/DesktopMenu/DesktopMenu'
import PanoramaBanner from './_Modules/PanoramaBanner/PanoramaBanner'
import ProductDetail from './_Modules/Product/ProductDetail/ProductDetail'
import ProductGridByCategoryOrTag from './_Modules/Product/ProductGridByCategoryOrTag/ProductGridByCategoryOrTag'
import ProductGridSearch from './_Modules/Product/ProductSearch/ProductGridSearch/ProductGridSearch'
import ProductSlider from './_Modules/ProductSlider/ProductSlider'
import ProtectedRoute from './_Elements/Protected/ProtectedRoute'
import ResetPassword from './_Modules/Account/ResetPassword/ResetPassword'
import SalesBanner from './_Modules/SalesBanner/SalesBanner'
import Signup from './_Modules/Account/Signup/Signup'
import Signupm2m from './_Modules/Account/Signup/Signupm2m'
import TextArticle from './_Modules/Articles/TextArticle/TextArticle'
import UpdateProfile from './_Modules/Account/UpdateProfile/UpdateProfile'
import Faq from './_Modules/FAQ/Faq'
import CheckOut from './_Modules/Checkout/Checkout/CheckOut'
import SecondaryMenu from './_Modules/HeaderMenu/SecondaryMenu/SecondaryMenu'
import ErrorPage from './_Modules/ErrorPage/ErrorPage'
import Storelocator from './_Modules/Storelocator/Storelocator'
import ProductGridTeaser from './_Modules/Product/ProductGridTeaser/ProductGridTeaser'
import Invite from './_Modules/Account/Invite/Invite'

export default {
    Banner: HeroBanner,
    'Slider Banner': SliderBanner,
    FAQ: Faq,
    Panorama: PanoramaBanner,
    'Product Slider': ProductSlider,
    'Hero Teaser': HeroTeaser,
    'Image Article': ImageArticle,
    'Sales Banner': SalesBanner,
    'Text Article': TextArticle,
    Content,
    orders: ProtectedRoute(EnhancedOrderList),
    accountProfile: ProtectedRoute(UpdateProfile),
    accountAddresses: ProtectedRoute(AddressesOverview),
    cart: CartComponent,
    checkout: CheckOut,
    mainmenu: DesktopMenu,
    secondarymenu: SecondaryMenu,
    menu: Footer,
    productdetail: ProductDetail,
    changepassword: ChangePassword,
    login: Login,
    'Product Category Grid': ProductGridByCategoryOrTag,
    'Product Grid (Search)': ProductGridByCategoryOrTag,
    'Product Grid (Teaser)': ProductGridTeaser,
    resetpassword: ResetPassword,
    register: Signup,
    errorpage: ErrorPage,
    storelocator: Storelocator,
    registerm2m: Signupm2m,
    'invite-member': Invite
}
