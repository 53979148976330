import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { BrPageContext } from '@bloomreach/react-sdk'
import { useSignup } from '@bloomreach/connector-components-react'
import 'react-datepicker/dist/react-datepicker.css'
import Container from '@material-ui/core/Container'
import { useForm } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import { useCookies } from 'react-cookie'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import BaldininiLogo from '../../../_Elements/Icons/Logo'
import useStyles from './style'
import Link from '../../../_Elements/Link'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import { COOKIE_LOCALE, COOKIE_LOCATION } from '../../../_Mappings/cookies'
import FormSelect from '../../../_Elements/Inputs/FormSelect'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'
import { REG_EXP , formatSimpleBbcode } from '../../../../lib/utils'
import { GoogleEnhancedEcommerce } from '../../ThirdPartyScripts/GoogleEnhancedEcommerce'
import countriesAlpha3 from '../../../_Mappings/countriesAlpha3'

const Signupm2m = () => {
    const translations = useTranslation()
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)
    const page = useContext(BrPageContext)
    const [signup, result, loading, error] = useSignup()
    const router = useRouter()
    const classes = useStyles()
    const pageCfg = usePageCfg()
    const [cookies] = useCookies([COOKIE_LOCALE])
    const locale = cookies[COOKIE_LOCALE] ?? 'en'
    const location = cookies[COOKIE_LOCATION] ?? ''
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const searchParams = router.query

    const ism2m = searchParams?.source && searchParams.source== 'm2m'
    const isqrc = searchParams?.source && searchParams.source== 'qrc'
    const inviter = searchParams?.inviter
    const {
        handleSubmit,
        setValue,
        getValues,
        ...formCtx
    } = useForm()

    const confirmMail = async () => {
        await pageCfg.httpClient
            .post(`${pageCfg.cmsRestserviceUrl}/mailing/signup`, {
                customer: result.customer,
                locale
            })
            .then(() => {
                const dateOfBirth = result.customer.dateOfBirth ? new Date(result.customer.dateOfBirth) : undefined
                const day = dateOfBirth?.getDate() > 9 ? dateOfBirth?.getDate().toString() : '0' + dateOfBirth?.getDate().toString()
                const month = dateOfBirth?.getMonth() + 1 > 9 ? (dateOfBirth?.getMonth()+1).toString() : '0' + (dateOfBirth?.getMonth()+1).toString()
                const year = dateOfBirth?.getFullYear().toString()
                pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/customer`, {
                    email: result.customer.email,
                    country: countriesAlpha3[location],
                    firstName: result.customer.firstName,
                    lastName: result.customer.lastName,
                    type: ism2m ? 'M2M' : 'QRC',
                    gender: result.customer.salutation ? (result.customer.salutation === 'male' ? 'U' : 'D') : '',
                    birthday: dateOfBirth ? '' + day + month + year : ''
                })
            })
            .catch((e) => {
                console.error('error', e)
            })
    }

    const updateCustomFields = async () => {
        await pageCfg.httpClient
            .post(`${pageCfg.cmsRestserviceUrl}/customer/update`, {
                email: result.customer.email,
                store: ism2m ? 'M2M' : 'QRC',
                inviter: inviter ? inviter : ''
            })
            .catch((e) => {
                console.error('error', e)
            })
    }

    useEffect(() => {
        if (result?.success) {
            confirmMail()
            updateCustomFields()
            setIsDialogOpen(true)

            pushToDataLayer({
                event: 'registration',
                eventCategory: 'Registrazione',
                eventAction: 'registrationSuccess',
                eventLabel: result.customer?.id ?? ''
            })
        }
    }, [result?.success])

    const genderOptions = [
        { label: `${translations('forms:gender.female')}`, value: 'female' },
        { label: `${translations('forms:gender.male')}`, value: 'male' },
        { label: `${translations('forms:gender.other')}`, value: 'other' }
    ]

    const handleFormSubmit = handleSubmit((values) => signup({
        ...values, middleName: ''
    }))

    return (
        <>
            <Toolbar>
                <Container maxWidth='xl' disableGutters className={classes.logoContainer}>
                    <Link
                        color='inherit'
                        href='/'
                    >
                        <BaldininiLogo />
                    </Link>
                </Container>
            </Toolbar>
            <Container maxWidth='md'>
                {ism2m &&
                <Typography
                    component='p'
                >
                    { formatSimpleBbcode(translations('eternity:landing.m2m.intro')) }
                   
                </Typography>
                }
                {isqrc &&
                <Typography
                    component='p'
                >
                   
                    { formatSimpleBbcode(translations('eternity:landing.qr.intro')) }
                    
                </Typography>
                }
            </Container>
            <Container maxWidth='sm'>
                <Typography variant='h4' component='h2' align='center' style={{ paddingTop: '50px' }}>
                    {translations('eternity:form.title')}
                </Typography>
                <form onSubmit={handleFormSubmit} className={classes.form}>
                    <FormTextField
                        name='email'
                        label={translations('forms:email.label')}
                        type='email'
                        formCtx={formCtx}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            },
                            validate: () => REG_EXP.emailOnly.test(getValues('email'))
                        }}
                    />
                    <FormTextField
                        name='firstName'
                        label={translations('forms:first.name.label')}
                        formCtx={formCtx}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            }
                        }}
                    />
                    <FormTextField
                        name='lastName'
                        label={translations('forms:last.name.label')}
                        formCtx={formCtx}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            }
                        }}
                    />
                    <FormTextField
                        name='password'
                        label={translations('forms:password.label')}
                        type='password'
                        formCtx={formCtx}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            }
                        }}
                    />
                    <Typography component='p'style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        { formatSimpleBbcode(translations('eternity:landing.msg.birthday')) }
                    </Typography>
                    <FormTextField
                        className={classes.dateFull}
                        name='dateOfBirth'
                        label={translations('forms:date.of.birth.label')}
                        formCtx={formCtx}
                        type='date'
                        min='1000-01-01'
                        max='9999-12-31'
                        InputLabelProps={{ shrink: true }}
                        defaultValue=''
                    />
                    <br/>
                    <FormSelect
                        id='salutation'
                        name='salutation'
                        formCtx={formCtx}
                        options={genderOptions}
                        label={translations('forms:gender.label')}
                        className={classes.dateFull}
                    />
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                    <Box align='center' className={classes.buttonContainerNew}>
                        <BaseButton
                            variant='contained'
                            size='large'
                            loading={loading}
                            type='submit'
                        >
                            {translations('eternity:form.cta')}
                        </BaseButton>
                    </Box>
                </form>

                <UserFeedbackDialog
                    open={isDialogOpen}
                    setOpen={setIsDialogOpen}
                    handleAfterClose={() => {
                        router.push(page.getUrl('/signin'))
                    }}
                >
                    <Typography variant='body1'>
                        {translations('login:signup.register.user.created.success')}
                    </Typography>
                </UserFeedbackDialog>
            </Container>
        </>
    )
}

export default Signupm2m
