import { useEffect, useState, useContext } from 'react'
import {
    shape, number, func, string, array
} from 'prop-types'
import ProductGrid from './ProductGrid'
import useGoogleProductImpressions from '../../../_Hooks/GoogleAnalytics/useGoogleProductImpressions'
import useFacetHomeSorting from '../../../_Hooks/useFacetHomeSorting'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'

const ProductGridClientRender = ({
    setTotal,
    categoryId,
    page,
    gridLayout,
    setFacetResult,
    teaser,
    setTotalHome,
    products,
    filters
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const { pushCategoryView } = useContext(SFMCCollectJs)
    const {
        offset, limit, total: _total, items
    } = products.queryResult || {}
    const { facetResult } = filters.queryResult || {}

    useEffect(() => {
        setTotal(_total)
        setTotalHome(_total)
    }, [_total])

    useEffect(() => {
        setIsLoading(false)
    }, [offset, limit])

    useEffect(() => {
        if (categoryId) {
            pushCategoryView(categoryId)
        }
    }, [categoryId])

    useFacetHomeSorting(facetResult, setFacetResult)
    useGoogleProductImpressions(products.queryResult, page)

    return (
        <>
            <ProductGrid
                limit={limit}
                offset={offset}
                total={_total}
                gridLayout={gridLayout}
                isLoading={isLoading}
                items={items}
                teaser={teaser}
            />
        </>
    )
}

ProductGridClientRender.propTypes = {
    setTotal: func,
    location: string,
    count: number,
    categoryId: string,
    tag: string,
    page: shape({
        getUrl: func
    }),
    pageSize: number.isRequired,
    gridLayout: string.isRequired,
    storeKey: string,
    brUid2: string,
    currency: string.isRequired,
    setFacetResult: func,
    facetFieldFilters: array,
    sortFields: string,
    teaser: array,
    setTotalHome: func
}

ProductGridClientRender.defaultProps = {
    setTotal: () => {},
    location: '',
    count: 0,
    categoryId: undefined,
    tag: undefined,
    page: undefined,
    brUid2: undefined,
    storeKey: undefined,
    teaser: [],
    setFacetResult: () => {},
    facetFieldFilters: [],
    sortFields: undefined,
    setTotalHome: () => {},
    pageSize: 12,
    currency: 'EUR'
}

export default ProductGridClientRender
