import makeStyles from '@material-ui/styles/makeStyles'
import theme from '../../../../lib/theme'

export default makeStyles(() => ({
    dialogAction: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
        }
    },
    addToCartButton: {
        display: 'flex'
    }
}))
