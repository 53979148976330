import React from 'react'
import {
    DialogContent, Dialog, DialogActions
} from '@material-ui/core'
import { func, node, bool } from 'prop-types'

import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'

const UserFeedbackDialog = ({
    children,
    setOpen,
    open,
    handleAfterClose
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    return (
        <Dialog open={open}>
            <div className={classes.dialogContainer}>
                <DialogContent>{children}</DialogContent>
            </div>
            <DialogActions className={classes.dialogAction}>
                <BaseButton
                    onClick={() => {
                        setOpen(false)
                        if (handleAfterClose) {
                            handleAfterClose()
                        }
                    }}
                >
                    {translations('global:close')}
                </BaseButton>
            </DialogActions>
        </Dialog>
    )
}

UserFeedbackDialog.propTypes = {
    children: node,
    open: bool,
    setOpen: func.isRequired,
    handleAfterClose: func
}

UserFeedbackDialog.defaultProps = {
    children: null,
    open: false,
    handleAfterClose: undefined
}

export default UserFeedbackDialog
