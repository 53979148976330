import {
    bool, node, object, string
} from 'prop-types'
import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { useController } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { checkRequiredForInputLabelProps } from '../../../../lib/utils'

const FormTextField = ({
    formCtx,
    placeholder,
    name,
    label,
    defaultValue,
    disabled,
    multiline,
    type,
    className,
    variant,
    InputLabelProps = {},
    mask,
    rules,
    shouldUnregister,
    autoFocus,
    ...inputProps
}) => {
    const { control, formState: { errors } } = formCtx
    const {
        field: {
            ref, value, name: _name, onBlur, onChange
        }
    } = useController({
        defaultValue,
        name,
        control,
        rules,
        shouldUnregister
    })

    const error = errors[_name]

    const renderInputField = () => (
        
        <TextField
            ref={ref}
            name={_name}
            variant={variant}
            className={className}
            multiline={multiline}
            disabled={disabled}
            fullWidth
            InputLabelProps={{
                ...InputLabelProps,
                required: checkRequiredForInputLabelProps(rules)
            }}
            label={label}
            error={!!error}
            helperText={error?.message ?? null}
            type={type}
            margin='normal'
            placeholder={placeholder}
            inputProps={{
                ...(mask
                    ? null
                    : {
                        defaultValue,
                        onBlur,
                        onChange
                    }),
                ...inputProps
            }}
            autoFocus={autoFocus}
        />
    )

    return mask
        ? (
            <InputMask
                mask={mask}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                maskChar=''
            >
                {renderInputField}
            </InputMask>
        )
        : renderInputField()
}

FormTextField.propTypes = {
    autoFocus: bool,
    disabled: bool,
    shouldUnregister: bool,
    rules: object,
    formCtx: object.isRequired,
    name: string.isRequired,
    label: node,
    defaultValue: string,
    type: string,
    multiline: bool,
    className: string,
    variant: string,
    placeholder: string,
    InputLabelProps: object,
    mask: string
}

FormTextField.defaultProps = {
    autoFocus: undefined,
    disabled: null,
    shouldUnregister: false,
    rules: null,
    label: undefined,
    defaultValue: '',
    multiline: false,
    type: 'text',
    className: undefined,
    variant: undefined,
    placeholder: undefined,
    InputLabelProps: undefined,
    mask: undefined
}

export default FormTextField
