import { func, bool } from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import useStyles from './style'
import Close from '../../../../lib/icons/close.svg'
import AddAddress from '../../Account/AddAddress/AddAddress'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const AddAddressDialog = ({
    setAddress, isVisible, onClose
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    const handleSuccess = (id) => {
        setAddress(id)
        onClose()
    }

    return (
        <Dialog open={isVisible} onClose={onClose} aria-labelledby='form-dialog-title'>
            <DialogTitle disableTypography id='form-dialog-title' className={classes.dialogTitle}>
                <Typography variant='h6'>
                    {translations('forms:add.address.title')}
                </Typography>
                <IconButton aria-label='close' onClick={onClose} color='primary'>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <AddAddress onSuccess={handleSuccess} disableEmail />
            </DialogContent>
        </Dialog>
    )
}

AddAddressDialog.propTypes = {
    setAddress: func,
    onClose: func,
    isVisible: bool
}

AddAddressDialog.defaultProps = {
    setAddress: () => {},
    onClose: undefined,
    isVisible: false
}

export default AddAddressDialog
