import {
    FormControl, Radio, RadioGroup
} from '@material-ui/core'
import {
    array, string, func, bool
} from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import useStyles from './style'
import DisplayAddress from '../DisplayAddress/DisplayAddress'
import AddAddressDialog from '../AddAddressDialog/AddAddressDialog'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'

const AddressSelect = ({
    value,
    onChange,
    name,
    options,
    className,
    allowAddAddress
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const [cookies] = useCookies([COOKIE_LOCATION])
    const shippingLocation = cookies[COOKIE_LOCATION]

    const [isDialogVisible, setIsDialogVisible] = useState(false)

    const handleChange = (event) => {
        if (event.target.value === 'new_address') {
            setIsDialogVisible(true)
        } else {
            onChange(event.target.value)
        }
    }

    return (
        <>
            <FormControl
                color='primary'
                className={className}
            >
                <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
                    {options
                        .filter((option) => option?.country === shippingLocation)
                        .map((option) => (
                            <div key={option.id} className={classes.optionContainer}>
                                <Radio
                                    className={classes.radio}
                                    value={option.id}
                                    checked={value === option.id}
                                    color='primary'
                                    data-qa='radio-accountAddresses-chooseBillingAddress'
                                />
                                <DisplayAddress address={option} isSelect />
                            </div>
                        ))}
                    {
                        allowAddAddress
                        && (
                            <div className={classes.optionContainer}>
                                <Radio
                                    value='new_address'
                                    color='primary'
                                    className={classes.radio}
                                    data-qa='radio-accountAddresses-chooseBillingAddressNewAddress'
                                />
                                <div className={classes.newAddressOption}>
                                    <Typography>
                                        {translations('checkout:new.address.option')}
                                    </Typography>
                                </div>
                            </div>
                        )
                    }

                </RadioGroup>
            </FormControl>
            <AddAddressDialog
                setAddress={onChange}
                isVisible={isDialogVisible}
                onClose={() => setIsDialogVisible(false)}
            />
        </>
    )
}

AddressSelect.propTypes = {
    value: string,
    onChange: func.isRequired,
    name: string.isRequired,
    className: string,
    options: array,
    allowAddAddress: bool
}

AddressSelect.defaultProps = {
    value: undefined,
    className: '',
    options: null,
    allowAddAddress: false
}

export default AddressSelect
