import Typography from '@material-ui/core/Typography'
import { Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const PriceSummary = ({
    totalQuantity, totalPurchasePrice, totalListPrice, className, shipAmount, discounts
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    return (
        <Card className={clsx(classes.summaryContainer, className)} elevation={0}>
            <div>
                {discounts.length > 0 && (
                    <Typography variant='body1' className={classes.summarySubtotal}>
                        {translations('checkout:discount.label')}
                    </Typography>
                )}

                {/* SHIPPING */}
                <Typography variant='body1' style={{ wordBreak: 'nowrap' }}>
                    {translations('checkout:shipping')}
                </Typography>

                <Typography variant='body1' className={classes.priceContainer}>
                    {translations('checkout:grand.total')}
                </Typography>
                <Typography variant='body2'>
                    (
                    {totalQuantity}
                    {' '}
                    {totalQuantity === 1
                        ? `${translations('checkout:item.single')}`
                        : `${translations('checkout:item.multiple')}`}
                    )
                </Typography>
            </div>
            <div className={classes.summaryPrices}>
                {discounts.length > 0  && (
                    <Typography variant='subtitle2' className={classes.summarySubtotal}>
                        {totalPurchasePrice.currency}
                        {' '}
                        {(totalPurchasePrice?.amount - totalListPrice?.amount - (shipAmount?.amount ?? 0)).toFixed(2)}
                    </Typography>
                )}

                {/* SHIPPING */}
                {shipAmount?.displayValue || (discounts.length === 0 && totalPurchasePrice.amount !== totalListPrice.amount)
                    ? (
                        <Typography variant='subtitle2'>
                            { shipAmount?.displayValue ?? `${totalPurchasePrice?.currency} ${(totalPurchasePrice.amount - totalListPrice.amount).toFixed(2)} `}
                        </Typography>
                    )
                    : (
                        <Typography variant='body1'>
                            {translations('checkout:shipping.cost.requirement')}
                        </Typography>
                    )
                }

                <div className={classes.priceContainer}>
                    <Typography variant='subtitle2' className={classes.grandTotal}>
                        {`${totalPurchasePrice?.currency} ${(+totalPurchasePrice?.amount).toFixed(2)}`}
                    </Typography>
                </div>
            </div>
        </Card>
    )
}

PriceSummary.propTypes = {
    totalQuantity: PropTypes.number,
    totalPurchasePrice: PropTypes.object,
    totalListPrice: PropTypes.object,
    className: PropTypes.string,
    shipAmount: PropTypes.shape({
        amount: PropTypes.number,
        channelId: PropTypes.number,
        currency: PropTypes.string,
        displayValue: PropTypes.string
    })
}

PriceSummary.defaultProps = {
    totalQuantity: undefined,
    totalPurchasePrice: {},
    totalListPrice: {},
    className: undefined,
    shipAmount: {}
}

export default PriceSummary
