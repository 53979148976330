import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    form: {
        maxWidth: theme.spacing(60),
        margin: theme.spacing(0, 'auto'),
        display: 'flex',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    searchButton: {
        marginBottom: theme.spacing(0.2),
        marginLeft: 0,
        height: theme.spacing(7),
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0
        }
    },
    input: {
        [theme.breakpoints.up('sm')]: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px'
                }
            }
        }
    }
}))
