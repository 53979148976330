import { useContext, useEffect } from 'react'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const useGoogleSearch = (isLoading, isLoadingMore, itemsPageResult) => {
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)

    const length = itemsPageResult?.items?.length ?? 0

    useEffect(() => {
        if (!isLoading && !isLoadingMore && itemsPageResult?.items) {
            const { items } = itemsPageResult

            const productIds = items?.length ? items.map((item) => item.itemId.code).join(',') : ''

            pushToDataLayer({
                event: 'search',
                eventCategory: 'Search',
                eventAction: 'Search Result',
                productIds
            })
        }
    }, [isLoading, isLoadingMore, length])
}

export default useGoogleSearch
