import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import SyncIcon from '@material-ui/icons/Sync'
import { useUpdateCartItem } from '@bloomreach/connector-components-react'
import IconButton from '@material-ui/core/IconButton'
import { CircularProgress, TextField } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'
import useStyles from './style'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'

const UpdateCartItemForm = ({
    cartId, entry, storeKey, distributionChannel
}) => {
    const classes = useStyles()

    const [quantity, setQuantity] = useState(entry.quantity)
    const [customAttrs] = useState((entry.customAttrs ?? []).map((attr) => {
        const values = attr.values?.map((value) => value ?? '')
        return { name: attr.name, values }
    }))
    const { pushCartActivity } = useContext(SFMCCollectJs)

    const [updateCart, , loading, error] = useUpdateCartItem({
        cartId,
        storeKey,
        distributionChannel
    })

    useEffect(() => {
        setQuantity(entry.quantity)
    }, [entry.quantity])

    const handleOnClick = async () => {
        const responseUpdate = await updateCart({
            quantity,
            customAttrs,
            entryId: entry.id
        })
        if(responseUpdate?.success){
            pushCartActivity(responseUpdate.cart.entries)
        }
    }

    return (
        <>
            <form>
                <Box display='flex'>
                    <TextField
                        type='number'
                        label='Quantity'
                        name='quantity'
                        value={quantity}
                        className={classes.input}
                        inputProps={{ style: { textAlign: 'center' } }}
                        onChange={(e) => setQuantity(parseInt(e.currentTarget.value, 10))}
                    />
                    {loading
                        ? <CircularProgress />
                        : (
                            <IconButton aria-label='refresh' onClick={handleOnClick} className={classes.updateBtn} data-qa='button-cart-refresh'>
                                <SyncIcon />
                            </IconButton>
                        )}
                </Box>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
            </form>
        </>
    )
}

UpdateCartItemForm.propTypes = {
    cartId: PropTypes.string,
    entry: PropTypes.object,
    storeKey: PropTypes.string,
    distributionChannel: PropTypes.string
}

UpdateCartItemForm.defaultProps = {
    cartId: undefined,
    entry: undefined,
    distributionChannel: undefined,
    storeKey: undefined
}

export default UpdateCartItemForm
