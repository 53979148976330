import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => ({
  btnLink: {
    fontWeight: 'bold',
    fontSize: '.875em',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'black'
  },
  atag: {
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'black'
  }
}))