import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    offsetTop: {
        height: 80,
        backgroundColor: theme.palette.imgOverlay.main,

        [theme.breakpoints.up('md')]: {
            height: 80 + 69
        }
    },
    productSlider: {
        paddingBottom: theme.spacing(2),

        '& .react-multi-carousel-item': {
            padding: theme.spacing(2)
        }
    },
    infoContainer: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4, 2),

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(24)
        },

        '& > *': {
            marginBottom: theme.spacing(2)
        }
    },
    divider: {
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
    },
    imgOverlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.imgOverlay.main
    },
    accordion: {
        width: '100%',
        margin: '0!important'
    },
    smallText: {
        fontSize: 12,
        marginBottom: theme.spacing(1.5),
        lineHeight: '15.6px',
        letterSpacing: '0.05em',
        color: theme.palette.primary.main,
        fontFamily: 'DM Sans'
    },
    mobileImgOverlay: {
        position: 'absolute',
        top: -16,
        right: -16,
        bottom: 0,
        left: -16,
        backgroundColor: theme.palette.imgOverlay.main
    },
    colorVariants: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }
}))
