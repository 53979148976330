import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4)
    },
    back: {
        marginRight: theme.spacing(3)
    }
}))
