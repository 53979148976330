import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        overflow: 'hidden'
    },
    imageContainer: {
        position: 'relative'
    },
    fillCover: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            height: '640px'
        }
    },
    content: {
        position: 'absolute',
        top: theme.spacing(7),
        bottom: theme.spacing(7),
        width: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(-50%, 50%)'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(15),
            paddingRight: theme.spacing(15)
        }
    },
    preHeadline: {
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '1px',
        lineHeight: '32.5px',
        color: 'white',
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    headline: {
        color: 'white',
        [theme.breakpoints.down('md')]: {
            fontSize: '38px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px'
        },
        textAlign: 'center'
    },
    button: {
        marginTop: 'auto',
        marginBottom: theme.spacing(0.5)
    },
    mobileButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3, 0)
    }
}))
