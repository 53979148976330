import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        boxShadow: 'none',
        transition: theme.transitions.create(['background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.complex
        })
    },
    shadowBar: {
        background: `linear-gradient(to bottom,#25211e 0,rgba(37,33,30,0) 100%)`,
        width: '100%',
        height: '150%',
        top: 0,
        left: 0,
        position:'absolute'
    },
    placeholder: {
        height: '80px',
        background: 'transparent'
    },
    placeholder2: {
        height: '69px',
        background: 'transparent'
    },
    placeholder3: {
        background: 'transparent'
    },
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between'
    },
    toolbarTop: {
        height: '80px',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #BFBFBF'
    },
    topLeft: {
        paddingLeft: theme.spacing(5),
        textAlign: 'start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    colorBlack: {
        color: theme.palette.secondary.contrastText
    },
    topRight: {
        paddingRight: theme.spacing(5),
        textAlign: 'end',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topRightItem: {
        fontSize: '12px',
        paddingLeft: theme.spacing(5)
    },
    toolbarBottom: {
        borderBottom: '1px solid #BFBFBF',
        background: 'transparent',
        justifyContent: 'center'
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0
            }
        }
    },
    mainHeader: {
        position: 'absolute',
        left: '-1000000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden'
    }
}))
