import makeStyles from '@material-ui/styles/makeStyles'
import { wrap } from 'lodash'

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: theme.spacing(0, 'auto'),
        flexWrap: 'wrap',
        maxWidth: theme.breakpoints.values.lg,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            margin: theme.spacing(0)
        }
    },
    loyaltyPointsContainer: {
        width: '40%',
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
            width: '90%',
        }
    },
    updateProfileContainer: {
        maxWidth: theme.breakpoints.values.s,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3)
        }
    },
    title: {
        margin: theme.spacing(3, 0),
        width: '100%'
    },
    updateButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: theme.spacing(2, 0)
    },

    gender: {
        width: '100%'
    }

}))
