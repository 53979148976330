import { Grid, Divider } from '@material-ui/core'
import { node } from 'prop-types'

const TextDivider = ({ children, ...props }) => (
    <Grid container alignItems='center' spacing={3} {...props}>
        <Grid item xs>
            <Divider />
        </Grid>
        <Grid item>{children}</Grid>
        <Grid item xs>
            <Divider />
        </Grid>
    </Grid>
)

TextDivider.propTypes = {
    children: node
}

TextDivider.defaultProps = {
    children: null
}

export default TextDivider
