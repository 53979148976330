import getConfig from 'next/config'

// TODO: remove when bloomreach implements channelKey as param
const useChannelKeyWorkaround = (channelKey) => {
    const { publicRuntimeConfig } = getConfig()
    switch (channelKey) {
    case 'channel-world':
        return publicRuntimeConfig.channelKeyWorld
    case 'channel-us':
        return publicRuntimeConfig.channelKeyUS
    case 'channel-it':
        return publicRuntimeConfig.channelKeyIT
    case 'channel-fr':
        return publicRuntimeConfig.channelKeyFR
    case 'channel-ru':
        return publicRuntimeConfig.channelKeyRU
    case 'channel-uk':
        return publicRuntimeConfig.channelKeyUK
    default:
        return publicRuntimeConfig.channelKeyEU
    }
}

export default useChannelKeyWorkaround
