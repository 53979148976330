import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {
    object, func, oneOf, string
} from 'prop-types'
import clsx from 'clsx'
import { useMediaQuery } from '@material-ui/core'
import headCells from '../../../_Mappings/orderHeadCells'
import theme from '../../../../lib/theme'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const EnhancedTableHead = (props) => {
    const translations = useTranslation()
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props
    const createSortHandler = (property) => (е) => {
        onRequestSort(е, property)
    }

    const useIsMobile = () => useMediaQuery((thm) => thm.breakpoints.down(theme.breakpoints.values.sm))
    const isMobile = useIsMobile()

    return (
        <TableHead className={clsx(isMobile && classes.visuallyHidden)}>
            <TableRow>
                {headCells.map((headCell) => {
                    const { id, align, label } = headCell
                    return (
                        <TableCell
                            key={id}
                            align={align}
                            sortDirection={orderBy === id ? order : false}
                            className={classes.tableHeadCell}
                        >
                            <TableSortLabel
                                active={orderBy === id}
                                direction={orderBy === id ? order : 'asc'}
                                onClick={createSortHandler(id)}
                            >
                                {translations(label)}
                                {orderBy === id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    classes: object.isRequired,
    onRequestSort: func.isRequired,
    order: oneOf(['asc', 'desc']).isRequired,
    orderBy: string.isRequired
}

export default EnhancedTableHead
