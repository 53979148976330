const shippingCountries = [
    {
        label: 'Albania',
        value: 'AL'
    },
    {
        label: 'Australia',
        value: 'AU'
    },
    {
        label: 'Austria',
        value: 'AT'
    },
    {
        label: 'Azerbaijan',
        value: 'AZ'
    },
    {
        label: 'Belgium',
        value: 'BE'
    },
    {
        label: 'Bosnia and Herzegovina',
        value: 'BA'
    },
    {
        label: 'Bulgaria',
        value: 'BG'
    },
    {
        label: 'Canada',
        value: 'CA'
    },
    {
        label: 'Croatia',
        value: 'HR'
    },
    {
        label: 'Cyprus',
        value: 'CY'
    },
    {
        label: 'Czech Republic',
        value: 'CZ'
    },
    {
        label: 'Denmark',
        value: 'DK'
    },
    {
        label: 'Estonia',
        value: 'EE'
    },
    {
        label: 'Finland',
        value: 'FI'
    },
    {
        label: 'France',
        value: 'FR'
    },
    {
        label: 'Georgia',
        value: 'GE'
    },
    {
        label: 'Germany',
        value: 'DE'
    },
    {
        label: 'Greece',
        value: 'GR'
    },
    {
        label: 'Hungary',
        value: 'HU'
    },
    {
        label: 'India',
        value: 'IN'
    },
    {
        label: 'Israel',
        value: 'IL'
    },
    {
        label: 'Italia',
        value: 'IT'
    },
    {
        label: 'Japan',
        value: 'JP'
    },
    {
        label: 'Latvia',
        value: 'LV'
    },
    {
        label: 'Lithuania',
        value: 'LT'
    },
    {
        label: 'Luxembourg',
        value: 'LU'
    },
    {
        label: 'Malta',
        value: 'MT'
    },
    {
        label: 'Moldova',
        value: 'MD'
    },
    {
        label: 'Monaco',
        value: 'MC'
    },
    {
        label: 'Montenegro',
        value: 'ME'
    },
    {
        label: 'Netherlands',
        value: 'NL'
    },
    {
        label: 'Norway',
        value: 'NO'
    },
    {
        label: 'Poland',
        value: 'PL'
    },
    {
        label: 'Portugal',
        value: 'PT'
    },
    {
        label: 'Romania',
        value: 'RO'
    },
    {
        label: 'Russian Federation',
        value: 'RU'
    },
    {
        label: 'Saudi Arabia',
        value: 'SA'
    },
    {
        label: 'Serbia',
        value: 'RS'
    },
    {
        label: 'Slovakia',
        value: 'SK'
    },
    {
        label: 'Slovenia',
        value: 'SI'
    },
    {
        label: 'Spain',
        value: 'ES'
    },
    {
        label: 'Sweden',
        value: 'SE'
    },
    {
        label: 'Switzerland',
        value: 'CH'
    },
    {
        label: 'Turkey',
        value: 'TR'
    },
    {
        label: 'Ukraine',
        value: 'UA'
    },
    {
        label: 'United Kingdom',
        value: 'GB'
    },
    {
        label: 'United States',
        value: 'US'
    }
]

export default shippingCountries
