import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    select: {
        //margin: theme.spacing(4, 0)
        marginBottom: theme.spacing(4)
    },
    order: {
        order: 1
    },
    openButton: {
        display: 'block'
    },
    dialogAction: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '& > *': {
            flexBasis: '100%'
        }
    },
    titleWithClose: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonClose: {
        alignSelf: 'flex-start',
        marginRight: theme.spacing(-2),
        marginTop: theme.spacing(-1)
    },
    labelwarn: {
        marginTop: theme.spacing(-2)
    }
}))
