import { BrComponentContext } from '@bloomreach/react-sdk'
import { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { useMediaQuery } from '@material-ui/core'
import { scroller } from 'react-scroll'
import clsx from 'clsx'
import useStyles from './style'
import BaldininiDownArrow from '../../../public/arrow-down.svg'
import layoutMap from './layoutMap'
import Banner from '../../_Elements/Banner/Banner'

const HeroBanner = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const params = component.getParameters()
    const { videoEntry } = component.getModels()
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    // First sibling div after header section.
    // Might need a better solution, but is working for now.
    const setScrollTarget = () => {
        document.querySelector('.makeStyles-root-1')
            ?.nextElementSibling
            ?.setAttribute('name', 'scroll-target')
        document.querySelector('.jss1')
            ?.nextElementSibling
            ?.setAttribute('name', 'scroll-target')
    }

    const scrollToSection = () => {
        return 
        setScrollTarget()

        if (document.getElementsByName('scroll-target')) {
            scroller.scrollTo('scroll-target', {
                duration: 800,
                delay: 0,
                // offset backwards to prevent scrolling behind the headermenu
                offset: -150,
                smooth: 'easeInOutQuart'
            })
        }
    }

    const getHeadlineContainerContent = (cssClassSuffix = '') => (
        <>
            {params.preHeadline && (
                <Typography
                    component= 'p'
                    variant={isDesktop ? 'h2' : 'h3'}
                    className={classes[`preHeadline${cssClassSuffix}`]}
                >
                    {params.preHeadline}
                </Typography>
            )}
            {params.headline && (
                <Typography
                    component= 'h2'
                    variant={isDesktop ? 'h4' : 'h5'}
                    className={classes[`headline${cssClassSuffix}`]}
                >
                    {params.headline}
                </Typography>
            )}
        </>
    )

    const isFullImage = params.layoutOptions === 'Full image banner'
    const isSlimImage = params.layoutOptions === 'Slim image banner'
    const isSlimImageWithIntro = isSlimImage && params.intro

    const hasHeadlineContainer = (isDesktop && (isFullImage || isSlimImageWithIntro)) || !isDesktop

    return (
        <>
            {(params.image || params.video) && (params.layoutOptions !== 'Text-only banner')
                ? (
                    <>
                        <div className={clsx(
                            { [classes.slimImageHeight]: isSlimImage },
                            { [classes.fillCoverFull]: isFullImage },
                            { [classes.fillCoverSlim]: isSlimImage },
                            classes.imageContainer
                        )}
                        >
                            <Banner banner={{ ...params, videoEntry }} layoutMap={layoutMap} />

                            {
                                (hasHeadlineContainer)
                                && (
                                    <div className={clsx(classes.widthConstraint, classes.headlineContainer)}>
                                        { getHeadlineContainerContent() }
                                    </div>
                                )
                            }
                        </div>

                        {isSlimImageWithIntro
                        && (
                            <div
                                className={classes.introContainer}
                                role='button'
                                tabIndex={0}
                                onClick={scrollToSection}
                                onKeyDown={scrollToSection}
                            >
                                <div className={clsx(classes.widthConstraint, classes.introTextContainer)}>
                                    <Typography component= 'h2' variant='h3' className={classes.intro}>
                                        {params.intro}
                                    </Typography>
                                </div>
                                <div className={clsx(classes.widthConstraint, classes.introLinkContainer)}>
                                    <Typography variant='body1'>
                                        {params.anchorLinkLabel}
                                    </Typography>
                                    <BaldininiDownArrow className={classes.anchorLinkIcon} />
                                </div>
                            </div>
                        )}
                    </>
                )
                : (
                    <div className={classes.textOnlyContainer}>
                        <div className={clsx(classes.widthConstraint, classes.textOnlyTextContainer)}>
                            { getHeadlineContainerContent('TextOnly') }
                        </div>
                    </div>
                )}
        </>
    )
}

export default HeroBanner
