import Head from 'next/head'
import ScalapayLogo from '../../../public/scalapay_Logo.svg'
import { COOKIE_LANGUAGE } from '../../_Mappings/cookies'
import useStyles from './style'
import clsx from 'clsx'
import { useCookies } from 'react-cookie'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Grid, Typography, useMediaQuery
} from '@material-ui/core'
const Scalapay = () => {
    const classes = useStyles()
    const [cookies] = useCookies([COOKIE_LANGUAGE])
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'

    React.useEffect(() => {
        const timer = setTimeout(() => {
            const target = document.querySelector('scalapay-widget')?.shadowRoot?.lastChild?.shadowRoot?.lastChild
            if (target) {
                target.style.setProperty('font-family', 'DM sans')
                target.style.setProperty('font-size', '12px')
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);



    return (
        <>
            <Head>
                <script type="module" src="https://cdn.scalapay.com/widget/v3/js/scalapay-widget.esm.js"></script>
                <script nomodule src="https://cdn.scalapay.com/widget/v3/js/scalapay-widget.js"></script>
            </Head>

            <Grid container spacing={2}
                justifyContent="center"
                alignItems="center">
                <Grid item xs={3} spacing={3}>
                    <ScalapayLogo ></ScalapayLogo>
                </Grid>
                <Grid item xs={6} className={clsx(classes.spPayInstallment)}>
                    <Typography component='span' variant={'body2'} >
                        <scalapay-widget
                            frequency-number='30'
                            number-of-installments='3'
                            hide='false'
                            hide-price='false'
                            min='5'
                            max='1500'
                            amount-selectors='["#price-container"]'
                            currency-position='before'
                            currency-display='symbol'
                            logo-size='100'
                            theme='primary'
                            locale={language =='ru' ? 'en' : language}
                        >
                        </scalapay-widget>
                    </Typography>
                </Grid>
            </Grid>










        </>
    )
}

export default Scalapay
