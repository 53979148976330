import { useCookies } from 'react-cookie'
import { func, shape } from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useMediaQuery } from '@material-ui/core'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import storeKeys from '../../../_Mappings/storeKeys'
import ProductGridClientRender from '../ProductGridByCategoryOrTag/ProductGridClientRender'
import { getCountLoadMoreClickFromHistory, getFacetFilterFromUrl } from '../../../../lib/utils'
import { FilterSortPanelProvider } from '../FilterSortPanel/FilterSortPanel'
import FilterSortPanelMobile from '../FilterSortPanel/FilterSortPanelMobile'
import FilterSortPanelDesktop from '../FilterSortPanel/FilterSortPanelDesktop'
import theme from '../../../../lib/theme'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import UserContext from '../../../_Elements/UserContext/UserCx'
import { useContext } from 'react';

const teaserHelper = (ref, page) => {
    const {
        buttonLink, buttonText, headline, subline, image: imageRef
    } = page.getContent(ref)?.model?.data

    const imageData = page.getContent(imageRef)?.model?.data

    return {
        buttonLink,
        buttonText,
        headline,
        subline,
        imageVariants: {
            vertical: imageData?.vertical?.links?.site?.href,
            portrait: imageData?.portrait?.links?.site?.href,
            square: imageData?.square?.links?.site?.href
        }
    }
}

// MASONRY
const ProductGridTeaser = ({ page, component }) => {
    const {
        pageSize,
        categoryId = '',
        gridLayout,
        tag,
        teaser1,
        teaser2,
        teaser1PositionsInGrid,
        teaser1SizeOnDesktop,
        teaser2PositionsInGrid,
        teaser2SizeOnDesktop
    } = component.getParameters()

    const { products, filters } = usePageCfg()
    const componentId = component.getId()
    const [cookies] = useCookies([COOKIE_LOCATION, '_br_uid_2'])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const brUid2 = cookies?._br_uid_2
    const { storeKey, currency } = storeKeys[location] || {}
    const [facetResult, setFacetResult] = useState({})

    const { query } = useRouter()

    const urlFacetFilter = useMemo(() => getFacetFilterFromUrl(componentId, query), [])
    const [facetFieldFilters, setFacetFieldFilters] = useState(urlFacetFilter)

    const [showPanel, setShowPanel] = useState(false)
    const [total, setTotal] = useState(0)
    const [totalHome, setTotalHome] = useState(-1)
    const isMobile = useMediaQuery((thm) => thm.breakpoints.down(theme.breakpoints.values.m))
    const { scrollRef } = useContext(UserContext);

    const teaser = useMemo(() => {
        const _teaser = []

        if (teaser1) {
            _teaser.push({
                position: +teaser1PositionsInGrid,
                width: +teaser1SizeOnDesktop.split('x')[0],
                height: +teaser1SizeOnDesktop.split('x')[1],
                ...teaserHelper(teaser1, page)
            })
        }

        if (teaser2) {
            _teaser.push({
                position: +teaser2PositionsInGrid,
                width: +teaser2SizeOnDesktop.split('x')[0],
                height: +teaser2SizeOnDesktop.split('x')[1],
                ...teaserHelper(teaser2, page)
            })
        }

        return _teaser
    }, [
        teaser1,
        teaser2,
        teaser1PositionsInGrid,
        teaser2PositionsInGrid,
        teaser1SizeOnDesktop,
        teaser2SizeOnDesktop
    ])

    useEffect(() => {
        setShowPanel(true)
        window.scrollTo(0, scrollRef.current.scrollPos);

        const handleScrollPos = () => {
        //every time the window is scrolled, update the reference. This will not cause a re-render, meaning smooth uninterrupted scrolling.
        scrollRef.current.scrollPos = window.scrollY
        };

        window.addEventListener('scroll', handleScrollPos);

        return () => {
        //remove event listener on unmount
        window.removeEventListener('scroll', handleScrollPos);
        };
    }, [])

    if (!(storeKey && currency) || (tag === '' && categoryId === '')) return null

    return (
        <>
            {showPanel && (
                <FilterSortPanelProvider
                    total={total}
                    facetResult={facetResult}
                    facetFieldFilters={facetFieldFilters}
                    setFacetFieldFilters={setFacetFieldFilters}
                    componentId={componentId}
                    totalHome={totalHome}
                >
                    {isMobile
                        ? <FilterSortPanelMobile />
                        : <FilterSortPanelDesktop />}

                </FilterSortPanelProvider>
            )}

            <ProductGridClientRender
                categoryId={categoryId}
                page={page}
                products={products}
                filters={filters}
                teaser={teaser}
                gridLayout={gridLayout}
                setTotal={setTotal}
                setFacetResult={setFacetResult}
                setTotalHome={setTotalHome}
            />

        </>
    )
}

ProductGridTeaser.propTypes = {
    page: shape({
        getUrl: func
    }),
    component: shape({
        getParameters: func
    })
}

ProductGridTeaser.defaultProps = {
    page: undefined,
    component: undefined
}

export default ProductGridTeaser
