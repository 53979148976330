import Typography from '@material-ui/core/Typography'
import { Card } from '@material-ui/core'
import { object, bool } from 'prop-types'
import useStyles from './style'

const DisplayAddress = ({ address, isSelect }) => {
    const classes = useStyles({ isSelect })

    const {
        firstName,
        lastName,
        middleName,
        additionalStreetInfo,
        streetAddress,
        city,
        state,
        postalCode,
        country,
        phone
    } = address

    return (
        <Card className={classes.summaryContainer} elevation={0}>
            <Typography>
                {`${firstName} ${middleName || ''} ${lastName}`}
            </Typography>
            <Typography>
                {streetAddress}
            </Typography>
            <Typography>
                {additionalStreetInfo}
            </Typography>
            <Typography>
                {`${postalCode} ${city}`}
            </Typography>
            <Typography>
                {`${country} ${state || ''}`}
            </Typography>
            <Typography>
                {phone}
            </Typography>
        </Card>
    )
}

DisplayAddress.propTypes = {
    address: object.isRequired,
    isSelect: bool
}

DisplayAddress.defaultProps = {
    isSelect: false
}

export default DisplayAddress
