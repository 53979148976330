import { SvgIcon } from '@material-ui/core'

// eslint-disable-next-line react/prop-types
const Search = (props, { stroke }) => (
    <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        {...props}
    >
        <path
            d='M22 22L16.5 16.5M19 10.5C19 15.1944 15.1944 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5Z'
            stroke={stroke}
            fill='none'
        />

    </SvgIcon>

)

export default Search
