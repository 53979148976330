import { gql } from '@apollo/client'

const CREATE_WISHLIST_MUTATION = gql`
    mutation createWishlist {
        createWishlist(createWishlistInput: { name: "default" }) {
            success
        }
    }
`

export default CREATE_WISHLIST_MUTATION
