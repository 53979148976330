import PropTypes from 'prop-types'
import { Radio } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useStyles from './style'

const SizeButton = ({
    size, checked, disabled, ...props
}) => {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            checked={checked}
            disabled={disabled}
            disableRipple
            checkedIcon={(
                <Typography
                    variant='subtitle1'
                    component='p'
                    className={disabled ? classes.disabled : classes.iconChecked}
                >

                    {size}
                </Typography>
            )}
            icon={(
                <Typography
                    variant='subtitle1'
                    component='p'
                    className={disabled ? classes.disabled : classes.icon}
                >
                    {size}
                </Typography>
            )}
            {...props}
        />
    )
}

SizeButton.propTypes = {
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool

}

SizeButton.defaultProps = {
    checked: false,
    disabled: false
}
export default SizeButton
