import makeStyles from '@material-ui/styles/makeStyles'
import theme from '../../../../lib/theme'

export default makeStyles(() => ({
    image: {
        display: 'block',
        marginRight: theme.spacing(2),

        '& > svg': {
            width: '100%',
            height: '100%'
        }
    }
}))
