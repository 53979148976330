import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        width: theme.breakpoints.values.s,
        margin: theme.spacing(1, 0),
        padding: theme.spacing(3, 3),
        backgroundColor: theme.palette.imgOverlay.main,
        borderRadius: theme.spacing(),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    paddingTop: {
        paddingTop: `${theme.spacing(3)}px !important`
    },
    title: {
        fontWeight: 'bold'
    },
    form: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    deleteText: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    button: {
        padding: theme.spacing(3)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0
    },
    chip: {
        margin: theme.spacing(0.5),
        borderRadius: '4px'
    }
}))
