import { array } from 'prop-types'
import {
    FormControl, FormControlLabel, FormLabel, RadioGroup
} from '@material-ui/core'
import { useState } from 'react'
import ColorSwatchButton from './components/ColorSwatchButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'

const ColorSwatch = ({ colors }) => {
    const classes = useStyles()
    const translations = useTranslation()

    const [selectedColorIndex, setSelectedColorIndex] = useState(0)
    const selectedColor = colors[selectedColorIndex]
    const handleChange = (e) => setSelectedColorIndex(+e.target.value)

    return (
        <FormControl component='fieldset' className={classes.root}>
            <FormLabel className={classes.label}>
                {selectedColor.colour2Hex
                    ? `${translations(`colors:${selectedColor.colour1Hex}`)}-${translations(`colors:${selectedColor.colour2Hex}`)}`
                    : translations(`colors:${selectedColor.colour1Hex}`)}
            </FormLabel>

            <RadioGroup name='color' onChange={handleChange} row>

                {colors.map((color, index) => (
                    <FormControlLabel
                        className={classes.formControlLabel}
                        key={JSON.stringify(color)}
                        value={index}
                        control={<ColorSwatchButton color={color} checked={index === selectedColorIndex} />}
                        label={selectedColor.colour2Hex
                            ? `${translations(`colors:${selectedColor.colour1Hex}`)}-${translations(`colors:${selectedColor.colour2Hex}`)}`
                            : translations(`colors:${selectedColor.colour1Hex}`)}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

ColorSwatch.propTypes = {
    colors: array.isRequired
}

export default ColorSwatch
