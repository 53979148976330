import { gql } from '@apollo/client'

const GET_LIGHT_MAIN_ITEM_BY_ID = gql`
    query findItemById($id: String!, $queryHint: QueryHintInput) {
        findItemById(id: $id, queryHint: $queryHint) {
            ...ItemFragment
        }
    }
    fragment ItemFragment on Item {
        ...ItemLikeFragment
    }
    fragment ItemLikeFragment on ItemLike {
        itemId {
            id
            code
        }
        displayName
        description
        imageSet {
            original {
                link {
                    href
                }
            }
        }
    }
    
`

export default GET_LIGHT_MAIN_ITEM_BY_ID
