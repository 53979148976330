import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import useStyles from './style'
import Link from '../../Link'

const DesktopMenuButtonLink = ({
    item, color, isExpanded, onMouseOver
}) => {
    const classes = useStyles()
    const hasSubMenu = !!item.getChildren().length
    const name = item.getName()
    const url = item.getUrl() || ''

    return (
        hasSubMenu
            ? (
                <Button
                    onMouseOver={onMouseOver}
                    component={hasSubMenu ? Button : Link}
                    color={color}
                    className={classes.button}
                    aria-haspopup={hasSubMenu ? true : null}
                    aria-expanded={isExpanded}
                    aria-controls={hasSubMenu ? 'menu' : null}
                >
                    <Typography variant='subtitle1' color={color} component='span'>
                        {name}
                    </Typography>
                </Button>
            )
            : (
                <Link
                    onMouseOver={onMouseOver}
                    href={url}
                    color={color}
                    className={classes.button}
                >
                    <Typography variant='subtitle1' color={color} component='span'>
                        {name}
                    </Typography>
                </Link>
            )
    )
}

DesktopMenuButtonLink.propTypes = {
    item: PropTypes.object,
    color: PropTypes.string,
    isExpanded: PropTypes.bool,
    onMouseOver: PropTypes.func
}

DesktopMenuButtonLink.defaultProps = {
    item: undefined,
    color: undefined,
    isExpanded: false,
    onMouseOver: null
}

export default DesktopMenuButtonLink
