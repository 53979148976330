const shippingRegions = {
    AL: 'Europe',
    AU: 'World',
    AT: 'Europe',
    AZ: 'World',
    BE: 'Europe',
    BA: 'Europe',
    BG: 'Europe',
    CA: 'United States',
    HR: 'Europe',
    CY: 'Europe',
    CZ: 'Europe',
    DK: 'Europe',
    EE: 'Europe',
    FI: 'Europe',
    FR: 'Europe',
    GE: 'World',
    DE: 'Europe',
    GR: 'Europe',
    HU: 'Europe',
    IN: 'World',
    IL: 'World',
    IT: 'Italia',
    JP: 'World',
    LV: 'Europe',
    LT: 'Europe',
    LU: 'Europe',
    MT: 'Europe',
    MD: 'World',
    MC: 'Europe',
    ME: 'Europe',
    NL: 'Europe',
    NO: 'Europe',
    PL: 'Europe',
    PT: 'Europe',
    RO: 'Europe',
    RU: 'Russian Area',
    SA: 'World',
    RS: 'Europe',
    SK: 'Europe',
    SI: 'Europe',
    ES: 'Europe',
    SE: 'Europe',
    CH: 'Europe',
    TR: 'World',
    UA: 'World',
    GB: 'United Kingdom',
    US: 'United States'
}

export default shippingRegions
