import { useCallback, useContext } from 'react'
import { useCart, useRemoveFromCart } from '@bloomreach/connector-components-react'
import { useCookies } from 'react-cookie'
import LoginContext from '../_Modules/Account/Login/LoginContext'
import storeKeys from '../_Mappings/storeKeys'
import { COOKIE_LOCATION } from '../_Mappings/cookies'
import channelKeys from '../_Mappings/channelKeys'
import useChannelKeyWorkaround from '../_Modules/Cart/useChannelKeyWorkaround'

const useRemoveCartItems = () => {
    const { cartId } = useContext(LoginContext)

    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}

    // TODO: remove when bloomreach implements channelKey as param
    const distributionChannel = channelKeys[storeKey]
    const distributionChannelWorkaround = useChannelKeyWorkaround(distributionChannel)

    const [cart] = useCart({
        cartId,
        storeKey
    })
    const [removeFromCart] = useRemoveFromCart({
        cartId,
        storeKey,
        distributionChannel: distributionChannelWorkaround
    })

    return useCallback(async (entryIds /* optional, defaults to all cart items  */) => {
        if (cart) {
            const ids = entryIds ?? cart?.entries
                .map((entry) => entry.id)
                .filter(Boolean) ?? []

            // eslint-disable-next-line no-restricted-syntax
            for (const entryId of ids) {
                // eslint-disable-next-line no-await-in-loop
                await removeFromCart({ entryId })
            }
        }
    }, [cart, removeFromCart])
}

export default useRemoveCartItems
