import { Card } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import Image from 'next/image'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
    bool, func, number, object, string
} from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import ProductLink from '../ProductLink'
import DisplayPrice from '../DisplayPrice/DisplayPrice'
import ColorVariants from '../ProductSize/components/ColorVariants'
import { stringToArr, getVarAttrs } from '../../../../lib/utils'
import PromotionBadges from '../../../_Elements/PromotionBadges/PromotionBadges'
import WishlistButton from '../../../_Elements/Wishlist/WishlistButton/WishlistButton'

const ProductCard = ({
    item, gridLayout, position, itemIsVariant, onClick
}) => {
    const classes = useStyles({ gridLayout })

    const { colourVariants } = getVarAttrs(itemIsVariant ? item : item.variants[0])
    const colorVariantsList = stringToArr(colourVariants)

    const productGridImageLoader = ({ src, width }) => src

    return (
        <Card
            onClick={onClick}
            key={item.itemId?.id}
            className={classes.card}
            component={ProductLink}
            item={item}
            position={position}
        >
            {item.imageSet?.original?.link?.href && (
                <CardMedia className={classes.imgContainer}>
                    <Image
                        src={item.imageSet.original.link.href}
                        height={480}
                        width={480}
                        loader={productGridImageLoader}
                        priority
                        className={clsx(classes.bounce, classes.image)}
                        alt= {item.displayName + ' image'}
                        title= {item.displayName}
                    />
                    <div className={clsx(classes.imgOverlay)} />

                    <div
                        className={classes.topInterferer}
                    >
                        <PromotionBadges productVariant={itemIsVariant ? item : item.variants[0]} />

                        <WishlistButton
                            product={item}
                            className={classes.wishlistIcon}
                        />
                    </div>
                </CardMedia>
            )}
            <CardContent className={classes.cardContent}>
                <Typography variant='subtitle1' className={classes.productName} component='h2'>
                    {item.displayName}
                </Typography>
                <DisplayPrice item={item} />
                { !itemIsVariant && (
                    <ColorVariants colorVariants={colorVariantsList} />
                )}
            </CardContent>
        </Card>
    )
}

ProductCard.propTypes = {
    item: object.isRequired,
    gridLayout: string,
    position: number,
    itemIsVariant: bool,
    onClick: func
}

ProductCard.defaultProps = {
    gridLayout: undefined,
    position: undefined,
    itemIsVariant: false,
    onClick: undefined
}

export default ProductCard
