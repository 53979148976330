import Typography from '@material-ui/core/Typography'
import { Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const SubtotalCard = ({
    totalListPrice, className
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    return (
        <Card className={clsx(classes.summaryContainer, className)} elevation={0}>
            <div>
                <Typography variant='body1' className={classes.summarySubtotal}>
                    {translations('checkout:subtotal')}
                </Typography>
            </div>
            <div className={classes.summaryPrices}>
                <Typography variant='subtitle2' className={classes.grandTotal}>
                    { totalListPrice?.displayValue }
                </Typography>
            </div>
        </Card>
    )
}

SubtotalCard.propTypes = {
    totalListPrice: PropTypes.object,
    className: PropTypes.string
}

SubtotalCard.defaultProps = {
    totalListPrice: {},
    className: undefined
}

export default SubtotalCard
