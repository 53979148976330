import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    formControl: {
        maxWidth: theme.breakpoints.values.s,
        display: 'flex',
        alignItems: 'center'
    },
    radioGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formControlLabel: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            display: 'none'
        }
    },
    sizeGuide: {
        marginRight: theme.spacing(2)
    }
}))
