import {
    bool, func, object, string
} from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { MenuItem, List } from '@material-ui/core'
import React from 'react'
import Link from '../../Link'
import useStyles from './style'
import { useRouter } from 'next/router'

const SubItem = ({
    subitem,
    handleMenuClose,
    className,
    isTitle
}) => {
    const classes = useStyles()
    const children = subitem.getChildren()
    const name = subitem.getName()
    const url = subitem.getUrl()
    const router = useRouter()
    return (
        <MenuItem
            onClick={handleMenuClose}
            className={className}
        >
            {url ? (
                
                    <Link
                    href={url}
                    variant={isTitle ? 'subtitle2' : 'body1'}
                    className={classes.itemLink}
                    
                >
                    {name}
                </Link>
                
                
            ) : (
                <Typography component='span' variant={isTitle ? 'subtitle2' : 'body1'} className={classes.itemText}>
                    {name}
                </Typography>
            )}

            {children.length ? (
                <List disablePadding className={classes.subList}>
                    {children.map((item) => (
                        <SubItem
                            subitem={item}
                            key={`${item.getName()}-sub-menu-item`}
                            className={classes.subItem}
                        />
                    ))}
                </List>
            ) : null}
        </MenuItem>
    )
}

SubItem.propTypes = {
    subitem: object,
    isTitle: bool,
    handleMenuClose: func,
    className: string
}

SubItem.defaultProps = {
    subitem: undefined,
    isTitle: false,
    handleMenuClose: () => {},
    className: undefined
}

export default SubItem
