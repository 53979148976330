import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    wrapper: {
        position: 'relative'
    },
    loadingSpinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))
