import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => ({
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    formSelect: {
        width: '100%'
    }
}))
