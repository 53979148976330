import clsx from 'clsx'
import { bool, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import useStyles from './style'

const CustomDot = ({
    isSelected, autoPlaySpeed, length, currentSlide
}) => {
    const classes = useStyles()
    const [transitionParams, setTransitionParams] = useState({})
    const [width, setWidth] = useState(null)

    useEffect(() => {
        const longer = autoPlaySpeed > 500
        const transitionDuration = `${(longer ? autoPlaySpeed - 500 : autoPlaySpeed) / 1000}s`
        const transitionDelay = longer ? '0.25s' : null

        setTransitionParams({
            longer,
            transitionDuration,
            transitionDelay
        })
    }, [autoPlaySpeed, isSelected])

    let timer

    useEffect(() => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            setWidth(isSelected && '100%')
        }, 100)
    }, [isSelected])

    return (
        <li className={classes.dotItemContainer}>
            <div className={clsx(classes.dotItem, isSelected && classes.dotActive)}>
                <div className={classes.progressBg}>
                    <div
                        style={{ transitionDuration: transitionParams?.transitionDuration, transitionDelay: transitionParams?.transitionDelay, width }}
                        className={classes.progressSlider}
                    />
                </div>
                {String(currentSlide + 1).padStart(2, '0')}
                &nbsp;&ndash;&nbsp;
                {String(length).padStart(2, '0')}
            </div>
        </li>
    )
}

CustomDot.propTypes = {
    autoPlaySpeed: number,
    length: number,
    currentSlide: number,
    isSelected: bool
}

CustomDot.defaultProps = {
    autoPlaySpeed: 5000,
    length: 5,
    currentSlide: undefined,
    isSelected: false
}

export default CustomDot
