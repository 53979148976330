import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    optionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    radio: {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(3),
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    },
    newAddressOption: {
        alignSelf: 'flex-start',
        margin: theme.spacing(1, 3, 1, 0),
        padding: theme.spacing(3.5, 3)
    }
}))
