import { useContext } from 'react'
import { BrComponentContext } from '@bloomreach/react-sdk'
import Image from 'next/image'
import clsx from 'clsx'
import {
    Card, Grid, Typography, CardMedia, Container, CardContent
} from '@material-ui/core'
import useStyles from './style'
import Link from '../../_Elements/Link'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'

const HeroTeaser = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const params = component.getParameters()
    const { componentHeadline } = params
    const { cmsBaseUrl } = usePageCfg()

    const heroTeaserImageLoader = ({ src, width }) => `/_next/image?url=${encodeURI(src)}&w=${width}&q=${width === 480 ? 95 : 85}`

    const teasers = []
    for (let i = 1; i < 4; i += 1) {
        const image = params[`teaser${i}Image`]
        if (image) {
            teasers.push(
                {
                    image,
                    headline: params[`teaser${i}Headline`],
                    text: params[`teaser${i}Text`],
                    link: params[`teaser${i}Link`],
                    key: i - 1
                }
            )
        }
    }

    return (
        <Container maxWidth='xl' disableGutters>
            <div className={classes.root}>
                {componentHeadline && (
                    <Typography align='center' component='h2' variant='h5' className={classes.headline}>
                        {componentHeadline}
                    </Typography>
                )}
                <Grid container className={classes.grid} spacing={2}>
                    {teasers.map((teaser) => {
                        const fileName = teaser.image.substring(teaser.image.lastIndexOf('/') + 1)
                        const imagePath = `${cmsBaseUrl}/binaries${teaser.image}`
                        const src = `${imagePath}/${fileName}/baldininibrxm:portraitbanner`
                        const hasHeadline = !!teaser.headline
                        const hasText = !!teaser.text
                        const hasCardContent = hasHeadline || hasText
                        const href = teaser.link ? teaser.link : undefined

                        return (
                            <Grid item xs={12} md={4} key={teaser.key}>
                                <Card
                                    className={clsx(classes.card, classes.teaserContainer)}
                                    href={href}
                                    component={!href ? href : Link}
                                >
                                    <CardMedia className={classes.imgContainer}>
                                        <Image
                                            className={classes.img}
                                            width={460}
                                            height={575}
                                            loader={heroTeaserImageLoader}
                                            src={src}
                                            objectFit='cover'
                                            alt={teaser.headline + ' image'}
                                            title={teaser.headline}
                                        />
                                    </CardMedia>
                                    { hasCardContent
                                    && (
                                        <CardContent className={classes.cardContent}>
                                            {hasHeadline && (
                                                <Typography
                                                    align='center'
                                                    variant='subtitle1'
                                                    component='h3'
                                                    className={classes.headlineText}
                                                >
                                                    {teaser.headline}
                                                </Typography>
                                            )}
                                            {hasText && <Typography variant='body2'>{teaser.text}</Typography>}
                                        </CardContent>
                                    )}
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Container>
    )
}

export default HeroTeaser
