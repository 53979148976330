import { useCookies } from 'react-cookie'
import { func, shape } from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useMediaQuery } from '@material-ui/core'
import ProductGridSearchClientRender from './ProductGridSearchClientRender'
import storeKeys from '../../../../_Mappings/storeKeys'
import { COOKIE_LOCATION } from '../../../../_Mappings/cookies'
import { getCountLoadMoreClickFromHistory, getFacetFilterFromUrl } from '../../../../../lib/utils'
import { FilterSortPanelProvider } from '../../FilterSortPanel/FilterSortPanel'
import FilterSortPanelMobile from '../../FilterSortPanel/FilterSortPanelMobile'
import FilterSortPanelDesktop from '../../FilterSortPanel/FilterSortPanelDesktop'
import theme from '../../../../../lib/theme'
import UserContext from '../../../../_Elements/UserContext/UserCx'
import { useContext } from 'react';

const ProductGridSearch = ({ page, component }) => {
    const { pageSize, gridLayout } = component.getParameters()
    const componentId = component.getId()

    const [cookies] = useCookies([COOKIE_LOCATION, '_br_uid_2'])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const brUid2 = cookies?._br_uid_2
    const { storeKey, currency } = storeKeys[location] || {}
    const { query: { search = '' } } = useRouter()
    const searchText = decodeURIComponent(search)
    const [facetResult, setFacetResult] = useState({})

    const { query } = useRouter()

    const urlFacetFilter = useMemo(() => getFacetFilterFromUrl(componentId, query), [])
    const [facetFieldFilters, setFacetFieldFilters] = useState(urlFacetFilter)

    const [showPanel, setShowPanel] = useState(false)
    const [sortFields, setSortFields] = useState('-variants.attributes.sort-order')
    const [total, setTotal] = useState(0)
    const [totalHome, setTotalHome] = useState(-1)
    const isMobile = useMediaQuery((thm) => thm.breakpoints.down(theme.breakpoints.values.m))
    const { scrollRef } = useContext(UserContext);

    useEffect(() => {
        setShowPanel(true)
            //called when the component has been mounted, sets the scroll to the currently stored scroll position
            window.scrollTo(0, scrollRef.current.scrollPos);

            const handleScrollPos = () => {
            //every time the window is scrolled, update the reference. This will not cause a re-render, meaning smooth uninterrupted scrolling.
            scrollRef.current.scrollPos = window.scrollY
            };
    
            window.addEventListener('scroll', handleScrollPos);
    
            return () => {
            //remove event listener on unmount
            window.removeEventListener('scroll', handleScrollPos);
            };
    }, [])

    return (
        <>
            {showPanel && (
                <FilterSortPanelProvider
                    total={total}
                    facetResult={facetResult}
                    facetFieldFilters={facetFieldFilters}
                    setFacetFieldFilters={setFacetFieldFilters}
                    sortFields={sortFields}
                    setSortFields={setSortFields}
                    componentId={componentId}
                    totalHome={totalHome}
                >
                    {isMobile
                        ? <FilterSortPanelMobile />
                        : <FilterSortPanelDesktop />}

                </FilterSortPanelProvider>
            )}

            <ProductGridSearchClientRender
                location={location}
                page={page}
                gridLayout={gridLayout}
                pageSize={pageSize}
                brUid2={brUid2}
                storeKey={storeKey}
                currency={currency}
                searchText={searchText}
                setTotal={setTotal}
                setFacetResult={setFacetResult}
                facetFieldFilters={facetFieldFilters}
                sortFields={sortFields}
                setTotalHome={setTotalHome}
            />
        </>
    )
}

ProductGridSearch.propTypes = {
    page: shape({
        getUrl: func
    }),
    component: shape({
        getParameters: func
    })
}

ProductGridSearch.defaultProps = {
    page: undefined,
    component: undefined
}

export default ProductGridSearch
