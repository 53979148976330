import {
    FormControl, FormHelperText, InputLabel, MenuItem, Select
} from '@material-ui/core'
import { useController } from 'react-hook-form'
import {
    arrayOf, shape, node, number, object, oneOfType, string, func, bool
} from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import { checkRequiredForInputLabelProps } from '../../../../lib/utils'
import LabelSelection from '../LabelSelection'

const FormSelect = ({
    formCtx,
    name,
    label,
    defaultValue,
    id,
    options,
    className,
    handleOnChange,
    fullWidth,
    ...controllerProps
}) => {
    const classes = useStyles()

    const { control, formState: { errors } } = formCtx

    const { field: { onChange, value } } = useController({
        defaultValue,
        name,
        control,
        ...controllerProps
    })

    const error = errors[name]

    const handleChange = ({ target }) => {
        onChange(target.value)
        if (handleOnChange) {
            handleOnChange(target.value)
        }
    }

    return (
        <FormControl
            color='primary'
            error={!!error}
            className={clsx(className, classes.formControl)}
            fullWidth={fullWidth}
        >
            <InputLabel
                required={checkRequiredForInputLabelProps(controllerProps.rules)}
                id={id}
            >
                {label}
            </InputLabel>
            <Select
                className={classes.inputBase}
                value={value}
                onChange={handleChange}
                color='primary'
                labelId={id}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }}
            >
                {options?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                    >
                        <LabelSelection option={option} />
                    </MenuItem>

                ))}
            </Select>
            {error?.message && (
                <FormHelperText>
                    {error?.message}
                </FormHelperText>
            )}
        </FormControl>
    )
}

FormSelect.propTypes = {
    formCtx: object.isRequired,
    name: string.isRequired,
    label: node,
    className: string,
    defaultValue: oneOfType([number, string]),
    options: arrayOf(shape({
        label: string,
        value: oneOfType([number, string]),
        img: shape({
            src: string,
            width: number,
            height: number
        })
    })),
    handleOnChange: func,
    id: (props, propName, componentName) => {
        if (props.label && !props.id) {
            return new Error(`${componentName}: id is required if label has been set.`)
        }
        return undefined
    },
    fullWidth: bool
}

FormSelect.defaultProps = {
    className: '',
    label: undefined,
    defaultValue: '',
    options: null,
    id: undefined,
    handleOnChange: null,
    fullWidth: false
}

export default FormSelect
