import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    summaryContainer: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: theme.breakpoints.values.s,
        margin: theme.spacing(1, 0),
        padding: theme.spacing(3, 3),
        backgroundColor: theme.palette.imgOverlay.main,
        borderRadius: theme.spacing(),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    priceContainer: {
        marginTop: theme.spacing(2)
    },
    grandTotal: {
        fontWeight: 750
    },
    oldGrandPrice: {
        textDecoration: 'line-through'
    },
    summaryPrices: {
        textAlign: 'right'
    },
    summarySubtotal: {
        marginBottom: theme.spacing(0.5)
    },
    paymentContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: theme.breakpoints.values.s,
        margin: theme.spacing(1, 0),
        padding: theme.spacing(3, 3),
        backgroundColor: theme.palette.imgOverlay.main,
        borderRadius: theme.spacing(),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    image: {
        display: 'block',
        marginRight: theme.spacing(2),

        '& > svg': {
            width: '100%',
            height: '100%'
        }
    }
}))
