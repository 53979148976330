import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    form: {
        width: '100%'
    },
    root: {
        maxWidth: theme.breakpoints.values.sm,
        marginTop: theme.spacing(4)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    backToLoginLink: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    serviceMessage: {
        margin: theme.spacing(1, 0)
    }
}))
