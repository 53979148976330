import React, { useContext } from 'react'
import { useUpdateCustomer } from '@bloomreach/connector-components-react'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'
import { BrPageContext } from '@bloomreach/react-sdk'
import { Divider } from '@material-ui/core'
import LoginContext from '../Login/LoginContext'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { LoyaltyPointsContext } from '../LoyaltyPoints/LoyaltyPointsContext'
import Link from '../../../_Elements/Link'
import FormSelect from '../../../_Elements/Inputs/FormSelect'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'

const UpdateProfile = () => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const pageCfg = usePageCfg()
    const { user, loading: userLoading } = useContext(LoginContext)
    const { currentCustomer } = user ?? {}
    const [updateCustomer, result, loading, error] = useUpdateCustomer()
    const translations = useTranslation()
    const { loyaltyPoints: { loyaltyPoints } } = useContext(LoyaltyPointsContext)
    

    const {
        handleSubmit, setValue, getValues, ...formCtx
    } = useForm()

    if (userLoading) {
        return <CircularProgress />
    }

    if (!currentCustomer) {
        return (
            <FormHelperText error>
                {translations('account:account.not.found.message')}
            </FormHelperText>
        )
    }

    const genderOptions = [
        { label: `${translations('forms:gender.female')}`, value: 'female' },
        { label: `${translations('forms:gender.male')}`, value: 'male' },
        { label: `${translations('forms:gender.other')}`, value: 'other' }
    ]

    const handleFormSubmit = handleSubmit((values) => updateCustomer({
        ...values,
        middleName: ''
    }).then((response) => {
        const dateOfBirth = response.customer?.dateOfBirth ? new Date(response.customer.dateOfBirth) : undefined
        const day = dateOfBirth?.getDate() > 9 ? dateOfBirth?.getDate().toString() : '0' + dateOfBirth?.getDate().toString()
        const month = dateOfBirth?.getMonth() + 1 > 9 ? (dateOfBirth?.getMonth()+1).toString() : '0' + (dateOfBirth?.getMonth()+1).toString()
        const year = dateOfBirth?.getFullYear().toString()
        pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/customer`, {
            email: response.customer?.email,
            firstName: response.customer?.firstName,
            lastName: response.customer?.lastName,
            gender: response.customer?.salutation ? (response.customer.salutation === 'male' ? 'U' : 'D') : '',
            birthday: dateOfBirth ? '' + day + month + year : ''
        })
    })
    )

    return (
        <div className={classes.root}>
           
            <Typography component='h2' variant='h5' align='center' className={classes.title} data-qa='text-account-updateAccount'>
                {translations('account:update.account.title')}
            </Typography>
            <div className={classes.updateProfileContainer}>
                
                <form onSubmit={handleFormSubmit}>
                    <FormTextField
                        name='email'
                        label={translations('forms:email.label')}
                        formCtx={formCtx}
                        disabled
                        readOnly
                        defaultValue={currentCustomer?.email ?? ''}
                        data-qa='input-account-email'
                    />
                    <FormTextField
                        name='firstName'
                        label={translations('forms:first.name.label')}
                        formCtx={formCtx}
                        defaultValue={currentCustomer?.firstName ?? ''}
                        data-qa='input-account-firstName'
                    />
                    <FormTextField
                        name='lastName'
                        label={translations('forms:last.name.label')}
                        formCtx={formCtx}
                        defaultValue={currentCustomer?.lastName ?? ''}
                        data-qa='input-account-lastName'
                    />
                    {/* <FormTextField
                        name='companyName'
                        label={translations('forms:company.label')}
                        formCtx={formCtx}
                    /> */}
                    <FormSelect
                        className={classes.gender}
                        id='salutation'
                        name='salutation'
                        formCtx={formCtx}
                        defaultValue={currentCustomer?.salutation ?? ''}
                        options={genderOptions}
                        label={translations('forms:gender.label')}
                        data-qa='select-account-gender'
                    />
                    <FormTextField
                        name='dateOfBirth'
                        label={translations('forms:date.of.birth.label')}
                        formCtx={formCtx}
                        defaultValue={currentCustomer?.dateOfBirth ?? ''}
                        InputLabelProps={{ shrink: true }}
                        type='date'
                        min='1000-01-01'
                        max='9999-12-31'
                        data-qa='date-account-date'
                    />
                    {/* gender??? */}
                    {/* <div className='form-group col-md-2'>
                    <label htmlFor='genderUpdate'>Gender</label>
                    {/!* !!!<p>{currentCustomer.customAttrs?.find((attr) => attr?.name === 'gender')?.values?.join(', ')}</p> !!!*!/}
                    </div> */}
                    {result?.success && (
                        <FormHelperText>
                            {translations('account:update.success.message')}
                        </FormHelperText>
                    )}
                    {error && <FormHelperText error>{error.message}</FormHelperText>}

                    <div className={classes.updateButtonContainer}>
                        <BaseButton
                            type='submit'
                            variant='contained'
                            loading={loading}
                            data-qa='button-account-saveChanges'
                        >
                            {translations('forms:save.changes.button')}
                        </BaseButton>

                    </div>
                    <Divider />
                    <div className={classes.updateButtonContainer}>
                        <BaseButton
                            variant='outlined'
                            component={Link}
                            href={page.getUrl('/account/change-password')}
                            data-qa='button-account-changePassword'
                        >
                            {translations('account:change.password.button')}
                        </BaseButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdateProfile
