import { useEffect } from 'react'
import { mergableFilterKeys, productFiltering } from '../_Mappings/productFiltering'
import useTranslation from '../_Elements/ResourceBundles/useTranslation'

const useFacetHomeSorting = (facetResultHome, setFacetResult) => {
    const translations = useTranslation()

    useEffect(() => {
        if (facetResultHome) {
            let facetResultHome_ = { ...facetResultHome }
            const facetResultHomeFields_ = []

            const combinedFacetFields = {}
            Object.keys(productFiltering).forEach((entry) => {
                combinedFacetFields[entry] = []
            })

            // Line up mergable Facet Keys by main facetId
            facetResultHome.fields.forEach((facetField) => {
                if (facetField.id in mergableFilterKeys) {
                    combinedFacetFields[mergableFilterKeys[facetField.id]].push(facetField.values)
                } else {
                    combinedFacetFields[facetField.id].push(facetField.values)
                }
            })

            // Combine available facet values for each main facetId and filter out duplicates by id
            Object.entries(combinedFacetFields).forEach(([combinedFacetFieldEntryKey, combinedFacetFieldEntryValue]) => {
                if (combinedFacetFieldEntryValue.length <= 0) return

                if (combinedFacetFieldEntryValue.length === 1) {
                    combinedFacetFields[combinedFacetFieldEntryKey] = [...combinedFacetFieldEntryValue[0]]
                } else {
                    combinedFacetFields[combinedFacetFieldEntryKey] = combinedFacetFieldEntryValue.flat()
                        .filter((v, i, a) => a.findIndex((v2) => (v2.id === v.id)) === i)
                }
            })

            // Sort each facet values array by name or, in case of colors, translated name
            facetResultHome.fields.forEach((facetField) => {
                const facetFieldId = facetField.id

                if (!(facetFieldId in combinedFacetFields)) return

                const filterType = productFiltering[facetFieldId]?.type
                const isColor = filterType === 'color'

                let sortedFacetFieldValues = combinedFacetFields[facetFieldId]

                if (isColor) {
                    sortedFacetFieldValues = sortedFacetFieldValues.sort((a, b) => {
                        const nameA = translations(`colors:${a.name}`)
                        const nameB = translations(`colors:${b.name}`)

                        return nameA.localeCompare(nameB)
                    })
                } else {
                    sortedFacetFieldValues = sortedFacetFieldValues.sort((a, b) => a.name.localeCompare(b.name))
                }

                facetResultHomeFields_.push({ ...facetField, values: sortedFacetFieldValues })
            })

            facetResultHome_ = { ...facetResultHome_, fields: facetResultHomeFields_ }

            setFacetResult(facetResultHome_)
        }
    }, [facetResultHome])
}

export default useFacetHomeSorting
