import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'


const useStyles = makeStyles((theme) => ({
    loader: {
        width: '50px',
        height: '50px',
        borderTop: '2px solid #FFF',
        borderBottom: '2px solid #000',
        borderLeft: '2px solid #000',
        borderRight: '2px solid #000',
        borderRadius: '50px',
        animation: `$rotation 1000ms infinite`
    },
    "@keyframes rotation": {
        "0%": {
          transform: "rotateZ(0deg)"
        },
        "100%": {
          transform: "rotateZ(360deg)"
        }
    },
}))

export const Loader = () => {
    const classes = useStyles()
    return (<div className={clsx(classes.loader)}/>)
}