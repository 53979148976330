import { Button, ListItem, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'

const LanguageSelect = ({
    isListItem, setModalIsOpen, languageLabel, languageLabel2, languageLabel1
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    const handleClick = () => {
        setModalIsOpen(() => true)
    }

    return (
        isListItem
            ? (
                <ListItem
                    button
                    onClick={handleClick}
                    className={classes.openButton}
                >
                    {languageLabel != null ? (
                    <Typography variant='subtitle1'><span>{languageLabel}</span></Typography>)
                    : 
                    (<>
                    <Typography variant='subtitle1'><span>{translations('shipping.location')}</span></Typography>
                    
                    <Typography >
                        {languageLabel1}
                    </Typography>
                    <Typography variant='subtitle1'style={{marginTop:"10px"}}><span>{translations('language.location')}</span></Typography>
                    <Typography >
                        {languageLabel2}
                    </Typography></>)}
                </ListItem>
            )
            : (
                <Button
                    color='inherit'
                    onClick={handleClick}
                >
                    {languageLabel}
                </Button>
            )
    )
}

LanguageSelect.propTypes = {
    isListItem: PropTypes.bool,
    setModalIsOpen: PropTypes.func,
    languageLabel: PropTypes.string,
    languageLabel1: PropTypes.string,
    languageLabel2: PropTypes.string
}

LanguageSelect.defaultProps = {
    isListItem: false,
    setModalIsOpen: undefined,
    languageLabel: undefined,
    languageLabel1: undefined,
    languageLabel2: undefined
}

export default LanguageSelect
