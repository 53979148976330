import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    list: {
        display: 'flex',
        justifyContent: 'center'
    },
    listItem: {
        width: 'auto',
        minWidth: 0,
        padding: theme.spacing(2, 1)
    },
    menuPopper: {
        paddingTop: theme.spacing(2),
        zIndex: 10,
        width: '100%'
    },
    menuPaper: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: theme.spacing(2),
        padding: `0 5%`
    },
    menuList: {
        height: 400,
        //display: 'flex',
        //flexDirection: 'column',
        //flexWrap: 'wrap',
        columnCount: 4,
        
    },
    menuTitle: {
        margin: theme.spacing(1, 2),
        height: '100%',
        display: 'inline-block',
        fontSize: 18,
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.gray.main
        }
    },
    menuItem: {
        //minWidth: `${100 / 5}%`,
        display: 'block',
        whiteSpace: 'initial',
        wordWrap: 'break-word',
        '&:last-of-type': {
           // marginRight: '100%'
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            textDecoration: 'none'
        }
    },
    root: {
        flexGrow: 1
    },
    hidden: {
        pointerEvents: 'none'
    },
    slide: {
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        transform: 'translateY(100%)'
    },
    transparent: {
        backgroundColor: 'transparent!important'
    },
    toolbarBottom: {
        borderBottom: '1px solid #BFBFBF',
        justifyContent: 'center',
        backgroundColor: 'white',
        transition: theme.transitions.create(['background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.complex
        })
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0
            }
        }
    }
}))
