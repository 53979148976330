import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    widthConstraint: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'calc(50vw + 8rem)',
        [theme.breakpoints.down('md')]: {
            maxWidth: '90vw'
        }
    },
    textOnlyContainer: {
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textOnlyTextContainer: {
        textAlign: 'center'
    },
    fillerBox: {
        flexGrow: 1
    },
    imageContainer: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center'
    },
    fillCoverFull: {
        width: '100%',
        height: '100vh'
    },
    fillCoverSlim: {
        width: '100%',
        height: '80vh'
    },
    slimImageHeight: {
        lineHeight: 0 // 5px image gutter fix
    },
    preHeadline: {
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '1px',
        lineHeight: '32.5px',
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
        fontSize: '16px',
        fontWeight: '400'
    },
    headline: {
        color: theme.palette.primary.contrastText,
        fontWeight: '400',
        lineHeight: '50px',
        fontSize: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: '38px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px'
        }
    },
    preHeadlineTextOnly: {
        fontWeight: '500',
        padding: theme.spacing(2)
    },
    headlineContainer: {
        textAlign: 'center',
        position: 'absolute',
        bottom: '10vh'
    },
    introContainer: {
        textAlign: 'center',
        width: '100%',
        overflow: 'hidden',
        background: theme.palette.gray.lighter
    },
    introTextContainer: {
        margin: theme.spacing(3, 'auto'),
        position: 'relative'
    },
    introLinkContainer: {
        padding: theme.spacing(2),
        margin: '0 auto',
        position: 'relative'
    },
    intro: {
        color: theme.palette.primary.main,
        fontWeight: '400',
        lineHeight: '27.5px',
        fontSize: '22px',
        [theme.breakpoints.down('md')]: {
            fontSize: '18px'
        }
    },
    anchorLinkIcon: {
        marginTop: theme.spacing(2),
        cursor: 'pointer'
    }
}))
