import { useContext, useEffect, useRef } from 'react'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const useGoogleTrackPurchase = (cart, order) => {
    const trackedRef = useRef(false)
    const { pushToDataLayer, prepareProductForDataLayer } = useContext(GoogleEnhancedEcommerce)

    useEffect(() => {
        if (!!order?.id && !!cart?.id && trackedRef.current === false && typeof window !== 'undefined') {
            trackedRef.current = true

            // Enhanced Ecommerce
            const products = cart.entries.map((entry) => {
                const { items, quantity = '' } = entry
                const item = items && items.length ? items[0] : {}
                const [id = '', variant = ''] = item.itemId?.code.split('-')

                return prepareProductForDataLayer(item, { id, variant, quantity })
            })

            const shipping = (order?.shipAmount) ? order.shipAmount.amount.toFixed(2) : ''
            const tax = (order.taxAmount?.amount).toFixed(2)
            const revenue = (order.totalAmount[0]?.amount).toFixed(2)
            const currencyCode = order.totalAmount[0]?.currency

            const couponNames = []
            const couponAmounts = []

            order.discountCodes.forEach((discountCode) => {
                // Get Coupon Name (Entered Text)
                couponNames.push(discountCode.code)

                // Get Coupon Amount
                const refDiscounts = order.discounts.filter((discount) => discountCode.refs.includes(discount.id)) || []
                const couponAmount = refDiscounts.reduce((partialSum, refDiscount) => partialSum + refDiscount.value?.moneyAmounts?.[0]?.amount, 0)

                couponAmounts.push(couponAmount)
            })

            pushToDataLayer({
                event: 'purchase',
                eventCategory: 'eCommerce',
                eventAction: 'Purchase',
                eventLabel: '',
                ecommerce: {
                    currencyCode,
                    purchase: {
                        actionField: {
                            id: order?.orderNumber,
                            tax,
                            shipping,
                            revenue,
                            coupon: couponNames.join('/'), // The transaction coupon redeemed with the transaction
                            couponValue: couponAmounts.join('/') // The transaction coupon value redeemed with the transaction
                        },
                        products
                    }
                }
            })
        }
    }, [order, cart])
}

export default useGoogleTrackPurchase
