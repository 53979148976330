/* eslint-disable */

import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import MuiLink from '@material-ui/core/Link'
import useStyles from './style'

export const NextLinkComposed = React.forwardRef((props, ref) => {
    const {
        to, linkAs, href, replace, scroll, passHref, shallow, prefetch, ...other
    } = props

    return (
        <NextLink
            href={to}
            prefetch={prefetch}
            as={linkAs}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
            passHref={passHref}
        >
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a ref={ref} {...other} />
        </NextLink>
    )
})

NextLinkComposed.propTypes = {
    href: PropTypes.any,
    linkAs: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    passHref: PropTypes.bool,
    prefetch: PropTypes.bool,
    replace: PropTypes.bool,
    scroll: PropTypes.bool,
    shallow: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}

const Link = React.forwardRef(({ secondary, ...props }, ref) => {
    const {
        activeClassName = 'active',
        as: linkAs,
        className: classNameProps,
        href,
        noLinkStyle,
        role, // Link don't have roles.
        ...other
    } = props
    const router = useRouter()

    const classes = useStyles()

    const pathname = typeof href === 'string' ? href : href.pathname
    const className = clsx(classNameProps, {
        [activeClassName]: router.asPath === pathname && activeClassName,
        [classes.secondary]: secondary
    })

    const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0 || href.indexOf('www') === 0)

    if (isExternal) {
        if (noLinkStyle) {
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            return <a className={className} href={href} ref={ref} {...other} />
        }

        return <MuiLink className={className} href={href} ref={ref} {...other} />
    }

    if (noLinkStyle) {
        return <NextLinkComposed className={className} ref={ref} to={href} {...other} />
    }

    return (
        <MuiLink
            color='inherit'
            style={{ textDecoration: 'none' }}
            component={NextLinkComposed}
            linkAs={linkAs}
            className={className}
            ref={ref}
            to={href}
            {...other}
        />
    )
})

Link.propTypes = {
    activeClassName: PropTypes.string,
    as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    className: PropTypes.string,
    href: PropTypes.any,
    noLinkStyle: PropTypes.bool,
    role: PropTypes.string,
    secondary: PropTypes.bool
}

export default Link
