import { useContext, useEffect } from 'react'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const useGoogleNewsletter = (success) => {
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)

    useEffect(() => {
        if (!success) return

        pushToDataLayer({
            event: 'newsletter',
            eventCategory: 'Newsletter',
            eventAction: 'Subscription',
            eventLabel: ''
        })
    }, [success])
}

export default useGoogleNewsletter
