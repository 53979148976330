import {
    useContext,
    useEffect,
    useState
} from 'react'
import {
    BrComponent, BrComponentContext, BrManageMenuButton, BrPageContext
} from '@bloomreach/react-sdk'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import clsx from 'clsx'
import {
    useMediaQuery, useScrollTrigger
} from '@material-ui/core'

import Container from '@material-ui/core/Container'
import { useCookies } from 'react-cookie'
import BaldininiLogo from '../../_Elements/Icons/Logo'
import useStyles from './style'
import Link from '../../_Elements/Link'
import MobileMenu from './MobileMenu/MobileMenu'
import DesktopContext from './ContextMenu/DesktopContext'
import MobileContext from './ContextMenu/MobileContext'
import useTransparentHeader from '../../_Hooks/useTransparentHeader'
import LanguageDialog from '../Language/LanguageDialog'
import shippingCountries from '../../_Mappings/shippingCountries'
import { COOKIE_LANGUAGE, COOKIE_LOCATION } from '../../_Mappings/cookies'
import languages from '../../_Mappings/languages'
import LoginContext from '../Account/Login/LoginContext'
import WelcomePageDialog from '../Account/WelcomePageDialog/WelcomePageDialog'

const Header = () => {
    const classes = useStyles()
    const trigger = useScrollTrigger()
    const page = useContext(BrPageContext)
    const component = useContext(BrComponentContext)
    const menuRef = component.getChildren().find((el) => el.getName() === 'mainmenu').getModels().menu
    const menu = menuRef && page?.getContent(menuRef)

    const secondaryMenuRef = component.getChildren().find((el) => el.getName() === 'secondarymenu').getModels().menu
    const secondaryMenu = secondaryMenuRef && page?.getContent(secondaryMenuRef)

    const [activeMenu, setActiveMenu] = useState('')

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const isPreview = page.isPreview()

    const [showWelcomePopup, setShowWelcomePopup] = useState(false)
    const { user } = useContext(LoginContext);

    // Location
    const [cookies, setCookie] = useCookies([COOKIE_LOCATION, COOKIE_LANGUAGE])
    let [location, setLocation] = useState(cookies[COOKIE_LOCATION])
    location = location === undefined ? 'US' : location

    // Language
    let [language, setLanguage] = useState(cookies[COOKIE_LANGUAGE])
    
    // Modal
    const [modalLocationIsOpen, setModalLocationIsOpen] = useState(false)
    const [modalLanguageIsOpen, setModalLanguageIsOpen] = useState(false)
    const locationLabel = shippingCountries.find(({ value }) => value === location)?.label
    const languageLabel = languages.find(({ value }) => value === language)?.value

    useEffect(() => {
        if(activeMenu && trigger) setActiveMenu('')
    }, [activeMenu, trigger])

    // Location
    const maxAge = 60 * 60 * 24 * 30

    // Initial default value fetch
    useEffect(() => {
        const getGeolocation = async () => {
            const { data } = await cfg.httpClient.get(`${cfg.cmsRestserviceUrl}/geolocation`).catch(console.error)
            // ADD FALLBACK 'GB'
            const location_ = shippingRegions[data?.country] ? data.country : 'GB'
            const language_ = languages.find(({ value }) => value === data?.country.toLowerCase())?.value
            const query = window.location.search;
            const params = new URLSearchParams(query);
            const isPopup = params.get('lang');

            setLocation(location_)
            setLanguage(language_ ? language_ : 'en')
            /*setCookie(COOKIE_LANGUAGE, language_ ? language_ : 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
            setCookie(COOKIE_LOCATION, location_, {
                maxAge, path: '/', sameSite: 'None', secure: true
            });*/
            if (!isPopup && isPopup != 'false') {
                setModalLanguageIsOpen(() => true)
            }

        }
        if (!location) {
            getGeolocation()
        } else if (location == 'IT' && !language) {
            setLanguage('it')
            setCookie(COOKIE_LANGUAGE, 'it', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        } else if (location == 'FR' && !language) {
            setLanguage('fr')
            setCookie(COOKIE_LANGUAGE, 'fr', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        } else if (location == 'RU' && !language) {
            setLanguage('ru')
            setCookie(COOKIE_LANGUAGE, 'ru', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        } else if (location != 'IT' && location != 'RU' && location != 'FR' && !language) {
            setLanguage('en')
            setCookie(COOKIE_LANGUAGE, 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        }


        let urlElements = window.location.href.split('/')
        let urlElelement = (urlElements[3])

        if ((urlElelement == "fr" || urlElelement == "it" || urlElelement == "ru") && urlElelement !== cookies[COOKIE_LANGUAGE]) {
            setLanguage(urlElelement);

            setCookie(COOKIE_LANGUAGE, urlElelement, {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
            /*if(urlElelement !== cookies[COOKIE_LANGUAGE]) {
                window.location.href = `/${urlElelement}`;
            }*/
        } else if (urlElelement !== cookies[COOKIE_LANGUAGE] && 'en' !== cookies[COOKIE_LANGUAGE]) {
            setLanguage('en');

            setCookie(COOKIE_LANGUAGE, 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        }
    }, [])

    // Show Welcome Popup
    useEffect(() => {
        const showWelcomePopup = (sessionStorage?.getItem('show_welcome_popup') ?? false);
        if(user?.currentCustomer === undefined || showWelcomePopup !== 'true') return;
        setShowWelcomePopup(true);
        sessionStorage.removeItem('show_welcome_popup');
    }, [user])

    // Initial default value fetch

    const {
        isTransparent,
        pageIsProductDetail,
        pageHasBannerImage
    } = useTransparentHeader()

    return (
        <div className={classes.root}>
            {isPreview && (
                <BrManageMenuButton menu={menu} />
            )}

            {!(pageHasBannerImage || pageIsProductDetail) && (
                <>
                    <Toolbar className={classes.placeholder} />
                    { isDesktop && <Toolbar className={clsx(classes.placeholder2)} /> }
                    <Toolbar variant='dense' className={classes.placeholder3} />
                </>
            )}
            
            <AppBar
                position={isPreview && pageHasBannerImage ? 'sticky' : 'fixed'}
                className={clsx(classes.root, { [classes.colorBlack]: pageIsProductDetail })}
                color={!isPreview && isTransparent ? 'transparent' : 'secondary'}
            >
                {!isPreview && isTransparent ? (<div className={classes.shadowBar}></div>) : ''}
                <BrComponent path='salesbanner' />

                <Toolbar className={classes.toolbarTop}>
                    <Container maxWidth='xl' disableGutters className={classes.container} data-qa='container-header-categories'>

                        {isDesktop
                            ? (
                                <div className={classes.topLeft}>
                                    {isPreview && (
                                        <BrManageMenuButton menu={secondaryMenu} />
                                    )}
                                    <BrComponent path='secondarymenu' />
                                </div>
                            )
                            : (
                                <BrComponent path='mainmenu'>
                                    <MobileMenu
                                        modalLocationIsOpen={modalLocationIsOpen}
                                        setModalLocationIsOpen={setModalLocationIsOpen}
                                        locationLabel={locationLabel}
                                        modalLanguageIsOpen={modalLanguageIsOpen}
                                        setModalLanguageIsOpen={setModalLanguageIsOpen}
                                        languageLabel={languageLabel}
                                    />
                                </BrComponent>
                            )}

                        <Link
                            color='inherit'
                            href={page.getUrl('/')}
                        >
                            <BaldininiLogo />
                        </Link>

                        {
                            isDesktop
                                ? (
                                    <DesktopContext
                                        className={clsx(classes.topRight)}
                                        modalLocationIsOpen={modalLocationIsOpen}
                                        setModalLocationIsOpen={setModalLocationIsOpen}
                                        location={location}
                                        locationLabel={locationLabel}
                                        modalLanguageIsOpen={modalLanguageIsOpen}
                                        setModalLanguageIsOpen={setModalLanguageIsOpen}
                                        language={language}
                                        languageLabel={languageLabel}
                                    />
                                )
                                : <MobileContext 
                                        className={clsx(classes.topRight)}
                                        locationLabel={locationLabel}
                                        modalLanguageIsOpen={modalLanguageIsOpen}
                                        setModalLanguageIsOpen={setModalLanguageIsOpen}
                                        languageLabel={languageLabel} 
                                        />
                        }
                    </Container>
                </Toolbar>

                {isDesktop && <BrComponent path='mainmenu' />}
            </AppBar>
            <BrComponent path='banner' />
            
            <LanguageDialog
                modalIsOpen={modalLanguageIsOpen}
                setModalIsOpen={setModalLanguageIsOpen}
                setCookie={setCookie}
                language={language}
                languageLabel={languageLabel}
                location={location}
            />
            <h1 className={classes.mainHeader}>{page.getTitle()}</h1>

            <WelcomePageDialog
                open={showWelcomePopup}
                setOpen={setShowWelcomePopup}>
            </WelcomePageDialog>
        </div>
    )
}

export default Header
