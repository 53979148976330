import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import LoginForm from './LoginForm'
import BaldininiLogo from '../../../../public/Baldinini-Logo.svg'
import useStyles from './style'
import Link from '../../../_Elements/Link'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'

const Login = () => {
    const [showForgotPassword, setShowForgotPassword] = useState(undefined)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const classes = useStyles()
    const translations = useTranslation()

    useEffect(() => {
        if (showForgotPassword === false) {
            setIsDialogOpen(true)
        }
    }, [showForgotPassword])

    return (
        <>
            <div className={classes.logoContainer}>
                <Link
                    color='inherit'
                    href='/'
                >
                    <BaldininiLogo />
                </Link>
            </div>
            {showForgotPassword
                ? <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
                : (
                    <>
                        <LoginForm setShowForgotPassword={setShowForgotPassword} />
                        <UserFeedbackDialog open={isDialogOpen} setOpen={setIsDialogOpen}>
                            <Typography variant='body1'>
                                {translations('login:reset.password.success')}
                            </Typography>
                        </UserFeedbackDialog>
                    </>
                )}
        </>
    )
}

export default Login
