const channelKeys = {
    'storefront-world': 'channel-world',
    'storefront-eu': 'channel-eu',
    'storefront-fr': 'channel-fr',
    'storefront-it': 'channel-it',
    'storefront-ru': 'channel-ru',
    'storefront-uk': 'channel-uk',
    'storefront-us': 'channel-us'
}

export default channelKeys
