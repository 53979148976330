import { func } from 'prop-types'
import React, {
    useCallback,
    useContext, useEffect, useRef, useState
} from 'react'
import Typography from '@material-ui/core/Typography'
import {
    Checkbox, CircularProgress, Collapse, FormControlLabel, Grid
} from '@material-ui/core'
import { BrPageContext } from '@bloomreach/react-sdk'
import useStyles from '../style'
import BaseButton from '../../../../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../../../../_Elements/ResourceBundles/useTranslation'
import AddAddress from '../../../../../Account/AddAddress/AddAddress'
import useAddresses from './useAddresses'
import CheckOutContext from '../../../../CheckOutContext'
import Address from './Address/Address'
import AddAddressButton from '../../../../AddAddressButton/AddAddressButton'
import NavigationButtons from '../../NavigationButtons/NavigationButtons'
import LoginContext from '../../../../../Account/Login/LoginContext'
import ChangeAddressButton from '../../../../ChangeAddressButton/ChangeAddressButton'
import Link from '../../../../../../_Elements/Link'
import useCustomSetOrderDetails from '../../../../../../_Hooks/useCustomCheckout/useCustomSetOrderDetails'

const isValidAddress = ({
    firstName,
    lastName,
    country,
    city,
    postalCode,
    streetAddress,
    phone
}) => firstName !== null
    && lastName !== null
    && country !== null
    && city !== null
    && postalCode !== null
    && streetAddress !== null
    && phone !== null

const AddressSelection = ({ onNext }) => {
    const page = useContext(BrPageContext)
    const { user } = useContext(LoginContext)
    const { cartId } = useContext(LoginContext)

    const [showShippingAddresses, setShowShippingAddresses] = useState(false)
    const [showBillingAddresses, setShowBillingAddresses] = useState(false)

    const [setOrderDetails] = useCustomSetOrderDetails()

    const t = useTranslation()
    const classes = useStyles()

    const addresses = useAddresses()
    const { orderDraft, setOrderDraft } = useContext(CheckOutContext)

    const requestIsRunningRef = useRef(false)
    const onAddAddress = useCallback(async ({ email, ...values }) => {
        const input = {
            id: cartId,
            billingAddress: { address: values },
            shipmentAddress: { address: values }
        }

        if (email) {
            input.email = email
        }

        const result = await setOrderDetails({
            variables: { input }
        })

        if (setOrderDraft && result?.data?.setOrderDetails?.order) {
            setOrderDraft((od) => ({
                ...od,
                ...result?.data?.setOrderDetails?.order
            }))
        }
    }, [setOrderDraft])

    const onShippingAddressChanged = useCallback(async ({ email, ...values }) => {
        if (JSON.stringify(orderDraft?.billingAddress) === JSON.stringify(orderDraft?.shippingAddress)) {
            const result = await setOrderDetails({
                variables: {
                    input: {
                        id: cartId,
                        billingAddress: { address: values },
                        shipmentAddress: { address: values }
                    }
                }
            })

            if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                setOrderDraft((od) => ({
                    ...od,
                    billingAddress: values,
                    shippingAddress: values
                }))
            }
        } else {
            const result = await setOrderDetails({
                variables: {
                    input: {
                        id: cartId,
                        shipmentAddress: { address: values }
                    }
                }
            })

            if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                setOrderDraft((od) => ({
                    ...od,
                    shippingAddress: values
                }))
            }
        }
    }, [setOrderDraft, orderDraft])

    const onBillingAddressChanged = useCallback(async ({ email, ...values }) => {
        const result = await setOrderDetails({
            variables: {
                input: {
                    id: orderDraft.id,
                    billingAddress: { address: values }
                }
            }
        })

        if (setOrderDraft && result?.data?.setOrderDetails?.order) {
            setOrderDraft((od) => ({
                ...od,
                billingAddress: values
            }))
        }
    }, [setOrderDraft, orderDraft])

    useEffect(() => {
        (async () => {
            // SET ORDER ADDRESSES IF USER HAS VALID ADDRESSES
            if (orderDraft && !requestIsRunningRef.current && !addresses.orderHasValidAddresses && addresses.list.length > 0) {
                requestIsRunningRef.current = true
                await addresses.setShippingAddress(addresses.defaultShippingAddress.id)
                requestIsRunningRef.current = false
            }
        })()
    }, [orderDraft, addresses.orderHasValidAddresses, addresses.list.length, addresses.setShippingAddress])

    useEffect(() => {
        if (showBillingAddresses) setShowBillingAddresses(() => false)
    }, [orderDraft?.billingAddress?.id])

    useEffect(() => {
        if (showShippingAddresses) setShowShippingAddresses(() => false)
    }, [orderDraft?.shippingAddress?.id])

    if (!orderDraft) return null

    return (
        <div
            className={classes.addressesContainer}
        >
            { addresses.isLoading && <div className={classes.loadingOverlay}><CircularProgress /></div> }
            { !(isValidAddress(orderDraft.shippingAddress)
                && (user?.anonymous || addresses.list?.some(({ id }) => id === orderDraft?.shippingAddress?.id)))
                ? (
                    <>
                        <Typography
                            align='center'
                            variant='h6'
                            className={classes.header}
                        >
                            {t('checkout:add.shipping.address.title')}
                        </Typography>
                        <AddAddress onSubmit={onAddAddress} />
                    </>
                ) : (
                    <>
                        <Typography
                            variant='h6'
                            align='center'
                            className={classes.header}
                            gutterBottom
                        >
                            {t('checkout:step.header.address')}
                        </Typography>
                        <Grid
                            container
                            spacing={4}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                direction='column'
                                container
                            >
                                <Typography
                                    variant='h6'
                                    gutterBottom
                                >
                                    {t('address:shipping.address.title')}
                                </Typography>

                                <Address active address={orderDraft.shippingAddress} />

                                { !user.anonymous
                                    ? (
                                        <>
                                            <div>
                                                <BaseButton
                                                    size='small'
                                                    color={showShippingAddresses ? 'secondary' : 'primary'}
                                                    onClick={() => {
                                                        setShowShippingAddresses((ssa) => !ssa)
                                                    }}
                                                >
                                                    { t('checkout:choose.shipping.address.title') }
                                                </BaseButton>
                                            </div>

                                            <Collapse
                                                in={showShippingAddresses}
                                            >
                                                { addresses.list.map((address) => (
                                                    <Address
                                                        onClick={() => addresses.setShippingAddress(address.id)}
                                                        isSelectable
                                                        key={address.id}
                                                        active={address.id === orderDraft.shippingAddress.id}
                                                        address={address}
                                                    />
                                                )) }
                                                <AddAddressButton size='small' data-qa='button-accountAddresses-AddAddressButton' />
                                            </Collapse>
                                        </>
                                    )
                                    : (
                                        <div>
                                            <ChangeAddressButton setAddress={onShippingAddressChanged} />
                                        </div>
                                    )}
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={6}
                                direction='column'
                                container
                            >
                                <Typography
                                    variant='h6'
                                    gutterBottom
                                >
                                    { t('address:billing.address.title') }
                                </Typography>
                                { JSON.stringify(orderDraft.shippingAddress) === JSON.stringify(orderDraft.billingAddress)
                                    ? (
                                        <div
                                            className={classes.checkboxContainer}
                                        >
                                            <FormControlLabel
                                                label={t('checkout:same.as.shipping.checkbox')}
                                                control={(
                                                    <Checkbox
                                                        color='primary'
                                                        checked
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                    : (
                                        <Address active address={orderDraft.billingAddress} />
                                    )}
                                { !user.anonymous
                                    ? (
                                        <>
                                            <div>
                                                <BaseButton
                                                    size='small'
                                                    color={showBillingAddresses ? 'secondary' : 'primary'}
                                                    onClick={() => {
                                                        setShowBillingAddresses((sba) => !sba)
                                                    }}
                                                >
                                                    { t('checkout:choose.billing.address.title') }
                                                </BaseButton>
                                            </div>
                                            <Collapse
                                                in={showBillingAddresses}
                                            >
                                                { addresses.list.map((address) => (
                                                    <Address
                                                        onClick={() => addresses.setBillingAddress(address.id)}
                                                        isSelectable
                                                        key={address.id}
                                                        active={address.id === orderDraft.billingAddress.id}
                                                        address={address}
                                                    />
                                                )) }
                                                <AddAddressButton size='small' data-qa='button-accountAddresses-AddAddressButton' />
                                            </Collapse>
                                        </>
                                    )
                                    : (
                                        <div>
                                            <ChangeAddressButton setAddress={onBillingAddressChanged} />
                                        </div>
                                    )}
                            </Grid>
                        </Grid>
                        <NavigationButtons
                            hrefBack={page.getUrl('/cart')}
                            component={Link}
                            dataQaBack='button-checkout-address-back'
                            isDisabledNext={!orderDraft.shippingAddress}
                            onClickNext={onNext}
                            dataQaNext='button-checkout-next'
                        />
                    </>
                )}
        </div>
    )
}

AddressSelection.propTypes = {
    onNext: func
}

AddressSelection.defaultProps = {
    onNext: undefined
}

export default AddressSelection
