import { InputBase } from '@material-ui/core'
import { string, func, bool } from 'prop-types'
import useStyles from './style'

import BaseButton from '../Button/BaseButton'

const InputButtonGroup = ({
    error,
    inputName,
    inputType,
    buttonType,
    buttonLabel,
    onClick,
    onChange,
    loading
}) => {
    const classes = useStyles()
    const handleKeyDown = ({ key }) => {
        if (key === 'Enter' && onClick) {
            onClick()
        }
    }

    return (
        <div className={classes.inputButton}>
            <InputBase
                error={error}
                name={inputName}
                type={inputType}
                className={classes.input}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                fullWidth
            />

            <BaseButton
                type={buttonType}
                className={classes.button}
                onClick={onClick}
                loading={loading}
            >
                {buttonLabel}
            </BaseButton>
        </div>
    )
}

InputButtonGroup.propTypes = {
    inputName: string,
    inputType: string,
    buttonType: string,
    buttonLabel: string,
    onClick: func,
    onChange: func,
    loading: bool,
    error: bool
}

InputButtonGroup.defaultProps = {
    inputName: undefined,
    inputType: 'text',
    buttonType: 'button',
    buttonLabel: 'button',
    onClick: undefined,
    onChange: undefined,
    loading: undefined,
    error: false
}

export default InputButtonGroup
