import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import GET_LIGHT_MAIN_ITEM_BY_ID from './GET_LIGHT_MAIN_ITEM_BY_ID'

const useTrackPurchase = (cart, order) => {
    const trackedRef = useRef(false)

    const [getLightMainItemDetailsById] = useLazyQuery(GET_LIGHT_MAIN_ITEM_BY_ID)

    useEffect(() => {
        (async () => {
            if (!!order?.id && !!cart?.id && trackedRef.current === false && typeof window !== 'undefined') {
                trackedRef.current = true

                const shippingCost = (order?.shipAmount) ? order.shipAmount.amount : 0
                const shippingMethod = order?.shipMethod
                const tax = order.taxAmount?.amount
                const total = order.totalAmount[0]?.amount

                const couponNames = []

                order.discountCodes.forEach((discountCode) => {
                    // Get Coupon Name (Entered Text)
                    couponNames.push(discountCode.code)
                })

                const products = await Promise.all(cart.entries.map(async (item) => {
                    const mainItemId = item?.items[0]?.mainItem?.itemId?.id

                    let image = item?.items[0].imageSet?.original?.link?.href

                    if (!image) {
                        await getLightMainItemDetailsById({
                            variables: {
                                id: mainItemId
                            }
                        }).then((response) => {
                            image = response?.data?.findItemById?.imageSet?.original?.link?.href
                        })
                    }

                    const productSlug = item?.items[0].itemId?.code?.split('-')[0]?.toLowerCase()

                    return {
                        id: item?.items[0].itemId?.code?.split('-')?.[0],
                        name: item?.items[0].displayName,
                        image,
                        price: item?.purchasePrice?.moneyAmounts[0]?.amount,
                        currency: item?.listPrice?.moneyAmounts[0]?.currency,
                        quantity: item?.quantity,
                        location: `${window.location.origin}/products/${productSlug}`
                    }
                }))

                window.hero('track', {
                    type: 'ecommerce:purchase',
                    purchase: {
                        id: order?.orderNumber,
                        subtotal: cart.totalPurchasePrice.moneyAmounts[0].amount,
                        currency: cart.totalPurchasePrice.moneyAmounts[0].currency,
                        total,
                        tax,
                        shippingCost,
                        shippingMethod,
                        coupon: couponNames.join('/') // The transaction coupon redeemed with the transaction
                    },
                    products
                })
            }
        })()
    },
    [order, cart])
}

export default useTrackPurchase
