import 'react-multi-carousel/lib/styles.css'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useCookies } from 'react-cookie'
import useStyles from './style'
import RelatedSliderByCategory from './RelatedSliderByCategory'
import RelatedSliderByItems from './RelatedSliderByItems'

import { COOKIE_LOCATION } from '../../_Mappings/cookies'


const RelatedtSlider = ({sliderTitle, maximumNumberOfProducts, itemsIds, brUid2, storeKey, query, parentId}) => {
    const classes = useStyles()
 

    const [cookies] = useCookies([COOKIE_LOCATION, '_br_uid_2'])
    const location = cookies[COOKIE_LOCATION] ?? ''
    

    return (
        <Container maxWidth='xl'>
            { (itemsIds && itemsIds.length > 0) ?
                <div className={classes.root}>
                    <Typography align='center' component='h2' variant='h5' className={classes.headline}>{sliderTitle}</Typography>
                    <RelatedSliderByItems
                        maximumNumberOfProducts={maximumNumberOfProducts}
                        items={itemsIds}
                        brUid2={brUid2}
                        storeKey={storeKey}
                    /> 
                </div>
                :
                <RelatedSliderByCategory maxWidth='xl'
                    maximumNumberOfProducts={maximumNumberOfProducts}
                    brUid2={brUid2}
                    storeKey={storeKey}
                    query={query}
                    title={sliderTitle}
                    parentId={parentId}
                />
            }
        </Container>
    )
}

RelatedtSlider.propTypes = {
}

RelatedtSlider.defaultProps = {
}

export default RelatedtSlider
