import { gql } from '@apollo/client'

const SET_ORDER_DETAILS_MUTATION = gql`
    mutation SetOrderDetails($input: SetOrderDetailsInput!) {
        setOrderDetails(setOrderDetailsInput: $input) {
            code
            success
            message
            order {
                ...OrderDraftFragment
            }
        }
    }
    fragment OrderDraftFragment on Order {
        id
        status
        draft
        creationDate
        email
        shipMethod
        shipAmount {
            ...MoneyAmountFragment
        }
        shippingAddress {
            ...AddressFragment
        }
        billingAddress {
            ...AddressFragment
        }
        totalAmount {
            ...MoneyAmountFragment
        }
    }
    fragment AddressFragment on Address {
        id
        firstName
        middleName
        lastName
        streetAddress
        additionalStreetInfo
        company
        postalCode
        city
        state
        country
        billingAddress
        shippingAddress
        readOnly
        phone
    }
    fragment MoneyAmountFragment on MoneyAmount {
        amount
        currency
        displayValue
        channelId
    }
    
`

export default SET_ORDER_DETAILS_MUTATION
