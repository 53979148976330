import { bool, func, string } from 'prop-types'
import BaseButton from '../../../../../_Elements/Inputs/Button/BaseButton'
import Link from '../../../../../_Elements/Link'
import useTranslation from '../../../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'

const NavigationButtons = ({
    onClickBack, isDisabledBack, dataQaBack, hrefBack,
    onClickNext, isDisabledNext, dataQaNext, loading
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    return (
        <div className={classes.buttons}>
            <BaseButton
                color='secondary'
                onClick={onClickBack}
                className={classes.back}
                data-qa={dataQaBack}
                disabled={isDisabledBack}
                component={hrefBack ? Link : undefined}
                href={hrefBack}
            >
                {translations('checkout:back')}
            </BaseButton>

            <BaseButton
                variant='contained'
                color='primary'
                type='submit'
                onClick={onClickNext}
                data-qa={dataQaNext}
                disabled={isDisabledNext}
                loading={loading}
            >
                {translations('checkout:next')}
            </BaseButton>
        </div>
    )
}

NavigationButtons.propTypes = {
    hrefBack: string,
    onClickBack: func,
    onClickNext: func,
    dataQaBack: string,
    isDisabledBack: bool,
    dataQaNext: string,
    isDisabledNext: bool,
    loading: bool
}

NavigationButtons.defaultProps = {
    hrefBack: undefined,
    onClickBack: () => undefined,
    onClickNext: () => undefined,
    dataQaBack: undefined,
    isDisabledBack: false,
    dataQaNext: undefined,
    isDisabledNext: false,
    loading: false
}

export default NavigationButtons
