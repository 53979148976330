import { useEffect, useState } from 'react'
import { useProductGridSearch } from '@bloomreach/connector-components-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { array, func, number, shape, string } from 'prop-types'
import { getConnector, getSmViewId, pushCountLoadMoreClickToHistory } from '../../../../../lib/utils'
import ProductGrid from '../../ProductGridByCategoryOrTag/ProductGrid'
import channelKeys from '../../../../_Mappings/channelKeys'
import useChannelKeyWorkaround from '../../../Cart/useChannelKeyWorkaround'
import useGoogleSearch from '../../../../_Hooks/GoogleAnalytics/useGoogleSearch'
import useTranslation from '../../../../_Elements/ResourceBundles/useTranslation'
import { mergableFilterKeys, productFiltering } from '../../../../_Mappings/productFiltering'
import useFacetHomeSorting from '../../../../_Hooks/useFacetHomeSorting'

const ProductGridSearch = ({
    setTotal,
    location,
    count,
    page,
    pageSize,
    gridLayout,
    storeKey,
    brUid2,
    currency,
    searchText,
    setFacetResult,
    facetFieldFilters,
    sortFields,
    setTotalHome
}) => {
    const connector = getConnector(page)
    const smViewId = getSmViewId(page)

    const [isLoading, setIsLoading] = useState(false)
    const [countClickMore, setCountClickMore] = useState(count)
    const distributionChannelWorkaround = useChannelKeyWorkaround(channelKeys[storeKey])

    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const defaultfacetFieldFilters = {
        id: 'variants.scopedPrice',
        values: ['exists']
    }

    const facet = {
        name: 'facet',
        values: [
            'variants.attributes.size',
            'variants.attributes.colour-1-hex',
            'variants.attributes.colour-2-hex'
        ]
    }

    const productGridRequestDefaultObj = {
        brUid2,
        connector,
        smViewId,
        storeKey,
        searchText,
        params: [
            {
                name: 'priceCurrency',
                values: [currency]
            },
            {
                name: 'priceChannel',
                values: [distributionChannelWorkaround]
            },
            {
                name: 'priceCountry',
                values: [location]
            }
        ]
    }

    const [onLoadMore, itemsPageResult, loading, error] = useProductGridSearch({
        ...productGridRequestDefaultObj,
        pageSize: pageSize * (count + 1),
        sortFields,
        facetFieldFilters: [defaultfacetFieldFilters, ...facetFieldFilters]
    })

    const [, itemsPageResultTwo] = useProductGridSearch({
        ...productGridRequestDefaultObj,
        pageSize: 1,
        facetFieldFilters: [defaultfacetFieldFilters],
        params: [
            ...productGridRequestDefaultObj.params,
            facet
        ]
    })

    const { facetResult: facetResultHome, total: totalHome } = itemsPageResultTwo || {}

    const {
        offset, limit, total: _total, items
    } = itemsPageResult || {}

    useEffect(() => {
        setTotal(_total)
    }, [_total])

    useEffect(() => {
        setIsLoading(false)
    }, [itemsPageResult])

    useEffect(() => {
        if (Number.isInteger(totalHome)) setTotalHome(totalHome)
    }, [totalHome])

    useFacetHomeSorting(facetResultHome, setFacetResult)

    useGoogleSearch(isLoading, isLoadingMore, itemsPageResult)

    if (error) {
        console.error(error.message)
    }

    if (!itemsPageResult) {
        return null
    }

    const handleLoadMoreClick = () => {
        setIsLoading(true)
        setIsLoadingMore(true)
        const nextCount = countClickMore + 1
        pushCountLoadMoreClickToHistory(nextCount)
        setCountClickMore(nextCount)

        onLoadMore().then(() => {
            setIsLoadingMore(false)
        })
    }

    if (loading) {
        return <CircularProgress />
    }

    return (
        <ProductGrid
            limit={limit}
            offset={offset}
            total={_total}
            gridLayout={gridLayout}
            isLoading={isLoading}
            items={items}
            handleLoadMoreClick={handleLoadMoreClick}
        />
    )
}

ProductGridSearch.propTypes = {
    setTotal: func,
    location: string,
    count: number,
    page: shape({
        getUrl: func
    }),
    pageSize: number.isRequired,
    gridLayout: string.isRequired,
    storeKey: string,
    brUid2: string,
    currency: string.isRequired,
    searchText: string,
    setFacetResult: func,
    facetFieldFilters: array,
    sortFields: string,
    setTotalHome: func
}

ProductGridSearch.defaultProps = {
    setTotal: () => {},
    location: '',
    count: 0,
    page: undefined,
    brUid2: undefined,
    storeKey: undefined,
    searchText: '',
    setFacetResult: () => {},
    facetFieldFilters: [],
    sortFields: undefined,
    setTotalHome: () => {}
}

export default ProductGridSearch
