import {
    useContext, useEffect, useMemo, useState
} from 'react'
import { BrPageContext } from '@bloomreach/react-sdk'

const SCROLL_OFFSET = 48

const useTransparentHeader = () => {
    const page = useContext(BrPageContext)
    const pageIsProductDetail = !!page.getComponent('main', 'productdetail')

    const pageHasBannerImage = useMemo(() => {
        const bannerComponent = page.getComponent('header', 'banner', 'Banner')
        || page.getComponent('header', 'banner', 'SliderBanner')

        if (bannerComponent) {
            return (!(bannerComponent.getParameters().layoutOptions === 'Text-only banner'))
        }

        return false
    }, [page])

    const [isTransparent, setIsTransparent] = useState(false)

    useEffect(() => {
        setIsTransparent(typeof window !== 'undefined' && window.scrollY <= SCROLL_OFFSET && (pageHasBannerImage || pageIsProductDetail))
    }, [page])

    useEffect(() => {
        const handleScroll = () => {
            setIsTransparent(typeof window !== 'undefined' && window.scrollY <= SCROLL_OFFSET && (pageHasBannerImage || pageIsProductDetail))
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [pageHasBannerImage, pageIsProductDetail])

    return {
        isTransparent,
        pageIsProductDetail,
        pageHasBannerImage
    }
}

export default useTransparentHeader
