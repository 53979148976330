import { useForgotPassword } from '@bloomreach/connector-components-react'
import { func } from 'prop-types'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import Container from '@material-ui/core/Container'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import useStyles from './style'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'

const ForgotPassword = ({ setShowForgotPassword }) => {
    const pageCfg = usePageCfg()
    const [forgotPassword, result, loading, error] = useForgotPassword()
    const { handleSubmit, ...formCtx } = useForm()
    const classes = useStyles()
    const translations = useTranslation()
    const buttonFullWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'))
    const [submittedEmail, setSubmittedEmail] = useState('')

    const handleFormSubmit = handleSubmit(async ({ email }) => {
        if (!email) {
            return
        }

        // dmoda account check: is not relevant for fe, can be ignored
        await pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/forgotpassword`, {
            customerEmail: email
        }).catch((err) => {
            // ignore err
            console.error('Error:', err)
        })
        setSubmittedEmail(email)
        await forgotPassword({ email })
    })

    useEffect(() => {
        if (result?.success) {
            setShowForgotPassword(false)
        }
    }, [result])

    return (
        <Container maxWidth='sm' className={classes.root}>
            <Typography variant='h4' component='h2' className={classes.marginBottom}>
                {translations('login:reset.password.title')}
            </Typography>
            <Typography variant='body1'>
                {translations('login:reset.password.description')}
            </Typography>
            <form onSubmit={handleFormSubmit} className={classes.form}>
                <FormTextField
                    label={translations('forms:email.label')}
                    name='email'
                    formCtx={formCtx}
                    type='email'
                    className={classes.marginBottom}
                />
                {error
                && (
                    <>
                        <FormHelperText error>{translations('login:forgot.password.error.message', { email: submittedEmail })}</FormHelperText>
                        <FormHelperText className={classes.serviceMessage}>
                            {translations('login:forgot.password.service.message')}
                        </FormHelperText>
                    </>
                )}
                <BaseButton
                    type='submit'
                    variant='contained'
                    fullWidth={buttonFullWidth}
                    loading={loading}
                    className={classes.marginBottom}
                >
                    {translations('login:submit.email.button')}
                </BaseButton>
            </form>
            <div>
                <Typography
                    variant='body1'
                    onClick={() => setShowForgotPassword(false)}
                    className={classes.backToLoginLink}
                >
                    {translations('login:back.to.login.link')}
                </Typography>
            </div>
        </Container>
    )
}

ForgotPassword.propTypes = {
    setShowForgotPassword: func
}

ForgotPassword.defaultProps = {
    setShowForgotPassword: undefined
}

export default ForgotPassword
