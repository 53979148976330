import { useContext, useMemo } from 'react'
import { BrComponentContext } from '@bloomreach/react-sdk'
import Typography from '@material-ui/core/Typography'
import { Grid, useMediaQuery } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Image from 'next/image'
import useStyles from './style'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'

const ImageArticle = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)

    const {
        headline,
        subHeadline,
        image,
        imageAspectRatio,
        imageWidth,
        layoutOptions,
        text
    } = component.getParameters()

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const { cmsBaseUrl } = usePageCfg()

    const _imageWidth = useMemo(() => {
        switch (imageWidth) {
        case '1/3':
            return 4
        case '2/3':
            return 7
        default:
            return 5
        }
    }, [imageWidth])

    const _textWidth = useMemo(() => {
        switch (imageWidth) {
        case '1/3':
            return 8
        case '2/3':
            return 5
        default:
            return 5
        }
    }, [imageWidth])

    const imageProps = useMemo(() => {
        switch (imageWidth) {
        case '1/3':
            return {
                width: 460,
                height: imageAspectRatio === 'Square'
                    ? 460
                    : 740
            }
        case '2/3':
            return {
                width: 750,
                height: imageAspectRatio === 'Square'
                    ? 750
                    : 460
            }
        default:
            return {
                width: 550,
                height: imageAspectRatio === 'Square'
                    ? 550
                    : 340
            }
        }
    }, [imageAspectRatio, imageWidth])

    return (
        <Container maxWidth='xl' disableGutters>
            <Grid className={classes.root}>
                <Typography align='center' component='p' variant='h5' className={classes.subHeadline}>{subHeadline}</Typography>
                <Typography align='center'  component='h2' variant='h5' className={classes.headline}>{headline}</Typography>

                {
                    layoutOptions === 'Right' && isDesktop
                        ? (
                            <Grid container className={classes.grid} spacing={6}>
                                <Grid item xs={12} md={_textWidth}>
                                    <Typography align='left' component='p' variant='h5' className={classes.article}>{text}</Typography>
                                </Grid>
                                <Grid item xs={12} md={_imageWidth}>
                                    {image
                                        ? (
                                            <div className={classes.imgContainer}>
                                                <Image
                                                    {...imageProps}
                                                    quality={100}
                                                    src={`${cmsBaseUrl}/binaries${image}`}
                                                    objectFit='cover'
                                                    alt={headline + ' - image'}
                                                    title={headline}
                                                />
                                            </div>
                                        )
                                        : (
                                            <div className={classes.whiteBox}>
                                                <div className={classes.fillerBox} />
                                            </div>
                                        )}
                                </Grid>
                            </Grid>
                        )
                        : (
                            <Grid container className={classes.grid} spacing={6}>
                                <Grid item xs={12} md={_imageWidth}>
                                    {image
                                        ? (
                                            <div className={classes.imgContainer}>
                                                <Image
                                                    {...imageProps}
                                                    quality={100}
                                                    src={`${cmsBaseUrl}/binaries${image}`}
                                                    objectFit='cover'
                                                    alt={headline + ' - image'}
                                                    title={headline}
                                                />
                                            </div>
                                        )
                                        : isDesktop && (
                                            <div className={classes.whiteBox}>
                                                <div className={classes.fillerBox} />
                                            </div>
                                        )}

                                </Grid>
                                <Grid item xs={12} md={_textWidth}>
                                    <Typography align='left' component='p' variant='h5' className={classes.article}>{text}</Typography>
                                </Grid>
                            </Grid>
                        )
                }

            </Grid>
        </Container>
    )
}

export default ImageArticle
