import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.md,
        margin: theme.spacing(0, 'auto')
    },
    title: {
        marginTop: theme.spacing(5)
    },
    itemList: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    flexEnd: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    },
    button: {
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    emptyCartContainer: {
        margin: theme.spacing('10vw', 'auto'),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    emptyCartText: {
        marginBottom: theme.spacing(3)
    },
    summaryContainer: {
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center'
        }
    }
}))
