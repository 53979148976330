import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    dialogContainer: {
        margin: theme.spacing(3)
    },
    dialogAction: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'center'
    }
}))
