import makeStyles from '@material-ui/styles/makeStyles'
import theme from '../../../../lib/theme'

export default makeStyles(() => ({
    inputBase: {
        padding: 0
    },
    formControl: {
        minWidth: 130,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}))
