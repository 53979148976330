import Typography from '@material-ui/core/Typography'
import { useContext } from 'react'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import useStyles from './style'
import Link from '../../../_Elements/Link'
import useTransparentHeader from '../../../_Hooks/useTransparentHeader'

const SecondaryMenu = () => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const component = useContext(BrComponentContext)
    const menuRef = component?.getModels().menu
    const menu = menuRef && page?.getContent(menuRef)
    const menuItems = menu.getItems()

    const {
        isTransparent,
        pageIsProductDetail
    } = useTransparentHeader()

    const maximumItems = 2
    const limitedItems = menuItems.slice(0, maximumItems)

    return (
        <>
            { limitedItems.map((item) => (
                <Typography
                    key={item?.getName()}
                    component={item?.getUrl() ? Link : 'p'}
                    href={item?.getUrl()}
                    edge='start'
                    color={isTransparent && !pageIsProductDetail ? 'secondary' : 'primary'}
                    className={classes.topLeftItem}
                    variant='subtitle1'
                    noWrap
                >
                    {item.getName()}
                </Typography>
            ))}
        </>
    )
}

export default SecondaryMenu
