import { SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '306px',
        height: '60px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        top: '50%',
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(-50%, -50%) scale(0.30)'
        }
    }
}))

// eslint-disable-next-line react/prop-types
const Logo = ({ fill }) => {
    const classes = useStyles()

    return (
        <SvgIcon
            width='306'
            height='60'
            viewBox='0 0 153 30'
            fill='none'
            className={clsx(classes.logo)}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={fill}>
                <path id="path12" className="s0" d="m50.9 7v12.9h15.6v2.1h-16.5v-15z" />
                <path id="path14" fillRule="evenodd" className="s0" d="m22.6 14.2c2 0.6 3.3 2 3.3 3.8 0 2.3-2 4-4.6 4h-14.4v-7.3h-6.9v-0.8h6.9v-6.9h13.8c2.6 0 4.6 1.6 4.6 3.8 0 1.5-1 2.7-2.7 3.4m-14.8-0.3h12c2 0 3.2-1.1 3.2-3 0-1.9-1.1-3.1-2.9-3.1h-12.3zm13 7.3c1.7 0 2.9-1.3 2.9-3.2 0-2.5-1.7-3.4-3.2-3.4h-12.7v6.6h13z" />
                <path id="path16" className="s0" d="m93 7h0.8v15h-0.8z" />
                <path id="path18" className="s0" d="m122.6 7h0.8v15h-0.8z" />
                <path id="path20" className="s0" d="m152.2 7h0.8v15h-0.8z" />
                <path id="path22" fillRule="evenodd" className="s0" d="m89.1 14.5c0 4.3-3.5 7.5-8 7.5h-11.1v-15h11.1c4.5 0 8 3.2 8 7.5zm-2.3 0c0-2-0.6-3.7-1.6-4.9-1.1-1.2-2.6-1.8-4.4-1.8h-9.9v13.4h9.9c1.8 0 3.3-0.6 4.4-1.8 1-1.2 1.6-2.9 1.6-4.9z" />
                <path id="path24" fillRule="evenodd" className="s0" d="m46.9 22h-2.3l-2.5-4.3h-10.6l-2.8 4.3h-0.8l9.5-15h0.8zm-9.8-13.4l-5.1 8.3h9.7z" />
                <path id="path26" className="s0" d="m117.7 22h-1.4l-16.8-12.3v12.3h-0.8v-15h1.4l16.8 12.3v-12.3h0.8z" />
                <path id="path28" className="s0" d="m147.3 22h-1.4l-16.8-12.3v12.3h-0.8v-15h1.4l16.8 12.3v-12.3h0.8z" />
            </g>
        </SvgIcon>

    )
}

export default Logo
