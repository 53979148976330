import { useContext, useEffect, useRef } from 'react'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const useGoogleProductDetail = (item) => {
    const { pushToDataLayer, prepareProductForDataLayer } = useContext(GoogleEnhancedEcommerce)

    const trackedRef = useRef('')

    useEffect(() => {
        if (!!item?.itemId?.id && trackedRef.current !== item?.itemId?.id) {
            trackedRef.current = item?.itemId?.id

            const product = prepareProductForDataLayer(item)

            pushToDataLayer({
                event: 'productDetail',
                eventCategory: 'eCommerce',
                eventAction: 'Product Details',
                eventLabel: '',
                ecommerce: {
                    detail: {
                        products: [product]
                    }
                }
            })
        }
    }, [item])
}

export default useGoogleProductDetail
