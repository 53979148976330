import React, {
    useCallback, useEffect, useState
} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useUpdateAddress } from '@bloomreach/connector-components-react'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useForm } from 'react-hook-form'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useCookies } from 'react-cookie'
import { Edit } from '@material-ui/icons'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import Close from '../../../../lib/icons/close.svg'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import statesMap from '../../../_Mappings/statesMap'
import FormSelect from '../../../_Elements/Inputs/FormSelect'

const UpdateAddressButton = ({ address, asIcon, disabled }) => {
    const classes = useStyles()
    const translations = useTranslation()
    const { handleSubmit, reset, ...formCtx } = useForm()
    const [cookies] = useCookies([COOKIE_LOCATION])
    const shippingLocation = cookies[COOKIE_LOCATION]
    const states = statesMap[shippingLocation]

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const { id: addressId } = address

    const [updateAddress, result, loading, error] = useUpdateAddress({ addressId })

    const handleFormSubmit = handleSubmit((values) => {
        updateAddress({
            ...values,
            readOnly: false
        })
    })

    useEffect(() => {
        if (result?.success) {
            handleClose()
        }
    }, [result, handleClose])

    return (
        <>
            {
                asIcon
                    ? (
                        <IconButton
                            disabled={disabled}
                            color='primary'
                            size='small'
                            onClick={handleClickOpen}
                        >
                            <Edit />
                        </IconButton>
                    )
                    : (
                        <BaseButton
                            size='small'
                            color='primary'
                            onClick={handleClickOpen}
                            data-qa='button-accountAddresses-editYourAddress'
                            disabled={disabled}
                        >
                            {translations('address:update.address.button')}
                        </BaseButton>
                    )
            }
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id='form-dialog-title' disableTypography className={classes.dialogTitle}>
                    <Typography>{translations('address:update.address.title')}</Typography>
                    <IconButton aria-label='close' onClick={handleClose} color='primary'>
                        <Close />
                    </IconButton>
                </DialogTitle>
                {loading
                    ? <CircularProgress />
                    : (
                        <DialogContent>
                            <form onSubmit={handleFormSubmit}>
                                <FormTextField
                                    label={translations('forms:first.name.label')}
                                    name='firstName'
                                    defaultValue={address?.firstName}
                                    maxLength={15}
                                    formCtx={formCtx}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-firstName'
                                />
                                <FormTextField
                                    label={translations('forms:middle.name.label')}
                                    name='middleName'
                                    maxLength={15}
                                    defaultValue={address?.middleName}
                                    formCtx={formCtx}
                                    data-qa='input-accountUpdateAddress-middleName'
                                />
                                <FormTextField
                                    label={translations('forms:last.name.label')}
                                    name='lastName'
                                    maxLength={70}
                                    defaultValue={address?.lastName}
                                    formCtx={formCtx}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-lastName'
                                />
                                <FormTextField
                                    label={translations('forms:company.label')}
                                    name='company'
                                    defaultValue={address?.company}
                                    formCtx={formCtx}
                                    data-qa='input-accountUpdateAddress-company'
                                />
                                <FormTextField
                                    label={translations('forms:country.label')}
                                    name='country'
                                    defaultValue={shippingLocation}
                                    disabled
                                    formCtx={formCtx}
                                    maxLength={2}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-country'
                                />
                                {states && (
                                    <FormSelect
                                        label={translations('forms:state.label')}
                                        name='state'
                                        maxLength={35}
                                        defaultValue={address?.state}
                                        id='addressState'
                                        formCtx={formCtx}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: translations('forms:rules.message.field.required')
                                            }
                                        }}
                                        options={states}
                                        className={classes.formSelect}
                                        data-qa='input-accountAddAddress-state'
                                    />
                                )}
                                <FormTextField
                                    label={translations('forms:city.label')}
                                    name='city'
                                    defaultValue={address?.city}
                                    formCtx={formCtx}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-city'
                                />
                                <FormTextField
                                    label={translations('forms:postal.code.label')}
                                    name='postalCode'
                                    defaultValue={address?.postalCode}
                                    formCtx={formCtx}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-postalCode'
                                />
                                <FormTextField
                                    label={translations('forms:address.label')}
                                    name='streetAddress'
                                    defaultValue={address?.streetAddress}
                                    maxLength={50}
                                    formCtx={formCtx}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-address'
                                />
                                <FormTextField
                                    label={translations('forms:address.additional.info.label')}
                                    name='additionalStreetInfo'
                                    defaultValue={address?.additionalStreetInfo}
                                    formCtx={formCtx}
                                    maxLength={50}
                                    data-qa='input-accountUpdateAddress-additionalAddressInfo'
                                />
                                <FormTextField
                                    label={translations('forms:address.phone')}
                                    name='phone'
                                    defaultValue={address?.phone}
                                    type='number'
                                    formCtx={formCtx}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: translations('forms:rules.message.field.required')
                                        }
                                    }}
                                    data-qa='input-accountUpdateAddress-phone'
                                />
                                {error && (
                                    <FormHelperText error>{error.message}</FormHelperText>
                                )}
                                {result && (
                                    <FormHelperText>{result.message}</FormHelperText>
                                )}
                                <div className={classes.updateButtonContainer}>
                                    <BaseButton
                                        type='submit'
                                        variant='contained'
                                        data-qa='button-accountUpdateAddress-add'
                                    >
                                        {translations('forms:update.button')}
                                    </BaseButton>
                                </div>
                            </form>
                        </DialogContent>
                    )}
            </Dialog>
        </>
    )
}

UpdateAddressButton.propTypes = {
    address: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        middleName: PropTypes.string,
        lastName: PropTypes.string.isRequired,
        company: PropTypes.string,
        country: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string,
        postalCode: PropTypes.string.isRequired,
        streetAddress: PropTypes.string.isRequired,
        additionalStreetInfo: PropTypes.string,
        billingAddress: PropTypes.bool.isRequired,
        shippingAddress: PropTypes.bool.isRequired,
        phone: PropTypes.string
    }),
    asIcon: PropTypes.bool,
    disabled: PropTypes.bool
}

UpdateAddressButton.defaultProps = {
    address: {
        id: undefined,
        middleName: undefined,
        company: undefined,
        state: undefined,
        additionalStreetInfo: undefined,
        phone: undefined
    },
    asIcon: false,
    disabled: false
}

export default UpdateAddressButton
