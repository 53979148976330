import { gql } from '@apollo/client'

const CART_QUERY = gql`query Cart($id: String, $cartHint: CartHintInput) {
    getCart(id: $id, cartHint: $cartHint) {
        ...CartFragment
    }
}

fragment CartFragment on Cart {
    id
    totalQuantity
    revision
    totalListPrice {
        moneyAmounts {
            ...MoneyAmountFragment
        }
    }
    totalPurchasePrice {
        moneyAmounts {
            ...MoneyAmountFragment
        }
    }
    active
    state
    entries {
        id
        quantity
        listPrice {
            moneyAmounts {
                ...MoneyAmountFragment
            }
        }
        purchasePrice {
            moneyAmounts {
                ...MoneyAmountFragment
            }
        }
        customAttrs {
            name
            values
        }
        items {
            ... on Item {
                ...ItemFragment
            }
            ... on ItemVariant {
                ...ItemVariantFragment
            }
        }
    }
    discounts {
        ...DiscountFragment
    }
}

fragment ItemFragment on Item {
    ...ItemLikeFragment
    varAttrTypes {
        name
    }
    salePriceRange
    priceRange
    customAttrs {
        name
        values
    }
    variants {
        ...ItemVariantFragment
    }
    slug
}

fragment ItemLikeFragment on ItemLike {
    itemId {
        id
        code
    }
    displayName
    description
    imageSet {
        original {
            link {
                href
            }
        }
        thumbnail {
            link {
                href
            }
        }
        variants {
            name
            image {
                dimension {
                    w
                    h
                }
                link {
                    href
                }
            }
        }
    }
    listPrice {
        moneyAmounts {
            ...MoneyAmountFragment
        }
    }
    purchasePrice {
        moneyAmounts {
            ...MoneyAmountFragment
        }
    }
}

fragment MoneyAmountFragment on MoneyAmount {
    amount
    currency
    displayValue
    channelId
}

fragment ItemVariantFragment on ItemVariant {
    ...ItemLikeFragment
    master
    varAttrs {
        name
        values
    }
    customAttrs {
        name
        values
    }
    mainItem {
        itemId {
            id
            code
        }
        displayName
    }
    availability {
        inStock
        availableQuantity
        restockableInDays
        channels {
            channelId
            inStock
            availableQuantity
            restockableInDays
        }
    }
}

fragment DiscountFragment on Discount {
    id
    code
    targetIds
    type
    name
}`

export default CART_QUERY
