import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    oldPrice: {
        color: theme.palette.gray.main,
        textDecoration: 'line-through',
        position: 'relative'
    },

    newPrice: {
        color: theme.palette.red.main,
        marginLeft: theme.spacing()
    },
    cartItemPrice: {
        color: theme.palette.red.main
    }
}))
