import { BrComponentContext } from '@bloomreach/react-sdk'
import React, {
    useContext, useEffect, useMemo, useRef, useState
} from 'react'
import Typography from '@material-ui/core/Typography'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import useStyles from './style'
import Link from '../../_Elements/Link'
import CustomDot from './CustomDot'
import Banner from '../../_Elements/Banner/Banner'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'


const sliderBannerEntryKeys = [
    'title',
    'image',
    'video',
    'buttonLabel',
    'secondButtonLabel',
    'buttonLink',
    'secondButtonLink'
]



const SliderBanner = () => {
    const [banners, setBanners] = useState([])
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const carouselRef = useRef()
    const timer = useRef(null)
    const {isEternity} = usePageCfg()

    const pgf = usePageCfg()

    const params = component.getParameters()

    const defaultAutoPlaySpeed = params.autoplayDuration * 1000

    const [currentCarouselSlide, setCurrentCarouselSlide] = useState(0)
    const [autoPlaySpeed, setAutoPlaySpeed] = useState(defaultAutoPlaySpeed)

    const updateAutoPlaySpeed = (speed) => {
        const newSpeed = speed > 0 ? speed : defaultAutoPlaySpeed
        setAutoPlaySpeed(newSpeed)
    }

    const { videoEntries } = component.getModels()

    const rgbBackgroundColor = (params.backgroundColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1).match(/.{2}/g))?.map((x) => parseInt(x, 16))
    const backgroundColor = `rgba(${rgbBackgroundColor?.join(',')},${1 - (params.transparency / 100)})`

    const sliderBannerEntries = useMemo(() => [1, 2, 3, 4, 5].map((index) => {
        const temp = {}
        sliderBannerEntryKeys.forEach((key) => {
            temp[key] = params[`${key}${index}`]
        })
        return temp
    }).filter((bannerEntry) => !(bannerEntry.image === '' && bannerEntry.video === '')), [params])

    useEffect(() => {
        setBanners(
            sliderBannerEntries.map((bannerEntry, index) => {
                const videoEntry = videoEntries?.[`video${index + 1}`]
                return ({ ...bannerEntry, videoEntry })
            })
        )
    }, [sliderBannerEntries])

    useEffect(() => {
        clearTimeout(timer.current)
        timer.current = undefined

        timer.current = setTimeout(() => {
            setCurrentCarouselSlide(currentCarouselSlide + 1)
        }, autoPlaySpeed)

        return () => {
            clearTimeout(timer.current)
            timer.current = undefined
        }
    }, [autoPlaySpeed, currentCarouselSlide, banners, timer])

    const handleOnCarouselChange = (now) => {
        setCurrentCarouselSlide(now)
    }

    const getContent = (banner, index) => {
    
        const {
            title,
            buttonLink,
            buttonLabel,
            secondButtonLink,
            secondButtonLabel
        } = banner

        return (
            <div className={classes.fillCoverFull}>
                <div
                    style={{ backgroundColor }}
                    className={classes.contentBox}
                >
                    <Typography
                        variant='h2'
                        color='secondary'
                        component='span'
                        className={classes.headline}
                    >
                        {title}
                    </Typography>

                    <div className={classes.buttons}>
                        {buttonLink && buttonLabel && (
                            <Link
                                href={buttonLink}
                                className={classes.button}
                                
                            >
                                {buttonLabel}
                            </Link>
                        )}

                        {secondButtonLink && secondButtonLabel && (
                            <>
                                <Link
                                    href={secondButtonLink}
                                    className={classes.button}
                                >
                                    {secondButtonLabel}
                                </Link>
                            </>
                        )}
                    </div>
                </div>

                <Banner
                    banner={banner}
                    currentSlide={currentCarouselSlide}
                    slideNumber={index}
                    updateAutoPlaySpeed={updateAutoPlaySpeed}
                />
            </div>
        )
    }

    const getContentLink = (banner, index) => {
        
        const {
            title,
            buttonLink,
            buttonLabel,
            secondButtonLink,
            secondButtonLabel
        } = banner

        return (
            <div className={classes.sectionLink}>
                <div>
                    <Typography align='center' component='h2' variant='h5' className={classes.subHeadline}>{title}</Typography>
                    <div className={classes.buttonSectionLink}>
                        {buttonLink  && buttonLabel && (
                            
                            <Link
                                href={buttonLink}
                                className={classes.aSectionLink}
                                color='secondary'
                            >
                                {buttonLabel}
                            </Link>
                        )}
                        {secondButtonLink && secondButtonLabel && (
                            <>
                                <Link
                                    href={secondButtonLink}
                                    className={classes.button}
                                >
                                    {secondButtonLabel}
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const getBanner = (banner = {}, index) => (
       
        (banner.buttonLink && !banner.buttonLabel )
            ? (
                <Link href={banner.buttonLink} key={index}>
                    {getContent(banner, index)}
                </Link>
            ) : (
                <div key={index}>
                    {getContent(banner, index)}
                </div>
            )
    )

    const getLink = (banner = {}, index) => (
        <div key={index}>
            {getContentLink(banner, index)}
        </div>    
    )

    const customRenderIndicator = (onClickHandler, isSelected, index) => (
        <CustomDot
            autoPlaySpeed={autoPlaySpeed}
            length={banners.length}
            currentSlide={currentCarouselSlide}
            isSelected={index === currentCarouselSlide}
        />
    )

    return (banners.length > 1
        ? (
            <div className={classes.root}>
                <Carousel
                    ref={carouselRef}
                    selectedItem={currentCarouselSlide}
                    onChange={handleOnCarouselChange}
                    infiniteLoop
                    emulateTouch
                    showIndicators
                    swipeable
                    centerMode
                    centerSlidePercentage='100'
                    renderIndicator={customRenderIndicator}
                    animationHandler='slide'
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    axis='horizontal'
                    width='100%'
                    autoPlay={false}
                    // Carousel AutoPlay fix
                    // https://github.com/leandrowd/react-responsive-carousel/issues/621#issuecomment-881655218
                    interval={9999999}
                >
                    {banners.map((banner, index) => getBanner(banner, index))}
                </Carousel>
            </div>
        )
        : isEternity ? getLink(banners[0], 0)  : getBanner(banners[0], 0)
    )
}

export default SliderBanner
