const countriesAlpha3 = {
    AL: 'ALB',
    AU: 'AUS',
    AT: 'AUT',
    AZ: 'AZE',
    BA: 'BIH',
    BE: 'BEL',
    BG: 'BGR',
    CA: 'CAN',
    CH: 'CHE',
    CY: 'CYP',
    CZ: 'CZE',
    DE: 'DEU',
    DK: 'DNK',
    ES: 'ESP',
    EE: 'EST',
    FI: 'FIN',
    FR: 'FRA',
    GB: 'GBR',
    GE: 'GEO',
    GR: 'GRC',
    HR: 'HRV',
    HU: 'HUN',
    IN: 'IND',
    IL: 'ISR',
    IT: 'ITA',
    JP: 'JPN',
    LV: 'LVA',
    LT: 'LTU',
    LU: 'LUX',
    MC: 'MCO',
    MD: 'MDA',
    ME: 'MNE',
    MT: 'MLT',
    NL: 'NLD',
    NO: 'NOR',
    PL: 'POL',
    PT: 'PRT',
    RO: 'ROU',
    RS: 'SRB',
    RU: 'RUS',
    SA: 'SAU',
    SE: 'SWE',
    SI: 'SVN',
    SK: 'SVK',
    TR: 'TUR',
    UA: 'UKR',
    US: 'USA'
}

export default countriesAlpha3
