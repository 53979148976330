import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    itemText: {
        marginBottom: theme.spacing(),
        whiteSpace: 'initial',
        wordWrap: 'break-word'
    },
    itemLink: {
        marginBottom: theme.spacing(),
        whiteSpace: 'initial',
        wordWrap: 'break-word',
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.gray.main
        }
    },
    subList: {
        margin: theme.spacing(1, 0)
    },
    subItem: {
        padding: 0,
        margin: 0,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    button: {
        padding: theme.spacing(1, 2),
        position: 'relative',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'transparent'
        },
        '&[aria-expanded="true"]:after':{
            content: `''`,
            display: 'block',
            width: 0,
            height: 0, 
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid #000',
            position: 'absolute',
            bottom: -10,
            left: `calc(50% - 10px)`,
            zIndex: 4
        }
    }
}))
