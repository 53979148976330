import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.md,
        padding: theme.spacing(1, 0)
    },
    card: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(3, 3),
        backgroundColor: theme.palette.imgOverlay.main,
        borderRadius: theme.spacing(),
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    media: {
        maxWidth: '10rem',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'
        }
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2, 0)
        }
    },
    productInfo: {
        // margin: theme.spacing(1, 0)
    },
    updateProductContainer: {
        display: 'flex'
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            textAlign: 'left'
        }
    },
    price: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 1)
    },
    subtotal: {
        fontWeight: 750
    },
    colorInfo: {
        margin: theme.spacing(1, 0, 0.5, 0)
    },
    info: {
        margin: theme.spacing(0, 0, 0.5, 0)
    },
    displayPrice: {
        flexDirection: 'column'
    }
}))
