import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { useCookies } from 'react-cookie'
import { COOKIE_LOCALE, COOKIE_LOCATION } from '../../_Mappings/cookies'
import storeKeys from '../../_Mappings/storeKeys'
import CHECKOUT_MUTATION from './CHECKOUT_MUTATION'
import LoginContext from '../../_Modules/Account/Login/LoginContext'

const useCustomCheckout = (variables) => {
    const { cartId: id } = useContext(LoginContext)
    const [cookies] = useCookies([COOKIE_LOCALE, COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}

    const { cartId, ...cartHint } = (variables ?? { cartId: id, storeKey })

    return useMutation(CHECKOUT_MUTATION, {
        variables: {
            cartId,
            cartHint
        }
    })
}

export default useCustomCheckout
