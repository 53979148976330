import { ListItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Link from '../../Link'
import useStyles from './style'
import theme from '../../../../lib/theme'

const MobileBaseItem = ({ item, inset, closeDrawer }) => {
    const classes = useStyles()
    const itemUrl = item.getUrl() ?? undefined

    return (
        <div
            style={inset ? { paddingLeft: theme.spacing(2) } : undefined}
            className={classes.listItem}
        >
            <ListItem
                button
                variant='h5'
                component={itemUrl ? Link : Typography}
                onClick={() => {
                    if (itemUrl) {
                        closeDrawer()
                    }
                }}
                href={itemUrl}
            >
                {item.getName()}
            </ListItem>
        </div>
    )
}

MobileBaseItem.propTypes = {
    item: PropTypes.object,
    inset: PropTypes.bool,
    closeDrawer: PropTypes.func
}

MobileBaseItem.defaultProps = {
    item: undefined,
    inset: undefined,
    closeDrawer: () => {}
}

export default MobileBaseItem
