import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    selectContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    inputLabel: {
        display: 'block',
        color: theme.palette.primary.main,
        marginRight: theme.spacing(),
        fontSize: 14,
        letterSpacing: '0.05em',
        lineHeight: '17.5px',
        fontWeight: 400,
        fontFamily: 'DM Sans'
    },
    select: {
        padding: theme.spacing(0, 3),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        borderRadius: 0,
        fontFamily: 'FuturaPT, DM Sans',
        fontSize: 16,
        letterSpacing: '0.1em',
        lineHeight: '20px',
        fontWeight: 450,
        textTransform: 'uppercase'
    }
}))
