import { useContext } from 'react'
import { BrPageContext } from '@bloomreach/react-sdk'
import Typography from '@material-ui/core/Typography'
import useStyles from './style'
import Link from '../../_Elements/Link'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'

const TermsConditions = () => {
    const classes = useStyles()
    const translations = useTranslation()
    const page = useContext(BrPageContext)

    return (
        <>
            <Typography
                variant='body1'
                color='primary'
                gutterBottom
                className={classes.terms}
            >
                <Link href={page.getUrl('/terms')}>
                    {translations('footer:terms')}
                </Link>
            </Typography>
            <Typography
                variant='body1'
                color='primary'
                gutterBottom
                className={classes.privacy}
            >
                <Link href={page.getUrl('/privacypolicy')}>
                    {translations('footer:privacy')}
                </Link>
            </Typography>
            <Typography
                variant='body1'
                color='primary'
                gutterBottom
                className={classes.cookie}
            >
                <Link href={page.getUrl('/cookiepolicy')}>
                    {translations('footer:cookie')}
                </Link>
            </Typography>
        </>
    )
}

export default TermsConditions
