import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    flex: {
        display: 'flex'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    registerContainer: {
        padding: theme.spacing(5, 0),
        textAlign: 'center',
        width: '70vw'
    },
    loginTitle: {
        marginTop: theme.spacing(3)
    },
    googleButton: {
        background: '#dd4b39',
        minWidth: '250px'

    },
    facebookButton: {
        backgroundColor: '#3B5998',
        minWidth: '250px'
    },
    appleButton: {
        backgroundColor: '#000000',
        minWidth: '250px'
    },
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    forgotPasswordLink: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    form: {
        width: '100%'
    },
    serviceMessage: {
        margin: theme.spacing(1, 0)
    },
    loginWrap:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(0, 'auto'),
        flexWrap: 'wrap',
        textAlign: 'center',
        maxWidth: theme.breakpoints.values.lg,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            margin: theme.spacing(0)
        }
    },
    halfWrap: {
        width: '40%',
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    loginPopContainer: {
        padding: '10px'
    }
}))
