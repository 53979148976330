import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    list: {
        width: 250
    },
    mobileList: {
        width: '100%'
    },
    title: {
        fontWeight: '500',
        flexGrow: 1,
        alignSelf: 'center'
    },
    topRight: {
        paddingRight: theme.spacing(5),
        textAlign: 'end',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topRightItem: {
        paddingLeft: theme.spacing(5)
    },
    mobileTopRight: {
        textAlign: 'end',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))
