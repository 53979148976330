import { gql } from '@apollo/client'

const WISHLIST_QUERY = gql`
    query findWishlists {
        findWishlists(offset: 0, limit: 1) {
            ...WishlistFragment
        }
    }

    fragment MoneyAmountFragment on MoneyAmount {
        amount
        currency
        displayValue
        channelId
    }

    fragment ItemVariantFragment on ItemVariant {
        itemId {
            id
            code
        }
        displayName
        description
        imageSet {
            original {
                link {
                    href
                }
            }
            thumbnail {
                link {
                    href
                }
            }
            variants {
                name
                image {
                    dimension {
                        w
                        h
                    }
                    link {
                        href
                    }
                }
            }
        }
        mainItem {
            slug
        }
        varAttrs {
            name
            values
        }
        listPrice {
            moneyAmounts {
                ...MoneyAmountFragment
            }
        }
        purchasePrice {
            moneyAmounts {
                ...MoneyAmountFragment
            }
        }
    }

    fragment WishlistFragment on WishlistsPageResult {
        wishlists {
            id
            name
            description
            key
            entries {
                id
                items {
                    ...ItemVariantFragment
                }
            }
        }
    }
`

export default WISHLIST_QUERY
