export const itemsByCategory = `
  query ItemsByCategory(
    $categoryId: String!,
    $offset: Int,
    $limit: Int,
    $queryHint: QueryHintInput,
    $sortFields: String
  ) {
    findItemsByCategory(
      categoryId: $categoryId
      offset: $offset
      limit: $limit
      queryHint: $queryHint
      sortFields: $sortFields
    ) {
      offset
      limit
      count
      total
      items {
        itemId {
          id
          code
        }
        imageSet {
          original {
            link {
              href
            }
          }
          thumbnail {
            link {
              href
            }
          }
          variants {
            name
            image {
              dimension {
                w
                h
              }
              link {
                href
              }
            }
          }
        }
        displayName
        description
        listPrice {
          moneyAmounts {
            ...MoneyAmountFragment
          }
        }
        purchasePrice {
          moneyAmounts {
            ...MoneyAmountFragment
          }
        }
        varAttrTypes {
          name
        }
        salePriceRange
        priceRange
        customAttrs {
          name
          values
        }
        variants {
          ...ItemVariantFragment
        }
        slug
        categories {
          id
          displayName
          parentId
          path
          slug
        }
      }
      facetResult {
        ...FacetResultFragment
      }
      queryHint {
        cursor
        autoCorrectQuery
        autoCorrectQuerySet
        redirectHint {
          url
          query
          newQuery
        }
        statsFields {
          listPrice {
            max
            min
          }
          purchasePrice {
            max
            min
          }
        }
      }
    }
  }

  fragment MoneyAmountFragment on MoneyAmount {
    amount
    currency
    displayValue
    channelId
  }

  fragment ItemVariantFragment on ItemVariant {
    master
    varAttrs {
      name
      values
    }
    customAttrs {
      name
      values
    }
    mainItem {
      itemId {
        id
        code
      }
      displayName
      slug
    }
    availability {
      inStock
      availableQuantity
      restockableInDays
      channels {
        channelId
        inStock
        availableQuantity
        restockableInDays
      }
    }
  }

  fragment FacetResultFragment on FacetResult {
    fields {
      id
      name
      values {
        id
        parentId
        name
        count
      }
    }
    ranges {
      name
      id
      values {
        start
        end
        count
      }
    }
  }
`;

export const searchItems = `
  query Items(
    $query: String!,
    $offset: Int,
    $limit: Int,
    $queryHint: QueryHintInput,
    $sortFields: String
  ) {
    findItemsByKeyword(
      text: $query
      offset: $offset
      limit: $limit
      queryHint: $queryHint
      sortFields: $sortFields
    ) {
      offset
      limit
      count
      total
      items {
        ...ItemFragment
        
      }
      facetResult {
        ...FacetResultFragment
        
      }
      queryHint {
        cursor
        autoCorrectQuery
        autoCorrectQuerySet
        redirectHint {
          url
          query
          newQuery
          
        }
        statsFields {
          listPrice {
            max
            min
            
          }
          purchasePrice {
            max
            min
            
          }
          
        }
        
      }
      
    }
  }

  fragment ItemFragment on Item {
    ...ItemLikeFragment
    itemId {
      id
      code
      
    }
    displayName
    description
    imageSet {
      original {
        link {
          href
          
        }
        
      }
      thumbnail {
        link {
          href
          
        }
        
      }
      variants {
        name
        image {
          dimension {
            w
            h
            
          }
          link {
            href
            
          }
          
        }
        
      }
      
    }
    listPrice {
      moneyAmounts {
        ...MoneyAmountFragment
        
      }
      
    }
    purchasePrice {
      moneyAmounts {
        ...MoneyAmountFragment
        
      }
      
    }
    
    varAttrTypes {
      name
      
    }
    salePriceRange
    priceRange
    customAttrs {
      name
      values
      
    }
    variants {
      ...ItemVariantFragment
      
    }
    slug
    categories {
      id
      displayName
      parentId
      path
      slug
      
    }
    
  }

  fragment ItemLikeFragment on ItemLike {
    purchasePrice {
      moneyAmounts {
        ...MoneyAmountFragment
        
      }
      
    }
    
  }

  fragment MoneyAmountFragment on MoneyAmount {
    amount
    currency
    displayValue
    channelId
    
  }

  fragment ItemVariantFragment on ItemVariant {
    ...ItemLikeFragment
    master
    varAttrs {
      name
      values
      
    }
    customAttrs {
      name
      values
      
    }
    mainItem {
      itemId {
        id
        code
        
      }
      displayName
      slug
      
    }
    availability {
      inStock
      availableQuantity
      restockableInDays
      channels {
        channelId
        inStock
        availableQuantity
        restockableInDays
        
      }
      
    }
    
  }

  fragment FacetResultFragment on FacetResult {
    fields {
      id
      name
      values {
        id
        parentId
        name
        count
        
      }
      
    }
    ranges {
      name
      id
      values {
        start
        end
        count
        
      }
      
    }
    
  }
`;
