import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useContext } from 'react'
import { BrPageContext } from '@bloomreach/react-sdk'
import { useProductGridSearch } from '@bloomreach/connector-components-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMediaQuery } from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from './style'
import ProductCard from '../Product/ProductCard/ProductCard'
import { getConnector, getSmViewId } from '../../../lib/utils'
import useGoogleProductImpressions from '../../_Hooks/GoogleAnalytics/useGoogleProductImpressions'

const ProductSliderByTag = ({
    maximumNumberOfProducts, tag, brUid2, storeKey
}) => {
    const classes = useStyles()
    const page = useContext(BrPageContext)

    const connector = getConnector(page)
    const smViewId = getSmViewId(page)
    const customVariantAttrFields = ['tags']

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    const [, itemsPageResult, loading, error] = useProductGridSearch({
        connector,
        smViewId,
        brUid2,
        customVariantAttrFields,
        searchText: tag,
        storeKey,
        sortFields: '-variants.attributes.sort-order'
    })

    useGoogleProductImpressions(itemsPageResult, page)

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        console.error(error.message)
    }

    if (!itemsPageResult) {
        return null
    }

    const { items } = itemsPageResult

    if (!itemsPageResult) {
        return null
    }
    return (
        <>
            {isDesktop
                ? (
                    <Carousel
                        className={classes.productSlider}
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        minimumTouchDrag={80}
                        containerClass='container'
                        centerMode
                        arrows
                        draggable
                        keyBoardControl
                        renderButtonGroupOutside
                        focusOnSelect={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 960
                                },
                                items: maximumNumberOfProducts
                            },
                            tablet: {
                                breakpoint: {
                                    max: 960,
                                    min: 600
                                },
                                items: 2
                            }
                        }}
                    >
                        {items.filter(Boolean)
                            .map((item) => (
                                <ProductCard key={`tag-slider-${item.itemId.id}`} item={item} />
                            ))}
                    </Carousel>
                )
                : (
                    <Carousel
                        className={classes.productSlider}
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        minimumTouchDrag={80}
                        containerClass='container'
                        arrows
                        draggable
                        keyBoardControl
                        renderButtonGroupOutside
                        focusOnSelect={false}
                        responsive={{
                            mobile: {
                                breakpoint: {
                                    max: 600,
                                    min: 0
                                },
                                items: 1
                            }
                        }}
                    >
                        {items.filter(Boolean)
                            .map((item) => (
                                <ProductCard key={`tag-slider-${item.itemId.id}`} item={item} />
                            ))}
                    </Carousel>
                )}
        </>
    )
}

ProductSliderByTag.propTypes = {
    maximumNumberOfProducts: PropTypes.number,
    tag: PropTypes.string,
    brUid2: PropTypes.string,
    storeKey: PropTypes.string
}

ProductSliderByTag.defaultProps = {
    maximumNumberOfProducts: undefined,
    tag: undefined,
    brUid2: undefined,
    storeKey: undefined
}

export default ProductSliderByTag
