const storeKeys = {
    AL: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    AU: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    AT: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    AZ: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    BE: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    BA: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    BG: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    CA: {
        storeKey: 'storefront-us',
        currency: 'USD'
    },
    HR: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    CY: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    CZ: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    DK: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    EE: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    FI: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    FR: {
        storeKey: 'storefront-fr',
        currency: 'EUR'
    },
    GE: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    DE: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    GR: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    HU: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    IN: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    IL: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    IT: {
        storeKey: 'storefront-it',
        currency: 'EUR'
    },
    JP: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    LV: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    LT: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    LU: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    MT: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    MD: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    MC: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    ME: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    NL: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    NO: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    PL: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    PT: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    RO: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    RU: {
        storeKey: 'storefront-ru',
        currency: 'RUB'
    },
    SA: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    RS: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    SK: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    SI: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    ES: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    SE: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    CH: {
        storeKey: 'storefront-eu',
        currency: 'EUR'
    },
    TR: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    UA: {
        storeKey: 'storefront-world',
        currency: 'EUR'
    },
    GB: {
        storeKey: 'storefront-uk',
        currency: 'GBP'
    },
    US: {
        storeKey: 'storefront-us',
        currency: 'USD'
    }
}

export default storeKeys
