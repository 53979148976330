import makeStyles from '@material-ui/styles/makeStyles'

const VARIANT = {
    OUTLINED: 'outlined',
    CONTAINED: 'contained',
    TEXT: 'text'
}

const getButtonSizeStyles = (size, theme) => {
    if (size === 'large') {
        return {
            padding: theme.spacing(1, 8),
            fontSize: 18,
            letterSpacing: '0.06em',
            lineHeight: '22.5px'
        }
    }

    if (size === 'small') {
        return {
            padding: theme.spacing(0.75, 1.25),
            fontSize: 14,
            letterSpacing: '0.06em',
            lineHeight: '17.5px'
        }
    }

    return {
        height: theme.spacing(7.5),
        padding: theme.spacing(2.625, 5.125),
        fontSize: 16,
        letterSpacing: '0.1em',
        lineHeight: '20px'
    }
}

const getButtonColorStyles = (variant, theme, disabled, color) => {
    switch (variant) {
    case VARIANT.CONTAINED:
        if (color === 'secondary') {
            return {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    textStroke: '0.5px black'
                }
            }
        }
        return {
            borderColor: theme.palette.primary.main,
            borderWidth: (disabled ? 0 : 1),
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.main,
                textStroke: '0.5px white'
            }
        }

    case VARIANT.OUTLINED:
        if (color === 'secondary') {
            return {
                borderColor: theme.palette.secondary.main,
                transition: 'outline 0.6s linear',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                    textStroke: '0.5px white',
                    borderWidth: '2px',
                    padding: theme.spacing(2.5, 5)
                }
            }
        }

        return {
            borderColor: theme.palette.primary.main,
            transition: 'outline 0.6s linear',
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                textStroke: '0.5px black',
                borderWidth: '2px',
                padding: theme.spacing(2.5, 5)
            }
        }

    default: return {}
    }
}

const getDefaultPadding = (defaultPadding) => {
    if (!defaultPadding) return {}

    return {
        padding: '5px 15px!important'
    }
}

export default makeStyles((theme) => ({
    button: ({
        variant, disabled, color, size, defaultPadding
    }) => ({
        position: 'relative',
        margin: theme.spacing(1.25, 1.25),
        fontWeight: 400,
        textTransform: 'uppercase',
        fontFamily: 'FuturaPT, DM Sans',
        opacity: (disabled ? 0.4 : 1),
        textAlign: 'center',
        ...getButtonSizeStyles(size, theme),
        ...getButtonColorStyles(variant, theme, disabled, color, size),
        ...getDefaultPadding(defaultPadding)
    }),
    loadingSpinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))
