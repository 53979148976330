import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    productGrid: {
        display: 'grid',
        gridAutoFlow: 'row dense',
        gridGap: theme.spacing(0.2),
        gridTemplateColumns: ({ gridLayout }) => (gridLayout === '4 Items' ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'),

        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr 1fr!important'
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr!important'
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr!important'
        }
    },
    root: {
       maxWidth: theme.spacing(400)
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(4, 0)
    }
}))
