import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => ({
    videoPlayer: {
        position: 'absolute',
        inset: 0,
        boxSizing: 'border-box',
        padding: 0,
        border: 'none',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        maxHeight: '100%',
        objectFit: 'cover'
    }
}))
