import { string } from 'prop-types'
import { useMemo } from 'react'
import { useProductDetail } from '@bloomreach/connector-components-react'
import { useCookies } from 'react-cookie'
import { getVarAttrs } from '../../../../lib/utils'
import storeKeys from '../../../_Mappings/storeKeys'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import ColorSwatch from './ColorSwatch'
import ProductLink from '../ProductLink'

const ColorSwatchSku = ({ sku }) => {
    const [cookies] = useCookies([COOKIE_LOCATION, '_br_uid_2'])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const brUid2 = cookies?._br_uid_2
    const { storeKey } = storeKeys[location] || {}

    const [item] = useProductDetail({
        itemId: {
            code: sku
        },
        brUid2,
        storeKey
    })

    const attributes = useMemo(() => (
        item ? getVarAttrs(item.variants[0]) : null
    ), [item?.itemId.id])

    const colors = useMemo(() => (attributes ? [{
        colour1Hex: attributes.colour1Hex,
        colour2Hex: attributes.colour2Hex
    }] : null), [attributes])

    if (!(sku && item)) {
        return null
    }

    return (
        <ProductLink item={item}>
            <ColorSwatch colors={colors} />
        </ProductLink>
    )
}

ColorSwatchSku.propTypes = {
    sku: string
}

ColorSwatchSku.defaultProps = {
    sku: ''
}

export default ColorSwatchSku
