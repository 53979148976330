import { createContext, useContext } from 'react'
import { node, object } from 'prop-types'

const PageCfgContext = createContext(null)

const PageCfgContextProvider = ({ cfg, children }) => (
    <PageCfgContext.Provider value={cfg}>
        { children }
    </PageCfgContext.Provider>
)

PageCfgContextProvider.propTypes = {
    children: node,
    cfg: object
}

PageCfgContextProvider.defaultProps = {
    children: null,
    cfg: null
}

export default PageCfgContextProvider

export const usePageCfg = () => useContext(PageCfgContext)
