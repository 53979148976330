import { array } from 'prop-types'
import Typography from '@material-ui/core/Typography'
import useStyles from './style'
import useTranslation from '../../../../_Elements/ResourceBundles/useTranslation'

const ColorVariants = ({ colorVariants }) => {
    const classes = useStyles()
    const translations = useTranslation()
    const colorVariantsNumber = colorVariants?.length ? colorVariants.length + 1 : 1

    return (
        <Typography variant='body2' className={classes.colorText}>
            {`${colorVariantsNumber} ${translations(`product:color.${colorVariantsNumber === 1 ? 'single' : 'multiple'}`)}`}
        </Typography>
    )
}

ColorVariants.propTypes = {
    colorVariants: array
}

ColorVariants.defaultProps = {
    colorVariants: undefined
}

export default ColorVariants
