import { object, number, func } from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import {
    useWidth, isMobileOrTablet, getOrientation
} from '../../../lib/utils'
import { usePageCfg } from '../PageConfig/PageCfg'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import theme from '../../../lib/theme'
import useBannerImageLoader from '../../_Hooks/useBannerImageLoader'

const Banner = ({
    banner, layoutMap, currentSlide, updateAutoPlaySpeed, slideNumber
}) => {
    const breakpoint = useWidth()
    const { cmsBaseUrl } = usePageCfg()
    const [src, setSrc] = useState('')
    const heroBannerImageLoader = useBannerImageLoader(breakpoint, banner.params)

    const [videoLength, setVideoLength] = useState(0)

    const getVideoSrc = () => {
        const { lg, xl } = theme?.breakpoints?.values
        const videoOrientation = isMobileOrTablet()
        && getOrientation() === 'portrait'
        && banner?.videoEntry?.vertical?.files?.length
            ? 'vertical'
            : 'horizontal'
        const comparisonEdge = videoOrientation === 'vertical' ? 'height' : 'width'
        const videoEntryOriented = banner?.videoEntry?.[videoOrientation]?.files

        setVideoLength(banner?.videoEntry?.[videoOrientation]?.duration)

        if (!(videoEntryOriented?.length)) return ''

        const getVideosQuality = (quality) => videoEntryOriented.filter((item) => (item.quality === quality))

        const hdVideos = getVideosQuality('hd')
        const sdVideos = getVideosQuality('sd')
        const videos = hdVideos?.length ? hdVideos : sdVideos

        return videos
            .filter(
                (item) => item?.[comparisonEdge] >= (
                    window.screen?.[comparisonEdge] <= lg ? lg : xl
                )
            )?.[0]?.link ?? hdVideos?.[hdVideos?.length - 1].link
    }

    const getImageSrc = () => {
        if (!banner?.image) return ''

        const fileName = banner?.image.substring(banner?.image.lastIndexOf('/') + 1)
        const imagePath = `${cmsBaseUrl}/binaries${banner?.image}`

        const srcDefault = `${imagePath}/${fileName}`
        const bannerType = layoutMap?.[banner?.layoutOptions]
        const srcBannerType = bannerType ? `/${bannerType}` : ''
        const srcExtend = breakpoint.search(/sm|xs/g) > -1 ? '/baldininibrxm:portraitbanner' : srcBannerType
        return srcDefault + srcExtend
    }

    const currentBannerActive = useMemo(() => currentSlide.toString() === slideNumber.toString(), [currentSlide, slideNumber])

    useEffect(() => {
        setSrc(banner?.video ? getVideoSrc() : getImageSrc())
    }, [banner, breakpoint])

    useEffect(() => {
        if (currentBannerActive) {
            updateAutoPlaySpeed(videoLength * 1000)
        }
    }, [currentBannerActive, videoLength])

    const bannerItemOutput = useMemo(() => (banner && src
            && (
                <>
                    {banner?.video && <VideoPlayer src={src} isPlaying={currentBannerActive} />}
                    {!banner?.video && banner?.image && (
                        <Image
                            src={src}
                            layout='fill'
                            priority
                            loader={heroBannerImageLoader}
                            objectFit='cover'
                            alt={banner?.headline + ' image'}
                            title={banner?.headline}
                        />
                    )}
                </>
            )
    ), [src, currentBannerActive])

    return (bannerItemOutput)
}

Banner.propTypes = {
    banner: object,
    layoutMap: object,
    updateAutoPlaySpeed: func,
    currentSlide: number,
    slideNumber: number
}

Banner.defaultProps = {
    banner: undefined,
    layoutMap: {},
    updateAutoPlaySpeed: () => {},
    currentSlide: 0,
    slideNumber: 0
}

export default Banner
