import React, {
    useContext, useEffect, useState
} from 'react'
import {
    ButtonBase, Typography, Divider
} from '@material-ui/core'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import { ExpandMore } from '@material-ui/icons'
import ProductSort from '../ProductSort/ProductSort'
import { FilterSortPanel } from './FilterSortPanel'

const FilterSortPanelDesktop = () => {
    const {
        getTranslatedLabel,
        getFilterGroup,
        total,
        sortFields,
        setSortFields,
        facetResultSort,
        collapsed,
        setCollapsed,
        translations,
        resetFilters,
        classes,
        showFilterButton,
        facetFieldFilters
    } = useContext(FilterSortPanel)
    const [showResetButton, setShowResetButton] = useState(false)

    useEffect(() => {
        const canShowResetButton = Object.keys(facetFieldFilters).length
        setShowResetButton(canShowResetButton)
    }, [facetFieldFilters])


    return (
        <div className={classes.root}>
            <Divider className={classes.divider} />

            <Box className={classes.optionsContainer}>
                {showFilterButton ? (
                    <div className={classes.buttons}>
                        <ButtonBase
                            type='button'
                            onClick={() => { setCollapsed(!collapsed) }}
                        >
                            <Typography variant='body2'>
                                {translations(`product:filters.${collapsed ? 'show' : 'hide'}`)}
                            </Typography>
                            <ExpandMore className={clsx(classes.iconExpandMore, !collapsed && classes.hide)} />
                        </ButtonBase>

                        {Boolean(showResetButton) && (
                            <ButtonBase
                                type='button'
                                onClick={resetFilters}
                                className={classes.reset}
                            >
                                <Typography variant='caption' component='span'>
                                    {translations('product:filters.reset')}
                                </Typography>
                            </ButtonBase>
                        )}
                    </div>
                ) : (<div />)}

                {(total || total === 0) && (
                    <Typography variant='body2'>
                        {`${total} ${translations('product:products')}`}
                    </Typography>
                )}
                <ProductSort
                    sortFields={sortFields}
                    setSortFields={setSortFields}
                />
            </Box>

            {Boolean(facetResultSort?.length) && (
                <ul className={clsx(classes.filterGroup, classes.filterGroupDesktop)}>
                    {collapsed
                        ? facetResultSort.map((filterGroup) => getFilterGroup(filterGroup))
                        : (facetResultSort.map((filterGroup) => {
                            const label = getTranslatedLabel(filterGroup.id)

                            return (
                                <li
                                    key={filterGroup.id}
                                    aria-label={label}
                                    className={clsx(classes.wrapButtons)}
                                >
                                    {!collapsed && (
                                        <Typography
                                            component='div'
                                            variant='subtitle1'
                                            aria-hidden='true'
                                            className={classes.filterGroupButtonsTitle}
                                        >
                                            {label}
                                        </Typography>
                                    )}

                                    {getFilterGroup(filterGroup)}
                                </li>
                            )
                        }))}
                </ul>
            )}
        </div>
    )
}

export default FilterSortPanelDesktop
