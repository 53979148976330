import React, { useMemo, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
    array, bool, func, number, string
} from 'prop-types'
import ProductCard from '../ProductCard/ProductCard'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import ProductGridTeaserCard from '../ProductGridTeaser/ProductGridTeaserCard/ProductGridTeaserCard'
import { Loader } from '../../../_Elements/Icons/Loader'
import { Pagination } from '../../../_Elements/Pagination'


const ProductGrid = ({
    items,
    isLoading,
    offset,
    limit,
    total,
    gridLayout,
    teaser
}) => {
    const classes = useStyles({ gridLayout })
    const translations = useTranslation()
    

    const itemsToShow = useMemo(() => {
        if (items){
            if (!teaser || teaser.length === 0) return items
            const _itemsToShow = [...items]

            teaser
                .sort((a, b) => a.position - b.position)
                .forEach((t) => {
                    const tPosition = t.position - 1

                    if (_itemsToShow.length > tPosition) {
                        _itemsToShow.splice(tPosition, 0, t)
                    }
                })

            return _itemsToShow
        }
    }, [items, teaser])
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const checkPagination = /page=\d+/.test(window.location.search)
            if (checkPagination) {
                window.onload = function() {
                    setTimeout(function(){
                        window.scrollBy({ top: -320, behavior: 'smooth' });
                    }, 50);
                }
            }
        };
    })
    

    return (
        <>
        {items?.length ? (
                <Box className={classes.buttonContainer} id="idSort">
                    <Pagination pages={total % limit ? Math.floor(total / limit) + 1 : total / limit} />
                </Box>
            ) : <Box className={classes.buttonContainer}></Box>}
            <div id='products-container'>
                <Container
                    className={classes.root}
                    maxWidth={false}
                    id='products-inner-container'
                    name='product-grid-scroll-target'
                    data-qa='container-productGrid-ProductOverview'
                >
                    {items?.length
                        ? (
                            <div className={classes.productGrid}>
                                {itemsToShow.map((item, index) => {
                                    // if isTeaser
                                    if (Object.hasOwnProperty.call(item, 'width')) {
                                        return (
                                            <ProductGridTeaserCard
                                                key={`${item.headline}${item.subline}`}
                                                teaser={item}
                                            />
                                        )
                                    }

                                    return (
                                        <ProductCard
                                            key={`${item.itemId?.id}`}
                                            item={item}
                                            position={index}
                                        />
                                    )
                                })}
                            </div>
                        )
                        : <Typography>No product found</Typography>}
                </Container>
            </div>
            {items?.length ? (
                <Box className={classes.buttonContainer}>
                    <Pagination pages={total % limit ? Math.floor(total / limit) + 1 : total / limit} />
                </Box>
            ) : <Box className={classes.buttonContainer}></Box>}
            
        </>
    )
}

ProductGrid.propTypes = {
    items: array.isRequired,
    isLoading: bool.isRequired,
    offset: number.isRequired,
    limit: number.isRequired,
    total: number.isRequired,
    gridLayout: string.isRequired,
    handleLoadMoreClick: func,
    teaser: array
}

ProductGrid.defaultProps = {
    teaser: []
}

export default ProductGrid
