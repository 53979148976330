import makeStyles from '@material-ui/styles/makeStyles'

const getTwoColorsStyle = (color, theme) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    transform: 'rotate(50deg)',
    background: `linear-gradient(to right, ${color.colour1Hex} 0%, ${color.colour1Hex} 50%, ${color.colour2Hex} 51%, ${color.colour2Hex} 100%)`
}
)
export default makeStyles((theme) => ({
    swatchContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    radio: {
        padding: 0,
        margin: theme.spacing(0, 1)
    },
    colorName: {
        marginBottom: theme.spacing(1.5),
        fontSize: 12,
        lineHeight: '15.6px',
        letterSpacing: '0.05em',
        color: theme.palette.primary.main
    },
    checkedIconContainer: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        border: '1px solid black'
    },
    iconContainer: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        borderColor: theme.palette.gray.light,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    icon: ({ color }) => ({
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',
        backgroundColor: color.colour1Hex,
        '&:hover': {
            backgroundColor: color.colour1Hex
        }
    }),
    twoColorIcon: ({ color }) => ({
        ...getTwoColorsStyle(color, theme)
    })
}))
