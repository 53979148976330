import {
    createContext,
    useEffect,
    useState
} from 'react'
import { node } from 'prop-types'
import templatesDataLayerMap from '../../_Mappings/templatesDataLayerMap'
import usePushQueueReducer from '../../_Hooks/GoogleAnalytics/usePushQueueReducer'
import { getVarAttrs } from '../../../lib/utils'

export const GoogleEnhancedEcommerce = createContext({
    pushAddToWishList: (obj) => {},
    pushToDataLayer: (obj) => {},
    pushAddToCart: (items) => {},
    pushRemoveFromToCart: (item) => {},
    pushProductClick: (item, pageIdentifier) => {},
    pushAddDiscountCode: (discountCode, products) => {},
    pushProductOverViewImpressions: (items, pageIdentifier) => {},
    setInitializationState: (initializationState) => {},
    prepareProductForDataLayer: (product, extraObject) => {},
    prepareItemForDataLayer: (item, extraObject) => {},
    getPageTemplate: (templateKey) => {},
    updateGenericDatalayer: (obj) => {}
})

export const GoogleEnhancedEcommerceProvider = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(true)
    const { dispatch } = usePushQueueReducer()

    const setInitializationState = (initializationState) => { setIsInitialized((prevState) => !prevState) }

    const getPageTemplate = (templateKey) => {
        if (templatesDataLayerMap[templateKey]) {
            return templatesDataLayerMap[templateKey]
        }

        const keys = Object.keys(templatesDataLayerMap).filter((tmplKey) => {
            const regex = new RegExp(tmplKey, 'g')
            return regex.test(templateKey)
        })
        return templatesDataLayerMap[keys[0]] || 'Other'
    }

    const prepareProductForDataLayer = (product, extraObject = {}) => {
        const {
            listPrice,
            purchasePrice,
            displayName: name = '',
            itemId: {
                code: id = ''
            }
        } = product

        const _oldPrice = listPrice?.moneyAmounts?.[0]?.amount
        const oldPrice = typeof _oldPrice === 'number' ? _oldPrice.toFixed(2) : ''

        const _price = purchasePrice?.moneyAmounts?.[0]?.amount
        const price = typeof _price === 'number' ? _price.toFixed(2) : ''

        let varAttributes = null

        if (product?.__typename === 'ItemVariant') { // type ItemVariant passed by checkout events
            varAttributes = product ? getVarAttrs(product) : null
        } else if (product?.__typename === 'Item') { // type Item passed passed by other events
            varAttributes = product ? getVarAttrs(product.variants?.[0]) : null
        }

        const category = varAttributes?.analyticsCategories || ''

        return {
            name,
            id: id.split('-')[0],
            price,
            category,
            oldPrice,
            ...extraObject
        }
    }

    const prepareItemForDataLayer = (item, extraObject = {}) => {
        const {
            listPrice,
            purchasePrice,
            displayName: item_name = '',
            itemId: {
                code: id = ''
            }
        } = item

        const _oldPrice = listPrice?.moneyAmounts?.[0]?.amount
        const oldPrice = typeof _oldPrice === 'number' ? _oldPrice.toFixed(2) : ''

        const _price = purchasePrice?.moneyAmounts?.[0]?.amount
        const price = typeof _price === 'number' ? _price.toFixed(2) : ''

        const discount = price != oldPrice ? oldPrice - price : 0

        let varAttributes = null

        if (item?.__typename === 'ItemVariant') { // type ItemVariant passed by checkout events
            varAttributes = item ? getVarAttrs(item) : null
        } else if (item?.__typename === 'Item') { // type Item passed passed by other events
            varAttributes = item ? getVarAttrs(item.variants?.[0]) : null
        }

        const item_category = varAttributes?.analyticsCategories || ''

        return {
            item_id: id.split('-')[0],
            item_name,
            discount,
            item_brand: 'Baldinini',
            item_category,
            price,
            ...extraObject
        }
    }

    const pushToDataLayer = (obj) => {
        if (!isInitialized) return
        window.dataLayer = window.dataLayer || [{}]

        dispatch({ type: 'ADD_TO_DATALAYER', value: obj })
    }

    const updateGenericDatalayer = (obj) => {
        const firstConsentUpdateOccurred = window.firstConsentUpdateOccurred ?? false

        if (!firstConsentUpdateOccurred && window.dataLayer) {
            window.dataLayer[0] = obj
            return
        }

        pushToDataLayer(obj)
        pushToDataLayer({ event: 'custom.historyChange' })
    }

    const pushAddToWishList = (addToWishListItem) => {
        if (!isInitialized) return

        const {
            name: eventAction,
            price,
            oldPrice,
            category: productType,
            id: productSku
        } = addToWishListItem

        pushToDataLayer({
            event: 'whishlist',
            eventCategory: 'Whishlist',
            eventAction,
            eventLabel: '',
            productSku,
            productType,
            price,
            oldPrice
        })
    }

    const pushAddToCart = (addToCartItem) => {
        if (!isInitialized) return

        pushToDataLayer({
            event: 'addToCart',
            eventCategory: 'eCommerce',
            eventAction: 'Add to Cart',
            eventLabel: '',
            ecommerce: {
                add: {
                    products: [addToCartItem]
                }
            }
        })
    }

    const pushRemoveFromToCart = (removeCartItem) => {
        if (!isInitialized) return

        pushToDataLayer({
            event: 'removeFromCart',
            eventCategory: 'eCommerce',
            eventAction: 'Remove from Cart',
            eventLabel: '',
            ecommerce: {
                remove: {
                    products: [removeCartItem]
                }
            }
        })
    }

    const pushProductClick = (product, pageIdentifier = 'Other') => {
        if (!isInitialized) return

        pushToDataLayer({
            event: 'productClick',
            eventCategory: 'eCommerce',
            eventAction: 'Product click',
            ecommerce: {
                click: {
                    actionField: { list: pageIdentifier },
                    products: [product]
                }
            }
        })
    }

    const pushAddDiscountCode = (discountCode, products) => {
        if (!isInitialized) return

        pushToDataLayer({
            event: "coupon_added",
            promotion_name: discountCode,
            promotion_id: discountCode,
            products
        })
    }

    const pushProductOverViewImpressions = (pageResultItems, list = 'Other') => {
        if (!isInitialized) return

        const impressions = pageResultItems.map((item, index) => prepareProductForDataLayer(
            item, {
                position: index + 1,
                list
            }
        ))

        pushToDataLayer({
            event: 'impressions',
            eventCategory: 'eCommerce',
            eventAction: 'impressions',
            ecommerce: { impressions }
        })
    }

    useEffect(() => {
        window.addEventListener('gtm_loaded', () => {
            dispatch({ type: 'UPDATE_GTM_QUEUE_FLAG', value: true })
        })
        window.addEventListener('consentUpdated', () => {
            dispatch({ type: 'UPDATE_CONSENT_QUEUE_FLAG', value: true })
        })
    }, [])

    return (
        <GoogleEnhancedEcommerce.Provider value={{
            pushAddToCart,
            pushRemoveFromToCart,
            pushToDataLayer,
            pushProductClick,
            pushAddDiscountCode,
            pushProductOverViewImpressions,
            setInitializationState,
            prepareProductForDataLayer,
            prepareItemForDataLayer,
            getPageTemplate,
            pushAddToWishList,
            updateGenericDatalayer
        }}
        >
            {children}
        </GoogleEnhancedEcommerce.Provider>
    )
}

GoogleEnhancedEcommerceProvider.propTypes = {
    children: node.isRequired
}
