import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { useEffect, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { getLoginStatus } from '../../../lib/utils'
import WISHLIST_QUERY from './WISHLIST_QUERY'
import CREATE_WISHLIST_MUTATION from './CREATE_WISHLIST_MUTATION'

const useCreateDefaultWishlist = () => {
    const [user] = useCurrentCustomer()
    const isLoggedIn = getLoginStatus(user) !== 'Guest'

    const { data, refetch } = useQuery(WISHLIST_QUERY)
    const prevIsLoggedIn = useRef(isLoggedIn)
    useEffect(() => {
        if (prevIsLoggedIn.current !== isLoggedIn) {
            prevIsLoggedIn.current = isLoggedIn
            refetch()
        }
    }, [isLoggedIn])

    const { wishlists } = data?.findWishlists ?? {}

    const [createDefaultWishlist, { called }] = useMutation(CREATE_WISHLIST_MUTATION, {
        refetchQueries: [WISHLIST_QUERY]
    })

    useEffect(() => {
        if (isLoggedIn && wishlists?.length === 0 && !called) {
            createDefaultWishlist()
        }
    }, [wishlists, isLoggedIn])
}

export default useCreateDefaultWishlist
