const headCells = [
    {
        id: 'purchaseDate',
        align: 'right',
        label: 'orders.list.headCells.purchaseDate'
    },
    {
        id: 'orderNumber',
        label: 'orders.list.headCells.orderNumber'
    },
    {
        id: 'sku',
        label: 'orders.list.headCells.sku'
    },
    {
        id: 'productName',
        label: 'orders.list.headCells.productName'
    },
    {
        id: 'quantity',
        align: 'center',
        label: 'orders.list.headCells.quantity'
    },
    {
        id: 'price',
        align: 'center',
        label: 'orders.list.headCells.price'
    },
    {
        id: 'shopDescription',
        align: 'center',
        label: 'orders.list.headCells.shopDescription'
    },
    {
        id: 'type',
        label: 'orders.list.headCells.type'
    },
    {
        id: 'status',
        label: 'orders.list.headCells.status'
    },
    {
        id: 'shippingService',
        label: 'orders.list.headCells.shippingService'
    },
    {
        id: 'trackingNumber',
        align: 'right',
        label: 'orders.list.headCells.trackingNumber'
    }
]

export default headCells
