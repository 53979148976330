import { useContext, useEffect, useRef } from 'react'
import { BrComponentContext } from '@bloomreach/react-sdk'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const useGoogleProductImpressions = (itemsPageResult, page) => {
    const { pushProductOverViewImpressions, getPageTemplate } = useContext(GoogleEnhancedEcommerce)
    const trackedRef = useRef(false)

    const {
        name: pageTemplateKey = ''
    } = page.root.model

    const component = useContext(BrComponentContext)
    const {
        name: componentTemplate = ''
    } = component.model

    const pageIdentifier = `${getPageTemplate(pageTemplateKey)}/${componentTemplate}`

    useEffect(() => {
        if (trackedRef.current === false && itemsPageResult?.items?.length) {
            trackedRef.current = true

            pushProductOverViewImpressions(itemsPageResult.items, pageIdentifier)
        }
    }, [itemsPageResult])
}

export default useGoogleProductImpressions
