import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import {
    ButtonBase,
    Typography,
    Divider,
    DialogContent,
    DialogActions,
    Dialog
} from '@material-ui/core'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import { ExpandMore } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import ProductSort from '../ProductSort/ProductSort'
import { FilterSortPanel } from './FilterSortPanel'
import Close from '../../../../lib/icons/close.svg'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'

const FilterSortPanelMobile = () => {
    const {
        delayedSetFacetFieldFilters,
        getTranslatedLabel,
        getFilterGroup,
        total,
        setFacetFieldFilters,
        sortFields,
        setSortFields,
        facetResultSort,
        collapsed,
        setCollapsed,
        cacheFacetFieldFilters,
        translations,
        resetFilters,
        classes,
        showFilterButton
    } = useContext(FilterSortPanel)

    const onClose = () => {
        setCollapsed(() => true)
    }

    const applyFilters = () => {
        setFacetFieldFilters(cacheFacetFieldFilters)
        setCollapsed(() => true)
    }

    useEffect(() => {
        if (collapsed) {
            delayedSetFacetFieldFilters()
        }
    }, [cacheFacetFieldFilters])

    return (
        <>
            <div className={classes.root}>
                <Box className={classes.optionsContainer}>
                    {showFilterButton && (
                        <ButtonBase
                            type='button'
                            onClick={() => {
                                setCollapsed(!collapsed)
                            }}
                        >
                            <Typography variant='body2'>
                                {translations(`product:filters.${collapsed ? 'show' : 'hide'}`)}
                            </Typography>
                            <ExpandMore className={clsx(classes.iconExpandMore, !collapsed && classes.hide)} />
                        </ButtonBase>
                    )}

                    {!showFilterButton && (<div />)}

                    <ProductSort
                        sortFields={sortFields}
                        setSortFields={setSortFields}
                    />
                </Box>

                {!!(facetResultSort?.length) && (
                    <ul className={classes.filterGroup}>
                        {facetResultSort.map((filterGroup) => getFilterGroup(filterGroup, true))}
                    </ul>
                )}
            </div>

            <Dialog
                onClose={onClose}
                open={!collapsed}
                fullScreen
            >
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    color='primary'
                    className={classes.buttonClose}
                >
                    <Close />
                </IconButton>

                <DialogContent>
                    <Typography
                        variant='h6'
                        component='span'
                        className={classes.dialogTitle}
                    >
                        {translations('product:filters.title')}
                    </Typography>
                    <Divider />

                    <div className={classes.dialogBlock}>
                        {(total || total === 0) && (
                            <Typography variant='body2'>
                                {`${total} ${translations('product:products')}`}
                            </Typography>
                        )}

                        <ButtonBase
                            type='button'
                            onClick={resetFilters}
                            className={classes.reset}
                        >
                            <Typography variant='caption' component='span'>
                                {translations('product:filters.reset')}
                            </Typography>
                        </ButtonBase>
                    </div>
                    <Divider />

                    {!!(facetResultSort?.length) && (
                        <ul className={clsx(classes.filterGroup, classes.filterGroupMobil)}>
                            {facetResultSort.map((filterGroup) => {
                                const label = getTranslatedLabel(filterGroup.id)

                                return (
                                    <li
                                        key={filterGroup.id}
                                        aria-label={label}
                                        className={clsx(classes.wrapButtons)}
                                    >
                                        <Typography
                                            component='div'
                                            variant='subtitle1'
                                            aria-hidden='true'
                                            className={classes.filterGroupButtonsTitle}
                                        >
                                            {label}
                                        </Typography>
                                        {getFilterGroup(filterGroup, false)}
                                        <Divider />
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </DialogContent>

                <DialogActions className={classes.dialogAction}>
                    <BaseButton
                        onClick={applyFilters}
                        color='primary'
                    >
                        {translations('product:filters.apply')}
                    </BaseButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FilterSortPanelMobile
