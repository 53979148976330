import { SvgIcon } from '@material-ui/core'

// eslint-disable-next-line react/prop-types
const Bag = (props, { stroke }) => (
    <SvgIcon
        viewBox='0 0 24 24'
        width='24'
        height='24'
        {...props}
    >
        <path
            d='M9 8V4.85714C9 3.27919 10.3431 2 12 2C13.6569 2 15 3.27919 15 4.85714V7.9998M15 7.9998L6 8L3 21H21L18 7.99974L15 7.9998Z'
            stroke={stroke}
            fill='none'
        />

    </SvgIcon>

)

export default Bag
