import { useContext } from 'react'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import useStyles from './style'

const Faq = () => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const component = useContext(BrComponentContext)

    const entries = component.getModels().faqEntries

    if (!entries) return null

    const entriesWithContent = {}
    Object.entries(entries).forEach(([key, value]) => {
        entriesWithContent[key] = value.map((faqRef) => page?.getContent(faqRef)).map((model) => model.model.data).sort((a, b) => a.sortOrderField - b.sortOrderField)
    })

    return (
        <Container maxWidth='md'>
            { Object.entries(entriesWithContent).map(([folder, faqList]) => faqList.length > 0 && (
                <div
                    key={folder}
                    className={classes.faq}
                >
                    <Typography variant='h4'>{ folder }</Typography>
                    {faqList.map((el) => (
                        <Accordion
                            key={el.id}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls='panel1a-content'
                                id='panel1a-content'
                            >
                                <Typography>
                                    {el.questionField}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div dangerouslySetInnerHTML={{ __html: el.answerField.value }} className={classes.mobileOverflow} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            ))}
        </Container>
    )
}
export default Faq
