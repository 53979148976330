import Typography from '@material-ui/core/Typography'
import { Card, CircularProgress, IconButton } from '@material-ui/core'
import {
    object, bool, func, string
} from 'prop-types'
import React from 'react'
import {
    Delete, RadioButtonChecked, RadioButtonUnchecked
} from '@material-ui/icons'
import { useRemoveAddress } from '@bloomreach/connector-components-react'

import clsx from 'clsx'
import useStyles from './style'
import UpdateAddressButton from '../../../../../../Account/UpadeAddressButton/UpdateAddressButton'

const Address = ({
    address, isSelectable, active, onClick, className
}) => {
    const classes = useStyles({ isSelectable, active })
    const [removeAddress,, loading] = useRemoveAddress()

    const {
        firstName,
        lastName,
        middleName,
        additionalStreetInfo,
        streetAddress,
        city,
        state,
        postalCode,
        country,
        phone
    } = address

    const onCardClick = () => {
        if (isSelectable && !active && onClick) {
            onClick()
        }
    }

    return (
        <Card onClick={onCardClick} className={clsx(classes.addressContainer, className)} elevation={0}>
            {loading && <div className={classes.loadingOverlay}><CircularProgress /></div> }
            <div
                className={classes.addressContent}
            >
                { isSelectable && (
                    <div
                        className={classes.addressRadioContainer}
                    >
                        {
                            active
                                ? <RadioButtonChecked />
                                : <RadioButtonUnchecked />
                        }
                    </div>
                )}

                <div
                    className={classes.addressDataContainer}
                >
                    <Typography>
                        {`${firstName} ${middleName || ''} ${lastName}`}
                    </Typography>
                    <Typography>
                        {streetAddress}
                        {' '}
                        {additionalStreetInfo}
                    </Typography>
                    <Typography
                        gutterBottom
                    >
                        {`${country} ${state || ''} ${postalCode} ${city}`}
                    </Typography>

                    <Typography>
                        {phone}
                    </Typography>
                </div>
                { isSelectable && (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <div
                        tabIndex={-1}
                        role='group'
                        onKeyDown={undefined}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        className={classes.addressActionsContainer}
                    >
                        <UpdateAddressButton
                            address={address}
                            asIcon
                        />

                        <IconButton
                            onClick={() => { removeAddress({ addressId: address.id }) }}
                            color='primary'
                            size='small'
                        >
                            <Delete />
                        </IconButton>
                    </div>
                )}
            </div>

        </Card>
    )
}

Address.propTypes = {
    address: object.isRequired,
    isSelectable: bool,
    active: bool,
    onClick: func,
    className: string
}

Address.defaultProps = {
    isSelectable: false,
    active: false,
    onClick: undefined,
    className: undefined
}

export default Address
