import React, {
    createContext, useEffect, useMemo, useState, useRef
} from 'react'
import queryString from 'query-string'

import {
    array, func, node, number, object, string
} from 'prop-types'
import {
    ButtonBase, Typography
} from '@material-ui/core'
import clsx from 'clsx'
import { productFiltering } from '../../../_Mappings/productFiltering'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import Close from '../../../../lib/icons/close.svg'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'

export const FilterSortPanel = createContext({
    delayedSetFacetFieldFilters: () => {},
    isSlider: (filterGroupId) => {},
    getTranslatedLabel: (filterGroupId) => {},
    getFilterGroup: (filterGroup = {}) => {},
    total: undefined,
    facetResult: undefined,
    facetFieldFilters: undefined,
    setFacetFieldFilters: () => {},
    sortFields: undefined,
    setSortFields: () => {},
    facetResultSort: undefined,
    collapsed: true,
    setCollapsed: () => {},
    cacheFacetFieldFilters: undefined,
    timer: undefined,
    setButtons: () => {},
    resetFilters: () => {},
    classes: undefined,
    showFilterButton: false,
    totalHome: undefined
})

export const FilterSortPanelProvider = ({
    children,
    total,
    facetResult,
    setFacetFieldFilters,
    componentId,
    totalHome
}) => {
    const translations = useTranslation()
    const [collapsed, setCollapsed] = useState(true)
    const [showFilterButton, setShowFilterButton] = useState(false)
    const { sortFields, currentFilters } = usePageCfg()
    let facetFieldFilters = (() => {
        const buttons_ = {}
        currentFilters.forEach(filter => buttons_[filter.id] = filter.values.map(value => value.replace(/"/g, '')))
        return buttons_
    })()

    const [cacheFacetFieldFilters, setCacheFacetFieldFilters] = useState([])
    const timer = useRef(null)

    const handleButtonClick = (filter, id) => {
        const newButton = {}
        const currentButton = facetFieldFilters?.[id]
        
        if (currentButton) {
            const filteredValues = currentButton.filter((value) => value !== filter.id)
            if (!(currentButton.find((value) => value === filter.id))) {
                filteredValues.push(filter.id)
            }
            newButton[id] = filteredValues
        } else {
            newButton[id] = [filter.id]
        }
        const buttons = ({ ...facetFieldFilters, ...newButton })

        if (Object.keys(buttons).length) {
            setCacheFacetFieldFilters((prevFacetFieldFilters) => {
                const newFilter = prevFacetFieldFilters?.filter((filter) => !buttons[filter.id])
                newFilter?.push(...Object.keys(buttons).map((id) => {
                    const values = buttons[id].map((value) => `\"${value}\"`)

                    return ({
                        id,
                        values
                    })
                }))

               setFacet(newFilter)
            })
        }
    }

    const resetFilters = () => {
        setFacet(null)
    }

    const faceResultFields = useMemo(() => (facetResult?.fields ? [...facetResult.fields] : []), [facetResult])

    const facetResultSort = useMemo(() => faceResultFields.sort(
        (a, b) => ((productFiltering[a.id]?.type !== productFiltering[b.id]?.type)
            ? -1
            : 0)
    ), [faceResultFields])

    const gridCountButtons = useMemo(
        () => faceResultFields?.length ?? 0,
        [faceResultFields]
    )
    const classes = useStyles({ collapsed, gridCountButtons })

    const getTranslatedLabel = (filterGroupId) => {
        const label = productFiltering[filterGroupId]?.label
        return label ? translations(`product:${label}`) : ''
    }

    const getFilterGroup = (filterGroup = {}, isCollapsed = collapsed) => {
        const { id } = filterGroup
        const filterType = productFiltering[id]?.type

        const getButton = (filter, isButtonActive) => {
            const isColor = filterType === 'color'
            return (
                <ButtonBase
                    key={filter.id}
                    size='small'
                    type='button'
                    onClick={() => handleButtonClick(filter, id)}
                    className={clsx(classes.filterButton, isButtonActive && classes.active)}
                >
                    <Typography
                        variant='caption'
                        component='span'
                        color='textPrimary'
                    >
                        {isColor
                            ? translations(`colors:${filter.name}`)
                            : filter.name}
                    </Typography>
                    {isButtonActive && <Close className={classes.icon} />}
                </ButtonBase>
            )
        }
        return filterGroup?.values?.filter((filter) => filter.id)
            .map((filter) => {
                const isButtonActive = facetFieldFilters[id]?.some((buttonId) => filter.id === buttonId)
                if (isCollapsed) {
                    return isButtonActive && (
                        <li
                            key={id + filter.id}
                            aria-label={getTranslatedLabel(id)}
                        >
                            {getButton(filter, true)}
                        </li>
                    )
                }

                return getButton(filter, isButtonActive)
            })
    }

    const setSortField = (sort) => {
        window.location.href = window.location.origin + window.location.pathname + `?sort=${sort}`

        const currentParams = {sort}
        const queryParams = queryString.parse(window.location.search)

        delete queryParams[`sort`]

        const newParams = {
            ...queryParams,
            ...currentParams
        }

        Object.keys(newParams).forEach((k) => (newParams[k] == null || newParams[k] === '') && delete newParams[k])

        const { state } = window.history
        const { pathname } = window.location

        let paramStr = queryString.stringify(newParams)
        paramStr = paramStr ? `${pathname}?${paramStr}` : pathname

        const historyStateNext = {
            ...state,
            as: paramStr
        }


        window.history.pushState(historyStateNext, state.url, paramStr)
        window.location.reload()
    }

    const setFacet = (filters) => {
        const currentParams = {}
        const queryParams = queryString.parse(window.location.search)

        if (filters !== null) {
            filters.forEach((entry) => {
                const entryDef = productFiltering[entry.id]

                const entryValues = entry.values
                    .map((_entry) => {
                        const tmp = _entry.replaceAll('"', '')
                        return (entryDef.type === 'color') ? tmp.replaceAll('#', '') : tmp
                    })

                if (entryValues.length <= 0) return

                currentParams[`${componentId}_${entryDef.paramKey}`] = entryValues?.join(',')
            })
        }

        Object.values(productFiltering).forEach((value) => {
            delete queryParams[`${componentId}_${value.paramKey}`]
        })

        const newParams = {
            ...queryParams,
            ...currentParams
        }

        Object.keys(newParams).forEach((k) => (newParams[k] == null || newParams[k] === '') && delete newParams[k])

        const { state } = window.history
        const { pathname } = window.location

        let paramStr = queryString.stringify(newParams)
        paramStr = paramStr ? `${pathname}?${paramStr}` : pathname
        const checkPagination = /page=\d+/.test(paramStr)
        if (checkPagination) {
            paramStr = paramStr.replace(/page=\d+/, `page=1`)
        }
        const historyStateNext = {
            ...state,
            as: paramStr
        }


        window.history.pushState(historyStateNext, state.url, paramStr)
        window.location.reload()
    }


    useEffect(() => {
        const canShowFilterButton = totalHome > 0
        setShowFilterButton(canShowFilterButton)
    }, [totalHome])

    const delayedSetFacetFieldFilters = () => {
    }

    return (
        <FilterSortPanel.Provider
            value={{
                delayedSetFacetFieldFilters,
                getTranslatedLabel,
                getFilterGroup,
                total,
                facetResult,
                facetFieldFilters,
                setFacetFieldFilters: setFacet,
                sortFields,
                setSortFields: setSortField,
                facetResultSort,
                collapsed,
                setCollapsed,
                cacheFacetFieldFilters,
                timer,
                translations,
                resetFilters,
                classes,
                showFilterButton,
                setShowFilterButton
            }}
            total={total}
            facetResult={facetResult}
            facetFieldFilters={facetFieldFilters}
            setFacetFieldFilters={setFacetFieldFilters}
            sortFields={sortFields}
            setSortFields={setSortField}
        >
            {children}
        </FilterSortPanel.Provider>
    )
}

FilterSortPanelProvider.propTypes = {
    children: node.isRequired,
    total: number,
    totalHome: number,
    facetResult: object,
    facetFieldFilters: array,
    setFacetFieldFilters: func,
    sortFields: string,
    setSortFields: func,
    componentId: string
}

FilterSortPanelProvider.defaultProps = {
    total: undefined,
    totalHome: undefined,
    facetResult: {},
    facetFieldFilters: undefined,
    setFacetFieldFilters: undefined,
    sortFields: undefined,
    setSortFields: () => {},
    componentId: undefined
}
