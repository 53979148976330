import { useContext } from 'react'
import { BrComponentContext } from '@bloomreach/react-sdk'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import useStyles from './style'

const TextArticle = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)

    const {
        headline,
        subHeadline,
        textLeft,
        textRight
    } = component.getParameters()

    return (
        <Container maxWidth='xl'>
            <Grid className={classes.root}>
                <Typography align='center' component='p' variant='h5' className={classes.subHeadline}>{subHeadline}</Typography>
                <Typography align='center' component='h2' variant='h5' className={classes.headline}>{headline}</Typography>

                <Grid container className={classes.grid} spacing={6}>
                    <Grid item xs={12} md={5}>
                        <Typography align='left' component='p' variant='h5' className={classes.article}>{textLeft}</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography align='left' component='p' variant='h5' className={classes.article}>{textRight}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default TextArticle
