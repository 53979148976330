import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    summaryContainer: ({
        isSelect
    }) => ({
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        maxWidth: theme.breakpoints.values.sm,
        margin: theme.spacing(1, 0),
        padding: theme.spacing(3, 3),
        backgroundColor: isSelect ? 'transparent' : theme.palette.imgOverlay.main,
        borderRadius: theme.spacing()
    })
}))
