import { bool, object, string } from 'prop-types'
import axios from 'axios'
import { BrComponent, BrPage } from '@bloomreach/react-sdk'
import { CommerceConnectorProvider } from '@bloomreach/connector-components-react'
import { useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { NoSsr } from '@material-ui/core'
import componentMapping from '../components'
import { LoginProvider } from '../components/_Modules/Account/Login/LoginContext'
import Header from '../components/_Modules/HeaderMenu/Header'
import ResourceBundles from '../components/_Elements/ResourceBundles/ResourceBundles'
import { getTokenFromSession } from '../lib/utils'
import PageCfgContextProvider from '../components/_Elements/PageConfig/PageCfg'
import { LoyaltyPointsProvider } from '../components/_Modules/Account/LoyaltyPoints/LoyaltyPointsContext'
import { COOKIE_LANGUAGE, COOKIE_LOCATION } from '../components/_Mappings/cookies'
import languages from '../components/_Mappings/languages'
import ThirdPartyScripts from '../components/_Modules/ThirdPartyScripts/ThirdPartyScripts'
import storeKeys from '../components/_Mappings/storeKeys'
import regionLocales from '../components/_Mappings/regionLocales'
import { GoogleEnhancedEcommerceProvider } from '../components/_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'
import { SFMCCollectJsProvider } from '../components/_Modules/ThirdPartyScripts/SFMCCollectJs'
import GAGenericDataLayer from '../components/_Modules/GAGenericDataLayer/GAGenericDataLayer'
import getInitialProps from '../lib/getInitialProps/getInitialProps'
import GlobalHooks from '../components/_Elements/GlobalHooks/GlobalHooks'
import PropTypes from 'prop-types'


// needed to have cookies send to brxm
axios.defaults.withCredentials = true
// axios.defaults.headers = {
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Headers': '*'
// }

// axios.defaults.headers = {
//     'Content.js-Security-Policy': 'frame-ancestors *',
//     'X-Frame-Options': 'ALLOWALL'
// }

const mapping = {
    ...componentMapping
}

const PageWrapper = ({
    configuration, cmsBaseUrl, googleAnalyticsEnvironment, page, initialResourceBundles, products, sortFields, pagination, filters, currentFilters
}) => {
    const cfg = {
        ...configuration,
        httpClient: axios
    }

    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const language = cookies[COOKIE_LANGUAGE] ?? ''
    const { currency } = storeKeys[location] || {}

    const defaultRequestHeaders = useMemo(() => ({
        'Accept-Language': languages.find(({ value }) => value === language)?.header
    }), [language])

    const defaultAnonymousCredentials = useMemo(() => ({
        country: location,
        currency,
        attrs: {
            channel: regionLocales[location]
        }
    }), [location, currency])

    const isCheckout = page?.root?.model?.name === 'checkout'

    const pageEternity = cfg.request.path

    const isEternity = pageEternity && pageEternity?.includes("eternity") ? true : false

    return (
        <PageCfgContextProvider cfg={{ ...cfg, cmsBaseUrl, googleAnalyticsEnvironment, isEternity, products, sortFields, pagination, filters, currentFilters }}>
            <BrPage
                configuration={cfg}
                page={page}
                mapping={mapping}
            >
                <ResourceBundles
                    configuration={cfg}
                    initialResourceBundles={initialResourceBundles}
                >
                    <GoogleEnhancedEcommerceProvider>
                        <SFMCCollectJsProvider>
                            <NoSsr>
                                <ThirdPartyScripts />
                            </NoSsr>
                            <CommerceConnectorProvider
                                connector='commercetools'
                                graphqlServiceUrl={cfg.graphqlUrl}
                                existingToken={getTokenFromSession()}
                                defaultRequestHeaders={defaultRequestHeaders}
                                defaultAnonymousCredentials={defaultAnonymousCredentials}
                            >
                                <LoginProvider>
                                    <LoyaltyPointsProvider>
                                        <BrComponent path='header'>
                                            <Header />
                                        </BrComponent>

                                        <BrComponent path='main' />
                                   
                                        <BrComponent path='footer' />

                                        {isCheckout ? null : <GAGenericDataLayer />}
                                  
                                        <GlobalHooks />
                                    </LoyaltyPointsProvider>
                                </LoginProvider>
                            </CommerceConnectorProvider>
                        </SFMCCollectJsProvider>
                    </GoogleEnhancedEcommerceProvider>
                </ResourceBundles>
            </BrPage>
        </PageCfgContextProvider>
    )
}

PageWrapper.getInitialProps = getInitialProps

PageWrapper.propTypes = {
    configuration: PropTypes.object,
    cmsBaseUrl: PropTypes.string,
    page: PropTypes.object,
    isEternity: PropTypes.bool,
    googleAnalyticsEnvironment: PropTypes.string,
    initialResourceBundles: PropTypes.object,
    products: PropTypes.object,
    sortFields: PropTypes.string,
    pagination: PropTypes.number
}

PageWrapper.defaultProps = {
    configuration: null,
    cmsBaseUrl: '',
    page: null,
    isEternity: false,
    googleAnalyticsEnvironment: 'TEST',
    initialResourceBundles: null,
    products : null,
    sortFields: null,
    pagination: null
}

export default PageWrapper
