import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    topInterferer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,

        '& > *:not(:last-child)': {
            marginRight: theme.spacing()
        }
    },
    card: {
        flexGrow: '1',
        boxShadow: 'none',
        borderRadius: 0
    },
    bounce: {
        transform: 'translateY(0)',
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.complex
        })
    },
    imgContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',

        '&:hover': {
            '& $bounce': {
                transform: `translateY(${theme.spacing(-2)}px)`
            }
        }
    },
    image: {
        minHeight: '80% !important',
        minWidth: '80% !important'
    },
    imgOverlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.imgOverlay.main,
        //borderRadius: theme.spacing()
    },
    wishlistIcon: {
        position: 'absolute',
        top: theme.spacing(0.2),
        right: theme.spacing(0.5)
    },
    cardContent: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        '& .MuiTypography-subtitle2': {
            fontSize: '13px',
        },

        '& .MuiTypography-body2': {
            fontSize: '12px',
        }
    },
    productName: {
        marginBottom: theme.spacing(),
        fontSize: '14px',

    }
}))
