import { gql } from '@apollo/client'

const REMOVE_ENTRY_FROM_WISHLIST_MUTATION = gql`
    mutation removeEntryFromWishlist($wishlistId: String!, $entryId: String!) {
        removeEntryFromWishlist(removeEntryFromWishlistInput: { wishlistId: $wishlistId, entryId: $entryId }) {
            success
        }
    }
`

export default REMOVE_ENTRY_FROM_WISHLIST_MUTATION
