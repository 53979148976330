import { useReducer } from 'react'

const initialQueue = {
    gtmLoaded: false,
    consentUpdated: false,
    storeInQueue: true,
    queue: []
}

const actionTypeMap = {
    UPDATE_CONSENT_QUEUE_FLAG: ['consentUpdated', 'gtmLoaded'],
    UPDATE_GTM_QUEUE_FLAG: ['gtmLoaded', 'consentUpdated']
}

const pushQueueInitializer = (initialState) => ({
    gtmLoaded: initialState.gtmLoaded,
    consentUpdated: initialState.consentUpdated,
    storeInQueue: initialState.storeInQueue,
    queue: initialState.queue
})

const pushQueueReducer = (state, action) => {
    const nextState = { ...state }
    const { type, value } = action
    switch (type) {
    case 'ADD_TO_DATALAYER': {
        if (state.storeInQueue) {
            nextState.queue = [...state.queue, value]
        } else {
            window.dataLayer?.push(value)
        }
        break
    }
    case 'UPDATE_GTM_QUEUE_FLAG':
    case 'UPDATE_CONSENT_QUEUE_FLAG': {
        Object.assign(nextState, {
            [actionTypeMap[type][0]]: value,
            storeInQueue: !(value && state[actionTypeMap[type][1]])
        })

        if (!nextState.storeInQueue) {
            state.queue.forEach((obj) => {
                window.dataLayer?.push(obj)
            })
            nextState.queue = []
        }
        break
    }
    default:
        break
    }
    return nextState
}

const usePushQueueReducer = () => {
    const [queueState, dispatch] = useReducer(pushQueueReducer, initialQueue, pushQueueInitializer)
    return { queueState, dispatch }
}

export default usePushQueueReducer
