import { object } from 'prop-types'
import useStyles from './style'
import { formatSimpleBbcode } from '../../../lib/utils'
import { COOKIE_LANGUAGE } from '../../_Mappings/cookies'
import { useCookies } from 'react-cookie'

const PromotionBadges = ({ productVariant }) => {
    const [cookies] = useCookies([COOKIE_LANGUAGE])
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    const classes = useStyles()
    const promotionBadges = new Array(
        JSON.parse(
            productVariant.varAttrs?.filter((attr) => (attr.name === 'label-br'))[0]?.values || '[]'
        )[language]
    )

    if (!(promotionBadges && promotionBadges.length > 0 && promotionBadges[0])) {
        return null
    }

    const getColorFromBBCode = (text) => {
        const charIndexStart = text.search(/\[color=/)
        if (charIndexStart < 0) {
            return undefined
        }
        return text.slice(charIndexStart + 7, text.search(/]/))
    }

    return (
        <div className={classes.root}>
            {promotionBadges.map((badge) => {
                const color = getColorFromBBCode(badge)
                return (
                    <div
                        key={badge}
                        style={{ color }}
                        className={classes.badge}
                    >
                        {formatSimpleBbcode(badge.trim())}
                    </div>
                )
            })}
        </div>
    )
}

PromotionBadges.propTypes = {
    productVariant: object.isRequired
}

export default PromotionBadges
