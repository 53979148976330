import React, { useContext, useState } from 'react'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import clsx from 'clsx'
import { IconButton, NoSsr, SwipeableDrawer } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import PropTypes from 'prop-types'
import useStyles from './style'
import { hasChildren } from '../../../../lib/utils'
import MobileBaseItem from '../../../_Elements/MenuItems/Mobile/MobileBaseItem'
import MobileMultiItem from '../../../_Elements/MenuItems/Mobile/MobileMultiItem'
import LocationSelect from '../../ShippingLocation/LocationSelect'
import ProductSearchPopover from '../../Product/ProductSearch/ProductSearchPopover/ProductSearchPopover'
import LanguageSelect from '../../Language/LanguageSelect'

const MobileMenu = ({ modalLocationIsOpen, setModalLocationIsOpen, locationLabel, modalLanguageIsOpen, setModalLanguageIsOpen, languageLabel }) => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const page = useContext(BrPageContext)
    const menuRef = component?.getModels().menu
    const menu = menuRef && page?.getContent(menuRef)

    const [drawer, setDrawer] = useState(false)

    const toggleDrawer = (open) => (e) => {
        if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            return
        }
        setDrawer(open)
    }

    const closeDrawer = () => {
        setDrawer(false)
    }

    const drawerList = () => (
        <div
            className={clsx(classes.list)}
            role='presentation'
        >
            <List component='div'>
                {menu.getItems()
                    .map((item) => (
                        hasChildren(item)
                            ? (
                                <MobileMultiItem
                                    key={item.getName()}
                                    item={item}
                                    closeDrawer={closeDrawer}
                                />
                            )
                            : (
                                <MobileBaseItem
                                    key={item.getName()}
                                    item={item}
                                    closeDrawer={closeDrawer}
                                />
                            )
                    ))}

            </List>
        </div>
    )

    return (
        <div>
            <IconButton
                color='inherit'
                aria-label='menu'
                onClick={toggleDrawer(true)}
            >
                <MenuIcon color='inherit' />
            </IconButton>
            <SwipeableDrawer
                anchor='left'
                open={drawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {drawerList()}
            </SwipeableDrawer>
            <ProductSearchPopover />
        </div>
    )
}

MobileMenu.propTypes = {
    modalIsOpen: PropTypes.bool,
    setModalIsOpen: PropTypes.func,
    locationLabel: PropTypes.string
}

MobileMenu.defaultProps = {
    modalIsOpen: undefined,
    setModalIsOpen: undefined,
    locationLabel: undefined
}

export default MobileMenu
