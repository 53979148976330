import { SvgIcon } from '@material-ui/core'

// eslint-disable-next-line react/prop-types
const User = (props, { stroke }) => (
    <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        {...props}
    >
        <path
            d='M21 22L16 15H8L3 22M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7Z'
            stroke={stroke}
            fill='none'
        />

    </SvgIcon>

)

export default User
