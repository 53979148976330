import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    paginationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paginationSpans: {
        fontSize: 20,
        margin: '0 20px'
    },
    button: {
        background: 'none',
        border: 'none',
        cursor: 'pointer'
    },
    disabled: {
        opacity: .5,
        cursor: 'auto'
    },
}))