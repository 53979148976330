import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'inherit',
        margin: theme.spacing(0.5)
    },
    badge: {
        width: 'max-content',
        height: theme.spacing(3.5),
        fontSize: 15,
        lineHeight: 1.8,
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: theme.spacing(0.75)
    }
}))
