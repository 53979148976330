import { useContext, useEffect } from 'react'
import { GoogleEnhancedEcommerce } from '../ThirdPartyScripts/GoogleEnhancedEcommerce'

const ErrorPage = () => {
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)

    useEffect(() => {
        const pushObject = {
            event: 'ErrorPage 404',
            eventCategory: 'error',
            eventAction: '404 error',
            eventLabel: ''
        }

        pushToDataLayer(pushObject)
    }, [])

    return null
}

export default ErrorPage
