/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { camelCase } from 'lodash'
import { useMediaQuery, useTheme } from '@material-ui/core'
import theme from './theme'
import { productFiltering } from '../components/_Mappings/productFiltering'

const SM_VIEW_ID_ITEM_NAME = 'smViewId'
export const SESSION_CART = 'cartId'
export const TOKEN = 'token'
export const FIREBASE_CONFIG = {
    /* TEST VALUES
    apiKey: "AIzaSyA4KI11qu_LrP-9s8qlRyNbs5qZ2To9Ntg",
    authDomain: "baldinini-test.firebaseapp.com",
    projectId: "baldinini-test",
    storageBucket: "baldinini-test.appspot.com",
    messagingSenderId: "410024670187",
    appId: "1:410024670187:web:5a84e45e0913fedb545fae",
    measurementId: "G-8E4TX7SPWX"*/
    apiKey: "AIzaSyD8yvn9QGkYgnfp0AjZtBP_qh8I8Ka8uqI",
    authDomain: "baldinini-35cc3.firebaseapp.com",
    projectId: "baldinini-35cc3",
    storageBucket: "baldinini-35cc3.appspot.com",
    messagingSenderId: "714191839837",
    appId: "1:714191839837:web:7d3159a45144d2370d23b4",
    measurementId: "G-E9VXJW0P5M"
}

export function getDefaultSMConnector(page) {
    // TODO: use spa-sdk instead of manually casting when CMS-13627 completed
    return page.toJSON().channel?.info.props.defaultSMConnector ?? 'brsm'
}

export function getDefaultSMViewId(page) {
    // TODO: use spa-sdk instead of manually casting when CMS-13627 completed
    return page.toJSON().channel?.info.props.smViewId
}

export function getConnector(page) {
    const useBrsm = process.env.REACT_APP_USE_BRSM === 'true'
    return useBrsm ? getDefaultSMConnector(page) : undefined
}

export function getSmViewId(page) {
    const defaultSmViewId = getDefaultSMViewId(page)
    if (typeof window !== 'undefined') {
        return sessionStorage.getItem(SM_VIEW_ID_ITEM_NAME) ?? defaultSmViewId
    }
    return defaultSmViewId
}

export function setPreferredSmViewId(smViewId) {
    if (smViewId) {
        sessionStorage.setItem(SM_VIEW_ID_ITEM_NAME, smViewId)
    } else {
        sessionStorage.removeItem(SM_VIEW_ID_ITEM_NAME)
    }
}

export function setCartIdInSession(cartId) {
    if (cartId) {
        sessionStorage.setItem(SESSION_CART, cartId)
    } else {
        sessionStorage.removeItem(SESSION_CART)
    }
}

export function getCartIdFromSession() {
    if (typeof window !== 'undefined') {
        const fallbackCartId = localStorage.getItem('fallbackCartId')
        if (fallbackCartId && window.location.search.includes('checkoutStep=4')) {
            sessionStorage.setItem(SESSION_CART, fallbackCartId)
            localStorage.removeItem('fallbackCartId')
            return fallbackCartId
        }

        if (fallbackCartId && (!window.location.pathname.includes('/checkout') || window.location.search.includes('checkoutStep=1'))) {
            sessionStorage.setItem(SESSION_CART, fallbackCartId)
            localStorage.removeItem('fallbackCartId')
            window.location.reload()
        }

        return sessionStorage.getItem(SESSION_CART) ?? undefined
    }
    return undefined
}

export function setTokenInSession(token) {
    if (token) {
        sessionStorage.setItem(TOKEN, token)
    } else {
        sessionStorage.removeItem(TOKEN)
    }
}

export function getTokenFromSession() {
    if (typeof window !== 'undefined') {
        return sessionStorage.getItem(TOKEN) ?? undefined
    }
    return undefined
}

const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000

export function convertDateToLocalTimezone(date) {
    return new Date(date.getTime() + timezoneOffset)
}

export function convertDateToUTCTimezone(date) {
    return new Date(date.getTime() - timezoneOffset)
}

export function getSlugFromUrl(url, index = url.split('/').length - 1) {
    const getSlug = (_url) => _url.split('/')[index]
    const hasParams = (_url) => _url.split('?').length > 1 && _url.split('?')[index] !== ''
    const removeParams = (_url) => getSlug(_url).split('?')[0]

    return hasParams(url) ? removeParams(url) : getSlug(url)
}

export const getVarAttrs = (variant) => Object.fromEntries(variant.varAttrs?.map(({
    name,
    values
}) => {
    try {
        return ([
            camelCase(name),
            JSON.parse(values[0])
        ])
    } catch (e) {
        return ([
            camelCase(name),
            values[0]
        ])
    }
}))

export const availableSizes = (item) => item?.variants?.map(({ itemId, availability }) => {
    const [sku, size] = itemId.code.split('-')
    return { sku, size, quantity: availability?.channels?.[0]?.availableQuantity }
})

export function hasChildren(item) {
    const children = item.getChildren()

    if (children === undefined) {
        return false
    }

    return children.length !== 0
}

export const useWidth = () => {
    const thm = useTheme()
    const keys = [...thm.breakpoints.keys].reverse()
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(thm.breakpoints.up(key))
            return !output && matches ? key : output
        }, null) || 'xs'
    )
}

export const getValueAtPath = (path, object) => path.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : null), object)

export const checkRequiredForInputLabelProps = (rules) => {
    switch (typeof rules?.required) {
    case 'object':
        return rules.required.value
    case 'string':
        return !!rules.required
    default:
        return false
    }
}

export const getUserAgent = () => navigator.userAgent
    || navigator.vendor
    || window.opera
    || null

export const isMobileOrTablet = () => {
    const userAgent = getUserAgent() || ''
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgent.substr(0, 4))
}

export const getDevice = () => {
    if (!isMobileOrTablet()) {
        return 'Desktop'
    }
    return window.innerWidth < theme.breakpoints.values.m ? 'Mobile' : 'Tablet'
}

export const getOrientation = () => (window.screen.height < window.screen.width ? 'landscape' : 'portrait')

export const REG_EXP = {
    emailOnly: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
}

export const getLoginStatus = (user) => (user?.currentCustomer ? 'Logged' : 'Guest')

export const ENVIRONMENT = process.env.GOOGLE_ANALYTICS_ENV || 'TEST'

export const stopPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
}

export const pushCountLoadMoreClickToHistory = (countClick) => {
    if (countClick) {
        const { state } = window.history
        const { search, pathname } = window.location
        const searchWithOutQuestionMark = search.replace('?', '')
        const and = searchWithOutQuestionMark ? '&' : ''
        const newCountParam = `countclickloadmore=${countClick}`
        const param = `?${searchWithOutQuestionMark.search(/countclickloadmore=/) > -1
            ? searchWithOutQuestionMark.replace(/(countclickloadmore\=)([0-9]+)/ig, newCountParam)
            : searchWithOutQuestionMark + and + newCountParam}`
        const historyStateNext = {
            ...state,
            as: pathname + param
        }
        window.history.pushState(historyStateNext, state.url, param)
    }
}

export const getCountLoadMoreClickFromHistory = () => {
    try {
        const count = window.history.state.as.split('countclickloadmore=')?.[1]?.split('&')[0]
        return count ? parseInt(count, 10) : 0
    } catch {
        return 0
    }
}

export const getFacetFilterFromUrl = (componentId, incomingQueryStringValues) => {
    if (incomingQueryStringValues == null || Object.keys(incomingQueryStringValues).length === 0) return []

    const urlParams = []

    Object.entries(productFiltering).forEach(([key, value]) => {
        const incomingQueryStringValue = incomingQueryStringValues[`${componentId}_${value.paramKey}`]

        if (!incomingQueryStringValue || incomingQueryStringValue === '') return

        urlParams.push({
            id: key,
            values: incomingQueryStringValue
                .split(',')
                .map((e) => ((value.type === 'color') ? `"#${e}"` : `"${e}"`))
        })
    })


    return urlParams
}

export const formatSimpleBbcode = (text = '') => {
    let textToEdit = text
        .replace(/<\s*script.*?>.*?(<\s*\/script.*?>|$)/igm, '')
        .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/igm, '')

    if (textToEdit.search(/\[\/(b|i|u|s|br|color)\]/g) < 0) {
        return textToEdit
    }

    const getTextSimpleReplacePart = (txt, bbcode, htmlTag) => {
        const reColorBbcodeStart = new RegExp(`\\[${bbcode}]`, 'g')
        const reColorBbcodeEnd = new RegExp(`\\[\\/${bbcode}]`, 'g')
        return txt.replace(reColorBbcodeStart, `<${htmlTag}>`).replace(reColorBbcodeEnd, `</${htmlTag}>`)
    }

    Object.entries({
        b: 'b',
        i: 'i',
        u: 'ins',
        s: 'del',
        br: 'br' 
    }).forEach((item) => {
        textToEdit = getTextSimpleReplacePart(textToEdit, item[0], item[1])
    })

    const reColorBbcodeStart = /\[color=[a-zA-Z0-9# .,\\(\\)]*\]/
    const reColorBbcodeEnd = /\[\/color]/
    while (reColorBbcodeStart.test(textToEdit) && reColorBbcodeEnd.test(textToEdit)) {
        const substringStart = textToEdit.slice(textToEdit.search(reColorBbcodeStart))
        const substring = substringStart.slice(0, substringStart.search(reColorBbcodeEnd) + 8)
        const matchColorBbcodeStart = substring.match(reColorBbcodeStart)
        if (!matchColorBbcodeStart) {
            break
        }
        const color = matchColorBbcodeStart[0].replace(/\[color=|]/g, '')
        const replaceSubstring = substring
            .replace(reColorBbcodeStart, `<span style='color:${color}'>`)
            .replace(reColorBbcodeEnd, '</span>')

        textToEdit = textToEdit.replace(substring, replaceSubstring)
    }

    return <span dangerouslySetInnerHTML={{ __html: textToEdit }} />
}

export const stringToArr = (string, divider = ',') => string?.split(divider).filter((item) => !!item) || []

export const get4DigitLocale = () => {
    const locale = navigator?.language ?? 'en'
    return locale.length < 3 ? `${locale}-${locale.toUpperCase()}` : locale
}

export const nodeLog = (title, value) => {
    console.log('')
    console.log('')
    console.log(title)
    console.log('')
    console.log(value)
    console.log('')
}