import { object, bool } from 'prop-types'
import { Radio } from '@material-ui/core'
import useStyles from './style'

const ColorSwatchButton = ({
    color, checked, ...props
}) => {
    const classes = useStyles({ color })
    const cssClass = color.colour2Hex ? 'twoColorIcon' : 'icon'
    return (
        <div className={classes.swatchContainer}>
            <Radio
                className={classes.radio}
                checked={checked}
                disableRipple
                checkedIcon={(
                    <span className={classes.checkedIconContainer}>
                        <span className={classes[cssClass]} />
                    </span>
                )}
                icon={<span className={classes.iconContainer}><span className={classes[cssClass]} /></span>}
                {...props}
            />
        </div>
    )
}

ColorSwatchButton.propTypes = {
    color: object.isRequired,
    checked: bool
}

ColorSwatchButton.defaultProps = {
    checked: false
}
export default ColorSwatchButton
