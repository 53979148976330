import Typography from '@material-ui/core/Typography'
import { Card } from '@material-ui/core'
import clsx from 'clsx'
import { shape, string } from 'prop-types'
import { AccountBalance } from '@material-ui/icons'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const paymentImageSize = { width: 64, height: 43 }

const ChosenPayment = ({ paymentInfos }) => {
    const classes = useStyles()
    const translations = useTranslation()

    const { paymentLabel, paymentLogo } = paymentInfos

    return (
        <Card className={clsx(classes.paymentContainer)} elevation={0}>
            <div>
                <Typography variant='body1' className={classes.summarySubtotal}>
                    {translations('checkout:payment.chosen')}
                </Typography>
            </div>
            <div className={classes.summaryPrices}>
                { paymentLogo
                    ? (
                        <img
                            alt={`${paymentLabel} logo`}
                            className={classes.image}
                            src={paymentLogo}
                            style={{ width: 64, height: 43 }}
                        />
                    )
                    : <AccountBalance style={paymentImageSize} stroke='black' />}
            </div>
        </Card>
    )
}

ChosenPayment.propTypes = {
    paymentInfos: shape({
        encryptedPayment: string,
        paymentMethodCategory: string,
        paymentLabel: string,
        paymentLogo: string
    })
}

ChosenPayment.defaultProps = {
    paymentInfos: {
        encryptedPayment: '',
        paymentMethodCategory: '',
        paymentLabel: '',
        paymentLogo: ''
    }
}

export default ChosenPayment
