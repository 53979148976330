import { useForm } from 'react-hook-form'
import { useAddAddress } from '@bloomreach/connector-components-react'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useContext, useEffect } from 'react'
import { func, bool } from 'prop-types'
import { useCookies } from 'react-cookie'
import { BrPageContext } from '@bloomreach/react-sdk'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import FormSelect from '../../../_Elements/Inputs/FormSelect'
import statesMap from '../../../_Mappings/statesMap'
import LoginContext from '../Login/LoginContext'
import Link from '../../../_Elements/Link'

const AddAddress = ({
    onSuccess, onSubmit, disableEmail
}) => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const translations = useTranslation()
    const {
        handleSubmit, reset, getValues, ...formCtx
    } = useForm()
    const [addAddress, result, loading, error] = useAddAddress()
    const [cookies] = useCookies([COOKIE_LOCATION])
    const { user } = useContext(LoginContext)
    const shippingLocation = cookies[COOKIE_LOCATION]
    const states = statesMap[shippingLocation]

    const handleFormSubmit = handleSubmit((values) => {
        onSubmit?.(values)

        if (!user?.anonymous) {
            addAddress({
                ...values,
                billingAddress: true,
                shippingAddress: true,
                readOnly: false
            })
        }
    })

    useEffect(() => {
        if (result?.success && result.address) {
            if (onSuccess) {
                onSuccess(result.address)
            }
            reset()
        }
    }, [result])

    return (
        <form onSubmit={handleFormSubmit}>
            {user?.anonymous && !disableEmail && (
                <FormTextField
                    name='email'
                    label={translations('forms:email.label')}
                    type='email'
                    formCtx={formCtx}
                    rules={{
                        required: {
                            value: true,
                            message: translations('forms:rules.message.field.required')
                        }
                    }}
                />
            ) }
            <FormTextField
                label={translations('forms:first.name.label')}
                name='firstName'
                formCtx={formCtx}
                maxLength={15}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-firstName'
            />
            <FormTextField
                label={translations('forms:middle.name.label')}
                name='middleName'
                maxLength={15}
                formCtx={formCtx}
                data-qa='input-accountAddAddress-middleName'
            />
            <FormTextField
                label={translations('forms:last.name.label')}
                name='lastName'
                maxLength={70}
                formCtx={formCtx}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-lastName'
            />
            <FormTextField
                label={translations('forms:company.label')}
                name='company'
                formCtx={formCtx}
                data-qa='input-accountAddAddress-company'
            />
            <FormTextField
                label={translations('forms:country.label')}
                name='country'
                defaultValue={shippingLocation}
                disabled
                maxLength={2}
                formCtx={formCtx}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-country'
            />
            {states && (
                <FormSelect
                    label={translations('forms:state.label')}
                    name='state'
                    id='addressState'
                    maxLength={35}
                    formCtx={formCtx}
                    rules={{
                        required: {
                            value: true,
                            message: translations('forms:rules.message.field.required')
                        }
                    }}
                    options={states}
                    className={classes.formSelect}
                    data-qa='input-accountAddAddress-state'
                />
            )}
            <FormTextField
                label={translations('forms:city.label')}
                name='city'
                formCtx={formCtx}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-city'
            />
            <FormTextField
                label={translations('forms:postal.code.label')}
                name='postalCode'
                formCtx={formCtx}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-postalCode'
            />
            <FormTextField
                label={translations('forms:address.label')}
                name='streetAddress'
                formCtx={formCtx}
                maxLength={50}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-address'
            />
            <FormTextField
                label={translations('forms:address.additional.info.label')}
                name='additionalStreetInfo'
                formCtx={formCtx}
                maxLength={50}
                data-qa='input-accountAddAddress-additionalAddressInfo'
            />
            <FormTextField
                label={translations('forms:address.phone')}
                name='phone'
                type='number'
                formCtx={formCtx}
                rules={{
                    required: {
                        value: true,
                        message: translations('forms:rules.message.field.required')
                    }
                }}
                data-qa='input-accountAddAddress-phone'
            />
            {error && (
                <FormHelperText error>{error.message}</FormHelperText>
            )}
            {result && (
                <FormHelperText>{result.message}</FormHelperText>
            )}
            <div className={classes.addButtonContainer}>
                { disableEmail && (
                    <BaseButton
                        color='secondary'
                        component={Link}
                        href={page.getUrl('/cart')}
                        className={classes.back}
                        data-qa='button-accountAddAddress-back'
                    >
                        { translations('checkout:back') }
                    </BaseButton>
                ) }

                <BaseButton
                    type='submit'
                    variant='contained'
                    loading={loading}
                    data-qa='button-accountAddAddress-add'
                >
                    {translations('forms:add.address.button')}
                </BaseButton>
            </div>
        </form>
    )
}

AddAddress.propTypes = {
    onSuccess: func,
    onSubmit: func,
    disableEmail: bool
}

AddAddress.defaultProps = {
    onSuccess: undefined,
    onSubmit: undefined,
    disableEmail: false
}

export default AddAddress
