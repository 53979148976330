import { object } from 'prop-types'
import { Card, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import useStyles from './style'
import BaseButton from '../../../../_Elements/Inputs/Button/BaseButton'
import Link from '../../../../_Elements/Link'

const getImageVariant = (teaser) => {
    if (teaser.width === teaser.height) return 'square'
    return (teaser.width > teaser.height) ? 'vertical' : 'portrait'
}

const ProductGridTeaserCard = ({ teaser }) => {
    const classes = useStyles({
        mobileBackground: `url(${teaser.imageVariants.portrait})`,
        width: teaser.width,
        height: teaser.height
    })

    const cardStyle = useMemo(() => ({
        backgroundImage: `url(${teaser.imageVariants[getImageVariant(teaser)]})`
    }), [teaser])

    return (
        <Card
            style={cardStyle}
            elevation={0}
            className={classes.card}
        >
            <div
                className={classes.minHeighthelper}
            />
            <div
                className={classes.contentWrapper}
            >
                <div
                    className={classes.textWrapper}
                >
                    { teaser.subline && (
                        <div>
                            <Typography
                                className={classes.subline}
                                align='center'
                                color='secondary'
                                variant='h5'
                                component='p'
                            >
                                {teaser.subline}
                            </Typography>
                        </div>
                    )}
                    { teaser.headline && (
                        <div>
                            <Typography
                                className={classes.headline}
                                align='center'
                                color='secondary'
                                variant='h3'
                                component='h2'
                            >
                                {teaser.headline}
                            </Typography>
                        </div>
                    )}
                </div>

                { teaser.buttonLink && (
                    <div>
                        <BaseButton
                            noLinkStyle
                            component={Link}
                            href={teaser.buttonLink}
                            color='secondary'
                        >
                            {teaser.buttonText}
                        </BaseButton>
                    </div>
                )}
            </div>
        </Card>
    )
}

ProductGridTeaserCard.propTypes = {
    teaser: object
}

ProductGridTeaserCard.defaultProps = {
    teaser: undefined
}

export default ProductGridTeaserCard
