import {
    useState, useEffect, useContext, createContext
} from 'react'
import { node } from 'prop-types'
import {
    CommerceConnectorContext, useCart, useCurrentCustomer
} from '@bloomreach/connector-components-react'
import { getCartIdFromSession, setCartIdInSession, setTokenInSession } from '../../../../lib/utils'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import storeKeys from '../../../_Mappings/storeKeys'
import { useCookies } from 'react-cookie'

const LoginContext = createContext({ loading: false })
export const LoginConsumer = LoginContext.Consumer
export const LoginProvider = ({ children }) => {
    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}
    const [user, loading, error] = useCurrentCustomer()
    const [cartId, setCartId] = useState(getCartIdFromSession())
    const { currentToken } = useContext(CommerceConnectorContext)
    const [cart] = useCart({ cartId: undefined, storeKey })

    useEffect(() => {
        setCartIdInSession(cartId)
    }, [cartId])

    useEffect(() => {
        setTokenInSession(currentToken)
    }, [currentToken])

    useEffect(() => {
        if (cart?.state === 'Ordered' && setCartId) {
            setCartId(undefined)
        }
    }, [cart, setCartId])

    if (error) {
        throw error
    }

    return (
        <LoginContext.Provider
            value={{
                loading,
                user,
                cartId,
                setCartId
            }}
        >
            {children}
        </LoginContext.Provider>
    )
}

LoginProvider.propTypes = {
    children: node
}

LoginProvider.defaultProps = {
    children: undefined
}

export default LoginContext
