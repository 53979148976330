import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    container: {
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center'
    },
    banner: {
        background: 'black'
    },
    text: {
        width: '100%',
        textAlign: 'center',
        color: 'white',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    placeholder: {
        background: 'transparent'
    },
    carouselContainer: {
        width: '100%'
    },
    carouselItem: {
        padding: theme.spacing(0, 12),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    carouselItemMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
