import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        position: 'relative'
    },
    item: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(4)
    },
    itemLast: {
        paddingTop: theme.spacing(1)
    },
    footer: {
        position: 'relative',
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6)
        }
    },
    gridContainer: {
        align: 'stretch'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    terms: {
        fontSize: '12px',
        paddingRight: theme.spacing(2)
    },
    privacy: {
        fontSize: '12px'
    },
    cookie: {
        fontSize: '12px',
        paddingLeft: theme.spacing(2)
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0
            }
        }
    }
}))
