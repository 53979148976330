import { BrComponentContext } from '@bloomreach/react-sdk'
import React, { useContext, useMemo } from 'react'
import Image from 'next/image'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useMediaQuery } from '@material-ui/core'
import useStyles from './style'
import BaseButton from '../../_Elements/Inputs/Button/BaseButton'
import Link from '../../_Elements/Link'
import { useWidth } from '../../../lib/utils'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'
import theme from '../../../lib/theme'
import useBannerImageLoader from '../../_Hooks/useBannerImageLoader'

const PanoramaBanner = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const breakpoint = useWidth()
    const isMobile = useMediaQuery((thm) => thm.breakpoints.down('sm'))

    const params = component.getParameters()
    const { cmsBaseUrl } = usePageCfg()
    const imageProps = useMemo(() => {
        if (!params.image) return null

        const fileName = params.image.substring(params.image.lastIndexOf('/') + 1)
        const imagePath = `${cmsBaseUrl}/binaries${params.image}`

        switch (breakpoint) {
        case 'sm':
        case 'xs': {
            return {
                layout: 'responsive',
                width: theme.breakpoints.values.md,
                height: 1200,
                src: `${imagePath}/${fileName}/baldininibrxm:portraitbanner`
            }
        }
        default: {
            return {
                layout: 'fill',
                objectFit: 'cover',
                src: `${imagePath}/${fileName}/baldininibrxm:panorama`
            }
        }
        }
    }, [breakpoint, params])

    const panoramaBannerImageLoader = useBannerImageLoader(breakpoint, params)

    return (
        <div className={classes.root}>
            {params.image && (
                <div className={clsx(classes.imageContainer, classes.fillCover)}>
                    {(!params.buttonLabel && params.link)
                        ? (
                            <Link href={params.link}>
                                <Image
                                    {...imageProps}
                                    loader={panoramaBannerImageLoader}
                                    alt={params.headline + ' image'}
                                    title={params.headline}
                                />
                            </Link>
                        ) : (
                            <Image
                                {...imageProps}
                                loader={panoramaBannerImageLoader}
                                alt={params.headline + ' image'}
                                title={params.headline}
                            />
                        )}
                    <div className={classes.content}>
                        {params.subheadline
                            && (
                                <Typography
                                    variant='subtitle1'
                                    className={classes.preHeadline}
                                    component='p'
                                >
                                    {params.subheadline}
                                </Typography>
                            )}
                        {params.headline
                            && (
                                <Typography
                                    variant='h1'
                                    component='div'
                                    className={classes.headline}
                                >
                                    {params.headline}
                                </Typography>
                            )}
                        {params.buttonLabel
                            && !isMobile && (
                            <BaseButton
                                color='secondary'
                                component={params.link ? Link : Typography}
                                noLinkStyle
                                href={params.link}
                                className={classes.button}
                            >
                                {params.buttonLabel}
                            </BaseButton>
                        )}
                    </div>
                </div>
            )}
            {params.buttonLabel
                && isMobile && (
                <div className={classes.mobileButtonContainer}>
                    <BaseButton
                        variant='outlined'
                        component={params.link ? Link : Typography}
                        noLinkStyle
                        href={params.link}
                        className={classes.button}
                    >
                        {params.buttonLabel}
                    </BaseButton>
                </div>
            )}
        </div>
    )
}

export default PanoramaBanner
