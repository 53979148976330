import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minWidth: theme.spacing(10)
    },
    formControlLabel: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            display: 'none'
        }
    },
    label: {
        fontSize: 12,
        margin: theme.spacing(0, 1, 1.5),
        lineHeight: '15.6px',
        letterSpacing: '0.05em',
        color: theme.palette.primary.main,
        fontFamily: 'DM Sans'
    }
}))
