const warehouseMap = {
    AL: 'inventory-italy',
    AU: 'inventory-italy',
    AT: 'inventory-italy',
    AZ: 'inventory-italy',
    BE: 'inventory-italy',
    BA: 'inventory-italy',
    BG: 'inventory-italy',
    CA: 'inventory-italy',
    HR: 'inventory-italy',
    CY: 'inventory-italy',
    CZ: 'inventory-italy',
    DK: 'inventory-italy',
    EE: 'inventory-italy',
    FI: 'inventory-italy',
    FR: 'inventory-italy',
    GE: 'inventory-italy',
    DE: 'inventory-italy',
    GR: 'inventory-italy',
    HU: 'inventory-italy',
    IN: 'inventory-italy',
    IL: 'inventory-italy',
    IT: 'inventory-italy',
    JP: 'inventory-italy',
    LV: 'inventory-italy',
    LT: 'inventory-italy',
    LU: 'inventory-italy',
    MT: 'inventory-italy',
    MD: 'inventory-italy',
    MC: 'inventory-italy',
    ME: 'inventory-italy',
    NL: 'inventory-italy',
    NO: 'inventory-italy',
    PL: 'inventory-italy',
    PT: 'inventory-italy',
    RO: 'inventory-italy',
    RU: 'inventory-russia',
    SA: 'inventory-italy',
    RS: 'inventory-italy',
    SK: 'inventory-italy',
    SI: 'inventory-italy',
    ES: 'inventory-italy',
    SE: 'inventory-italy',
    CH: 'inventory-italy',
    TR: 'inventory-italy',
    UA: 'inventory-italy',
    GB: 'inventory-italy',
    US: 'inventory-italy'
}

export default warehouseMap
