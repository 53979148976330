import {
    Badge, IconButton, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { bool } from 'prop-types'
import Favorite from '../../Icons/Favorite'
import WISHLIST_QUERY from '../../../_Hooks/useWishlist/WISHLIST_QUERY'
import WishlistModal from '../WishlistModal/WishlistModal'
import { getLoginStatus } from '../../../../lib/utils'
import useTranslation from '../../ResourceBundles/useTranslation'

const WishlistModalButton = ({ asListItem }) => {
    const t = useTranslation()
    const [showWishlist, setShowWishlist] = useState(false)
    const { data } = useQuery(WISHLIST_QUERY)
    const [user] = useCurrentCustomer()

    if (getLoginStatus(user) === 'Guest') return null

    const wishlist = data?.findWishlists.wishlists[0] ?? {}

    return (
        <>
            {asListItem
                ? (
                    <ListItem
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setShowWishlist(() => true)
                        }}
                        button
                    >
                        <ListItemIcon>
                            <Badge
                                badgeContent={wishlist?.entries?.length}
                                color='error'
                            >
                                <Favorite stroke='currentColor' />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={t('wishlist')} />
                    </ListItem>
                ) : (
                    <IconButton
                        onClick={() => { setShowWishlist(() => true) }}
                        color='inherit'
                        aria-label='wishlist'
                    >
                        <Badge
                            badgeContent={wishlist?.entries?.length}
                            color='error'
                        >
                            <Favorite stroke='currentColor' />
                        </Badge>
                    </IconButton>
                )}
            <WishlistModal
                open={showWishlist}
                onClose={() => { setShowWishlist(() => false) }}
            />
        </>
    )
}

WishlistModalButton.propTypes = {
    asListItem: bool
}

WishlistModalButton.defaultProps = {
    asListItem: false
}

export default WishlistModalButton
