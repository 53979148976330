import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { node } from 'prop-types'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const PROTECTED_ROUTE = ({ children, redirectUrl = '/signin' }) => {
    const [user] = useCurrentCustomer()
    const { push } = useRouter()

    useEffect(() => {
        if (user?.anonymous) push(redirectUrl)
    }, [user?.anonymous, redirectUrl])

    if (!user || user.anonymous) return null

    return children
}

PROTECTED_ROUTE.propTypes = {
    redirectUrl: node,
    children: node
}

PROTECTED_ROUTE.defaultProps = {
    redirectUrl: null,
    children: null
}

const ProtectedRoute = (Component) => (props) => (
    <PROTECTED_ROUTE redirectUrl='/signin'>
        <Component {...props} />
    </PROTECTED_ROUTE>
)

export default ProtectedRoute
