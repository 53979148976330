import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    rootnl: {
        marginTop: theme.spacing(4.5),
        marginBottom: theme.spacing(8)
    },
    bgnl: {
        display: 'flex',
        backgroundColor: theme.palette.gray.lighter,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6)
    },
    contentnl: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    iconnl: {
        marginBottom: theme.spacing(2.5)
    },
    headline: {
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '1px',
        lineHeight: '32.5px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4)
    },
    textnl: {
        marginBottom: theme.spacing(5)
    },
    formnl: {
        marginBottom: theme.spacing(5)
    }
}))
