import 'react-multi-carousel/lib/styles.css'
import { useContext } from 'react'
import { BrComponentContext } from '@bloomreach/react-sdk'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useCookies } from 'react-cookie'
import useStyles from './style'
import ProductSliderByCategory from './ProductSliderByCategory'
import ProductSliderByTag from './ProductSliderByTag'
import { COOKIE_LOCATION } from '../../_Mappings/cookies'
import storeKeys from '../../_Mappings/storeKeys'

const ProductSlider = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)

    const [cookies] = useCookies([COOKIE_LOCATION, '_br_uid_2'])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const brUid2 = cookies?._br_uid_2
    const { storeKey } = storeKeys[location] || {}

    const { sliderTitle, tag, maximumNumberOfProducts } = component.getParameters()

    return (
        <Container maxWidth='xl'>
            <div className={classes.root}>
                <Typography align='center' component='h2' variant='h5' className={classes.headline}>{sliderTitle}</Typography>

                {tag
                    ? (
                        <ProductSliderByTag
                            maximumNumberOfProducts={maximumNumberOfProducts}
                            tag={tag}
                            brUid2={brUid2}
                            storeKey={storeKey}

                        />
                    )
                    : (
                        <ProductSliderByCategory
                            maximumNumberOfProducts={maximumNumberOfProducts}
                            tag={tag}
                            brUid2={brUid2}
                            storeKey={storeKey}
                        />
                    )}
            </div>
        </Container>
    )
}

ProductSlider.propTypes = {
}

ProductSlider.defaultProps = {
}

export default ProductSlider
