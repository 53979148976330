import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        margin: theme.spacing(5, 'auto'),
        maxWidth: theme.breakpoints.values.lg
    },
    list: {
        margin: theme.spacing(4, 0, 0, 0)
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    addressContainer: {
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        },
        justifyContent: 'space-around'
    },
    addressListContainer: {
        width: theme.breakpoints.values.s,
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(45)
        }
    },
    defaultAddressesContainer: {
        width: theme.breakpoints.values.s,
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(45)
        }
    },
    addressListMargin: {
        marginTop: theme.spacing(5)
    },
    editButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    editAddressContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    sameAsShippingText: {
        marginBottom: theme.spacing(4)
    },
    checkbox: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    },
    addAddressButtonContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}))
