import { string, bool } from 'prop-types'
import React, { useEffect, useRef } from 'react'
import useStyles from './style'

const VideoPlayer = ({
    src, isPlaying, autoPlay, loop, muted, playsInline
}) => {
    const classes = useStyles()
    const videoRef = useRef()

    useEffect(() => {
        if (isPlaying) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
            videoRef.current.currentTime = 0
        }
    }, [isPlaying])

    return (
        <video
            ref={videoRef}
            className={classes.videoPlayer}
            src={src}
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
            playsInline={playsInline}
        />
    )
}

VideoPlayer.propTypes = {
    src: string.isRequired,
    autoPlay: bool,
    isPlaying: bool,
    loop: bool,
    muted: bool,
    playsInline: bool,
}

VideoPlayer.defaultProps = {
    autoPlay: true,
    isPlaying: true,
    loop: true,
    muted: true,
    playsInline: true,
}

export default VideoPlayer
