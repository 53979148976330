import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: theme.spacing(171),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    iconExpandMore: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        })
    },
    hide: {
        transform: 'rotate(180deg)'
    },
    reset: {
        marginLeft: theme.spacing(2),
        '& > span': {
            textDecoration: 'underline'
        }
    },
    optionsContainer: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('m')]: {
            '& > :first-child, & > :last-child': {
                flexGrow: 1,
                flexBasis: 0
            },
            '& > :last-child': {
                justifyContent: 'flex-end'
            }
        }
    },
    divider: {
        marginBottom: theme.spacing(6.25)
    },
    filterGroup: {
        padding: 0,
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: ({ collapsed }) => theme.spacing(collapsed ? -0.5 : -2.5),
        marginRight: ({ collapsed }) => theme.spacing(collapsed ? -0.5 : -2.5),
        '& > li': {
            margin: ({ collapsed }) => theme.spacing(0, 0, collapsed ? 0 : 4),
            paddingLeft: ({ collapsed }) => theme.spacing(collapsed ? 0 : 2),
            paddingRight: ({ collapsed }) => theme.spacing(collapsed ? 0 : 2)
        }
    },
    filterGroupDesktop: {
        marginBottom: theme.spacing(3)
    },
    filterGroupMobil: {
        marginTop: theme.spacing(3)
    },
    wrapButtons: {
        width: '100%',
        [theme.breakpoints.up('m')]: {
            width: ({ gridCountButtons }) => (gridCountButtons < 2 ? '100%' : '50%')
        },
        [theme.breakpoints.up('md')]: {
            width: ({ gridCountButtons }) => `${100 / (gridCountButtons < 4 ? gridCountButtons : 4)}%`
        },
        '& hr': {
            marginTop: theme.spacing(4.5)
        }
    },
    wrapSlider: {
        width: '100%!important',
        [theme.breakpoints.up('m')]: {
            width: '50%!important'
        }
    },
    rowBreak: {
        [theme.breakpoints.up('m')]: {
            marginRight: '50%!important'
        }
    },
    filterGroupButtonsTitle: {
        margin: theme.spacing(0, 0.5, 2)
    },
    filterSliderTitle: {
        margin: theme.spacing(0, 0.5)
    },
    slider: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        '& .MuiSlider-markLabel': {
            color: theme.palette.gray.main
        },
        '& .MuiSlider-markLabel[data-index="0"][style*="0"]': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiSlider-markLabel[style*="100%"]': {
            transform: 'translateX(-100%)'
        },
        '& .MuiSlider-thumb': {
            marginLeft: 0,
            marginRight: 0,
            width: '16px',
            height: '16px',
            marginTop: '-7px'
        },
        '& .MuiSlider-thumb[data-index="1"]': {
            transform: 'translate(-16px, 0)'
        }
    },
    sliderValues: {
        color: theme.palette.gray.main,
        marginTop: theme.spacing(-1),
        display: 'flex',
        justifyContent: 'space-between'
    },
    unit: {
        fontWeight: 'bold',
        marginRight: theme.spacing(0.5)
    },
    min: {
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(3)
    },
    max: {
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing(3)
    },
    filterButton: {
        borderRadius: '2px',
        color: '#000',
        border: `1px solid ${theme.palette.gray.light}`,
        padding: theme.spacing(0, 2),
        margin: theme.spacing(0.5),
        height: theme.spacing(6),
        [theme.breakpoints.up('m')]: {
            height: theme.spacing(3.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        }
    },
    active: {
        borderColor: '#000'
    },
    icon: {
        transform: 'scale(0.5)',
        marginRight: theme.spacing(-1)
    },
    // MOBILE
    buttonClose: {
        marginLeft: 'auto',
        padding: theme.spacing(3)
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(5)
    },
    dialogBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2.25),
        paddingBottom: theme.spacing(2.25)
    },
    dialogAction: {
        display: 'flex',
        justifyContent: 'center'
    }
}))
