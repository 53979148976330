import {
    useContext, useEffect, useMemo, useRef, useState
} from 'react'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'
import storeKeys from '../../_Mappings/storeKeys'
import regionLocales from '../../_Mappings/regionLocales'
import { getDevice, getLoginStatus } from '../../../lib/utils'
import { LoyaltyPointsContext } from '../../_Modules/Account/LoyaltyPoints/LoyaltyPointsContext'

const useGoogleCheckoutStep = (activeStep, cart, user, location, language, googleAnalyticsEnvironment) => {
    const {
        pushToDataLayer,
        prepareProductForDataLayer,
        prepareItemForDataLayer,
        updateGenericDatalayer,
        getPageTemplate
    } = useContext(GoogleEnhancedEcommerce)

    const { loyaltyPoints: { customerCode, customerStore } } = useContext(LoyaltyPointsContext)

    const userid = customerCode ? customerCode : ''
    const storeid = customerStore ? customerStore : ''

    const trackedRef = useRef(false)
    const [trackedActiveStep, setTrackedActiveStep] = useState(-1)

    const genericDataLayerObj = useMemo(() => ({
        environment: googleAnalyticsEnvironment,
        country: location,
        currencyCode: storeKeys[location]?.currency,
        contentGroup: 'Buying',
        language: language,
        siteName: 'Baldinini',
        userId: storeid + userid,
        pageTemplate: getPageTemplate('purchase'),
        domainName: window.location.hostname,
        device: getDevice(),
        loginStatus: getLoginStatus(user)
    }), [user, location])

    useEffect(() => {
        if (activeStep !== trackedActiveStep) {
            trackedRef.current = false
            setTrackedActiveStep(activeStep)
        }
    }, [activeStep])

    useEffect(() => {
        if (!!cart?.id
            && trackedActiveStep !== -1
            && trackedActiveStep !== 4
            && trackedRef.current === false
        ) {
            trackedRef.current = true
            const products = cart.entries.map((checkoutItem) => {
                const { items, quantity = '' } = checkoutItem
                const item = items ? items[0] : {}
                return prepareProductForDataLayer(item, { quantity })
            })

            const items = cart.entries.map((checkoutItem) => {
                const { items, quantity = '' } = checkoutItem
                const item = items ? items[0] : {}
                return prepareItemForDataLayer(item, { quantity })
            })

            if (trackedActiveStep === 1) {
                updateGenericDatalayer(genericDataLayerObj)
            }

            const cartSubtotal = (cart.totalPurchasePrice?.moneyAmounts[0]?.amount).toFixed(2)

            pushToDataLayer({
                event: 'checkout',
                eventCategory: 'eCommerce',
                eventAction: 'Checkout',
                cartSubtotal,
                ecommerce: {
                    checkout: {
                        actionField: { step: trackedActiveStep },
                        products,
                        items
                    }
                }
            })
        }
    }, [user, cart, trackedActiveStep])
}

export default useGoogleCheckoutStep
