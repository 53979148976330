import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    faq: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    mobileOverflow: {
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch'
    }
}))
