import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        color: 'black',
        fontWeight: '500',
        flexGrow: 1,
        alignSelf: 'center'
    },
    topLeftItem: {
        fontSize: '12px',
        paddingRight: theme.spacing(5)
    },
    list: {
        maxWidth: '80vw'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    fullList: {
        width: 'auto'
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0
            }
        }
    }
}))
