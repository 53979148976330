import { SvgIcon } from '@material-ui/core'

// eslint-disable-next-line react/prop-types
const Mail = (props, { stroke }) => (
    <SvgIcon
        viewBox='0 0 24 24'
        width='24'
        height='24'
        {...props}
    >
        <path
            d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z'
            stroke={stroke}
            fill='inherit'
        />

    </SvgIcon>
)

export default Mail
