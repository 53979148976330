import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: '100vh'
    },
    fillCoverFull: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center'
    },
    contentBox: {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing('auto', 2, 4),
        padding: theme.spacing(4, 2, 12),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            minWidth: `${300 + theme.spacing(8)}px`
        }
    },
    headline: {
        [theme.breakpoints.down('md')]: {
            fontSize: '38px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px'
        },
        textAlign: 'center'
    },
    buttons: {
        margin: theme.spacing(2, -1, 0),

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    },
    button: {
        color: '#fff',
        fontSize: 16,
        textTransform: 'uppercase',
        textDecoration: 'underline',
        textDecorationColor: theme.palette.red.light,
        textUnderlineOffset: '8px',
        textDecorationThickness: '2px'
    },
    dotItemContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    dotItem: {
        position: 'absolute',
        zIndex: -1,
        display: 'none',
        width: '200px',
        bottom: theme.spacing(6),
        color: '#fff',
        listStyle: 'none',
        [theme.breakpoints.up('s')]: {
            width: '300px'
        }
    },
    dotActive: {
        zIndex: 2,
        display: 'block'
    },
    progressBg: {
        width: '100%',
        height: '3px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        marginBottom: theme.spacing(2)
    },
    progressSlider: {
        backgroundColor: '#fff',
        width: 0,
        height: '100%',
        transitionProperty: 'width',
        transitionTimingFunction: 'ease-in'
    },
    subHeadline: {
        paddingBottom: theme.spacing(6),
        fontSize: 24,
        color: 'black',
        fontWeight: '500',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    buttonSectionLink: {
        color: 'white',
        fontSize: 16,
        textTransform: 'uppercase',
        textDecoration: 'none',
        borderRadius: '3px',

    },
    sectionLink: {
        textAlign: 'center'
    },
    aSectionLink: {
        backgroundColor: 'black',
        padding: '10px 20px',
        borderRadius: '3px',
        minWidth: '250px',
        display: 'inline-block'
    }
    
}))
