import { useCookies } from 'react-cookie'
import { useAddressGroup, useSetDefaultBillingAddress, useSetDefaultShippingAddress } from '@bloomreach/connector-components-react'
import { useContext, useMemo, useState } from 'react'
import { COOKIE_LOCATION } from '../../../../../../_Mappings/cookies'
import CheckOutContext from '../../../../CheckOutContext'
import useCustomSetOrderDetails from '../../../../../../_Hooks/useCustomCheckout/useCustomSetOrderDetails'
import LoginContext from '../../../../../Account/Login/LoginContext'

const useAddresses = () => {
    const [cookies] = useCookies([COOKIE_LOCATION])
    const shippingLocation = cookies[COOKIE_LOCATION]

    const { orderDraft, setOrderDraft } = useContext(CheckOutContext)
    const [setDefaultShippingAddress] = useSetDefaultShippingAddress()
    const [setDefaultBillingAddress] = useSetDefaultBillingAddress()
    const [setOrderDetails] = useCustomSetOrderDetails()
    // const { user } = useContext(LoginContext)
    const [addressGroup] = useAddressGroup()
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useContext(LoginContext)

    return useMemo(() => {
        // FILTER LOCALIZED ADDRESSES
        const list = addressGroup?.addresses.filter((addr) => addr?.country === shippingLocation) ?? []

        return {
            // LOCALIZED ADDRESSES
            list,
            // CHECK IF ORDER ADDRESSES ARE VALID
            orderHasValidAddresses: (
                list?.some(({ id }) => id === orderDraft?.shippingAddress?.id)
            && list.some(({ id }) => id === orderDraft.billingAddress?.id)
            ) ?? false,
            // SET (DEFAULT AND ORDER) ADDRESSES
            setShippingAddress: async (addressId) => {
                if (!orderDraft) return

                setIsLoading(() => true)
                // SYNC BILLING ADDRESS ON SHIPPING ADDRESS CHANGE
                await setDefaultShippingAddress({ addressId })
                setDefaultBillingAddress({ addressId })

                const result = await setOrderDetails({
                    variables: {
                        input: {
                            id: orderDraft?.id,
                            email: user.currentCustomer.email,
                            billingAddress: { addressId },
                            shipmentAddress: { addressId }
                        }
                    }
                })

                if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                    setOrderDraft(result.data.setOrderDetails.order)
                }
                setIsLoading(() => false)
            },
            // SET DEFAULT AND ORDER BILLING ADDRESS
            setBillingAddress: async (addressId) => {
                if (!orderDraft) return

                setIsLoading(() => true)
                setDefaultBillingAddress({ addressId })

                const result = await setOrderDetails({
                    variables: {
                        input: {
                            id: orderDraft.id,
                            email: user.currentCustomer.email,
                            billingAddress: { addressId }
                        }
                    }
                })

                if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                    setOrderDraft(result.data.setOrderDetails.order)
                }

                setIsLoading(() => false)
            },
            // DEFAULT SHIPPING ADDRESS
            defaultShippingAddress: addressGroup?.defaultShippingAddress
                && list.some((addr) => addr.id === addressGroup.defaultShippingAddress.id)
                ? addressGroup.defaultShippingAddress
                : list[0],
            // DEFAULT BILLING ADDRESS
            defaultBillingAddress: addressGroup?.defaultBillingAddress
            && list.some((addr) => addr.id === addressGroup.defaultBillingAddress.id)
                ? addressGroup.defaultBillingAddress
                : list[0],
            // LOADING STATE
            isLoading
        }
    }, [addressGroup, orderDraft, shippingLocation, isLoading])
}

export default useAddresses
