import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    orderSuccess: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    orderSuccessCard: {
        backgroundColor: theme.palette.imgOverlay.main,
        maxWidth: theme.breakpoints.values.sm,
        padding: theme.spacing(3),
        margin: theme.spacing(4, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(2)
    },
    text: {
        margin: '5px',
        marginTop: 'inherit'
    },
    titleWithClose: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '5px'
    },
    dialogAction: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '& > *': {
            flexBasis: '100%'
        }
    }
}))
