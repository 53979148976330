import { Collapse, ListItem } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import List from '@material-ui/core/List'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { hasChildren } from '../../../../lib/utils'
import MobileBaseItem from './MobileBaseItem'
import useStyles from './style'
import theme from '../../../../lib/theme'

const MobileMultiItem = ({ item, inset, closeDrawer }) => {
    const classes = useStyles()
    const children = item.getChildren()
    const [collapse, setCollapse] = useState({})
    const itemName = item.getName()
    const isIn = collapse[itemName]

    const handleClick = (param) => {
        setCollapse((prevState) => ({ [param]: !prevState[param] }))
    }

    return (
        <div
            style={inset ? { paddingLeft: theme.spacing(2) } : undefined}
            className={classes.listItem}
        >
            <ListItem
                button
                onClick={() => handleClick(itemName)}
            >
                <Typography
                    variant='h5'
                    component='span'
                    className={classes.text}
                >
                    {itemName}
                    {isIn ? <ExpandLess /> : <ExpandMore />}
                </Typography>
            </ListItem>
            <Collapse
                in={isIn}
                timeout='auto'
                unmountOnExit
            >
                <List
                    component='div'
                    disablePadding
                >
                    {children.map((child) => (
                        hasChildren(child)
                            ? (
                                <MobileMultiItem
                                    key={child.getName()}
                                    item={child}
                                    inset
                                    closeDrawer={closeDrawer}
                                />
                            )
                            : (
                                <MobileBaseItem
                                    key={child.getName()}
                                    item={child}
                                    inset
                                    closeDrawer={closeDrawer}
                                />
                            )
                    ))}
                </List>
            </Collapse>
        </div>
    )
}

MobileMultiItem.propTypes = {
    item: PropTypes.object,
    inset: PropTypes.bool,
    closeDrawer: PropTypes.func
}

MobileMultiItem.defaultProps = {
    item: undefined,
    inset: undefined,
    closeDrawer: () => {}
}
export default MobileMultiItem
