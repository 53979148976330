import {
    Badge,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer
} from '@material-ui/core'

import { useContext, useState } from 'react'
import {
    Close,
    ContactsOutlined,
    ReceiptOutlined,
    ExitToAppOutlined,
    MoreVert,
    LanguageOutlined,
} from '@material-ui/icons'
import List from '@material-ui/core/List'
import { BrPageContext } from '@bloomreach/react-sdk'
import { useCart } from '@bloomreach/connector-components-react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import BagIcon from '../../../../lib/icons/bag.svg'
import UserIcon from '../../../../lib/icons/user.svg'
import Link from '../../../_Elements/Link'
import Protected from '../../../_Elements/Protected/Protected'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import storeKeys from '../../../_Mappings/storeKeys'
import LoginContext from '../../Account/Login/LoginContext'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import WishlistModalButton from '../../../_Elements/Wishlist/WishlistModalButton/WishlistModalButton'
import useLogout from '../../../_Hooks/useLogout'

const MobileContext = ({locationLabel, modalLanguageIsOpen, setModalLanguageIsOpen, languageLabel}) => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const [logout] = useLogout()
    const router = useRouter()
    const [drawer, setDrawer] = useState(false)
    const translations = useTranslation()

    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}

    // Cart
    const { cartId } = useContext(LoginContext)
    const [cart] = useCart({ cartId, storeKey })

    const handleLogout = async () => {
        const success = await logout()

        if (success) {
            await router.push(page.getUrl('/'))
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setDrawer(open)
    }

    const drawerList = () => (
        <div
            className={classes.mobileList}
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List component='div'>
                {/*
                <ListItem button>
                    <ListItemIcon>
                        <FavoriteIcon />
                    </ListItemIcon>
                    <ListItemText primary='Favorites' />
                </ListItem> */}

                <Protected fallback={(
                    <ListItem button href='/signin' component={Link} data-qa='icon-navigation-account'>
                        <ListItemIcon>
                            <UserIcon />
                        </ListItemIcon>
                        <ListItemText primary={translations('global:login')} />
                    </ListItem>
                )}
                >
                    <WishlistModalButton asListItem />

                    <ListItem button href='/account' component={Link}>
                        <ListItemIcon>
                            <UserIcon />
                        </ListItemIcon>
                        <ListItemText primary={translations('global:account')} />
                    </ListItem>

                    <ListItem button href='/account/orders' component={Link}>
                        <ListItemIcon>
                            <ReceiptOutlined />
                        </ListItemIcon>
                        <ListItemText primary={translations('global:orders')} />
                    </ListItem>

                    <ListItem button href='/account/addresses' component={Link}>
                        <ListItemIcon>
                            <ContactsOutlined />
                        </ListItemIcon>
                        <ListItemText primary={translations('global:addresses')} />
                    </ListItem>

                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <ExitToAppOutlined />
                        </ListItemIcon>
                        <ListItemText primary={translations('global:logout')} />
                    </ListItem>
                </Protected>
                
                <ListItem button onClick={() => {setModalLanguageIsOpen(() => true)}}>
                    <ListItemIcon>
                        <LanguageOutlined />
                    </ListItemIcon>
                    <ListItemText primary={locationLabel + " | " + languageLabel} />
                </ListItem>

                <ListItem button>
                    <ListItemIcon>
                        <Close />
                    </ListItemIcon>
                    <ListItemText primary={translations('global:close')} />
                </ListItem>
            </List>
        </div>
    )

    return (
        <div className={clsx(classes.mobileTopRight, classes.topRightItem)}>
            <IconButton
                color='inherit'
                aria-label='cart'
                href='/cart'
                component={Link}
                data-qa='icon-navigation-utilitymenu-cart'
            >
                <Badge
                    badgeContent={cart?.totalQuantity}
                    color='error'
                >
                    <BagIcon stroke='currentColor' />
                </Badge>
            </IconButton>
            <IconButton
                aria-label='menu'
                color='inherit'
                className={classes.title}
                onClick={toggleDrawer(true)}
            >
                <MoreVert stroke='currentColor' />
            </IconButton>
            <SwipeableDrawer
                anchor='bottom'
                open={drawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {drawerList()}
            </SwipeableDrawer>
        </div>

    )
}

export default MobileContext

MobileContext.propTypes = {
    locationLabel: PropTypes.string,
    modalLanguageIsOpen: PropTypes.bool,
    setModalLanguageIsOpen: PropTypes.func,
    languageLabel: PropTypes.string,
}

MobileContext.defaultProps = {
    locationLabel: undefined,
    modalLanguageIsOpen: undefined,
    setModalLanguageIsOpen: undefined,
    languageLabel: undefined
}
