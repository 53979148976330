import { useContext, useState }from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { BrPageContext } from '@bloomreach/react-sdk'
import { useCookies } from 'react-cookie'
import { COOKIE_LOCALE } from '../../../_Mappings/cookies'
import Typography from '@material-ui/core/Typography'
import BaldininiLogo from '../../../_Elements/Icons/Logo'
import Link from '../../../_Elements/Link'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import { formatSimpleBbcode } from '../../../../lib/utils'
import { useForm } from 'react-hook-form'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'
import { useRouter } from 'next/router'

const Invite = () => {
    const classes = useStyles()
    const router = useRouter()
    const page = useContext(BrPageContext)
    const translations = useTranslation()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [cookies] = useCookies([COOKIE_LOCALE])
    const locale = cookies[COOKIE_LOCALE] ?? 'en'
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const {
        handleSubmit,
        setValue,
        getValues,
        ...formCtx
    } = useForm()
    const pageCfg = usePageCfg()
    const handleFormSubmit = handleSubmit( async (values) => {
        await pageCfg.httpClient
            .post(`${pageCfg.cmsRestserviceUrl}/mailing/invite`, {
                ...values, locale
            })
            .then(() => {
                setIsDialogOpen(true)
            })
            .catch((e) => {
                console.error('error', e)
                setIsErrorDialogOpen(true)
            })
    })

    return (
        <>
            <Toolbar>
                <Container maxWidth='xl' disableGutters className={classes.logoContainer}>
                    <Link
                        color='inherit'
                        href='/'
                    >
                        <BaldininiLogo />
                    </Link>
                </Container>
            </Toolbar>
            <Container maxWidth='md'>
                
                    <Typography
                        component='p'
                    >
                         { formatSimpleBbcode(translations('eternity:invite.intro')) }
                    </Typography>
            </Container>
            <Container maxWidth='sm'>
                    <Typography
                        variant='h4'
                        component='h2'
                        className={classes.loginTitle}
                    >
                        {translations('eternity:invite.title')}
                    </Typography>
                
                <form onSubmit={handleFormSubmit} className={classes.form}>
                    <FormTextField
                        name='email'
                        label={translations('eternity:invite.label.mailClient')}
                        type='email'
                        formCtx={formCtx}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            }
                        }}
                    />
                    <FormTextField
                        name='emailFriend'
                        label= {translations('eternity:invite.label.mailFriend')}
                        type='email'
                        formCtx={formCtx}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            }
                        }}
                    />
                    <FormTextField
                        name='message'
                        defaultValue=""
                        label={translations('eternity:invite.messageLabel')}
                        multiline={true}
                        type='text'
                        formCtx={formCtx}
                        style={{height: '100px'}}
                        rules={{
                            required: {
                                value: true,
                                message: translations('forms:rules.message.field.required')
                            }
                        }}
                    />
                    
                    <Box align='center' className={classes.buttonContainer}>
                        <BaseButton
                            variant='contained'
                            size='large'
                            type='submit'
                        >
                            {translations('eternity:invite.cta')}
                        </BaseButton>
                    </Box>
                </form>

                <UserFeedbackDialog
                    open={isDialogOpen}
                    setOpen={setIsDialogOpen}
                    handleAfterClose={() => {
                        router.push(page.getUrl('/'))
                    }}
                >
                    <Typography variant='body1'>
                        {translations('eternity:invite.formOk')}
                    </Typography>
                </UserFeedbackDialog>
                <UserFeedbackDialog
                    open={isErrorDialogOpen}
                    setOpen={setIsErrorDialogOpen}
                >
                    <Typography variant='body1'>
                        {translations('eternity:invite.formKo')}
                    </Typography>
                </UserFeedbackDialog>
            </Container>
        </>
    )
}

export default Invite