import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import dynamic from 'next/dynamic'
import {
    object, number, func
} from 'prop-types'
import { useCookies } from 'react-cookie'
import useStyles from './style'
import useTranslation from '../../../../../_Elements/ResourceBundles/useTranslation'
import Review from './Review'
import OrderSuccess from '../OrderSuccess/OrderSuccess'
import AddressSelection from './AddressSelection/AddressSelection'
import useGoogleCheckoutStep from '../../../../../_Hooks/GoogleAnalytics/useGoogleCheckoutStep'
import useCustomCart from '../../../../../_Hooks/useCustomCart/useCustomCart'
import { COOKIE_LANGUAGE, COOKIE_LOCALE, COOKIE_LOCATION } from '../../../../../_Mappings/cookies'
import { usePageCfg } from '../../../../../_Elements/PageConfig/PageCfg'

const PaymentOptions = dynamic(
    () => import('../Payment/PaymentAndShipping'),
    { ssr: false }
)

const StepContent = ({
    page,
    activeStep,
    user,
    onNext,
    onBack
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const [paymentInfos, setPaymentInfos] = useState({
        paymentMethodCategory: '',
        encryptedPayment: '',
        paymentLabel: '',
        paymentLogo: ''
    })
    const { cart } = useCustomCart()

    const { googleAnalyticsEnvironment } = usePageCfg()

    const [cookies] = useCookies([COOKIE_LOCALE, COOKIE_LOCATION, COOKIE_LANGUAGE])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'

    useGoogleCheckoutStep(activeStep, cart, user, location, language, googleAnalyticsEnvironment)

    switch (activeStep) {
    case 1: {
        return (
            <AddressSelection
                onNext={onNext}
                onBack={onBack}
            />
        )
    }
    case 2: {
        return (
            <>
                <Typography
                    variant='h6'
                    align='center'
                    className={classes.header}
                >
                    {translations('checkout:step.header.payment')}
                </Typography>
                <PaymentOptions
                    onNext={(pI) => {
                        setPaymentInfos(pI)
                        onNext()
                    }}
                    onBack={onBack}
                />
            </>
        )
    }

    case 3: {
        return (
            <>
                <Typography
                    variant='h6'
                    align='center'
                    className={classes.header}
                >
                    {translations('checkout:step.header.review')}
                </Typography>
                <Review
                    page={page}
                    user={user}
                    onNext={onNext}
                    onBack={onBack}
                    paymentInfos={paymentInfos}
                />
            </>
        )
    }

    case 4: {
        return (
            <OrderSuccess />
        )
    }

    default: return translations('checkout:steps.message.error.unknown.index')
    }
}

StepContent.propTypes = {
    page: object,
    activeStep: number,
    user: object,
    onNext: func,
    onBack: func
}

StepContent.defaultProps = {
    page: undefined,
    activeStep: 0,
    user: null,
    onNext: null,
    onBack: null
}

export default StepContent
