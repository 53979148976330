import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        margin: theme.spacing(4, 0)
    },
    tableDesktop: {
        minWidth: 750,
        '& .MuiTableCell-sizeSmall': {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(2)
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    orderTitle: {
        marginTop: theme.spacing(5)
    },
    orderText: {
        marginTop: theme.spacing(4)
    },
    tableHeadCell: {
        fontWeight: 'bold'
    },
    tableMobile: {
        display: 'block',
        '& tbody': {
            display: 'block!important'
        }
    },
    tableRowMobile: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.gray.light}`,
        '& td, & th': {
            borderBottom: 'none!important',
            display: 'block!important',
            [theme.breakpoints.up('s')]: {
                display: 'flex!important',
                flexWrap: 'wrap',
                justifyContent: 'space-between'
            }
        }
    },
    labelMobile: {
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }
}))
