import { useRemoveFromCart } from '@bloomreach/connector-components-react'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { IconButton } from '@material-ui/core'
import useStyles from './style'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'

const RemoveFromCartButton = ({
    cartId, entryId, storeKey, distributionChannel, onSuccessRemove
}) => {
    const classes = useStyles()
    const { pushCartActivity, clearCart } = useContext(SFMCCollectJs)
    const [removeFromCart, , , error] = useRemoveFromCart({
        cartId,
        storeKey,
        distributionChannel
    })

    const handleOnClick = async () => {
        const response = await removeFromCart({ entryId })
        if (response?.success) {
            if(response.cart.entries.length > 0){
                pushCartActivity(response.cart.entries)
            }
            else{
                clearCart()
            }
            onSuccessRemove()
        }
    }

    return (
        <>
            <IconButton
                aria-label='delete'
                onClick={handleOnClick}
                className={classes.button}
                component={IconButton}
                data-qa='button-cart-delete'
            >
                <DeleteOutlineIcon />
            </IconButton>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
        </>
    )
}

RemoveFromCartButton.propTypes = {
    cartId: PropTypes.string,
    entryId: PropTypes.string,
    storeKey: PropTypes.string,
    distributionChannel: PropTypes.string,
    onSuccessRemove: PropTypes.func
}

RemoveFromCartButton.defaultProps = {
    cartId: undefined,
    entryId: undefined,
    distributionChannel: undefined,
    storeKey: undefined,
    onSuccessRemove: undefined
}

export default RemoveFromCartButton
