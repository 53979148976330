import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import {
    Container, Grid
} from '@material-ui/core'
import { useCookies } from 'react-cookie'
import FormHelperText from '@material-ui/core/FormHelperText'
import useStyles from './style'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'
import MailIcon from '../../_Elements/Icons/Mail'
import InputButtonGroup from '../../_Elements/Inputs/InputButtonGroup/InputButtonGroup'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'
import { COOKIE_LOCALE, COOKIE_LOCATION } from '../../_Mappings/cookies'
import { REG_EXP, formatSimpleBbcode } from '../../../lib/utils'
import countriesAlpha3 from '../../_Mappings/countriesAlpha3'
import useGoogleNewsletter from '../../_Hooks/GoogleAnalytics/useGoogleNewsletter'

const Newsletter = () => {
    const cfg = usePageCfg()
    const classes = useStyles()
    const translations = useTranslation()
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [success, setSuccess] = useState(undefined)
    const [cookies] = useCookies([COOKIE_LOCALE])
    const locale = cookies[COOKIE_LOCALE] ?? 'en'
    const location = cookies[COOKIE_LOCATION] ?? ''

    const handleOnSubmit = async (e) => {
        e.preventDefault()

        try {
            setIsLoading(true)
            if (REG_EXP.emailOnly.test(email)) {
                await cfg.httpClient.post(`${cfg.cmsRestserviceUrl}/newsletter/subscribe`, {
                    email,
                    locale
                }).then(async () => {
                    setSuccess(true)

                    cfg.httpClient.post(`${cfg.cmsRestserviceUrl}/customer`, {
                        email,
                        country: countriesAlpha3[location],
                        firstName: '',
                        lastName: '',
                        type: 'MLI'
                    })
                })
            } else {
                setSuccess(false)
            }
        } catch (errorLog) {
            setSuccess(false)
            console.error('error', errorLog)
        }
        setIsLoading(false)
    }

    useGoogleNewsletter(success)

    return (
        <div className={classes.bgnl}>
            <Container maxWidth='md' className={classes.rootnl}>
                <Grid
                    container
                    justifyContent='center'
                    align='center'
                >
                    <Grid item xs={12} md={9}>
                        <MailIcon className={classes.iconnl} />

                        <div className={classes.content}>
                            <Typography variant='body1' className={classes.textnl}>
                                { formatSimpleBbcode(translations('newsletter:text')) }
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <form className={classes.formnl} onSubmit={handleOnSubmit}>
                            <InputButtonGroup
                                error
                                inputName='newsletterEmail'
                                inputType='email'
                                buttonType='submit'
                                onChange={({ currentTarget: { value } }) => setEmail(value)}
                                loading={isLoading}
                                buttonLabel={translations('newsletter:button.submit')}
                            />
                            {success && <FormHelperText>{translations('newsletter:success')}</FormHelperText>}
                            {!success && success !== undefined && <FormHelperText error>{translations('newsletter:error')}</FormHelperText>}
                        </form>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <div className={classes.contentnl}>
                            <Typography variant='caption'>
                                { formatSimpleBbcode(translations('newsletter:consent.text')) }
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Newsletter
