import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import { useProductGridSearch } from '@bloomreach/connector-components-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMediaQuery } from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from './style'
import ProductCard from '../Product/ProductCard/ProductCard'
import { getConnector, getSmViewId } from '../../../lib/utils'
import useGoogleProductImpressions from '../../_Hooks/GoogleAnalytics/useGoogleProductImpressions'
import { QueryBuilder } from '@material-ui/icons'

const RelatedSliderByCategory = ({ maximumNumberOfProducts, brUid2, storeKey, query, title, parentId }) => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const page = useContext(BrPageContext)

    const connector = getConnector(page)
    const smViewId = getSmViewId(page)
    const pageSize = 6;
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    const [, itemsPageResult, loading, error] = useProductGridSearch({
        brUid2,
        connector,
        smViewId,
        pageSize,
        storeKey,
        searchText: query
    })

    useGoogleProductImpressions(itemsPageResult, page)

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        console.error(error.message)
    }

    if (!itemsPageResult) {
        return null
    }

    const { items } = itemsPageResult

    if (!itemsPageResult) {
        return null
    }
    return (
        <div className={classes.root}>  
            <Typography align='center' variant='h5' component='h2' className={classes.headline}>{title}</Typography>
            {isDesktop
                ? (
                    <Carousel
                        className={classes.productSlider}
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        minimumTouchDrag={80}
                        containerClass='container'
                        centerMode={true}
                        arrows
                        draggable
                        keyBoardControl
                        renderButtonGroupOutside
                        focusOnSelect={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 960
                                },
                                items: maximumNumberOfProducts
                            },
                            tablet: {
                                breakpoint: {
                                    max: 960,
                                    min: 600
                                },
                                items: 2
                            }
                        }}
                    >
                        {items.filter(Boolean).filter((item) => (item.itemId.id != parentId))
                            .map((item) => (
                                <ProductCard key={`category-slider-${item.itemId.id}`} item={item} />
                            ))}
                    </Carousel>
                )
                : (
                    <Carousel
                        className={classes.productSlider}
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        minimumTouchDrag={80}
                        containerClass='container'
                        arrows
                        draggable
                        keyBoardControl
                        renderButtonGroupOutside
                        focusOnSelect={false}
                        responsive={{
                            mobile: {
                                breakpoint: {
                                    max: 600,
                                    min: 0
                                },
                                items: 1
                            }
                        }}
                    >
                        {items.filter(Boolean).filter((item) => (item.itemId.id != parentId))
                            .map((item) => (
                                <ProductCard key={`category-slider-${item.itemId.id}`} item={item} />
                            ))}
                    </Carousel>
                )}
        </div>
    )
}

RelatedSliderByCategory.propTypes = {
    maximumNumberOfProducts: PropTypes.number,
    brUid2: PropTypes.string,
    storeKey: PropTypes.string,
    query: PropTypes.string,
    title: PropTypes.string,
    parentId:PropTypes.string
}

RelatedSliderByCategory.defaultProps = {
    maximumNumberOfProducts: undefined,
    brUid2: undefined,
    storeKey: undefined,
    query: "PropTypes.string",
    title: "PropTypes.string",
    parentId:"PropTypes.string"
}

export default RelatedSliderByCategory
