import { useContext } from 'react'
import { isMenu } from '@bloomreach/spa-sdk'
import { BrComponentContext, BrManageMenuButton, BrPageContext } from '@bloomreach/react-sdk'
import { useMediaQuery } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import useStyles from './style'
import DesktopFooter from './DesktopFooter/DesktopFooter'
import MobileFooter from './MobileFooter/MobileFooter'
import Newsletter from '../Newsletter/Newsletter'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'

const Footer = () => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const page = useContext(BrPageContext)
    const menuRef = component?.getModels().menu
    const menu = menuRef && page?.getContent(menuRef)

    const {isEternity} = usePageCfg()

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

    if (!isMenu(menu)) {
        return null
    }

    return (
       
        <>
            <div className={classes.menuButton}>
                { page.isPreview() && <BrManageMenuButton menu={menu} /> }
            </div>
           
            {isEternity ? null : <Newsletter />}

            <Container maxWidth='xl'>
                {isDesktop
                    ? <DesktopFooter />
                    : <MobileFooter />}
            </Container>
        </>
    )
}

export default Footer
