import { Drawer, Typography } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { func } from 'prop-types'
import React from 'react'
import WISHLIST_QUERY from '../../../_Hooks/useWishlist/WISHLIST_QUERY'
import ProductCard from '../../../_Modules/Product/ProductCard/ProductCard'
import useStyles from './style'
import useTranslation from '../../ResourceBundles/useTranslation'

const WishlistModal = ({ onClose, ...props }) => {
    const t = useTranslation()
    const classes = useStyles()
    const { data } = useQuery(WISHLIST_QUERY)
    const wishlist = data?.findWishlists.wishlists[0] ?? {}

    return (
        <Drawer
            anchor='right'
            onClose={onClose}
            {...props}
        >
            <div
                className={classes.list}
            >
                <Typography
                    align='center'
                    variant='h4'
                >
                    {t('wishlist')}
                </Typography>

                {wishlist?.entries?.length ? (
                    wishlist.entries?.map((entry) => (
                        entry.items && (
                        <ProductCard
                            onClick={onClose}
                            key={entry.id}
                            itemIsVariant
                            item={entry.items[0]}
                        />
                    )))
                ) : (
                    <Typography variant='body1' align='center'>
                        {t('wishlist.empty')}
                    </Typography>
                )}
            </div>
        </Drawer>
    )
}

WishlistModal.propTypes = {
    onClose: func.isRequired
}

export default WishlistModal
