import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    footer: {
        position: 'relative',
        marginTop: theme.spacing(5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    socialWrapper: {
        paddingTop: theme.spacing(2)
    },
    gridContainer: {
        align: 'stretch'
    },
    item: {
        paddingLeft: 0,
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    copyTerms: {
        display: 'flex'
    },
    copyrightWrapper: {
        flex: 1,
        fontSize: '12px',
        justifyContent: 'flex-start',
        padding: theme.spacing(6)
    },
    termsWrapper: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(6)
    },
    langWrapper: {
        marginLeft: '-15px',
        marginTop: '40px'
    }
}))
