import {
    Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@material-ui/core'
import PropTypes, { bool } from 'prop-types'
import List from '@material-ui/core/List'
import BaseButton from '../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'
import CartItem from '../Cart/CartItem/CartItem'

const StockVerificationDialog = ({
    onClose, open, productsToDelete, origin, item
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    if (!productsToDelete) return null

    const handleClose = async () => {
        onClose()
    }

    const message = (_origin) => {
        switch (_origin) {
        case 'AddToCart':
            return (
                <Typography variant='subtitle2'>
                    {translations('product:stock.dialog.addToCart.single')}
                </Typography>
            )
        default:
            return (
                <Typography variant='subtitle2'>
                    {translations('product:stock.dialog.cart.multiple.title')}
                </Typography>
            )
        }
    }

    const list = (_origin) => {
        switch (_origin) {
        case 'AddToCart':
            return (
                <List aria-label='stock unavailable product list' className={classes.itemList}>
                    <CartItem
                        key={item.itemId.id}
                        entry={{ items: [item] }}
                        position={0}
                    />
                </List>
            )
        default:
            return (
                <List aria-label='stock unavailable product list' className={classes.itemList}>
                    {
                        productsToDelete?.map((entry, index) => (
                            <CartItem
                                key={entry?.items[0]?.itemId?.id}
                                entry={entry}
                                position={index}
                            />
                        ))
                    }
                </List>
            )
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth='md'
        >
            <DialogTitle disableTypography className={classes.titleWithClose}>
                { message(origin) }
            </DialogTitle>
            <DialogContent>
                { list(origin) }
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <BaseButton
                    onClick={onClose}
                    color='primary'
                >
                    {translations('button.close')}
                </BaseButton>
            </DialogActions>
        </Dialog>
    )
}

StockVerificationDialog.propTypes = {
    open: bool,
    onClose: PropTypes.func.isRequired,
    productsToDelete: PropTypes.array,
    origin: PropTypes.string,
    item: PropTypes.object
}

StockVerificationDialog.defaultProps = {
    open: false,
    productsToDelete: undefined,
    origin: 'Cart',
    item: {}
}

export default StockVerificationDialog
