import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    updateButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    formSelect: {
        width: '100%'
    }
}))
