import { BrPageContext } from '@bloomreach/react-sdk'
import { useContext } from 'react'
import clsx from 'clsx'
import Link from '../../_Elements/Link'
import useStyles from './style'
import Facebook from '../../../lib/icons/facebook.svg'
import Twitter from '../../../lib/icons/twitter.svg'
import Pinterest from '../../../lib/icons/pinterest.svg'
import Instagram from '../../../lib/icons/instagram.svg'

const SocialMedia = () => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const {
        facebookLink,
        twitterLink,
        pinterestLink,
        instagramLink
    } = page.toJSON().channel?.info?.props

    const socials = [
        {
            id: 0,
            url: facebookLink,
            component: <Facebook />
        },
        {
            id: 1,
            url: twitterLink,
            component: <Twitter />
        },
        {
            id: 2,
            url: pinterestLink,
            component: <Pinterest />
        },
        {
            id: 3,
            url: instagramLink,
            component: <Instagram />
        }
    ]

    return (
        <>
            {
                socials.map((platform, index) => platform.url && (
                    <Link
                        key={platform.id}
                        aria-label='search'
                        color='primary'
                        href={platform.url}
                        className={clsx(index === socials.length - 1 ? classes.itemLast : classes.item)}
                    >
                        {platform.component}
                    </Link>
                ))
            }
        </>
    )
}
export default SocialMedia
