import {
    useContext,
    useEffect,
    useState
} from 'react'
import { isMenu } from '@bloomreach/spa-sdk'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import { NoSsr, Grid, ListItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import clsx from 'clsx'
import Link from '../../../_Elements/Link'
import useStyles from './style'
import SocialMedia from '../SocialMedia'
import TermsConditions from '../Terms'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import LanguageSelect from '../../Language/LanguageSelect'
import LanguageDialog from '../../Language/LanguageDialog'
import shippingCountries from '../../../_Mappings/shippingCountries'
import { COOKIE_LANGUAGE, COOKIE_LOCATION } from '../../../_Mappings/cookies'
import languages from '../../../_Mappings/languages'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import shippingRegions from '../../../_Mappings/shippingRegions'
import { useCookies } from 'react-cookie'

const DesktopFooter = () => {
    const classes = useStyles()
    const translations = useTranslation()
    const component = useContext(BrComponentContext)
    const page = useContext(BrPageContext)
    const menuRef = component?.getModels().menu
    const menu = menuRef && page?.getContent(menuRef)
    const cfg = usePageCfg()

    // Location
    const [cookies, setCookie] = useCookies([COOKIE_LOCATION, COOKIE_LANGUAGE])
    const [location, setLocation] = useState(cookies[COOKIE_LOCATION])

    // Language
    const [language, setLanguage] = useState(cookies[COOKIE_LANGUAGE])

    // Modal
    const [modalLanguageIsOpen, setModalLanguageIsOpen] = useState(false)
    const locationLabel = shippingCountries.find(({ value }) => value === location)?.label
    const languageLabel = languages.find(({ value }) => value === language)?.value
    const maxAge = 60 * 60 * 24 * 30
    // Initial default value fetch
    useEffect( () => {
        const getGeolocation = async () => {
            const { data } = await cfg.httpClient.get(`${cfg.cmsRestserviceUrl}/geolocation`).catch(console.error)
            // ADD FALLBACK 'GB'
            const location_ = shippingRegions[data?.country] ? data.country : 'GB'
            const language_ = languages.find(({ value }) => value === data?.country.toLowerCase())?.value
            const query = window.location.search;
            const params = new URLSearchParams(query);
            const isPopup = params.get('lang');

            setLocation(location_)
            setLanguage(language_ ? language_ : 'en')
            /*setCookie(COOKIE_LANGUAGE, language_ ? language_ : 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
            setCookie(COOKIE_LOCATION, location_, {
                maxAge, path: '/', sameSite: 'None', secure: true
            });*/
            if (!isPopup && isPopup != 'false') {
                setModalLanguageIsOpen(() => true)
            }

        }
        if (!location) {
            getGeolocation()
        } else if (location == 'IT' && !language) {
            setLanguage('it')
            setCookie(COOKIE_LANGUAGE, 'it', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        } else if (location == 'FR' && !language) {
            setLanguage('fr')
            setCookie(COOKIE_LANGUAGE, 'fr', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        } else if (location == 'RU' && !language) {
            setLanguage('ru')
            setCookie(COOKIE_LANGUAGE, 'ru', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        } else if (location != 'IT' && location != 'RU' && location != 'FR' && !language) {
            setLanguage('en')
            setCookie(COOKIE_LANGUAGE, 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        }


        let urlElements = window.location.href.split('/')
        let urlElelement = (urlElements[3])

        if ((urlElelement == "fr" || urlElelement == "it" || urlElelement == "ru") && urlElelement !== cookies[COOKIE_LANGUAGE]) {
            setLanguage(urlElelement);

            setCookie(COOKIE_LANGUAGE, urlElelement, {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
            /*if(urlElelement !== cookies[COOKIE_LANGUAGE]) {
                window.location.href = `/${urlElelement}`;
            }*/
        } else if (urlElelement !== cookies[COOKIE_LANGUAGE] && 'en' !== cookies[COOKIE_LANGUAGE]) {
            setLanguage('en');

            setCookie(COOKIE_LANGUAGE, 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            });
        }
    }, [])

    if (!isMenu(menu)) {
        return null
    }

    return (
        <>
            <Grid
                container
                spacing={4}
                justifyContent='space-evenly'
                className={clsx(classes.footer)}
            >
                {menu.getItems()
                    .map((item) => (
                        <Grid
                            item
                            xs={2}
                            sm={2}
                            key={item.getName()}
                            className={classes.gridContainer}
                        >
                            <Typography variant='subtitle1' color='primary' gutterBottom component='h2'>
                                {item.getName()}
                            </Typography>
                            <List>
                                {item.getChildren()
                                    .map((subitem) => (
                                        <ListItem
                                            key={subitem.getName()}
                                            className={classes.item}
                                        >
                                            {subitem.getUrl() ? (
                                                <Link href={subitem.getUrl()} variant='body2'>
                                                    {subitem.getName()}
                                                </Link>
                                            ) : (
                                                <Typography variant='body2'>
                                                    {subitem.getName()}
                                                </Typography>
                                            )}
                                        </ListItem>
                                    ))}
                            </List>
                        </Grid>
                    ))}
                <Grid
                    item
                    xs={2}
                    sm={2}
                    key='social'
                    className={classes.gridContainer}
                >
                    <Typography
                        variant='subtitle1'
                        color='primary'
                        gutterBottom
                        component='h2'
                    >
                        {translations('footer:menu.title.social')}
                    </Typography>
                    <div className={classes.socialWrapper}>
                        <SocialMedia />
                    </div>
                    <div className={classes.langWrapper}>
                        <NoSsr>
                            <LanguageSelect
                                isListItem
                                modalIsOpen={modalLanguageIsOpen}
                                setModalIsOpen={setModalLanguageIsOpen}
                                languageLabel1={locationLabel}
                                languageLabel2={languageLabel}
                            />
                        </NoSsr>
                    </div>

                </Grid>

                <LanguageDialog
                    modalIsOpen={modalLanguageIsOpen}
                    setModalIsOpen={setModalLanguageIsOpen}
                    setCookie={setCookie}
                    language={language}
                    location={location}
                    locationLabel={locationLabel}
                />
            </Grid>

            <div className={classes.copyTerms}>
                <Typography
                    variant='body1'
                    color='primary'
                    component='p'
                    gutterBottom
                    className={classes.copyrightWrapper}
                >
                    {translations('footer:copyright')}
                </Typography>
                <div className={classes.termsWrapper}>
                    <TermsConditions />
                </div>

            </div>
        </>
    )
}

export default DesktopFooter
