import { object, string } from 'prop-types'
import { useCookies } from 'react-cookie'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { useContext, useEffect, useState } from 'react'
import { BrPageContext } from '@bloomreach/react-sdk'
import { COOKIE_LOCALE, COOKIE_LOCATION, COOKIE_LANGUAGE } from '../../_Mappings/cookies'
import { getDevice, getLoginStatus } from '../../../lib/utils'
import storeKeys from '../../_Mappings/storeKeys'
import regionLocales from '../../_Mappings/regionLocales'
import { GoogleEnhancedEcommerce } from '../ThirdPartyScripts/GoogleEnhancedEcommerce'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'
import { LoyaltyPointsContext } from '../Account/LoyaltyPoints/LoyaltyPointsContext'

const GAGenericDataLayer = () => {
    const { getPageTemplate, updateGenericDatalayer } = useContext(GoogleEnhancedEcommerce)
    const [cookies] = useCookies([COOKIE_LOCALE, COOKIE_LANGUAGE])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    const [user] = useCurrentCustomer()
    const { googleAnalyticsEnvironment } = usePageCfg()

    const page = useContext(BrPageContext)
    const { loyaltyPoints: { customerCode, customerStore } } = useContext(LoyaltyPointsContext)

    const userid = customerCode ? customerCode : ''
    const storeid = customerStore ? customerStore : ''

    const {
        name: template = '',
        meta: {
            params: { contentGroup = 'Other' }
        }
    } = page.root.model

    const [domainName, setDomainName] = useState({})

    const setDataLayerPush = () => {
        if (user) {
            updateGenericDatalayer({
                environment: googleAnalyticsEnvironment,
                country: location,
                currencyCode: storeKeys[location]?.currency,
                contentGroup,
                language: language,
                siteName: 'Baldinini',
                userId: storeid + userid,
                pageTemplate: getPageTemplate(template),
                domainName,
                device: getDevice(),
                loginStatus: getLoginStatus(user)
            })
        }
    }

    useEffect(() => {
        setDomainName(window.location.hostname)
        setDataLayerPush()
    },
    [user, template])
    return null
}

GAGenericDataLayer.propTypes = {
    contentGroup: string,
    page: object
}

GAGenericDataLayer.defaultProps = {
    contentGroup: 'Other',
    page: null
}

export default GAGenericDataLayer
