import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1.5, 0.8),
        padding: 0
    },
    icon: {
        padding: theme.spacing(0.8, 1.5),
        color: theme.palette.primary.main
    },
    iconChecked: {
        borderRadius: 2,
        padding: theme.spacing(0.8, 1.5),
        backgroundColor: theme.palette.primary.main
    },
    disabled: {
        color: theme.palette.gray.main,
        textDecoration: 'line-through'
    },
    colorText: {
        color: theme.palette.gray.main,
        marginTop: theme.spacing()
    }
}))
