import { createContext, useEffect, useState } from 'react'
import { node } from 'prop-types'

export const LoyaltyPointsContext = createContext({})

const restoreLoyaltyPoints = () => {
    try {
        return JSON.parse(sessionStorage?.getItem('loyaltyPoints')) || {}
    } catch (e) {
        return {}
    }
}
const storeLoyaltyPoints = (loyaltyPoints) => () => {
    if (loyaltyPoints && typeof window !== 'undefined') {
        sessionStorage?.setItem('loyaltyPoints', JSON.stringify(loyaltyPoints))
    }
}

export const LoyaltyPointsProvider = ({ children }) => {
    const [loyaltyPoints, setLoyaltyPoints] = useState(restoreLoyaltyPoints)
    useEffect(storeLoyaltyPoints(loyaltyPoints), [loyaltyPoints])

    return (
        <LoyaltyPointsContext.Provider value={{
            loyaltyPoints,
            setLoyaltyPoints
        }}
        >
            {children}
        </LoyaltyPointsContext.Provider>
    )
}

LoyaltyPointsProvider.propTypes = {
    children: node.isRequired
}
