import {
    createContext, useContext, useEffect, useMemo, useState
} from 'react'
import { node, object } from 'prop-types'
import { BrPageContext } from '@bloomreach/react-sdk'
import querystring from 'querystring'
import { useCookies } from 'react-cookie'
import useLayoutEffect from '../../_Hooks/useIsomorphicLayoutEffect'
import { COOKIE_LANGUAGE, COOKIE_LOCALE } from '../../_Mappings/cookies'

export const ResourceBundlesContext = createContext({})

const FETCH_DELAY = 1000 * 60 * 10 // 10 MIN

const mergeResourceBundles = (component, bundleSet) => {
    const { resourcebundles } = component.getModels()

    const bundles = resourcebundles ?? []
    bundles.forEach((b) => bundleSet.add(b))

    component.getChildren()
        ?.forEach((c) => {
            mergeResourceBundles(c, bundleSet)
        })
}

const getResourceBundles = (component) => {
    const bundleSet = new Set()

    mergeResourceBundles(component, bundleSet)

    return Array.from(bundleSet)
}

export const getSSRResourceBundles = async (page, httpClient, locale, cmsRestserviceUrl) => {
    if (typeof window !== 'undefined') return null

    const bundlenames = getResourceBundles(page.getComponent())

    if (bundlenames.length === 0) return {}

    const rsp = await httpClient
        .get(`${cmsRestserviceUrl}/bundles`, {
            params: {
                bundlenames,
                locale
            },
            paramsSerializer(params) {
                return querystring.stringify(params)
            }
        })

    return rsp?.data?.bundleMapping ?? {}
}

const getInitialFetchedMap = (initialBundles) => {
    if (!initialBundles) return {}

    const now = +new Date()
    return Object.keys(initialBundles).reduce((acc, b) => ({ ...acc, [b]: now }), {})
}

const ResourceBundles = ({ children, configuration, initialResourceBundles }) => {
    const page = useContext(BrPageContext)
    const resourcebundles = getResourceBundles(page.getComponent())
    const [bundles, setBundles] = useState(initialResourceBundles)

    const INITIAL_FETCHED_MAP = useMemo(() => getInitialFetchedMap(initialResourceBundles), [])
    const [fetchedMap, setFetchedMap] = useState(INITIAL_FETCHED_MAP)
    const [cookies] = useCookies([COOKIE_LOCALE])
    const locale = cookies[COOKIE_LANGUAGE] ?? 'en'

    useLayoutEffect(() => {
        if (INITIAL_FETCHED_MAP !== fetchedMap) {
            setFetchedMap({})
        }
    }, [locale])

    useEffect(() => {
        const now = +new Date()
        const outdatedBundles = resourcebundles.filter((b) => !fetchedMap[b] || fetchedMap[b] + FETCH_DELAY < now)
        if (outdatedBundles.length === 0) return
        setFetchedMap((fm) => outdatedBundles.reduce((acc, b) => ({ ...acc, [b]: now }), fm))

        configuration.httpClient
            .get(`${configuration.cmsRestserviceUrl}/bundles`, {
                params: {
                    bundlenames: resourcebundles,
                    locale
                },
                paramsSerializer(params) {
                    return querystring.stringify(params)
                }
            })
            .then((rsp) => {
                const newBundles = rsp?.data?.bundleMapping
                if (newBundles) setBundles((b) => ({ ...b, ...newBundles }))
            })
    }, [resourcebundles, locale])

    return (
        <ResourceBundlesContext.Provider value={bundles}>
            { children }
        </ResourceBundlesContext.Provider>
    )
}

ResourceBundles.propTypes = {
    children: node.isRequired,
    configuration: object.isRequired,
    initialResourceBundles: object
}

export default ResourceBundles
