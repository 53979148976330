import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5)
    },
    productSlider: {
        '& .react-multi-carousel-item': {
            padding: theme.spacing(2)
        }
    },
    headline: {
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '1px',
        lineHeight: '32.5px',
        color: 'black',
        padding: theme.spacing(2),
        fontWeight: '400',
        textAlign: 'center'
    }
}))
