import { useContext, useEffect, useRef } from 'react'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { GoogleEnhancedEcommerce } from '../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const useGoogleBasket = (cart) => {
    const { pushToDataLayer, prepareProductForDataLayer } = useContext(GoogleEnhancedEcommerce)

    const trackedRef = useRef(false)
    const [user] = useCurrentCustomer()

    useEffect(() => {
        if (user && cart && trackedRef.current === false) {
            trackedRef.current = true

            const products = cart.entries.map((cartItem) => {
                const { items, quantity = '' } = cartItem
                const item = items ? items[0] : {}

                return prepareProductForDataLayer(item, { quantity })
            })

            const cartSubtotal = (cart.totalPurchasePrice?.moneyAmounts[0]?.amount).toFixed(2)

            pushToDataLayer({
                event: 'basket',
                eventCategory: 'basket',
                eventAction: 'basket view',
                ecommerce: {
                    actionField: {
                        cartSubtotal
                    },
                    products
                }
            })
        }
    }, [user, cart])
}
export default useGoogleBasket
