import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    // StepContent
    header: {
        maxWidth: theme.breakpoints.values.md,
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(4, 'auto')
    },
    payment: {
        textAlign: 'center'
    },
    addAddressContainer: {
        maxWidth: theme.breakpoints.values.sm,
        margin: theme.spacing(4, 'auto')
    },
    // AdressSelection
    loadingOverlay: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    checkboxContainer: {
        [theme.breakpoints.up('md')]: {
            minHeight: 160,
            marginBottom: theme.spacing(),
            marginTop: theme.spacing()
        }
    },
    // AdressSelection & Review
    addressesContainer: {
        maxWidth: theme.breakpoints.values.md,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(0, 'auto')
    },
    // Review
    addresses: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    itemList: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    summaryContainer: {
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center'
        }
    }
}
))
