import PropTypes from 'prop-types'
import { useRemoveAddress } from '@bloomreach/connector-components-react'
import FormHelperText from '@material-ui/core/FormHelperText'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const RemoveAddressButton = ({ addressId }) => {
    const [removeAddress, , , error] = useRemoveAddress()
    const translations = useTranslation()

    const handleClick = () => removeAddress({ addressId })

    return (
        <>
            <BaseButton
                size='small'
                color='primary'
                onClick={handleClick}
                data-qa='button-accountAddresses-delete'
            >
                {translations('address:remove.address.button')}
            </BaseButton>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
        </>
    )
}
RemoveAddressButton.propTypes = {
    addressId: PropTypes.string
}

RemoveAddressButton.defaultProps = {
    addressId: undefined
}

export default RemoveAddressButton
