import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    card: ({ width, height, mobileBackground }) => ({
        backgroundSize: 'cover!important',
        backgroundPosition: 'center center!important',
        backgroundRepeat: 'no-repeat!important',
        position: 'relative',
        gridArea: `auto / span ${width} / span ${height} / auto`,
        [theme.breakpoints.down('xs')]: {
            gridArea: 'unset!important',
            backgroundImage: `${mobileBackground}!important`
        }
    }),
    minHeighthelper: ({ width, height }) => ({
        paddingBottom: `${(height / width) * 100}%`,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            paddingBottom: '133.34%!important'
        }
    }),
    contentWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        //padding: ({ width }) => theme.spacing(2, width * 2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    textWrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: ({ width, height }) => ((width === height) ? 'start' : 'center')
    },
    headline: {
        textTransform: 'uppercase'
    },
    subline: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        textUnderlineOffset: `${theme.spacing()}px`,
        marginBottom: theme.spacing(2)
    }
}))
