const regionLocales = {
    AL: 'en',
    AU: 'en',
    AT: 'en',
    AZ: 'en',
    BE: 'en',
    BA: 'en',
    BG: 'en',
    CA: 'en',
    HR: 'en',
    CY: 'en',
    CZ: 'en',
    DK: 'en',
    EE: 'en',
    EN: 'en',
    FI: 'en',
    FR: 'fr',
    GE: 'en',
    DE: 'en',
    GR: 'en',
    HU: 'en',
    IN: 'en',
    IL: 'en',
    IT: 'it',
    JP: 'en',
    LV: 'en',
    LT: 'en',
    LU: 'en',
    MT: 'en',
    MD: 'en',
    MC: 'en',
    ME: 'en',
    NL: 'en',
    NO: 'en',
    PL: 'en',
    PT: 'en',
    RO: 'en',
    RU: 'ru',
    SA: 'en',
    RS: 'en',
    SK: 'en',
    SI: 'en',
    ES: 'en',
    SE: 'en',
    CH: 'en',
    TR: 'en',
    UA: 'en',
    GB: 'en',
    US: 'en'
}

export default regionLocales
