import { createContext, useState } from 'react'
import { node } from 'prop-types'

const orgId = "510001712"

export const SFMCCollectJs = createContext({
    pushUser: (email) => {},
    pushContactAttribute: (customer) => {},
    pushItemView: (productCode) => {},
    pushSearch: (term) => {},
    pushCategoryView: (categoryId) => {},
    pushCartActivity: (items) => {},
    clearCart: () => {},
    trackPurchase: (items) => {}
})

export const SFMCCollectJsProvider = ({ children }) => {

    const pushOrgId = () => {
        window._etmc = []
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["trackPageView"])
    }

    const pushUser = (email) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["setUserInfo", {"email": email}])
        window._etmc.push(["trackPageView"])
    }

    const pushContactAttribute = (customer) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["setUserInfo", { 
            "email": customer.email, 
            "details": { 
                "gender": customer.gender ? customer.gender : "", 
                "name": customer.name ? customer.name : "", 
                "loyalty_status": customer.loyaltyStatus ? customer.loyaltyStatus : "",
                "language": customer.language ? customer.language : "en"
            } 
        }])
        window._etmc.push(["trackPageView"])
    }

    const pushItemView = (productCode) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["trackPageView", { "item" : productCode }])
    }

    const pushCategoryView = (categoryId) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["trackPageView", { "category" : categoryId }])
    }

    const pushSearch = (term) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["trackPageView", { "search" : term }])
    }

    const pushCartActivity = (entries) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        let cart = []
        entries.forEach(entry => {
            const itemToPush = {
                "item": entry.items[0].itemId.code.split('-')[0],
                "quantity": entry.quantity,
                "price": entry.items[0].purchasePrice.moneyAmounts[0].displayValue,
                "unique_id": entry.items[0].itemId.code
            }
            cart.push(itemToPush)
        });
        window._etmc.push(["trackCart", { "cart": cart }])
    }

    const clearCart = () => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        window._etmc.push(["trackCart", { "clear_cart": true } ])
    }

    const trackPurchase = (items) => {
        if(!window._etmc){
            pushOrgId()
        }
        window._etmc.push(["setOrgId", orgId])
        let cart = []
        items.forEach(entry => {
            const itemToPush = {
                "item": entry.items[0].itemId.code.split('-')[0],
                "quantity": entry.quantity,
                "price": entry.items[0].purchasePrice.moneyAmounts[0].displayValue,
                "unique_id": entry.items[0].itemId.code
            }
            cart.push(itemToPush)
        });
        window._etmc.push(["trackConversion", { "cart": cart }])
    }

    return (
        <SFMCCollectJs.Provider value={{
            pushUser,
            pushContactAttribute,
            pushItemView,
            pushCategoryView,
            pushSearch,
            pushCartActivity,
            clearCart,
            trackPurchase
        }}
        >
            {children}
        </SFMCCollectJs.Provider>
    )
}

SFMCCollectJsProvider.propTypes = {
    children: node.isRequired
}