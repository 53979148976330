import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import { useCookies } from 'react-cookie'
import CART_QUERY from './CART_QUERY'
import LoginContext from '../../_Modules/Account/Login/LoginContext'
import { COOKIE_LOCALE, COOKIE_LOCATION } from '../../_Mappings/cookies'
import storeKeys from '../../_Mappings/storeKeys'

const useCustomCart = (variables) => {
    const [cookies] = useCookies([COOKIE_LOCALE, COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}
    const { cartId } = useContext(LoginContext)

    const { cartId: id, customQueryOptionsProcessor, ...cartHint } = (variables ?? { cartId, storeKey })

    const { data: { getCart: cart } = {}, ...props } = useQuery(CART_QUERY, {
        variables: {
            id,
            customQueryOptionsProcessor,
            cartHint
        }
        // Testing
        // ssr: false,
        // nextFetchPolicy: 'network-only',
        // fetchPolicy: 'network-only'
    })

    return {
        cart,
        ...props
    }
}

export default useCustomCart
