import Typography from '@material-ui/core/Typography'
import { object, string, bool } from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'

const DisplayPrice = ({ item, className, isCartItem }) => {
    const classes = useStyles()
    const listPrice = item.listPrice?.moneyAmounts?.[0]
    const purchasePrice = item.purchasePrice?.moneyAmounts?.[0]

    if (purchasePrice && listPrice && listPrice.amount !== purchasePrice.amount) {
        return (
            <div className={clsx(classes.root, className)}>
                <Typography
                    variant='subtitle1'
                    className={classes.oldPrice}
                    component='p' 
                >
                    {listPrice.displayValue}
                </Typography>
                <Typography
                    id="price-container"
                    variant='subtitle2'
                    component='p' 
                    className={isCartItem ? classes.cartItemPrice : classes.newPrice}
                >
                    {purchasePrice.displayValue}
                </Typography>
            </div>
        )
    } if (purchasePrice) {
        return (
            <div>
                <Typography component='p' variant='subtitle2' id="price-container">{purchasePrice.displayValue}</Typography>
            </div>
        )
    }
    return null
}

DisplayPrice.propTypes = {
    item: object.isRequired,
    className: string,
    isCartItem: bool
}

DisplayPrice.defaultProps = {
    className: undefined,
    isCartItem: false
}

export default DisplayPrice
