import clsx from 'clsx'
import {
    IconButton,
    NoSsr,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
    useMediaQuery,
    Badge
} from '@material-ui/core'

import { useContext, useState } from 'react'
import { useCart } from '@bloomreach/connector-components-react'
import {
    Close,
    ContactsOutlined,
    ReceiptOutlined,
    ExitToAppOutlined
} from '@material-ui/icons'
import { useRouter } from 'next/router'
import { BrPageContext } from '@bloomreach/react-sdk'
import PropTypes from 'prop-types'
import useStyles from './style'
import BagIcon from '../../../_Elements/Icons/Bag'
import UserIcon from '../../../_Elements/Icons/User'
import UserBgIcon from '../../../_Elements/Icons/UserBg'
import Link from '../../../_Elements/Link'
import Protected from '../../../_Elements/Protected/Protected'
import LocationSelect from '../../ShippingLocation/LocationSelect'
import LanguageSelect from '../../Language/LanguageSelect'
import LoginContext from '../../Account/Login/LoginContext'
import ProductSearchPopover from '../../Product/ProductSearch/ProductSearchPopover/ProductSearchPopover'
import storeKeys from '../../../_Mappings/storeKeys'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import WishlistModalButton from '../../../_Elements/Wishlist/WishlistModalButton/WishlistModalButton'
import useLogout from '../../../_Hooks/useLogout'
import LoginPopover from '../../Account/Login/LoginPopover'


const DesktopContext = ({
    modalLocationIsOpen, setModalLocationIsOpen, locationLabel, location, modalLanguageIsOpen, setModalLanguageIsOpen, languageLabel, language
}) => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const [logout] = useLogout()
    const router = useRouter()
    const translations = useTranslation()
    const { user } = useContext(LoginContext);
    const { currentCustomer } = user ?? {}

    // Location
    const { storeKey } = storeKeys[location] || {}

    // Cart
    const { cartId } = useContext(LoginContext)
    const [cart] = useCart({ cartId, storeKey })

    const [drawer, setDrawer] = useState(false)
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

    

    const handleLogout = async () => {
        const success = await logout()

        if (success) {
            await router.push(page.getUrl('/'))
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setDrawer(open)
    }

    const drawerList = () => (
        <div
            className={clsx({
                [classes.list]: isDesktop,
                [classes.mobileList]: !isDesktop
            })}
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List component='div'>
                <ListItem button href='/cart' component={Link}>
                    <ListItemIcon>
                        <Badge
                            badgeContent={cart?.totalQuantity}
                            color='error'
                            data-qa='icon-navigation-utilitymenu-cart'
                        >
                            <BagIcon stroke='currentColor' />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={translations('global:shopping.cart')} />
                </ListItem>

                <ListItem button href='/account' component={Link} data-qa='icon-navigation-account'>
                    <ListItemIcon>
                        <UserIcon stroke='black' />
                    </ListItemIcon>
                    <ListItemText primary={translations('global:account')} />
                </ListItem>

                <ListItem button href='/account/orders' component={Link}>
                    <ListItemIcon>
                        <ReceiptOutlined />
                    </ListItemIcon>
                    <ListItemText primary={translations('global:orders')} />
                </ListItem>

                <ListItem button href='/account/addresses' component={Link}>
                    <ListItemIcon>
                        <ContactsOutlined />
                    </ListItemIcon>
                    <ListItemText primary={translations('global:addresses')} />
                </ListItem>

                <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppOutlined />
                    </ListItemIcon>
                    <ListItemText primary={translations('global:logout')} />
                </ListItem>

                <ListItem button>
                    <ListItemIcon>
                        <Close />
                    </ListItemIcon>
                    <ListItemText primary={translations('global:close')} />
                </ListItem>
            </List>
        </div>
    )

    return (
        <div className={clsx(classes.topRight)}>
             <NoSsr>
                <LanguageSelect
                    modalIsOpen={modalLanguageIsOpen}
                    setModalIsOpen={setModalLanguageIsOpen}
                    languageLabel={locationLabel + " | " + languageLabel} 
                />
            </NoSsr>
            
            <div className={classes.topRightItem}>
                <IconButton
                    color='inherit'
                    aria-label='cart'
                    href='/cart'
                    component={Link}
                    data-qa='icon-navigation-utilitymenu-cart'
                >
                    <Badge
                        badgeContent={cart?.totalQuantity}
                        color='error'
                    >
                        <BagIcon stroke='currentColor' />
                    </Badge>
                </IconButton>

                <WishlistModalButton />

                <Protected fallback={(
                    <LoginPopover />
                    
                )}
                >
                    
                    <IconButton
                        color='inherit'
                        aria-label='user'
                        onClick={toggleDrawer(true)}
                    >
                        <UserBgIcon stroke='currentColor' fill='currentColor'  /> 
                        <span className={currentCustomer?.firstName ? 'iconLogged' : 'iconSlogged'} style={{fontSize:'11px', position:'absolute', bottom:0, letterSpacing:'0.1rem'}}>{currentCustomer?.firstName  ?? ''}</span>
                    </IconButton>
                    <SwipeableDrawer
                        anchor='right'
                        open={drawer}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        {drawerList()}
                    </SwipeableDrawer>
                </Protected>
                
                <ProductSearchPopover />
            </div>
        </div>
    )
}

export default DesktopContext

DesktopContext.propTypes = {
    modalLocationIsOpen: PropTypes.bool,
    setModalLocationIsOpen: PropTypes.func,
    locationLabel: PropTypes.string,
    location: PropTypes.string,
    modalLanguageIsOpen: PropTypes.bool,
    setModalLanguageIsOpen: PropTypes.func,
    languageLabel: PropTypes.string,
    language: PropTypes.string
}

DesktopContext.defaultProps = {
    modalLocationIsOpen: undefined,
    setModalLocationIsOpen: undefined,
    locationLabel: undefined,
    location: undefined,
    modalLanguageIsOpen: undefined,
    setModalLanguageIsOpen: undefined,
    languageLabel: undefined,
    language: undefined
}
