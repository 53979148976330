export const productFiltering = {
    'variants.attributes.colour-1-hex': {
        type: 'color',
        label: 'filters.color',
        paramKey: 'c'
    },
    'variants.attributes.size': {
        type: 'button',
        label: 'filters.size',
        paramKey: 's'
    }
}

export const mergableFilterKeys = {
    'variants.attributes.colour-2-hex': 'variants.attributes.colour-1-hex'
}
