const languages = [
    {
        label: 'Français',
        value: 'fr',
        header: 'fr_FR'
    },
    {
        label: 'Italiano',
        value: 'it',
        header: 'it_IT'
    },
    {
        label: 'Русский',
        value: 'ru',
        header: 'ru_RU'
    },
    {
        label: 'English',
        value: 'en',
        header: 'en_GB'
    }
]

export default languages
