import { IconButton, Popover } from '@material-ui/core'
import { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '../../../../_Elements/Icons/Search'
import ProductSearchForm from '../ProductSearchForm/ProductSearchForm'
import useStyles from './style'
import useTranslation from '../../../../_Elements/ResourceBundles/useTranslation'

const ProductSearchPopover = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()
    const translations = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'search-popover' : undefined

    return (
        <>
            <IconButton
                color='inherit'
                aria-label='search'
                onClick={handleClick}
            >
                <SearchIcon stroke='currentColor' />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <div className={classes.searchContainer}>
                    <Typography align='center' variant='h6' component='h3'>
                        {translations('product:search.popover.text')}
                    </Typography>
                    <ProductSearchForm handleClose={handleClose} autoFocus={open} />
                </div>

            </Popover>
        </>
    )
}

export default ProductSearchPopover
