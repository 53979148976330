import PropTypes from 'prop-types'
import {
    FormControl, FormControlLabel, RadioGroup
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useEffect } from 'react'
import SizeButton from './components/SizeButton'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import Link from '../../../_Elements/Link'

const ProductSize = ({ onChange, value, sizeList }) => {
    const translations = useTranslation()
    const classes = useStyles()

    useEffect(() => {
        if (!value && sizeList) {
            const firstAvailableSize = sizeList.find(({ quantity }) => quantity)
            if (firstAvailableSize) {
                onChange(firstAvailableSize.size)
            }
        }
    }, [value, sizeList])
    const notEmptySizeArr = sizeList.filter((items) => items.size.length > 0)
    return (
        <>
            {notEmptySizeArr.length > 0
            && (
                <Typography
                    variant='subtitle1'
                    align='center'
                    component='p' 
                >
                    {translations('product:select.size')}
                </Typography>
            )}

            {notEmptySizeArr.length > 0
            && (
                <div>
                    <Link
                        secondary
                        href={translations('product:size.guide.link')}
                        className={classes.sizeGuide}
                    >
                        {translations('product:sizeGuide')}
                    </Link>

                    {/* <Link
                    secondary
                    href='#'
                >
                    {translations('product:sizeSoldOut')}
                </Link> */}
                </div>
            )}

            <FormControl component='fieldset' className={classes.formControl}>
                <RadioGroup
                    name='size'
                    onChange={(event) => {
                        event.persist()
                        onChange(event.target.value)
                    }}
                    row
                    className={classes.radioGroup}
                >
                    {sizeList?.map(({ size, quantity }) => {
                        const isDisabled = quantity === 0 || !quantity
                        return (
                            <FormControlLabel
                                className={classes.formControlLabel}
                                key={size}
                                value={size}
                                label={size}
                                control={(
                                    <SizeButton
                                        size={size}
                                        disabled={isDisabled}
                                        checked={String(size) === String(value?._mappedAttributes?.size)}
                                    />
                                )}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </>
    )
}

ProductSize.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    sizeList: PropTypes.array
}

ProductSize.defaultProps = {
    onChange: undefined,
    value: null,
    sizeList: []
}

export default ProductSize
