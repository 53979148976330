import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    input: {
        textAlign: 'center',
        maxWidth: theme.spacing(10)
    },
    updateBtn: {
        paddingBottom: 0,
        '&:hover': {
            background: 'none'
        }
    }
}))
