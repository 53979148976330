import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useContext } from 'react'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import { useProductGridCategory } from '@bloomreach/connector-components-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMediaQuery } from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from './style'
import RelatedProductCard from '../Product/ProductCard/RelatedProductCard'
import { getConnector, getSmViewId } from '../../../lib/utils'
import useGoogleProductImpressions from '../../_Hooks/GoogleAnalytics/useGoogleProductImpressions'

const RelatedSliderByItems = ({ maximumNumberOfProducts, brUid2, storeKey, items }) => {
    const classes = useStyles()
    const component = useContext(BrComponentContext)
    const page = useContext(BrPageContext)

    const connector = getConnector(page)
    const smViewId = getSmViewId(page)

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    if (!items) {
        return null
    }

    return (
        <>
            {isDesktop
                ? (
                    <Carousel
                        className={classes.productSlider}
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        minimumTouchDrag={80}
                        containerClass='container'
                        centerMode
                        arrows
                        draggable
                        keyBoardControl
                        renderButtonGroupOutside
                        focusOnSelect={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 960
                                },
                                items: maximumNumberOfProducts
                            },
                            tablet: {
                                breakpoint: {
                                    max: 960,
                                    min: 600
                                },
                                items: 2
                            }
                        }}
                    >
                        {items.filter(Boolean)
                            .map((item) => (
                                <RelatedProductCard key={`category-slider-${item}`} storeKey={storeKey} connector={connector} brUid2={brUid2} itemId={item} />
                            ))}
                    </Carousel>
                )
                : (
                    <Carousel
                        className={classes.productSlider}
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        minimumTouchDrag={80}
                        containerClass='container'
                        arrows
                        draggable
                        keyBoardControl
                        renderButtonGroupOutside
                        focusOnSelect={false}
                        responsive={{
                            mobile: {
                                breakpoint: {
                                    max: 600,
                                    min: 0
                                },
                                items: 1
                            }
                        }}
                    >
                        {items.filter(Boolean)
                            .map((item) => (
                                <RelatedProductCard key={`category-slider-${item}`} storeKey={storeKey} connector={connector} brUid2={brUid2} itemId={item} />
                            ))}
                    </Carousel>
                )}
        </>
    )
}

RelatedSliderByItems.propTypes = {
    maximumNumberOfProducts: PropTypes.number,
    brUid2: PropTypes.string,
    storeKey: PropTypes.string,
    categoryId: PropTypes.string,
}

RelatedSliderByItems.defaultProps = {
    maximumNumberOfProducts: undefined,
    brUid2: undefined,
    storeKey: undefined,
    categoryId: "PropTypes.string",
}

export default RelatedSliderByItems
