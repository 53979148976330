import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        overflow: 'hidden'
    },
    grid: {
        flexGrow: 1,
        justifyContent: 'center'
    },
    teaserContainer: {
        justifyContent: 'center'
    },
    headline: {
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '1px',
        lineHeight: '32.5px',
        color: 'black',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: '400',
        textAlign: 'center'
    },
    card: {
        textDecoration: 'none!important',
        flexGrow: '1',
        boxShadow: 'none',
        borderRadius: 0
    },
    imgContainer: {
        position: 'relative',
        justifyContent: 'center',
        display: 'flex'
    },
    img: {
        [theme.breakpoints.up('sm')]: {
            borderRadius: theme.spacing()
        }
    },
    cardContent: {
        padding: theme.spacing(2, 0, 2, 0),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '80%',
        margin: 'auto'
    },
    headlineText: {
        margin: theme.spacing(1, 0)
    }
}))
