import { useEffect, useRef } from 'react'

const useTrackProductDetail = (item) => {
    const trackedRef = useRef(-1)

    useEffect(() => {
        if (!!item?.itemId?.id && trackedRef.current === -1 && typeof window !== 'undefined') {
            trackedRef.current = 0

            const trackData = {
                type: 'ecommerce:detail',
                products: [{
                    id: item?.itemId?.code?.split('-')?.[0],
                    name: item?.displayName,
                    image: item?.imageSet?.original.link.href,
                    price: item?.purchasePrice?.moneyAmounts[0]?.amount,
                    currency: item?.listPrice?.moneyAmounts[0]?.currency,
                    location: window.location.href
                }]
            }

            if (typeof window.hero === 'function' && window.hero.toString() === 'function (){}') {
                document.addEventListener('hero:loaded', () => {
                    if (trackedRef.current === 0) {
                        window.hero('track', trackData)
                        trackedRef.current = 1
                    }
                })
            } else {
                window.hero('track', trackData)
            }
        }
    }, [item])
}

export default useTrackProductDetail
