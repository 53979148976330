import { useCallback } from 'react'

const useBannerImageLoader = (breakpoint, params) => useCallback(({
    src,
    width
}) => {
    let quality = 80

    switch (width) {
    case 480:
    case 768: {
        quality = 95
        break
    }
    case 1024: {
        quality = 90
        break
    }
    case 1440: {
        quality = 85
        break
    }
    case 1920: {
        quality = 80
        break
    }
    default: {
        break
    }
    }

    return `/_next/image?url=${encodeURI(src)}&w=${width}&q=${quality}`
}, [breakpoint, params])

export default useBannerImageLoader
