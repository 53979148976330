import { Button, ListItem, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'

const LocationSelect = ({
    isListItem, setModalIsOpen, locationLabel
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    const handleClick = () => {
        setModalIsOpen(() => true)
    }

    return (
        isListItem
            ? (
                <ListItem
                    button
                    onClick={handleClick}
                    className={classes.openButton}
                >
                    <span>{translations('shipping.location')}</span>
                    <Typography variant='subtitle2'>
                        {locationLabel}
                    </Typography>
                </ListItem>
            )
            : (
                <Button
                    color='inherit'
                    onClick={handleClick}
                >
                    {locationLabel}
                </Button>
            )
    )
}

LocationSelect.propTypes = {
    isListItem: PropTypes.bool,
    setModalIsOpen: PropTypes.func,
    locationLabel: PropTypes.string
}

LocationSelect.defaultProps = {
    isListItem: false,
    setModalIsOpen: undefined,
    locationLabel: undefined
}

export default LocationSelect
