import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import { Close } from '@material-ui/icons'
import {
    Collapse, IconButton, useMediaQuery
} from '@material-ui/core'
import { useState } from 'react'
import Container from '@material-ui/core/Container'
import Carousel from 'react-multi-carousel'
import { func, shape } from 'prop-types'
import useStyles from './style'
import Link from '../../_Elements/Link'
import theme from '../../../lib/theme'
import { formatSimpleBbcode } from '../../../lib/utils'

const SalesBanner = ({ component }) => {
    const classes = useStyles()

    const obj = component.getParameters()

    const array = [] // [{ text: textField1, link: link1 }, ... , { text: textField5, link: link5 }]

    const isDesktop = useMediaQuery((thm) => thm.breakpoints.up('md'))

    const [bannerVisibility, setBannerVisibility] = useState(true)

    const [bannerFadeIn, setBannerFadeIn] = useState(bannerVisibility)

    const timeout = 400

    Object.keys(component.getParameters())
        .filter((parameter) => (/textField/.test(parameter)))
        .forEach((textField, index) => {
            const text = obj[textField]
            if (text) {
                array.push({ text, link: obj[`link${index + 1}`] })
            }
        })

    const getContent = (item) => (
        <Typography
            component='div'
            variant='body1'
            className={classes.text}
        >
            { formatSimpleBbcode(item.text) }
        </Typography>
    )

    return (
        bannerVisibility && (
            <Collapse in={bannerFadeIn} timeout={timeout}>
                <Toolbar
                    variant='dense'
                    className={classes.banner}
                >
                    <Container maxWidth='xl' className={classes.container}>
                        { array.length > 1
                            ? (
                                <Carousel
                                    containerClass={classes.carouselContainer}
                                    itemClass={classes.carouselItem}
                                    arrows={isDesktop}
                                    infinite
                                    autoPlay
                                    autoPlaySpeed={4000}
                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: theme.breakpoints.values.md
                                            },
                                            items: 1
                                        },
                                        tablet: {
                                            breakpoint: {
                                                max: theme.breakpoints.values.md,
                                                min: theme.breakpoints.values.sm
                                            },
                                            items: 1
                                        },
                                        mobile: {
                                            breakpoint: {
                                                max: theme.breakpoints.values.md,
                                                min: 0
                                            },
                                            items: 1,
                                            partialVisibilityGutter: 30
                                        }
                                    }}
                                >
                                    {array
                                        .filter((item) => Boolean(item.text))
                                        .map((item) => (
                                            item.link
                                                ? (
                                                    <Link href={item.link} key={item.text}>
                                                        {getContent(item)}
                                                    </Link>
                                                ) : (
                                                    <div key={item.text}>
                                                        {getContent(item)}
                                                    </div>
                                                )
                                        ))}
                                </Carousel>
                            )
                            : (
                                getContent(array[0] || {})
                            )}
                    </Container>

                    {isDesktop && (
                        <IconButton
                            size='medium'
                            edge='end'
                            aria-label='close salesbanner'
                            onClick={() => {
                                setBannerFadeIn(false)
                                setTimeout(() => { setBannerVisibility(false) }, timeout + 1)
                            }}
                            color='secondary'
                        >
                            <Close />
                        </IconButton>
                    )}
                </Toolbar>
            </Collapse>
        )
    )
}

SalesBanner.propTypes = {
    component: shape({
        getParameters: func
    })
}

SalesBanner.defaultProps = {
    component: undefined
}

export default SalesBanner
