import { useEffect, useState } from 'react'
import {
    useMediaQuery,
    Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, Typography
} from '@material-ui/core'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useCookies } from 'react-cookie'
import clsx from 'clsx'
import useStyles from './style'
import EnhancedTableHead from './EnhancedTableHead'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import Link from '../../../_Elements/Link'
import headCells from '../../../_Mappings/orderHeadCells'
import theme from '../../../../lib/theme'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const EnhancedTable = () => {
    const translations = useTranslation()
    const cfg = usePageCfg()
    const [user] = useCurrentCustomer()
    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const classes = useStyles()
    dayjs.extend(LocalizedFormat)
    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('orderDate')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [rows, setRows] = useState(undefined)
    const [errorLabel, setErrorLabel] = useState(undefined)
    const useIsMobile = () => useMediaQuery((thm) => thm.breakpoints.down(theme.breakpoints.values.sm))
    const isMobile = useIsMobile()

    const descendingComparator = (a, b, _orderBy) => {
        const aOrderBy = a[_orderBy] === undefined ? '' : a[_orderBy]
        const bOrderBy = b[_orderBy] === undefined ? '' : b[_orderBy]
        if (bOrderBy < aOrderBy) {
            return -1
        }
        if (bOrderBy > aOrderBy) {
            return 1
        }
        return 0
    }

    const getComparator = (_order, _orderBy) => (
        _order === 'desc'
            ? (a, b) => descendingComparator(a, b, _orderBy)
            : (a, b) => -descendingComparator(a, b, _orderBy)
    )

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const _order = comparator(a[0], b[0])
            if (_order !== 0) return _order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }

    const getRows = (customerId) => {
        cfg.httpClient.get(`${cfg.cmsRestserviceUrl}/orderhistory/${customerId}?locale=${location}`)
            .then((response) => {
                const { data } = response
                if (data) {
                    setRows(data.products?.map((product) => {
                        const { shipping } = product
                        delete product.shipping
                        return { ...shipping, ...product }
                    }))
                    setErrorLabel(undefined)
                } else {
                    setErrorLabel('orders.list.error')
                }
            })
            .catch((e) => {
                console.error('error', e)
                setErrorLabel('orders.list.error')
            })
    }

    useEffect(() => {
        if (user) {
            getRows(user.currentCustomer?.id)
        }
    }, [user])

    if (!(rows || errorLabel)) return <CircularProgress />

    const handleRequestSort = (e, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10))
        setPage(0)
    }

    return (
        <Container maxWidth='xl' disableGutters>
            <Typography
                component='h2'
                variant='h5'
                align='center'
                className={classes.orderTitle}
            >
                {translations(errorLabel || 'orders.list.title')}
            </Typography>
            <Typography
                component='p'
                variant='h6'
                className={classes.orderText}
            >
                {translations('orders.list.text')}
            </Typography>
            {rows || errorLabel
                ? (
                    !errorLabel && (
                        <Paper className={classes.paper}>
                            <TableContainer>
                                <Table
                                    className={clsx(isMobile ? classes.tableMobile : classes.tableDesktop)}
                                    aria-labelledby='tableTitle'
                                    size='small'
                                    aria-label='enhanced table'
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`
                                                const {
                                                    purchaseDate,
                                                    orderNumber,
                                                    sku,
                                                    productName,
                                                    quantity,
                                                    price,
                                                    shopDescription,
                                                    type,
                                                    status,
                                                    shippingService,
                                                    trackingNumber,
                                                    trackingLink
                                                } = row

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={orderNumber + sku + purchaseDate}
                                                        className={clsx(isMobile && classes.tableRowMobile)}
                                                    >
                                                        <TableCell align={isMobile ? 'left' : 'right'}>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[0].label)}
                                                            </div>
                                                            {dayjs(purchaseDate).format('DD.MM.YYYY')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[1].label)}
                                                            </div>
                                                            {orderNumber}
                                                        </TableCell>
                                                        <TableCell
                                                            component='th'
                                                            id={labelId}
                                                            scope='row'
                                                        >
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[2].label)}
                                                            </div>
                                                            {sku}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[3].label)}
                                                            </div>
                                                            {productName}
                                                        </TableCell>
                                                        <TableCell align={isMobile ? 'left' : 'center'}>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[4].label)}
                                                            </div>
                                                            {quantity}
                                                        </TableCell>
                                                        <TableCell align={isMobile ? 'left' : 'center'}>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[5].label)}
                                                            </div>
                                                            {price}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[6].label)}
                                                            </div>
                                                            {shopDescription}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[7].label)}
                                                            </div>
                                                            {type}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[8].label)}
                                                            </div>
                                                            {translations(`orders.list.statusLabel.${status}`)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[9].label)}
                                                            </div>
                                                            {shippingService}
                                                        </TableCell>
                                                        <TableCell align={isMobile ? 'left' : 'right'}>
                                                            <div aria-hidden='true' className={classes.labelMobile}>
                                                                {translations(headCells[10].label)}
                                                            </div>
                                                            {trackingLink
                                                                ? (<Link href={trackingLink}>{trackingNumber}</Link>)
                                                                : trackingNumber}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component='div'
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    )
                )
                : <CircularProgress />}
        </Container>
    )
}

export default EnhancedTable
