import {
    useAddressGroup,
    useSetDefaultBillingAddress,
    useSetDefaultShippingAddress
} from '@bloomreach/connector-components-react'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import { Checkbox, CircularProgress } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import RemoveAddressButton from '../RemoveAddressButton/RemoveAddressButton'
import UpdateAddressButton from '../UpadeAddressButton/UpdateAddressButton'
import useStyles from './style'
import AddAddressButton from '../../Checkout/AddAddressButton/AddAddressButton'
import DisplayAddress from '../../Checkout/DisplayAddress/DisplayAddress'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import AddressSelect from '../../Checkout/AddressSelect/AddressSelect'
import CheckOutContext from '../../Checkout/CheckOutContext'
import LoginContext from '../Login/LoginContext'
import useCustomSetOrderDetails from '../../../_Hooks/useCustomCheckout/useCustomSetOrderDetails'

const AddressesOverview = ({ isCheckout }) => {
    const classes = useStyles()
    const [cookies] = useCookies([COOKIE_LOCATION])
    const shippingLocation = cookies[COOKIE_LOCATION]
    const [isSetShippingVisible, setIsSetShippingVisible] = useState(false)
    const [isSetBillingVisible, setIsSetBillingVisible] = useState(false)

    // ACCOUNT
    const [setDefaultShippingAddress] = useSetDefaultShippingAddress()
    const [setDefaultBillingAddress] = useSetDefaultBillingAddress()
    // CHECKOUT
    const [setOrderDetails] = useCustomSetOrderDetails()
    const { user } = useContext(LoginContext)
    const { orderDraft, setOrderDraft } = useContext(CheckOutContext)
    const [addressGroup, loading, error] = useAddressGroup({ skip: !user && user?.anonymous })

    // SET DEFAULT ORDER ADDRESSES
    useEffect(() => {
        if (
            isCheckout
            // FETCHED ?
            && addressGroup && orderDraft
            // SHIPPING NOT SET YET AND DEFAULT POSSIBLE
            && (!orderDraft.shippingAddress.id && (addressGroup.defaultShippingAddress?.id || addressGroup.addresses[0]?.id))
            // BILLING NOT SET YET AND DEFAULT POSSIBLE
            && (!orderDraft.billingAddress.id && (addressGroup.defaultBillingAddress?.id || addressGroup.addresses[0]?.id))
        ) {
            (async () => {
                const billingAddress = orderDraft.billingAddress.id || addressGroup.defaultBillingAddress?.id || addressGroup.addresses[0]?.id
                const shippingAddress = orderDraft.shippingAddress.id || addressGroup.defaultShippingAddress?.id || addressGroup.addresses[0]?.id
                const result = await setOrderDetails({
                    variables: {
                        input: {
                            id: orderDraft.id,
                            email: user.currentCustomer.email,
                            billingAddress: { addressId: billingAddress },
                            shipmentAddress: { addressId: shippingAddress }
                        }
                    }
                })

                if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                    setOrderDraft(result.data.setOrderDetails.order)
                }
            })()
        }
    }, [!!orderDraft, !!addressGroup])

    useEffect(() => {
        if (
            isCheckout
            // FETCHED ?
            && addressGroup && orderDraft
        ) {
            const hasValidAddresses = addressGroup?.addresses?.some(({ id }) => id === orderDraft?.shippingAddress?.id)
                && addressGroup.addresses.some(({ id }) => id === orderDraft.billingAddress?.id)

            if (!hasValidAddresses) {
                (async () => {
                    const billingAddress = addressGroup.defaultBillingAddress?.id || addressGroup.addresses[0]?.id || ''
                    const shippingAddress = addressGroup.defaultShippingAddress?.id || addressGroup.addresses[0]?.id || ''
                    const result = await setOrderDetails({
                        variables: {
                            input: {
                                id: orderDraft.id,
                                email: user.currentCustomer.email,
                                billingAddress: { addressId: billingAddress },
                                shipmentAddress: { addressId: shippingAddress }
                            }
                        }
                    })

                    if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                        setOrderDraft(result.data.setOrderDetails.order)
                    }
                })()
            }
        }
    }, [addressGroup?.addresses])

    const translations = useTranslation()

    if (loading) return <CircularProgress />

    if (error) {
        return <div>{error.message}</div>
    }

    if (!addressGroup || !addressGroup.addresses) {
        return <div>No addresses have been defined.</div>
    }

    const { addresses, defaultBillingAddress, defaultShippingAddress } = addressGroup
    const localizedAddresses = addresses.filter(Boolean).filter((addr) => addr?.country === shippingLocation)

    const billingAddress = isCheckout ? orderDraft.billingAddress : defaultBillingAddress
    const shippingAddress = isCheckout ? orderDraft.shippingAddress : defaultShippingAddress

    const displayDefaultBillingAddress = (shippingAddress?.id === billingAddress?.id ? (
        <>
            <Typography variant='h6'>
                {translations('address:default.billing.address.title')}
            </Typography>
            <Typography className={classes.sameAsShippingText}>
                <Checkbox
                    className={classes.checkbox}
                    color='primary'
                    checked={!isSetBillingVisible}
                    onChange={() => setIsSetBillingVisible(!isSetBillingVisible)}
                />
                {translations('address:same.as.shipping.checkbox')}
            </Typography>
        </>
    ) : (
        <>
            <Typography variant='h6'>
                {translations('address:default.billing.address.title')}
            </Typography>
            {billingAddress && <DisplayAddress address={billingAddress} />}
            <div className={classes.editButtonContainer}>
                <BaseButton
                    className={classes.editButton}
                    size='small'
                    color='primary'
                    onClick={() => setIsSetBillingVisible(!isSetBillingVisible)}
                    data-qa='button-accountAddresses-editDefaultBillingAddresses'
                >
                    {
                        isSetBillingVisible
                            ? translations('address:close.button')
                            : translations('address:edit.button')
                    }
                </BaseButton>
            </div>
        </>
    ))

    return (
        <div className={classes.root}>
            {!isCheckout && (
                <Typography component='h2' variant='h5' align='center' data-qa='text-accountAddresses-addressesOverview'>
                    { translations('address:addresses.overview.title') }
                </Typography>
            ) }
            <div className={classes.contentContainer}>
                <div className={classes.addressContainer}>
                    <div className={classes.addressListContainer}>
                        <List className={classes.list}>
                            {localizedAddresses.map((
                                addr,
                                index
                            ) => (
                                <div key={addr.id} className={clsx(index > 0 && classes.addressListMargin)}>
                                    <DisplayAddress address={addr} />
                                    <div className={classes.editAddressContainer}>
                                        <RemoveAddressButton addressId={addr.id} />
                                        <UpdateAddressButton address={addr} />
                                    </div>
                                </div>
                            ))}
                        </List>
                        <div className={classes.addAddressButtonContainer}>
                            <AddAddressButton data-qa='button-accountAddresses-AddAddressButton' />
                        </div>
                    </div>

                    <div className={classes.defaultAddressesContainer}>
                        {
                            shippingAddress && billingAddress?.country === shippingLocation
                                ? (
                                    <>
                                        <Typography variant='h6'>
                                            {isCheckout
                                                ? translations('address:shipping.address.title')
                                                : translations('address:default.shipping.address.title') }
                                        </Typography>
                                        <DisplayAddress address={shippingAddress} />
                                        <div className={classes.editButtonContainer}>
                                            <BaseButton
                                                size='small'
                                                color='primary'
                                                onClick={() => setIsSetShippingVisible(!isSetShippingVisible)}
                                                data-qa='button-accountAddresses-editShippingAddress'
                                            >
                                                {
                                                    isSetShippingVisible
                                                        ? translations('address:close.button')
                                                        : translations('address:edit.button')
                                                }
                                            </BaseButton>
                                        </div>
                                    </>
                                ) : (
                                    !!addresses.length
                                    && (
                                        <BaseButton
                                            onClick={() => setIsSetShippingVisible(!isSetShippingVisible)}
                                            variant='outlined'
                                            data-qa='button-accountAddresses-setdefaultShippingAddress'
                                        >
                                            {isCheckout
                                                ? translations('address:set.shipping.address.button')
                                                : translations('address:set.default.shipping.address.button') }
                                        </BaseButton>
                                    )
                                )
                        }

                        {isSetShippingVisible
                            && (
                                <AddressSelect
                                    name='shippingAddress'
                                    value={shippingAddress?.id ?? ''}
                                    onChange={async (addressId) => {
                                        if (!isCheckout) {
                                            setDefaultShippingAddress({ addressId })
                                        } else {
                                            const result = await setOrderDetails({
                                                variables: {
                                                    input: {
                                                        id: orderDraft.id,
                                                        email: user.currentCustomer.email,
                                                        shipmentAddress: { addressId }
                                                    }
                                                }
                                            })

                                            if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                                                setOrderDraft(result.data.setOrderDetails.order)
                                            }
                                        }
                                    }}
                                    options={addresses}
                                />
                            )}

                        {
                            billingAddress
                                ? displayDefaultBillingAddress
                                : (
                                    !!addresses.length
                                    && (
                                        <BaseButton
                                            onClick={() => setIsSetBillingVisible(!isSetBillingVisible)}
                                            variant='outlined'
                                            data-qa='button-accountAddresses-setdefaultBillingAddress'
                                        >
                                            {isCheckout
                                                ? translations('address:set.billing.address.button')
                                                : translations('address:set.default.billing.address.button')}

                                        </BaseButton>
                                    )
                                )
                        }

                        {isSetBillingVisible
                            && (
                                <AddressSelect
                                    name='billingAddress'
                                    value={billingAddress?.id ?? ''}
                                    onChange={async (addressId) => {
                                        if (!isCheckout) {
                                            setDefaultBillingAddress({ addressId })
                                        } else {
                                            const result = await setOrderDetails({
                                                variables: {
                                                    input: {
                                                        id: orderDraft.id,
                                                        email: user.currentCustomer.email,
                                                        billingAddress: { addressId }
                                                    }
                                                }
                                            })

                                            if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                                                setOrderDraft(result.data.setOrderDetails.order)
                                            }
                                        }
                                    }}
                                    options={addresses}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

AddressesOverview.propTypes = {
    isCheckout: PropTypes.bool
}

AddressesOverview.defaultProps = {
    isCheckout: false
}

export default AddressesOverview
