import React, {
    useState, useEffect, useContext, useMemo
} from 'react'
import { CircularProgress, Container, Stepper } from '@material-ui/core'
import { func, shape } from 'prop-types'
import { useAddressGroup } from '@bloomreach/connector-components-react'
import { useRouter } from 'next/router'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import BaldininiLogo from '../../../../public/Baldinini-Logo.svg'
import CheckOutContext from '../CheckOutContext'
import LoginContext from '../../Account/Login/LoginContext'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import StepContent from './components/StepContent/StepContent'
import Link from '../../../_Elements/Link'
import useCustomCart from '../../../_Hooks/useCustomCart/useCustomCart'
import useCustomCheckout from '../../../_Hooks/useCustomCheckout/useCustomCheckout'
import { RETURN_URL_UNLOGGED_CHECKOUT } from '../../../_Mappings/urlPart'

// const StepContent = dynamic(
//     () => import('./components/StepContent/StepContent'),
//     { ssr: false }
// )

const connector = 'commercetools'
const CheckOut = ({ page }) => {
    const {
        user,
        loading: userLoading,
        cartId
    } = useContext(LoginContext)
    const [addressGroup] = useAddressGroup()
    const [checkOut, { loading, error }] = useCustomCheckout()

    const [orderDraft, setOrderDraft] = useState()
    const [orderId, setOrderId] = useState()
    const router = useRouter()

    const classes = useStyles()
    const translations = useTranslation()
    const { refetch } = useCustomCart()

    const steps = useMemo(() => [
        { id: 'addresses', label: translations('checkout:addresses') },
        { id: 'payment', label: translations('checkout:payment') },
        { id: 'review', label: translations('checkout:review') },
        { id: 'done', label: translations('checkout:done') }
    ].filter(Boolean), [translations])

    const { query: { checkoutStep = '1' } } = useRouter()
    const checkoutStepInt = +checkoutStep
    const [activeStep, setActiveStep] = useState(checkoutStepInt === 3 ? 2 : checkoutStepInt)

    const handleNext = async () => {
        await refetch()
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = async () => {
        await refetch()
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    useEffect(() => {
        const { path, ...query } = router.query

        if (+checkoutStep !== +activeStep) {
            router.push({
                pathname: '/checkout',
                query: {
                    ...query,
                    checkoutStep: encodeURIComponent(activeStep)
                }
            })
        }
    }, [activeStep])

    useEffect(() => {
        if (+checkoutStep !== +activeStep) {
            setActiveStep(+checkoutStep)
        }
    }, [checkoutStep])

    useEffect(() => {
        // if (!cartId || user?.anonymous) {
        if (!cartId) {
            return
        }
        (async () => {
            const { data } = await checkOut()

            if (data?.checkOut?.code === '200' && data?.checkOut?.order) {
                setOrderDraft(data.checkOut.order)
            }
        })()
        // }, [cartId, user?.anonymous])
    }, [cartId])

    if (!user || userLoading || loading) {
        return <CircularProgress />
    }

    if (error) {
        return <div>{error.message}</div>
    }

    if (!cartId && !orderDraft) {
        return (
            <div>
                {translations('checkout:message.add.items')}
            </div>
        )
    }

    return (
        <Container maxWidth='lg'>
            <CheckOutContext.Provider value={{
                orderDraft,
                setOrderDraft,
                connector,
                orderId,
                setOrderId
            }}
            >
                <Link
                    color='inherit'
                    href={page.getUrl('/')}
                    className={classes.logoContainer}
                >
                    <BaldininiLogo />
                </Link>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep - 1} alternativeLabel>
                        {steps.map(({ id, label }) => (
                            <Step key={id}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <div>
                            <div className={classes.instructions}>
                                <StepContent
                                    page={page}
                                    activeStep={activeStep}
                                    addressGroup={addressGroup}
                                    user={user}
                                    classes={classes}
                                    onNext={handleNext}
                                    onBack={handleBack}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CheckOutContext.Provider>
        </Container>
    )
}

const propTypes = {
    page: shape({
        getButton: func,
        getChannelParameters: func,
        getComponent: func,
        getContent: func,
        getDocument: func,
        getMeta: func,
        getTitle: func,
        getUrl: func,
        getVersion: func,
        getVisitor: func,
        getVisit: func,
        isPreview: func,
        rewriteLinks: func,
        sync: func,
        toJSON: func
    })
}

const defaultProps = {
    page: undefined
}

const CheckoutProxy = (props) => {
    const { user } = useContext(LoginContext)
    const { page } = props
    const router = useRouter()
    const isGuestCheckout = router?.query?.guestCheckout

    const [renderCheckout, setRenderCheckout] = useState(false)

    useEffect(() => {
        if (user?.anonymous && !isGuestCheckout) {
            router.push({
                pathname: page.getUrl('/signin'),
                query: {
                    mergeWithExistingCustomerCart: false,
                    returnUrl: RETURN_URL_UNLOGGED_CHECKOUT
                }
            })
        } else {
            setRenderCheckout(true)
        }
    }, [user?.anonymous])

    if (!renderCheckout) {
        return null
    }

    return (
        <CheckOut {...props} />
    )
}

CheckOut.propTypes = propTypes
CheckOut.defaultProps = defaultProps
CheckoutProxy.propTypes = propTypes
CheckoutProxy.defaultProps = defaultProps

export default CheckoutProxy
