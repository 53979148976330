import { gql } from '@apollo/client'

const ADD_ENTRY_TO_WISHLIST_MUTATION = gql`
    mutation addEntryToWishlist($wishlistId: String!, $itemId: String!, $customAttrs: [AttributeInput!]) {
        addEntryToWishlist(addEntryToWishlistInput: { wishlistId: $wishlistId, itemId: $itemId, customAttrs: $customAttrs }) {
            success
        }
    }
`

export default ADD_ENTRY_TO_WISHLIST_MUTATION
