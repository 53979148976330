import { useLogout as useBloomreachLogout } from '@bloomreach/connector-components-react'
import {useCallback, useContext} from 'react'
import LoginContext from "../_Modules/Account/Login/LoginContext";

const useLogout = () => {
    const [logout, ...rest] = useBloomreachLogout()

    const { setCartId } = useContext(LoginContext)

    const customLogout = useCallback(async () => {
        try {
            const rsp = await logout()
            sessionStorage.removeItem('token')
            setCartId()
            return rsp
        } catch (e) {
            console.error(e)
            return e
        }
    }, [])

    return [customLogout, ...rest]
}

export default useLogout
