import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useChangePassword } from '@bloomreach/connector-components-react'
import { useForm } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useRouter } from 'next/router'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'
import useLogout from '../../../_Hooks/useLogout'

const ChangePassword = ({ page }) => {
    const translations = useTranslation()
    const [error, setError] = useState('')
    const router = useRouter()
    const classes = useStyles()
    const [changePassword, , changePasswordLoading, changePasswordError] = useChangePassword()
    const [logout, logoutLoading, logoutError] = useLogout()
    const { handleSubmit, ...formCtx } = useForm()
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    async function handleFormSubmit({ password, newPassword, newPasswordRepeat }) {
        if (newPassword !== newPasswordRepeat) {
            setError(translations('forms:change.password.match'))
            return
        }

        if (!password || !newPassword) {
            setError(translations('forms:change.password.empty'))
            return
        }

        const changePasswordResult = await changePassword({
            password,
            newPassword
        })

        if (changePasswordResult?.success) {
            const success = await logout()
            if (success) {
                setIsDialogOpen(true)
            }
        }
    }

    if (changePasswordLoading || logoutLoading) {
        return <CircularProgress />
    }

    const errorMessage = error || changePasswordError?.message || logoutError?.message

    return (
        <Container maxWidth='sm'>
            <Typography variant='h4' component='h2' align='center'>
                {translations('account:change.password.title')}
            </Typography>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FormTextField
                    label={translations('forms:password.label')}
                    name='password'
                    type='password'
                    formCtx={formCtx}
                />
                <FormTextField
                    label={translations('forms:new.password.label')}
                    name='newPassword'
                    type='password'
                    formCtx={formCtx}
                />
                <FormTextField
                    label={translations('forms:repeat.new.password.label')}
                    name='newPasswordRepeat'
                    type='password'
                    formCtx={formCtx}
                />
                {errorMessage && (
                    <FormHelperText error>{errorMessage}</FormHelperText>
                )}
                <div className={classes.buttonContainer}>
                    <BaseButton variant='contained' type='submit'>
                        {translations('account:change.password.button')}
                    </BaseButton>
                </div>
            </form>

            <UserFeedbackDialog
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                handleAfterClose={() => {
                    router.push(page.getUrl('/signin'))
                }}
            >
                <Typography variant='body1'>
                    {translations('forms:success.change.password.text')}
                </Typography>
            </UserFeedbackDialog>
        </Container>
    )
}

ChangePassword.propTypes = {
    page: PropTypes.object
}

ChangePassword.defaultProps = {
    page: undefined
}

export default ChangePassword
