import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    flex: {
        display: 'flex'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    registerContainer: {
        padding: theme.spacing(5, 0),
        textAlign: 'center',
        width: '70vw'
    },
    loginTitle: {
        marginTop: theme.spacing(3)
    },
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    forgotPasswordLink: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    form: {
        width: '100%'
    },
    serviceMessage: {
        margin: theme.spacing(1, 0)
    }
}))
