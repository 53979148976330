import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.md,
        padding: theme.spacing(1, 0)
    },
    card: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(3, 3),
        backgroundColor: theme.palette.imgOverlay.main,
        borderRadius: theme.spacing(),
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    media: {
        maxWidth: '10rem',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '20rem'
        }
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2, 0)
        }
    },
    dialogAction: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '& > *': {
            flexBasis: '100%'
        }
    },
    titleWithClose: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonClose: {
        alignSelf: 'flex-start',
        marginRight: theme.spacing(-2),
        marginTop: theme.spacing(-1)
    },
    itemList: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }
}))
