const synchronizeLoyaltyPoints = (customer, pageCfg) => {
    const customerId = { customerUUID: customer.id }

    return fetch(`${pageCfg.cmsRestserviceUrl}/loyalty/sync`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(customerId)
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error('Error:', error)
        })
}

export default synchronizeLoyaltyPoints
