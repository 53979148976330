import { Card } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import Image from 'next/image'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
    bool, func, number, object, string
} from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import ProductLink from '../ProductLink'
import DisplayPrice from '../DisplayPrice/DisplayPrice'
import ColorVariants from '../ProductSize/components/ColorVariants'
import { stringToArr, getVarAttrs } from '../../../../lib/utils'
import PromotionBadges from '../../../_Elements/PromotionBadges/PromotionBadges'
import WishlistButton from '../../../_Elements/Wishlist/WishlistButton/WishlistButton'
import { useProductDetail } from '@bloomreach/connector-components-react'
import CircularProgress from '@material-ui/core/CircularProgress'

const RelatedProductCard = ({
    itemId, gridLayout, position, itemIsVariant, onClick, connector, brUid2, storeKey
}) => {

    const customAttrFields = ['brand', 'thumb_image']
    const customVariantAttrFields = ['sku_thumb_images', 'sku_swatch_images']

    const [item, loading, error] = useProductDetail({
        connector,
        customAttrFields,
        customVariantAttrFields,
        slug: itemId.toLowerCase().trim(),
        brUid2,
        storeKey
    })
    const classes = useStyles({ gridLayout })

    const productGridImageLoader = ({ src, width }) => src

    const { colourVariants } = item ? getVarAttrs(itemIsVariant ? item : item.variants[0]) : ''
    const colorVariantsList = colourVariants ? stringToArr(colourVariants) : null

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        console.error(error.message)
    }

    return (
        <>
        { item ?
        (<Card
            onClick={onClick}
            key={item.itemId.id}
            className={classes.card}
            component={ProductLink}
            item={item}
            position={position}
        >
            {item.imageSet?.original?.link?.href && (
                <CardMedia className={classes.imgContainer}>
                    <Image
                        src={item.imageSet.original.link.href}
                        height={480}
                        width={480}
                        loader={productGridImageLoader}
                        priority
                        className={clsx(classes.bounce, classes.image)}
                        alt= {item.displayName + ' image'}
                        title= {item.displayName}
                    />
                    <div className={clsx(classes.imgOverlay)} />

                    <div
                        className={classes.topInterferer}
                    >
                        <PromotionBadges productVariant={itemIsVariant ? item : item.variants[0]} />

                        <WishlistButton
                            product={item}
                            className={classes.wishlistIcon}
                        />
                    </div>
                </CardMedia>
            )}
            <CardContent className={classes.cardContent}>
                <Typography variant='subtitle1' className={classes.productName} component='h2'>
                    {item.displayName}
                </Typography>
                <DisplayPrice item={item} />
                { !itemIsVariant && (
                    <ColorVariants colorVariants={colorVariantsList} />
                )}
            </CardContent>
        </Card>)
        : null
        }
        </>
    )
}

RelatedProductCard.propTypes = {
    itemId: string.isRequired,
    gridLayout: string,
    position: number,
    itemIsVariant: bool,
    onClick: func
}

RelatedProductCard.defaultProps = {
    gridLayout: undefined,
    position: undefined,
    itemIsVariant: false,
    onClick: undefined
}

export default RelatedProductCard
