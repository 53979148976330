import { gql } from '@apollo/client'

const CHECKOUT_MUTATION = gql`
    mutation CheckOut($cartId: String!, $cartHint: CartHintInput) {
        checkOut(cartId: $cartId, cartHint: $cartHint) {
            code
            success
            message
            order {
                ...CustomOrderDraftFragment
            }
        }
    }
    
    fragment CustomOrderDraftFragment on Order {
        id
        status
        draft
        creationDate
        shipMethod
        email
        shipAmount {
            ...MoneyAmountFragment
        }
        shippingAddress {
            ...AddressFragment
        }
        billingAddress {
            ...AddressFragment
        }
        totalAmount {
            ...MoneyAmountFragment
        }
    }
    
    fragment AddressFragment on Address {
        id
        firstName
        middleName
        lastName
        streetAddress
        additionalStreetInfo
        company
        postalCode
        city
        state
        country
        billingAddress
        shippingAddress
        readOnly
        phone
    }
    
    fragment MoneyAmountFragment on MoneyAmount {
        amount
        currency
        displayValue
        channelId
    }
`

export default CHECKOUT_MUTATION
